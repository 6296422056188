import dayjs from "dayjs";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { showCaseProduction } from "../../environment/environment";
import { useEndpoints } from "../../hooks/useEndpoints";
import { useI18n } from "../../hooks/useI18n";
import { IGeneralInformation } from "../../models/interface";
import { RealEstateCommercial } from "../../models/RealeEstateCommercial";
import { RealEstate } from "../../models/RealEstate";
import { formatCurrency } from "../../Utils/fuctions";
import { getStorageItem } from "../../Utils/localStorage";
import "./GeneralInformation.scss";

const GeneralInformation: FC<IGeneralInformation> = ({ realEstate }) => {
  const navigate = useNavigate();
  const {
    updatedAt,
    createdAt,
    approvalStatus,
    mediation,
    videoUrl,
    originalConsultant,
    visibleOnShowcase,
    _id,
    casafariId,
    casafariContactInfo,
    casafariDescription,
    externalListingUrl,
    priceHistory,
  } = realEstate;
  const { t } = useI18n();

  const [currentConsultantId] = useState(getStorageItem("me")?._id);
  const [urlInternal, setUrlInternal] = useState("");
  const [urlExternal, setUrlExternal] = useState("");

  const { getAgency } = useEndpoints();

  const fetchAgencyUrlShowCase = async () => {
    const { data } = await getAgency(getStorageItem("me")!.agency._id);

    const regex = /(https?:\/\/[^/]+)/i;
    const matchesInternal = data.settings.showcase.internalPropertiesUrl.match(
      regex
    );
    const matchesExternal = data.settings.showcase.externalPropertiesUrl.match(
      regex
    );
    if (matchesInternal && matchesInternal.length > 0) {
      setUrlInternal(matchesInternal[0]);
    }
    if (matchesExternal && matchesExternal.length > 0) {
      setUrlExternal(matchesExternal[0]);
    }
  };
  useEffect(() => {
    fetchAgencyUrlShowCase();
  }, []);

  const goToShowCase = () => {
    window.open(`${urlInternal}/real-estates/${_id}`, "_blank")?.focus();
  };
  const goToShowCaseExternal = () => {
    window
      .open(
        `${urlExternal}/list-realestates/${_id}?consultant=${currentConsultantId}`,
        "_blank"
      )
      ?.focus();
  };
  const goToSiteExt = () => {
    window.open(externalListingUrl, "_blank");
  };

  const infoInternal = (
    <div className="container-general-information">
      <p className="title mt-0">
        {t("realEstate.forms.compactRealEstate.generalInformation.title")}
      </p>
      <p className="label">{`${t(
        "realEstate.forms.compactRealEstate.generalInformation.lastUpdate"
      )}: ${dayjs(updatedAt).format("YYYY-MM-DD HH:mm")}`}</p>
      <p className="sub-title mt-4">{`${t(
        "realEstate.forms.compactRealEstate.generalInformation.origianlConsultant"
      )}: ${originalConsultant ? originalConsultant.surname : ""} ${
        originalConsultant ? originalConsultant.name : ""
      }`}</p>
      <p className="sub-title mt-4">{`${t(
        "realEstate.forms.compactRealEstate.generalInformation.state"
      )} ${t(`realEstate.approvalStatus.${approvalStatus}`)}`}</p>
      <p className="sub-title mt-4">{`${t(
        "realEstate.forms.compactRealEstate.generalInformation.dated"
      )}:  ${dayjs(createdAt).format("YYYY-MM-DD")}`}</p>
      <p className="sub-title mt-4">
        {t(
          "realEstate.forms.compactRealEstate.generalInformation.exclusiveAssignment"
        )}
      </p>
      <p className="label mt-2">{`${t(
        "realEstate.forms.compactRealEstate.generalInformation.startOn"
      )} ${mediation?.from ?? "-"} ${t(
        "realEstate.forms.compactRealEstate.generalInformation.endsOn"
      )} ${mediation?.to || "-"}`}</p>
      <p className="sub-title mt-4">
        {t("realEstate.forms.compactRealEstate.generalInformation.note")}
      </p>
      {videoUrl && (
        <p className="label">
          {t("realEstate.forms.compactRealEstate.generalInformation.video")}{" "}
          <a href={videoUrl}>{videoUrl}</a>
        </p>
      )}
      {visibleOnShowcase && urlInternal.length ? (
        <p
          className="sub-title mt-4 cursor-pointer"
          onClick={() => goToShowCase()}
        >
          {t(
            "realEstate.forms.compactRealEstate.generalInformation.goShowCase"
          )}{" "}
        </p>
      ):<></>}
    </div>
  );
  const infoExternal = (
    <div className="container-general-information">
      <p className="title mt-0">
        {t("realEstate.forms.compactRealEstate.generalInformation.title")}
      </p>
      {casafariContactInfo?.agency ? (
        <>
          <p className="label mt-4">
            {t(
              "realEstate.forms.compactRealEstate.generalInformation.agencyOrPrivate"
            )}
            :
          </p>
          <p className="sub-title mt-1">{casafariContactInfo?.agency}</p>
        </>
      ) : (
        <></>
      )}
      {casafariContactInfo?.agent ? (
        <>
          <p className="label mt-4">Agente:</p>
          <p className="sub-title mt-1">{casafariContactInfo?.agent}</p>
        </>
      ) : (
        <></>
      )}
      {casafariContactInfo?.email ? (
        <>
          <p className="label mt-4">Email:</p>
          <p className="sub-title mt-1">{casafariContactInfo?.email}</p>
        </>
      ) : (
        <></>
      )}
      {casafariContactInfo?.phone ? (
        <>
          <p className="label mt-4">Telefono:</p>
          <p className="sub-title mt-1">{casafariContactInfo?.phone}</p>
        </>
      ) : (
        <></>
      )}
      {externalListingUrl ? (
        <div className="mt-4">
          <span onClick={() => goToSiteExt()} className="sub-title pointer">
            {t(
              "realEstate.forms.compactRealEstate.generalInformation.goToSite"
            )}
          </span>
        </div>
      ) : (
        <></>
      )}
      {urlExternal.length ? (
        <p
          className="sub-title mt-4 cursor-pointer"
          onClick={() => goToShowCaseExternal()}
        >
          {t(
            "realEstate.forms.compactRealEstate.generalInformation.goShowCase"
          )}{" "}
        </p>
      ):<></>}
    </div>
  );

  const historyPrice = (
    <div className="container-general-information mt-2">
      <p className="title mt-0 mb-3">
        {" "}
        {t(
          "realEstate.forms.compactRealEstate.generalInformation.historyPrice"
        )}
        :
      </p>
      {priceHistory &&
        priceHistory.map(({ price, date }) => (
          <p className="sub-title mt-2">
            {`€ ${formatCurrency("EUR", price, false)}  (${dayjs(date).format(
              "DD/MM/YYYY"
            )})`}
          </p>
        ))}
    </div>
  );
  return (
    <>
      {casafariId ? infoExternal : infoInternal}
      {historyPrice}
    </>
  );
};

export default GeneralInformation;
