import { Box, useColorMode } from "@chakra-ui/react";
import React, { FC } from "react";
import "./PaginationPageButton.scss";

interface IPaginationPageButton {
  label: JSX.Element | number;
  onClick?: () => void;
  disabled?: boolean;
  activePage?: number;
}

const PaginationPageButton: FC<IPaginationPageButton> = ({
  label,
  onClick,
  disabled,
  activePage,
}) => {
  const { colorMode } = useColorMode();
  return (
    <Box
      as="button"
      onClick={onClick}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      disabled={Boolean(disabled)}
      width={{ base: "9vw", sm: 10 }}
      height={{ base: "9vw", sm: 10 }}
      lineHeight="1.2"
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      px="8px"
      mx={1}
      borderRadius={50}
      fontSize={{ base: "3vw", sm: "14px" }}
      fontWeight="semibold"
      bg={
        label === activePage
          ? colorMode === "light"
            ? "#cfcfcf"
            : "#454545"
          : '"transparent"'
      }
      color={colorMode === "light" ? "#3A3878" : "white"}
      _disabled={{
        userSelect: "none",
        touchAction: "none",
        color: "grey",
        _hover: {
          bg: "transparent",
          cursor: "inherit",
        },
      }}
      _hover={{ bg: colorMode === "light" ? "#e1e1e1" : "#454545" }}
      _active={{
        bg: colorMode === "light" ? "#bfbfbf" : "#565656",
        transform: "scale(0.98)",
        borderColor: "#bec3c9",
      }}
      _focus={{
        boxShadow:
          colorMode === "light"
            ? "0 0 1px 2px #cfcfcf, 0 1px 1px #cfcfcf"
            : "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
      }}
    >
      {label}
    </Box>
  );
};

export default PaginationPageButton;
