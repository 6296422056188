import {
  AVAILABILITY,
  CONSERVATION_STATUS,
  FURNITURE_STATUS,
  RealEstate,
} from "./RealEstate";

export enum COMMERCIAL_PROPERTY_TYPE {
  FARMHOUSE = "farmhouse", // agriturismo,
  HOTEL = "hotel",
  COMMERCIAL_ACTIVITY = "commercialActivity",
  FARM = "farm", // azienda agricola
  BAR = "bar",
  CAMPING_AREA = "campingArea",
  CHALET = "chalet",
  COMMERCIAL_OTHER = "commercialOther",
  DANCE_CLUB = "danceClub", // discoteca
  LABORATORY = "laboratory",
  COMMERCIAL_LOCAL = "commercialLocal", // locale commerciale
  STORE = "store",
  RESTAURANT = "restaurant",
  SHOW_ROOM = "showRoom",
  BUILDING = "building", // stabile / palazzo
  OFFICE = "office",
  NOT_PROVIDED = "notProvided",
  DEPOSIT = "deposit",
}
export enum ROOM_SEPARATOR {
  WALLS = "walls",
  OPEN_SPACE = "openSpace",
  PANELS = "panels",
  NOT_PROVIDED = "notProvided",
}
export enum CONTEXT {
  IN_MALL = "inMall", // in un centro commerciale
  OFFICE_COMPLEX = "officeComplex", // complesso uffici,
  MEZZANINE = "mezzanine",
  STREET = "street", // sulla strada
  OTHER = "other",
  NOT_PROVIDED = "notProvided",
}
export interface AverageRevenue {
  from?: number;
  to?: number;
}
export enum WEEKLY_REST {
  SATURDAY = "saturday",
  SUNDAY = "sunday",
  NOT_PROVIDED = "notProvided",
}
export enum FEE {
  YEARLY = "yearly",
  BIMONTHLY = "bimonthly", // bimestrale
  MONTHLY = "monthly",
  WEEKLY = "weekly",
  SEASONAL = "seasonal",
  NOT_PROVIDED = "notProvided",
}
export enum COMMERCIAL_INFO_ENTITY {
  ALARM = "alarm",
  GENERATOR = "generator", // gruppo elettrogeno
  SEA_VIEW = "seaView",
  ELECTRICAL_SYSTEM = "electricalSystem",
  ANTIFIRE = "antifire",
  REFRIGERATOR = "refrigerator",
  AIR_CONDITIONING = "airConditioning",
  LAKE_VIEW = "lakeView",
  NOT_PROVIDED = "notProvided",
}
export interface CommercialAdditionalDetails {
  roomSeparator?: ROOM_SEPARATOR;
  context?: CONTEXT;
  averageRevenue?: AverageRevenue; ////
  weeklyRest?: WEEKLY_REST;
  allowedActivities?: string;
  activitiesToo?: boolean; // anche attività
  activityValue?: number;
  fee?: FEE; // canone
  allowsManagement?: boolean; // possibilità di dare in gestione
  conservationStatus: CONSERVATION_STATUS;
  alarm: AVAILABILITY;
  reception: AVAILABILITY;
  intercom: AVAILABILITY;
  furniture: FURNITURE_STATUS;
  furnishedDescription: string;
}

export type RealEstateCommercial = RealEstate & {
  additionalDetails: CommercialAdditionalDetails;
  squareMetersCovered?: number; // metri quadri coperti - commerciale
  squareMetersUncovered?: number; // metri quadri scoperti - commerciale
  squareMetersCoverable?: number; // metri quadri copribili - commerciale
  showcaseExposition?: string; // esposizione vetrine - commerciale
  showcases?: number; // vetrine - commerciale
};
