import { MenuItem } from "@chakra-ui/react";
import { NestedKeys } from "advanced-types";
import { FC, useMemo } from "react";
import { Translation } from "../../../../assets/i18n/locals/en";
import { useI18n } from "../../../../hooks/useI18n";

interface IOptions {
  options:
    | {
        label: NestedKeys<Translation, "."> | "";
        value: string;
        checked?: boolean | undefined;
      }[]
    | undefined;
  handleSelect: (label: string, value: string) => void;
  valueForm: string;
}

export const Options: FC<IOptions> = ({ options, handleSelect, valueForm }) => {
  const { t } = useI18n();

  const option = (label: NestedKeys<Translation, "."> | "", value: string) => {
    return (
      <div className="menu-flex-custom-select">
        <span className="capitalize-first-letter">{t(label)}</span>
        {valueForm === value && <i className="icon icon-checkmark" />}
      </div>
    );
  };

  const optionsMemo = useMemo(() => {
    return (
      <>
        {" "}
        {options?.map(({ label, value }, i) => {
          return (
            <MenuItem
              key={i}
              _hover={{ bg: "transparent" }}
              p={0}
              _focus={{ bg: "transparent" }}
              onClick={() => handleSelect(label, value)}
              className="menu-item"
            >
              {option(label, value)}
            </MenuItem>
          );
        })}
      </>
    );
    // eslint-disable-next-line
  }, [options, valueForm]);
  return <>{optionsMemo}</>;
};
