import { FC, lazy, useEffect, useLayoutEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Loader from "./shared/components/Loader/Loader";
import { Suspense } from "react";
import Consultants from "./pages/Consultants";
import { useInterceptor } from "./hooks/useInterceptor";
import { getStorageItem } from "./Utils/localStorage";
import i18n from "./assets/i18n/i18n";
import Home from "./pages/Home";
import Auth from "./shared/components/Auth/Auth";
import { useSelector } from "react-redux";
//wallpapers
import loginWallPaper from "./assets/img/CopertinaKleos.jpg";
import CreateCustomer from "./pages/CreateCustomer";
import errorWallpaper from "./assets/img/error.jpg";
import StepperForm, {
  IStepperForm,
  IStepperFormLand,
} from "./shared/components/CustomForm/StepperForm/StepperForm";
import MobilePage from "./shared/components/MobilePage/MobilePage";
import Images from "./components/RealEstates/Forms/Images/Images";
import DemandCustomer from "./pages/DemandCustomer/DemandCustomer";
import DemandsCustomers from "./pages/DemandsCustomers/DemandsCustomers";
import ResultDemandCustomer from "./pages/ResultDemandCustomer/ResultDemandCustomer";
import Certifications from "./components/RealEstates/Forms/Certifications/Certifications";
import Description from "./components/RealEstates/Forms/Description/Description";
import OtherInfo from "./components/RealEstates/Forms/OtherInfo/OtherInfo";
import Price from "./components/RealEstates/Forms/Price/Price";
import ZoneCharacteristics from "./components/RealEstates/Forms/ZoneCharacteristics/ZoneCharacteristics";
import Location from "./components/RealEstates/Forms/Location/Location";
import { RoutesRouter } from "./models/Routes";
import CompactRealEstate from "./pages/CompactRealEstate/CompactRealEstate";
import dayjs from "dayjs";
import { useI18n } from "./hooks/useI18n";
import MatchesRealEstate from "./pages/MatchesRealEstate/MatchesRealEstate";
import { RootState } from "./redux/reducers";
require("dayjs/locale/it");
require("dayjs/locale/en");

const Login = lazy(() => import("./pages/Login/Login"));
const Agency = lazy(() => import("./pages/Agency/Agency"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const PageNotFound = lazy(() => import("./pages/PageNotFound"));
const Customers = lazy(() => import("./pages/Customers"));
const RealStates = lazy(() => import("./pages/RealEstates/RealEstates"));
const General = lazy(
  () => import("./components/RealEstates/Forms/General/General")
);
const CreateConsultant = lazy(() => import("./pages/CreateConsultant"));
const Agenda = lazy(() => import("./shared/components/Agenda/Agenda"));
const Dashboard = lazy(() => import("./shared/components/Dashboard/Dashboard"));
const Matches = lazy(() => import("./pages/Matches/Matches"));
const Messages = lazy(() => import("./pages/Messages/Messages"));

let calledInt = false;

export const stages: IStepperForm["stages"] = [
  {
    label: "realEstate.forms.stepperForm.labels.general",
    link: "general",
  },
  {
    label: "realEstate.forms.stepperForm.labels.price",
    link: "price",
  },
  {
    label: "realEstate.forms.stepperForm.labels.location",
    link: "location",
  },
  {
    label: "realEstate.forms.stepperForm.labels.description",
    link: "description",
  },
  {
    label: "realEstate.forms.stepperForm.labels.certifications",
    link: "certifications",
  },
  {
    label: "realEstate.forms.stepperForm.labels.otherInfo",
    link: "other-info",
  },
  {
    label: "realEstate.forms.stepperForm.labels.zoneCharacteristics",
    link: "zone-characteristics",
  },
  {
    label: "realEstate.forms.stepperForm.labels.images",
    link: "images",
  },
];

const stagesLand: IStepperFormLand["stages"] = [
  {
    label: "realEstate.forms.stepperForm.labels.general",
    link: "general",
  },
  {
    label: "realEstate.forms.stepperForm.labels.price",
    link: "price",
  },
  {
    label: "realEstate.forms.stepperForm.labels.location",
    link: "location",
  },
  {
    label: "realEstate.forms.stepperForm.labels.description",
    link: "description",
  },
  {
    label: "realEstate.forms.stepperForm.labels.otherInfo",
    link: "other-info",
  },
  {
    label: "realEstate.forms.stepperForm.labels.zoneCharacteristics",
    link: "zone-characteristics",
  },
  {
    label: "realEstate.forms.stepperForm.labels.images",
    link: "images",
  },
];

export const App = () => {
  const realEstateType = useSelector(
    ({ realEstate }: RootState) => realEstate.putRealEstate?.type
  );
  const { language, changeLanguage: changeLanguageState } = useI18n();
  const navigate = useNavigate();
  const { jwtInterceptor } = useInterceptor();

  const ProtectedRoute: FC = ({ children }) => {
    return !getStorageItem("token")?.accessToken ? (
      <Navigate to="/login" replace />
    ) : (
      <>{children}</>
    );
  };

  useEffect(() => {
    i18n.changeLanguage(language);
    dayjs.locale(language);
  }, [language]);

  useLayoutEffect(() => {
    changeLanguageState(getStorageItem("language") || "it");
    if (!calledInt) {
      jwtInterceptor(navigate);
      calledInt = true;
    }
    return () => {};
    // eslint-disable-next-line
  }, []);

  const RedirectRootPath = () => {
    const me = getStorageItem("me");
    const token = getStorageItem("token");
    if (!me || !token) return <Navigate to={RoutesRouter.login} />;

    return (
      <Navigate
        to={
          RoutesRouter[
            me.role === "ADMIN" || me.role === "SUPER_ADMIN"
              ? "consultants"
              : "customers"
          ]
        }
      />
    );
  };

  return (
    <Suspense fallback={<Loader />}>
      <MobilePage>
        <Routes>
          {/* Empty route */}
          <Route path="" element={<RedirectRootPath />} />
          {/* Home */}
          <Route path="/home" element={<Home />}>
            <Route
              path="customers"
              element={
                <ProtectedRoute>
                  <Customers />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="agenda"
              element={
                <ProtectedRoute>
                  <Agenda />
                </ProtectedRoute>
              }
            />
            <Route
              path="agency/edit"
              element={
                <ProtectedRoute>
                  <Agency />
                </ProtectedRoute>
              }
            />

            <Route
              path="realestates"
              element={
                <ProtectedRoute>
                  <RealStates />
                </ProtectedRoute>
              }
            />
            <Route
              path="realestates/id"
              element={
                <ProtectedRoute>
                  <RealStates />
                </ProtectedRoute>
              }
            />
            <Route
              path="demands"
              element={
                <ProtectedRoute>
                  <DemandsCustomers />
                </ProtectedRoute>
              }
            />
            <Route
              path="consultants"
              element={
                <ProtectedRoute>
                  <Consultants />
                </ProtectedRoute>
              }
            />
            <Route
              path="matches"
              element={
                <ProtectedRoute>
                  <Matches />
                </ProtectedRoute>
              }
            />
            <Route
              path="messages"
              element={
                <ProtectedRoute>
                  <Messages />
                </ProtectedRoute>
              }
            />

            <Route path="customers/edit" element={<CreateCustomer />} />

            <Route
              path="customers/:id/demand-customer/:idDemand/edit"
              element={
                <ProtectedRoute>
                  <DemandCustomer />
                </ProtectedRoute>
              }
            />
            <Route
              path="customers/:id/demand-customer"
              element={
                <ProtectedRoute>
                  <DemandsCustomers />
                </ProtectedRoute>
              }
            />
            <Route
              path="customers/:idCustomer/demand-customer/:id/results"
              element={
                <ProtectedRoute>
                  <ResultDemandCustomer type="demands" />
                </ProtectedRoute>
              }
            />
            <Route
              path="matches/:idCustomer/demand-customer/:id/results"
              element={
                <ProtectedRoute>
                  <ResultDemandCustomer type="matches" />
                </ProtectedRoute>
              }
            />
            <Route
              path="matches/:idRealEstate/results"
              element={
                <ProtectedRoute>
                  <MatchesRealEstate />
                </ProtectedRoute>
              }
            ></Route>

            <Route path="customers/:id/edit" element={<CreateCustomer />} />

            <Route path="consultants/edit" element={<CreateConsultant />} />
            <Route path="consultants/:id/edit" element={<CreateConsultant />} />
            <Route path="realestates/:id" element={<CompactRealEstate />} />
            <Route
              path="realestates/:id/edit"
              element={
                <StepperForm
                  stages={realEstateType === "land" ? stagesLand : stages}
                />
              }
            >
              {/* EDIT */}
              <Route path="" element={<Navigate to={"general"} />} />
              <Route path="general" element={<General />} />
              <Route path="price" element={<Price />} />
              <Route path="location" element={<Location />} />
              <Route path="description" element={<Description />} />
              <Route path="other-info" element={<OtherInfo />} />
              <Route path="certifications" element={<Certifications />} />
              <Route
                path="zone-characteristics"
                element={<ZoneCharacteristics />}
              />
              <Route path="images" element={<Images />} />
            </Route>
          </Route>
          {/* Other routes */}
          <Route
            path="/login"
            element={<Auth wallpaper={loginWallPaper} children={<Login />} />}
          />
          <Route
            path="/reset-password"
            element={
              <Auth wallpaper={loginWallPaper} children={<ResetPassword />} />
            }
          />
          <Route
            path="/page-not-found"
            element={
              <Auth
                wallpaper={errorWallpaper}
                children={<PageNotFound valuePageBack={-2} />}
              />
            }
          />
          <Route
            path="*"
            element={
              <Auth wallpaper={errorWallpaper} children={<PageNotFound />} />
            }
          />
        </Routes>
      </MobilePage>
    </Suspense>
  );
};

export default App;
