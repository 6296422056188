import React, { FC, Fragment, useEffect, useState } from "react";
import { Form, FormReturnType } from "../../../../hooks/useForm";
import { Field } from "../../../../models/CustomForm";
import {
  RoomPertinenceDetails,
  ROOM_PERTINENCE,
  ROOM_PERTINENCE_TYPE,
} from "../../../../models/RealEstate";
import { Nullable } from "../../../../typings/KeysFromObject";
import { getNestedValueFromString } from "../../../../Utils/fuctions";
import { Card } from "./Card";
import "./RoomPertinenceDetailsInput.scss";

interface ITextInput {
  form: Partial<Form<any>>;
  setForm: any;
  data: Field<any>;
  handleError: FormReturnType<any>["handleError"];
  error: string | undefined;
}

const RoomPertinenceDetailsInput: FC<ITextInput> = ({
  handleError,
  data: {
    isRequired,
    label,
    type,
    column = 4,
    roomPertinenceDetailsOptions,
    offset = 0,
    key,
    placeholder,
    maxColumn,
  },
  form,
  setForm,
  error,
}) => {
  //here should put absent in the description's inputs where need put absent as default
  const [selectedItems, setselectedItems] = useState<
    Nullable<RoomPertinenceDetails>[]
  >(() => {
    let selectedRooms = getNestedValueFromString<
      Nullable<RoomPertinenceDetails>[]
    >(key, form);
    [
      ROOM_PERTINENCE.CAMPER_PARKING_SPACES,
      ROOM_PERTINENCE.BOAT_PARKING_SPACES,
      ROOM_PERTINENCE.CELLAR,
      ROOM_PERTINENCE.WAREHOUSE,
      ROOM_PERTINENCE.DEPOT,
      ROOM_PERTINENCE.LOFT,
      ROOM_PERTINENCE.ATTIC,
      ROOM_PERTINENCE.SHED,
      ROOM_PERTINENCE.STABLE,
    ].forEach((nameRoom) => {
      const room = selectedRooms.find(({ name }) => name === nameRoom);
      if (!room) {
        selectedRooms.push({
          name: nameRoom,
          equipment: undefined,
          type: ROOM_PERTINENCE_TYPE.ABSENT,
          count: 0,
          squareMeters: undefined,
          dimensions: "",
        });
      }
    });

    roomPertinenceDetailsOptions!.forEach(({ name: { value } }) => {
      const room = selectedRooms.find(({ name }) => value);
      if (!room) {
        selectedRooms.push({
          name: value as ROOM_PERTINENCE,
          equipment: undefined,
          type: undefined,
          count: 0,
          squareMeters: undefined,
          dimensions: "",
        });
      } else {
        !room.count && (room.count = 0);
      }
    });

    return selectedRooms;
  });

  useEffect(() => {
    setForm(key, selectedItems);
    // eslint-disable-next-line
  }, [selectedItems]);

  const handleSelect = (
    value: any,
    index: number,
    type: "type" | "equipment" | "count"
  ) => {
    const { name } = roomPertinenceDetailsOptions![index];
    setselectedItems((prev) => {
      const item = prev.find(({ name: namePrev }) => namePrev === name.value);
      if (!prev.length || !item) {
        return [
          ...prev,
          {
            name: name.value,
            equipment: undefined,
            type: undefined,
            count: undefined,
            squareMeters: undefined,
            dimensions: "",
            [type]: value,
          } as RoomPertinenceDetails,
        ];
      } else {
        item[type] = value;
        return [...prev];
      }
    });
  };

  const handleChangeText = (
    value: any,
    index: number,
    type: "dimensions" | "squareMeters"
  ) => {
    const { name } = roomPertinenceDetailsOptions![index];
    setselectedItems((prev) => {
      const item = prev.find(({ name: namePrev }) => namePrev === name.value);

      if (!prev.length || !item) {
        return [
          ...prev,
          {
            name: name.value,
            count: undefined,
            equipment: undefined,
            squareMeters: undefined,
            type: undefined,
            dimensions: undefined,
            [type]: value,
          } as RoomPertinenceDetails,
        ];
      } else {
        item[type] =
          type === "squareMeters" ? Number(value) || undefined : value;
        return [...prev];
      }
    });
  };
  return (
    <>
      <div className={`col-lg-${column}`}>
        <div className={`form-group offset-lg-${offset}`}>
          <label className="form-control-label" htmlFor="name">
            {`${label} ${isRequired ? "*" : ""}`}
          </label>
          <div className="row">
            {roomPertinenceDetailsOptions!.map((data, i) => {
              const currentItem = form.roomsPertinencesDetails.find(
                ({ name }: { name: string }) => name === data.name.value
              );
              return (
                <Fragment key={i}>
                  <Card
                    offset={offset}
                    data={data}
                    index={i}
                    form={JSON.stringify(currentItem)}
                    error={error}
                    handleChangeText={handleChangeText}
                    handleError={handleError}
                    selectedItems={selectedItems}
                    keyForm={key}
                    handleSelect={handleSelect}
                  ></Card>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
      {maxColumn && <div className={`col-lg-${maxColumn}`} />}
    </>
  );
};

export default RoomPertinenceDetailsInput;
