import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useI18n } from "../../hooks/useI18n";
import CardRealEstates from "../../shared/components/CardRealEstates/CardRealEstates";
import EmptyResourcesTable from "../../shared/components/EmptyResourcesTable/EmptyResourcesTable";
import "./ResultDemandCustomer.scss";
import { Box } from "@chakra-ui/react";
import Pagination from "../../shared/components/Pagination/Pagination";
import useResultDemandCustomer from "../../hooks/useResultDemandCustomer";
import { RoutesRouter } from "../../models/Routes";
import CustomSelect from "../../shared/components/CustomSelect/CustomSelect";
import Button from "../../shared/components/Button/Button";
import getClasses from "../../Utils/getClasses";
import { useEndpoints } from "../../hooks/useEndpoints";
import { StatusDemand } from "../../models/ResultRealEstateDemand";
import { useToastAlert } from "../../shared/components/ToastAlert/ToastAlert";
import { DURATION_TOAST } from "../../Utils/costants";
import { NestedKeys } from "advanced-types";
import { Translation } from "../../assets/i18n/locals/en";

export interface IResultDemandCustomer {
  type: "demands" | "matches";
}
export type IntExt = "internal" | "external";
export type typeExternal = "all" | "agency" | "private";
export type Collaboration = "yes" | "no" | "onHold" | "null";
export type Negotiation = "yes" | "no" | "null";
const ResultDemandCustomer: FC<IResultDemandCustomer> = ({ type }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useI18n();
  const { id } = useParams();
  const containerTableRef = useRef<HTMLDivElement>(null);

  const [statusList] = useState<(StatusDemand | string)[]>([
    "active",
    "inactive",
    "sent",
  ]);
  const [listExInt] = useState(["internal", "external"]);
  const [listTypeExternal, setListTypeExternal] = useState([
    "all",
    "agency",
    "private",
  ]);

  const [listCollaboration, setListCollaboration] = useState([
    "yes",
    "no",
    "onHold",
    "null",
  ]);

  const [listNegotiation, setListNegotiation] = useState(["yes", "no", "null"]);

  useEffect(() => {
    if (type === "matches" && !listTypeExternal.includes("auction")) {
      setListTypeExternal((prevList) => [...prevList, "auction"]);
    } else if (type !== "matches" && listTypeExternal.includes("auction")) {
      setListTypeExternal((prevList) =>
        prevList.filter((item) => item !== "auction")
      );
    }
  }, [type]);

  const [status, setStatus] = useState<{ label: string; value: string } | null>(
    {
      label: t(
        `statusDemand.${searchParams.get("status") || "active"}` as NestedKeys<
          Translation,
          "."
        >
      ),
      value: searchParams.get("status") || "active",
    }
  );
  const [statusIntEx, setStatusIntEx] = useState<{
    label: string;
    value: IntExt;
  } | null>({
    label: t(
      `demandSource.${searchParams.get("source") || "internal"}` as NestedKeys<
        Translation,
        "."
      >
    ),
    value: (searchParams.get("source") as IntExt) || "internal",
  });

  const [typeExternal, setTypeExternal] = useState<{
    label: string;
    value: typeExternal;
  } | null>({
    label: t(
      `typeExternal.${searchParams.get("typeIfExt") || "all"}` as NestedKeys<
        Translation,
        "."
      >
    ),
    value: (searchParams.get("typeIfExt") as typeExternal) || "all",
  });

  const defaultCollaboration: { label: string; value: Collaboration }[] = [
    {
      label: t("collaboration.yes"),
      value: "yes",
    },
    {
      label: t("collaboration.no"),
      value: "no",
    },
    {
      label: t("collaboration.onHold"),
      value: "onHold",
    },
    {
      label: t("collaboration.null"),
      value: "null",
    },
  ];
  const defaultNegotiation: { label: string; value: Negotiation }[] = [
    {
      label: t("negotiation.yes"),
      value: "yes",
    },
    {
      label: t("negotiation.no"),
      value: "no",
    },
    {
      label: t("negotiation.null"),
      value: "null",
    },
  ];

  const queryCollaboration = searchParams.get("agencyCollaborates");
  const queryNegotiation = searchParams.get("negotiationInProgress");

  const [collaboration, setCollaboration] = useState<
    { label: string; value: Collaboration }[]
  >(
    queryCollaboration
      ? queryCollaboration.split(",").map((item: string) => ({
          label: t(`collaboration.${item}` as NestedKeys<Translation, ".">),
          value: item as Collaboration,
        }))
      : defaultCollaboration
  );

  const [negotiation, setNegotiation] = useState<
    { label: string; value: Negotiation }[]
  >(
    queryNegotiation
      ? queryNegotiation.split(",").map((item: string) => ({
          label: t(`negotiation.${item}` as NestedKeys<Translation, ".">),
          value: item as Negotiation,
        }))
      : defaultNegotiation
  );

  const {
    resultRealEstates,
    allResultRealEstates,
    limit,
    errorFetch,
    setLimit,
    isLoading,
    changePage,
    customerDemand,
    setReduxStateQueryTable,
    setStatusForFetch,
    reFetch,
    setSource,
    setTypeExternal: setTypeExt,
    setCollaboration: setCollaborationFunc,
    setNegotiation: setNegotiationFunc,
    setPagination,
  } = useResultDemandCustomer(containerTableRef, id!);

  const isSessionStorage = () => {
    const storedCheckList = sessionStorage.getItem("checkList");
    return storedCheckList ? true : false;
  };

  const [checkList, setCheckList] = useState<
    { checked: boolean; id: string; idDemand: string }[]
  >(isSessionStorage() ? JSON.parse(sessionStorage.getItem("checkList")!) : []);
  const toastAlert = useToastAlert();
  const { changeStatusMatch } = useEndpoints();
  const navigate = useNavigate();

  const selectElements = ({
    checked,
    id,
  }: {
    checked: boolean;
    id: string;
  }) => {
    checked
      ? setCheckList((prev) => [
          ...prev,
          {
            id,
            checked,
            idDemand: resultRealEstates?.find(
              ({ realEstate }) => realEstate._id === id
            )?._id!,
          },
        ])
      : setCheckList((prev) => [...prev.filter((item) => item.id !== id)]);
  };

  const selectAllElement = () => {
    setCheckList(
      resultRealEstates?.map(({ realEstate: { _id } }) => ({
        id: _id,
        checked: true,
        idDemand: resultRealEstates?.find(
          ({ realEstate }) => realEstate._id === _id
        )?._id!,
      }))!
    );
  };

  const checkIsDeselect = () => {
    return checkList.length === resultRealEstates?.length ? true : false;
  };

  const handlerFirstButton = async () => {
    if (status?.value === "active") {
      try {
        await changeStatusMatch(
          id!,
          "sent",
          checkList.map(({ idDemand }) => idDemand)
        );

        setCheckList([]);
        reFetch();
        toastAlert({
          message: t("matches.senderEmail.success"),
          type: "success",
          duration: DURATION_TOAST,
        });
      } catch (error) {
        const err = error as {
          response: {
            data: {
              error: string[];
            };
          };
        };

        if (
          err.response.data.error.includes("Customer cannot receive emails")
        ) {
          toastAlert({
            message: t("errorsToast.noEmail.title"),
            subMessage: t("errorsToast.noEmail.subtitle"),
            duration: 7000,
            type: "error",
          });
        } else {
          toastAlert({
            message: t("errorsToast.unexpectedError.title"),
            subMessage: t("errorsToast.unexpectedError.description"),
            duration: DURATION_TOAST,
            type: "error",
          });
        }

        console.error(error);
      }
    } else if (status?.value === "inactive") {
      try {
        await changeStatusMatch(
          id!,
          "active",
          checkList.map(({ idDemand }) => idDemand)
        );
        setCheckList([]);
        reFetch();
      } catch (error) {
        toastAlert({
          message: t("errorsToast.unexpectedError.title"),
          subMessage: t("errorsToast.unexpectedError.description"),
          duration: DURATION_TOAST,
          type: "error",
        });
        console.error(error);
      }
    } else {
      try {
        await changeStatusMatch(
          id!,
          "sent",
          checkList.map(({ idDemand }) => idDemand)
        );
        setCheckList([]);
        reFetch();
        toastAlert({
          message: t("matches.senderEmail.success"),
          type: "success",
          duration: DURATION_TOAST,
        });
      } catch (error) {
        toastAlert({
          message: t("errorsToast.unexpectedError.title"),
          subMessage: t("errorsToast.unexpectedError.description"),
          duration: DURATION_TOAST,
          type: "error",
        });
        console.error(error);
      }
    }
  };
  const handlerSecondButton = async () => {
    try {
      await changeStatusMatch(
        id!,
        "inactive",
        checkList.map(({ idDemand }) => idDemand)
      );
      setCheckList([]);
      reFetch();
    } catch (error) {
      toastAlert({
        message: t("errorsToast.unexpectedError.title"),
        subMessage: t("errorsToast.unexpectedError.description"),
        duration: DURATION_TOAST,
        type: "error",
      });
      console.error(error);
    }
  };

  useEffect(() => {
    setCheckList([]);
    setStatusForFetch(status?.value as StatusDemand);
  }, [status]);

  useEffect(() => {
    statusIntEx?.value && setSource(statusIntEx?.value);
    statusIntEx?.value === "internal" &&
      typeExternal?.value !== "all" &&
      setSearchParams({ typeExternal: "all" });
  }, [statusIntEx]);

  useEffect(() => {
    typeExternal?.value && setTypeExt(typeExternal.value);
  }, [typeExternal]);

  let collabFilters: string;
  let negotFilters: string;

  useEffect(() => {
    collabFilters = collaboration.map((item) => item.value).join(",");
    setCollaborationFunc(collabFilters);
  }, [collaboration]);

  useEffect(() => {
    if (collaboration.length === 0) {
      setCollaboration([...defaultCollaboration]);
    }
  }, [collaboration]);

  useEffect(() => {
    if (negotiation.length === 0) {
      setNegotiation([...defaultNegotiation]);
    }
  }, [negotiation]);

  useEffect(() => {
    negotFilters = negotiation.map((item) => item.value).join(",");
    setNegotiationFunc(negotFilters);
  }, [negotiation]);

  useEffect(() => {
    const checkListJSON = JSON.stringify(checkList);
    sessionStorage.setItem("checkList", checkListJSON);
  }, [checkList]);

  const checkSessionStorage = () => {
    const storedCheckList = sessionStorage.getItem("checkList");
    if (storedCheckList) {
      const parsedCheckList = JSON.parse(storedCheckList);
      setCheckList(parsedCheckList);
    }
  };

  useEffect(() => checkSessionStorage(), []);

  return (
    <div className="container-results-demand-customer">
      <div className="container__top">
        <div className="left">
          <h1 className="page-title">
            {t(
              `${
                type === "demands"
                  ? "resultsDemandCustomer"
                  : "resultsMatchesCustomer"
              }.title`
            )}{" "}
            <span
              className="pointer"
              onClick={() => {
                sessionStorage.clear();
                navigate(
                  `${RoutesRouter.customers}/${customerDemand?._id}/demand-customer`
                );
              }}
            >{`${
              customerDemand
                ? `${customerDemand.name} ${customerDemand.surname}`
                : ""
            }`}</span>
          </h1>
          <p className="page-description mt-1">
            {t(
              `${
                type === "demands"
                  ? "resultsDemandCustomer"
                  : "resultsMatchesCustomer"
              }.description`
            )}
          </p>
        </div>
      </div>
      <div className={`line-selects `}>
        <div className="line-single d-flex">
          <div className="filter ps-2 d-flex flex-column">
            <span className="fw-bold fs-6">
              {t("labelsFilterRealEstate.source")}
            </span>
            <CustomSelect
              selected={statusIntEx}
              labels={listExInt.map((label) => ({
                label: t(`demandSource.${label as IntExt}`),
                value: label,
              }))}
              placeholder={`demandSource.internal`}
              onChange={({ value, label }) => {
                setStatusIntEx({
                  label,
                  value: value as IntExt,
                });
                setPagination({ limit, page: 0 });
              }}
            />
          </div>
          <div
            className={`filter ps-2 d-flex flex-column ${
              statusIntEx?.value !== "external" ? "d-none" : ""
            }`}
          >
            <span className="fw-bold fs-6">
              {t("labelsFilterRealEstate.typology")}
            </span>
            <CustomSelect
              selected={typeExternal}
              labels={listTypeExternal.map((label) => ({
                label: t(`typeExternal.${label as typeExternal}`),
                value: label,
              }))}
              placeholder={`typeExternal.all`}
              onChange={({ value, label }) => {
                setTypeExternal({
                  label,
                  value: value as typeExternal,
                });
                setPagination({ limit, page: 0 });
              }}
            />
          </div>

          <div
            className={`filter ps-2 d-flex flex-column ${
              statusIntEx?.value !== "external" ? "d-none" : ""
            }`}
          >
            <span className="fw-bold fs-6">
              {t("labelsFilterRealEstate.collaboration")}
            </span>
            <CustomSelect
              selected={collaboration}
              showSelectedItems={true}
              labels={listCollaboration.map((label: string) => ({
                label: t(
                  `collaboration.${label}` as NestedKeys<Translation, ".">
                ),
                value: label,
              }))}
              placeholder={`collaboration.yes`}
              checkbox={true}
              onChange={(items) => {
                setCollaboration(
                  items as { label: string; value: Collaboration }[]
                );
                setPagination({ limit, page: 0 });
              }}
            />
          </div>

          <div
            className={`filter ps-2 d-flex flex-column ${
              statusIntEx?.value !== "external" ? "d-none" : ""
            }`}
          >
            <span className="fw-bold fs-6">
              {t("labelsFilterRealEstate.negotiation")}
            </span>
            <CustomSelect
              selected={negotiation}
              showSelectedItems={true}
              labels={listNegotiation.map((label: string) => ({
                label: t(
                  `negotiation.${label}` as NestedKeys<Translation, ".">
                ),
                value: label,
              }))}
              placeholder={`negotiation.yes`}
              checkbox={true}
              onChange={(items) => {
                setNegotiation(
                  items as { label: string; value: Negotiation }[]
                );
                setPagination({ limit, page: 0 });
              }}
            />
          </div>
        </div>

        <div className={`right-filters`}>
          <div className=" filter ps-2 d-flex flex-column me-2">
            <span className="fw-bold fs-6">
              {t("labelsFilterRealEstate.state")}
            </span>
            <CustomSelect
              selected={status}
              labels={statusList.map((label) => ({
                label: t(`statusDemand.${label as StatusDemand}`),
                value: label,
              }))}
              placeholder={`statusDemand.active`}
              onChange={({ value, label }) => {
                setStatus({
                  label,
                  value,
                });
                setStatusForFetch(value as StatusDemand);
                setPagination({ limit, page: 0 });
              }}
            />
          </div>
          <div className="d-flex max-content">
            <div
              onClick={() =>
                checkIsDeselect() ? setCheckList([]) : selectAllElement()
              }
              className="checkbox-selectall align-items-center"
            >
              <span className="text-selectall">
                {checkIsDeselect()
                  ? t("demandRealEstate.deselectAll")
                  : t("demandRealEstate.selectAll")}
              </span>
              <div className={`container-checkbox-circle me-1`}>
                <div
                  className={`circle ${
                    checkIsDeselect() ? "checked" : "unchecked"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" btn-line-width mb-4 mt-2">
        <div
          className={`element-count fw-bold ${getClasses({
            "d-none": checkList.length < 1,
          })}`}
        >
          {t("statusDemand.elementCount")} {checkList.length}
        </div>
        <div
          className={`flex-center ${getClasses({
            "d-none": checkList.length > 0 ? false : true,
          })}`}
        >
          <Button
            color={"tertiary"}
            className={`${getClasses({
              "d-none": status?.value === "inactive",
            })} btn-height`}
            onClick={() => handlerSecondButton()}
          >
            {t("statusDemand.moveOnDisactived")}
          </Button>
          <Button
            className="btn-height"
            color={"primary"}
            onClick={() => handlerFirstButton()}
          >
            {status?.value === "active"
              ? t("statusDemand.sendEmail")
              : status?.value === "inactive"
              ? t("statusDemand.moveOnActive")
              : t("statusDemand.resend")}
          </Button>
        </div>
      </div>
      {resultRealEstates && !errorFetch ? (
        <>
          <div
            ref={containerTableRef}
            className="container-results-demand-customer-card"
          >
            {Boolean(resultRealEstates?.length) ? (
              resultRealEstates.map(
                ({ realEstate: { _id, ...realEstate }, sentAt }, index) => (
                  <div className={`${index !== 0 ? "mt-4" : ""}`} key={_id}>
                    <CardRealEstates
                      onClick={() => {
                        setReduxStateQueryTable(_id);
                        navigate(`${RoutesRouter.realEstates}/${_id}`);
                      }}
                      realEstate={{ _id, ...realEstate }}
                      index={index}
                      setterCheckBox={selectElements}
                      checkListArray={checkList}
                      sentAt={status?.value === "sent" ? sentAt : undefined}
                    />
                  </div>
                )
              )
            ) : (
              <EmptyResourcesTable
                description={""}
                title={
                  "consultants.emptyResourcesTable.resultDemandCustomer.title"
                }
              />
            )}
          </div>
          <Box mt={5}>
            <Pagination
              onClickPage={changePage}
              isLoading={isLoading}
              data={allResultRealEstates!}
              selectLimit={{
                limit,
                setLimit,
                options: [
                  { label: "10", value: 10 },
                  { label: "20", value: 20 },
                  { label: "30", value: 30 },
                ],
              }}
            />
          </Box>
        </>
      ) : (
        <>
          {errorFetch && (
            <EmptyResourcesTable
              image={errorFetch.image}
              description={errorFetch.description}
              title={errorFetch.title}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ResultDemandCustomer;
