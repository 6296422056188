import {
  ChakraProvider,
  ColorModeScript,
  ComponentStyleConfig,
  extendTheme,
  ThemeConfig,
} from "@chakra-ui/react";
import * as React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";
import { Provider } from "react-redux";
import { store } from "./redux";
import { I18nextProvider } from "react-i18next";
import i18n from "./assets/i18n/i18n";
import { CookiesProvider } from "react-cookie";


const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

// 3. extend the theme

const MenuItem: ComponentStyleConfig = {
  baseStyle: {
    _hover: {
      bg: "red !important",
    },
  },
};
const theme = extendTheme({
  styles: {
    global: (props: any) => ({
      body: {
        color: "#3A3878",
        margin: 0,
        fontFamily: "Satoshi, Helvetica Neue, sans-serif",
      },
    }),
  },
  config,
  components: {
    Button: {
      baseStyle: {
        fontWeight: "bold",
        borderRadius: "50px !important",
      },
    },
    MenuItem,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <ColorModeScript />
          <BrowserRouter>
            <CookiesProvider>
              <App/>
            </CookiesProvider>
          </BrowserRouter>
        </ChakraProvider>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can chang
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
