import React, { FC } from "react";
import genericImg from "../../../assets/img/generic-img.svg";
import "./MobilePage.scss";

interface IMobilePage {}

const MobilePage: FC<IMobilePage> = ({ children }) => {
  const hideMobile = true;
  return (
    <>
      <div className={`h-100 ${hideMobile ? "desktop-layout" : ""}`}>
        {children}
      </div>
      {hideMobile && (
        <div className="container mobile-container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="login-content">
                {/* <img className="logo" src="/assets/img/logo.svg" alt="logo" /> */}
                <img
                  className="generic-img"
                  src={genericImg}
                  alt="generic-img"
                />
                <div className="mobile-layout">
                  <h1 className="title">{"Usa uno schermo più grande"}</h1>
                  <p className="description">{" Per usufruire al meglio delle funzionalità è preferibile utilizzare uno schermo più grande"}</p>
                </div>
                <div className="tablet-layout">
                  <h1 className="title">{"Ruota il tuo dispositivo"}</h1>
                  <p className="description">{" Per usufruire al meglio delle funzionalità è preferibile utilizzare lo schermo in orizzontale"}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MobilePage;
