import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RespRealEstatesDemand } from "../../models/ResultRealEstateDemand";

interface ResultsDemandCustomer {
  realEstates: RespRealEstatesDemand | undefined;
  queryTable:
    | { params: Record<string, string | number>; id: string }
    | undefined;
}

const initialState = {
  realEstates: undefined,
  putRealEstate: undefined,
  isSubmit: { submit: false, back: false },
  queryTable: undefined,
  canChangeStepForm: true,
} as ResultsDemandCustomer;

const resultsDemandCustomers = createSlice({
  name: "resultdDemandCustomer",
  initialState,
  reducers: {
    setResultsDemandCustomer(
      state: ResultsDemandCustomer,
      { payload }: PayloadAction<RespRealEstatesDemand | undefined>
    ) {
      state.realEstates = payload;
    },
    setQueryTableResultsDemandCustomer(
      state: ResultsDemandCustomer,
      {
        payload,
      }: PayloadAction<
        { params: Record<string, string | number>; id: string } | undefined
      >
    ) {
      state.queryTable = payload;
    },
  },
});

export const {
  setResultsDemandCustomer,
  setQueryTableResultsDemandCustomer,
} = resultsDemandCustomers.actions;
export default resultsDemandCustomers.reducer;
