import { ChevronDownIcon } from "@chakra-ui/icons";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { NestedKeys } from "advanced-types";
import React, { FC, useEffect, useState } from "react";
import { Translation } from "../../../../assets/i18n/locals/en";
import { additionalRooms } from "../../../../forms/realeEstate/selections";
import { Form } from "../../../../hooks/useForm";
import { useI18n } from "../../../../hooks/useI18n";
import { Field } from "../../../../models/CustomForm";
import { ROOM } from "../../../../models/RealEstate";
import { Nullable } from "../../../../typings/KeysFromObject";
import { getNestedValueFromString } from "../../../../Utils/fuctions";
import Button from "../../Button/Button";
import "./OtherRoomsDetailsInput.scss";

interface ITextInput {
  form: Partial<Form<any>>;
  setForm: any;
  data: Field<any>;
  error: string | undefined;
}

const OtherRoomsDetailsInput: FC<ITextInput> = ({
  data: {
    isRequired,
    label,
    type,
    column = 4,
    offset = 0,
    key,
    placeholder,
    maxColumn,
  },
  form,
  setForm,
  error,
}) => {
  const [otherRoomsDetails, setOtherRoomsDetails] = useState<
    Nullable<{
      type: {label: NestedKeys<Translation, "."> | undefined, value: ROOM | undefined};
      dimensions?: string;
      squareMeters?: number;
    }>[]
  >(getNestedValueFromString<any[]>(key, form).map((room) => {
   const type = Object.entries(additionalRooms).find(([key, value]) => value === room.type)
    return {
      ...room,
      type: type ? {label: type[0], value: type[1]} : {label: undefined, value:  undefined}
    }
  }));
  const{t}=useI18n()

  const handleAddRoom = () => {
    setOtherRoomsDetails((prev) => [
      ...prev,
      { type: {label: undefined, value: undefined}, dimensions: undefined, squareMeters: undefined },
    ]);
  };

  const removeRoom = (index: number) => {
    setOtherRoomsDetails((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };
  const handleSelect = (value: {label: NestedKeys<Translation, ".">, value: any}, index: number) => {
    setOtherRoomsDetails((prev) => {
      prev[index]["type"] = value;
      return [...prev];
    });
  };

  const handleChangeText = (
    value: string | number,
    index: number,
    type: "squareMeters" | "dimensions"
  ) => {
    setOtherRoomsDetails((prev) => {
      type === "dimensions" && (prev[index]["dimensions"] = value as string);
      type === "squareMeters" &&
        (prev[index]["squareMeters"] = Number(value) as number);
      return [...prev];
    });
  };


  useEffect(() => {
    setForm(
      key,
      otherRoomsDetails.length ? otherRoomsDetails.filter(({type}) => !!type?.value).map((room) => ({...room, type: room.type!.value})) : []
    );
    // eslint-disable-next-line
  }, [otherRoomsDetails]);


  const option = (
    label: NestedKeys<Translation,".">|"",
    value: string,
    labelSelect: ROOM | undefined
  ) => {
    return (
      <div className="menu-flex">
        <span className="capitalize-first-letter">{t(label)}</span>
        {labelSelect === value && <i className="icon icon-checkmark" />}
      </div>
    );
  };

  const renderTypeLabel = (i: number) => {
    const labelSelect = otherRoomsDetails[i].type;

    return (
      <div className="w-50">
        <Menu>
          <MenuButton ps={0} width={"100%"} className="menu-button">
            <div className="container-text-default">
              <span
                className={`${
                  labelSelect?.label
                    ? "menu-button-value capitalize-first-letter"
                    : "menu-button-default"
                }`}
              >
                {t(labelSelect?.label || "") || t("realEstate.forms.description.roomPertinenceDeatils.selectRoomsType")}
              </span>

              <ChevronDownIcon w={6} h={6} />
            </div>
          </MenuButton>
          <MenuList
            maxHeight={"20rem"}
            overflowY={"scroll"}
            p={0}
            zIndex={1000}
          >
            {/* {isRequired !== true && (
            <div
              onClick={() => handleSelect("-", "notProvided")}
              className="menu-item"
            >
              {option("-", "notProvided")}
            </div>
          )} */}

            {Object.entries(additionalRooms).map(
              ([ label, value], index) => {
                return (
                  <MenuItem
                    key={index}
                    _hover={{ bg: "transparent" }}
                    p={0}
                    _focus={{ bg: "transparent" }}
                    onClick={() => handleSelect({label: label as NestedKeys<Translation, ".">, value}, i)}
                    className="menu-item"
                  >
                    {option(label as NestedKeys<Translation, ".">, value, labelSelect?.value)}
                  </MenuItem>
                );
              }
            )}
          </MenuList>
        </Menu>
      </div>
    );
  };

  const renderDimensionText = (
    type: "dimensions" | "squareMeters",
    i: number
  ) => {
    return (
      <div className="w-25">
        <label className="form-control-label" htmlFor="name">
          {`${type === "dimensions" ? t("realEstate.forms.description.roomPertinenceDeatils.dimension") : "mq"}`}
        </label>
        <input
          type={type === "dimensions" ? "string" : "number"}
          value={otherRoomsDetails[i][type] as string | number}
          // onChange={({ target: { value } }) => handleChangeDate(value)}
          onChange={({ target: { value } }) => {
            handleChangeText(value, i, type);
          }}
          className={`form-control p-md input-text p-0 bg-transparent ps-0 fw-normal`}
          placeholder={`${type === "dimensions" ? "m x m" : "mq"}`}
        />
      </div>
    );
  };

  return (
    <>
      <div className={`col-lg-${column}`}>
        <div
          className={`form-group offset-lg-${offset} ${
            error ? "is-invalid" : ""
          }`}
        >
          <label className="form-control-label" htmlFor="name">
            {`${label} ${isRequired ? "*" : ""}`}
          </label>
          <div className="row">
            {otherRoomsDetails!.map((room, i) => {
              return (
                <div
                  key={i}
                  className={`col-lg-6 mb-5 container-room-pertinence`}
                >
                  <div className={`card overview-card py-2 ps-3 pe-2 `}>
                   <i
                      className="icon icon-close button-close pointer"
                      onClick={() => removeRoom(i)}
                    /> 
                    <div
                      // [class.gets-better]='analytics?.overall_score < 6'
                      className="score-container"
                    >
                      {renderTypeLabel(i)}
                    </div>
                    <div className="global-result">
                      <div className="d-flex justify-content-end">
                        {renderDimensionText("dimensions", i)}
                        {renderDimensionText("squareMeters", i)}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="d-flex justify-content-center">
            <Button onClick={handleAddRoom}>{t("realEstate.forms.description.roomPertinenceDeatils.addRooms")}</Button>
          </div> 
          {/* <input
            type={type}
            value={getNestedValueFromString(key, form)}
            onChange={({ target: { value } }) => handleChangeDate(value)}
            className="form-control p-md input-text"
            placeholder={placeholder ?? ""}
          /> */}
          {/* {error && (
            <div className="invalid-feedback">
              <div>{error}</div>
            </div>
          )} */}
        </div>
      </div>
      {maxColumn && <div className={`col-lg-${maxColumn}`} />}
    </>
  );
};

export default OtherRoomsDetailsInput;
