import {
  ACQUISITION_SOURCE,
  BOX_TYPE,
  CONSERVATION_STATUS,
  CONTRACT_TYPE,
  CURRENT_STATE,
  ELECTRICAL_SYSTEM_STATUS,
  FLOOR,
  FURNITURE_STATUS,
  GARDEN_TYPE,
  HEATING_TYPE,
  KITCHEN_TYPE,
  PROPERTY_TYPE,
  TV_STATUS,
} from "./RealEstate";

export enum DEMAND_STATUS {
  ACTIVE = "active",
  CANCELED = "canceled", // annullata
  CONCLUDED = "concluded", // conclusa
  LOST = "lost", // persa
  EXPIRED = "expired", // scaduta
  SUSPENDED = "suspended", // sospesa
  NOT_PROVIDED = "notProvided",
}

export type ConditionElement =
  | {
      name: "box";
      value: (BOX_TYPE | "*")[];
    }
  | {
      name: "kitchen";
      value: (KITCHEN_TYPE | "*")[];
    }
  | {
      name: "garden";
      value: (GARDEN_TYPE | "*")[];
    }
  | {
      name: "floor";
      value: (number | FLOOR | "*")[];
    }
  | {
      name: "heatingType";
      value: (HEATING_TYPE | "*")[];
    }
  | {
      name: "conservationStatus";
      value: (CONSERVATION_STATUS | "*")[];
    }
  | {
      name: "satTv" | "tvAntenna";
      value: (TV_STATUS | "*")[];
    }
  | {
      name: "electricalSystem";
      value: (ELECTRICAL_SYSTEM_STATUS | "*")[];
    }
  | {
      name:
        | "coveredParkingSpaces"
        | "openParkingSpaces"
        | "bikeParkingSpaces"
        | "boatParkingSpaces"
        | "camperParkingSpaces";
      value: number | "*";
    }
  | {
      name: "parkingSpaces";
      value: (PARKING | "*")[];
    }
  | {
      name:
        | "petsAllowed"
        | "elevator"
        | "balconies"
        | "cellar"
        | "depot"
        | "warehouse"
        | "bareOwnership"
        | "shed"
        | "loft"
        | "attic"
        | "stable"
        | "terrace"
        | "terrain"
        | "airConditioning"
        | "chimney"
        | "safetyBox"
        | "shower"
        | "aluminiumFixtures"
        | "phoneSystem"
        | "independentEntrance"
        | "alarmPredisposed"
        | "parquet"
        | "closet"
        | "jacuzzi"
        | "pool"
        | "atAuction"
        | "woodFixtures"
        | "shutters"
        | "suspendedSanitary"
        | "lakeView"
        | "seaView"
        | "porch";
      value: boolean | "*";
    };
export enum PARKING {
  COVERED = "covered",
  OPEN = "open",
  ABSENT = "absent",
}

export interface Viewport {
  northeast: {
    lat: number;
    lng: number;
  };
  southwest: {
    lat: number;
    lng: number;
  };
}

export interface Coord {
  lat: number;
  lng: number;
}
export interface Zone {
  name: string;
  viewport?: Viewport;
  coords?: Coord[];
  bounds?: {
    north: number;
    south: number;
    east: number;
    west: number;
  };
}

export interface Range {
  from: number;
  to: number;
}

export interface IDemandCustomer {
  contractType: CONTRACT_TYPE;
  status: DEMAND_STATUS;
  propertyTypes: PROPERTY_TYPE[];
  isCash: boolean;
  isLoan: boolean;
  gotFinancialAdvice: boolean;
  loanAmount?: number;
  missingArchitecturalBarriers: boolean;
  miscellaneous: string; // varie
  specificNeeds: string; // esigenze specifiche
  price: Range;
  totalSquareMeters: Range;
  locals: Range;
  title: string;
  seaLakeDistance: Range;
  acquisitionSource: ACQUISITION_SOURCE;
  latitude: number | null;
  longitude: number | null;
  rooms?: Range;
  bathrooms?: Range;
  furnitureStatus: FURNITURE_STATUS;
  beds?: Range;
  currentState?: CURRENT_STATE;
  forStudents?: boolean;
  zones: Zone[];
  type?: string;
  conditions: {
    required: ConditionElement[];
    optional: ConditionElement[];
  };
}
