import { mappedNationality } from "../";
import {
    CUSTOMER_ENTITY,
    CUSTOMER_SOURCE,
    CUSTOMER_STATE,
    CUSTOMER_TYPE,
    GENDER,
    IDENTITY_DOCUMENT,
    LANGUAGE,
    RATING,
  } from "../../models/Customer";
  import { ICustomForm } from "../../models/CustomForm";
  import { NewCustomerForm } from "../../pages/CreateCustomer/CreateCustomer";
  

  
  export const createCustomerForm: ICustomForm<NewCustomerForm> = {
    title: { edit: "createCustomerPage.create", show: "createCustomerPage.customer" },
    description: {edit: "createCustomerPage.fillInTheForm", show: ""},
    // buttonRight: "changeConsultant",
    labelBackButton:{edit:"createCustomerPage.back", show:"createCustomerPage.back", link: -1 }, // TO DO LINK => CLOSE DRAWER
    labelSubmitButton: {edit:"createCustomerPage.addCustomer", show: "createCustomerPage.back"},
    next: "/home/customers",
    forms: [
      {
        title: "createCustomerPage.specific",
        fields: [
          {
            label: "personalData.person",
            key: "entity",
            type: "select",
            options: [
              {
                label: "personalData.types.physical",
                value: CUSTOMER_ENTITY.PHYSICAL,
              },
              {
                label: "personalData.types.juridical",
                value: CUSTOMER_ENTITY.JURIDICAL,
              },
              {
                label: "personalData.types.notProvided",
                value: CUSTOMER_ENTITY.NOT_PROVIDED,
              },
            ],
          },
          {
            column: 4,
            label: "createCustomerPage.source",
            key: "source",
            type: "select",
            options: [
              { label: "source.sources.Advertisement", value: CUSTOMER_SOURCE.ADVERTISEMENT },
              {
                label: "source.sources.Signaler",
                value: CUSTOMER_SOURCE.SIGNALER,
              },
              {
                label: "source.sources.notProvided",
                value: CUSTOMER_SOURCE.NOT_PROVIDED,
              },
            ],
          },
  
          {
            label: "basicInfo.status.state",
            key: "customerState",
            type: "select",
            options: [
              { label: "basicInfo.status.options.active", value: CUSTOMER_STATE.ACTIVE, checked: true },
              {
                label: "basicInfo.status.options.suspended",
                value: CUSTOMER_STATE.SUSPENDED,
                checked: false,
              },
              {
                label: "basicInfo.status.options.dealInProgress",
                value: CUSTOMER_STATE.DEAL_IN_PROGRESS,
                checked: false,
              },
              {
                label: "basicInfo.status.options.dealConcluded",
                value: CUSTOMER_STATE.DEAL_CONCLUDED,
                checked: false,
              },
            ],
          },
          {
            label: "basicInfo.date",
            key: "acquisitionDate",
            type: "datepicker",
          },
          {
            label: "basicInfo.rating.state",
            key: "rating",
            type: "select",
            options: [
              { label: "basicInfo.rating.options.standard", value: RATING.STANDARD },
              { label: "basicInfo.rating.options.low", value: RATING.LOW },
              { label: "basicInfo.rating.options.high", value: RATING.HIGH },
              { label: "basicInfo.rating.options.excellent", value: RATING.EXCELLENT },
            ],
          },
          {
            column: 4,
            label: "createCustomerPage.type",
            key: "customerTypes",
            isRequired: true,
            type: "select-checkbox",
            options: [
              {
                label: "customerType.types.applicant",
                value: CUSTOMER_TYPE.APPLICANT,
                checked: false,
              },
              {
                label: "customerType.types.owner",
                value: CUSTOMER_TYPE.OWNER,
                checked: false,
              },
              {
                label: "customerType.types.supplier",
                value: CUSTOMER_TYPE.SUPPLIER,
                checked: false,
              },
              {
                label: "customerType.types.signaler",
                value: CUSTOMER_TYPE.SIGNALER,
                checked: false,
              },
              {
                label: "customerType.types.other",
                value: CUSTOMER_TYPE.OTHER,
                checked: false,
              },
            ],
          },
        ],
      },
      //GROUP: DATI ANAGRAFICI
      {
        title: "createCustomerPage.personalData",
        fields: [
          {
            label: "personalData.surname",
            isRequired: true,
            key: "surname",
            type: "text",
            placeholder: "personalData.placeholderSurname",
          },
          {
            label: "personalData.name",
            key: "name",
            isRequired: true,
            type: "text",
            placeholder: "personalData.placeholderName",
          },
          {
            label: "personalData.taxIdCode",
            key: "taxIdCode",
            type: "text",
            placeholder: "personalData.placeHolderTaxIdCode",
          },
          {
            label: "personalData.title",
            key: "title",
            type: "text",
            placeholder: "personalData.placeholderTitle",
          },
          {
            label: "personalData.genre",
            key: "gender",
            type: "select",
            options: [
              {
                label: "personalData.gender.male",
                value: GENDER.MALE,
              },
              {
                label: "personalData.gender.female",
                value: GENDER.FEMALE,
              },
              {
                label: "personalData.gender.notProvided",
                value: GENDER.NOTPROVIDED,
              },
              {
                label: "personalData.gender.other",
                value: GENDER.OTHER,
              },
            ],
          },
          {
            label: "personalData.birth.place",
            key: "birthPlace",
            type: "text",
            placeholder: "personalData.birth.city",
          },
          {
            label: "personalData.birth.date",
            key: "birthDate",
            type: "datepicker",
          },
          {
            label: "personalData.nationality",
            key: "nationality",
            type: "select",
            options: mappedNationality,
          },
        ],
      },
      //GROUP: RESIDENZA
      {
        title: "createCustomerPage.address",
        fields: [
          {
            label: "address.nation",
            key: "residence/country",
            type: "select",
            options: mappedNationality,
          },
          {
            label: "address.region",
            key: "residence/state",
            type: "text",
            placeholder: "address.placeholder.region",
          },
          {
            label: "address.town",
            key: "residence/municipality",
            type: "text",
            placeholder: "address.placeholder.town",
          },
          {
            label: "address.postalCode",
            key: "residence/postalCode",
            type: "text",
            placeholder: "address.placeholder.postalCode",
          },
          {
            label: "address.address",
            key: "residence/address",
            type: "text",
            placeholder: "address.placeholder.address",
          },
          {
            label: "address.civic",
            key: "residence/houseNumber",
            type: "text",
            placeholder: "address.placeholder.civic",
          },
        ],
      },
      //GROUP: DOCUMENTI
      {
        title: "createCustomerPage.docs",
        fields: [
          {
            label: "documents.type",
            key: "identityDocument/type",
            type: "select",
            options: [
              {
                label: "documents.docTypes.identityCard",
                value: IDENTITY_DOCUMENT.IDENTITY_CARD,
                checked: false,
              },
              {
                label: "documents.docTypes.passport",
                value: IDENTITY_DOCUMENT.PASSPORT,
                checked: false,
              },
              {
                label: "documents.docTypes.drivingLicense",
                value: IDENTITY_DOCUMENT.DRIVING_LICENSE,
                checked: false,
              },
              {
                label:
                  "documents.docTypes.publicDeeds",
                value: IDENTITY_DOCUMENT.SIGNED_DOCUMENTS,
                checked: false,
              },
              {
                label: "documents.docTypes.declaration",
                value: IDENTITY_DOCUMENT.ITALIAN_CONSOLATE_DECLARATION,
                checked: false,
              },
              {
                label: "documents.docTypes.certificateFromAnotherProfessional",
                value: IDENTITY_DOCUMENT.PROFESSIONAL_ATTESTATION,
                checked: false,
              },
              {
                label:
                  "documents.docTypes.certificateOfQualifiedIntermediaries",
                value: IDENTITY_DOCUMENT.INTERMEDIARY_ATTESTATION,
                checked: false,
              },
              {
                label: "documents.docTypes.other",
                value: IDENTITY_DOCUMENT.OTHER,
                checked: false,
              },
            ],
          },
          {
            label: "documents.docDetails",
            key: "identityDocument/documentId",
            type: "text",
          },
          {
            label: "documents.released",
            key: "identityDocument/releasedBy",
            type: "text",
            placeholder: "documents.placeholderReleased",
          },
          {
            label: "documents.date",
            key: "identityDocument/released",
            type: "datepicker",
          },
        ],
      },
      //GROUP: RECAPITI
      {
        title: "createCustomerPage.contacts",
        fields: [
          {
            isRequired: true,
            label: "contacts.phone.phone1",
            key: "phone1",
            type: "text",
            placeholder:"contacts.placeholderPhone"
          },
          {
            label: "contacts.phone.phone2",
            key: "phone2",
            type: "text",
            placeholder:"contacts.placeholderPhone"
          },
          {
            label: "",
            key: "smsDisabled",
            type: "checkbox",
            option: { label: "contacts.checkbox.sms", value: false },
          },
          {
            label: "contacts.email",
            key: "email",
            type: "text",
            placeholder: "contacts.placeholderEmail"
          },
          {
            label: "",
            key: "mailingDisabled",
            type: "checkbox",
            option: { label: "contacts.checkbox.mail", value: false },
          },
        ],
      },
      //GROUP: lINGUE PARLATE
      {
        title: "personalData.leng",
        fields: [
          {
            column: 4,
            columnRadio: 12,
            label: "",
            key: "spokenLanguages",
            type: "select-checkbox",
            multiple: true,
            options: Object.values(LANGUAGE).map((language) => ({
              label: `personalData.languages.${language}`,
              value: language,
              checked: false,
            })),
          },
        ],
      },
  
      {
        title: "createCustomerPage.notes",
        fields: [
          {
            column: 12,
            label: "",
            key: "note",
            type: "text-area",
            placeholder: "notes.placeholder",
          },
        ],
      },
    ],
  };
  