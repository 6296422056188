import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Translation } from "../assets/i18n/locals/en";
import { NestedKeys } from "../typings/KeysFromObject";
import { setLanguage } from "../redux/reducers/language";
import { TOptionsBase } from "i18next";
import { revereKeysWithValue } from "../Utils/fuctions";
import { RootState } from "../redux/reducers";
import { useCallback } from "react";
import { setStorageItem } from "../Utils/localStorage";

export const useI18n = () => {
  const dispatch = useDispatch();
  const {language} = useSelector(({language}: RootState) => language)
  const { t: translate } = useTranslation();
  const t = useCallback((
    key: NestedKeys<Translation, "."> | NestedKeys<Translation, ".">[] | "", options? : string | TOptionsBase
  ) => (Array.isArray(key) ? key.reduce((acc, arr) => acc += `${translate(arr, options)} `  , "") : key === "" ? "" : translate(key, options)), [translate]);
  const changeLanguage = (language: "it" | "en") => {
    setStorageItem("language", language)
    dispatch(setLanguage(language));
  };

  const getTranslateFromObj = <T extends Partial<Record<any, any>> = any, K extends any = any>(obj: T, selectedItem: K): string => selectedItem ? t(revereKeysWithValue(obj)[selectedItem]) : "-";

  return { t, changeLanguage, getTranslateFromObj, language };
};
