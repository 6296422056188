export const nationalities = [
   "Afghanistan",
   "Albania",
   "Algeria",
   "American Samoa",
   "Andorra",
   "Angola",
   "Anguilla",
   "Antarctica",
   "Antigua and Barbuda",
   "Argentina",
   "Armenia",
   "Aruba",
   "Ashmore and Cartier Islands",
   "Australia",
   "Austria",
   "Azerbaijan",
   "Bahamas, The",
   "Bahrain",
   "Baker Island",
   "Bangladesh",
   "Barbados",
   "Bassas da India",
   "Belarus",
   "Belgio",
   "Belize",
   "Benin",
   "Bermuda",
   "Bhutan",
   "Bolivia",
   "Bosnia and Herzegovina",
   "Botswana",
   "Bouvet Island",
   "Brasile",
   "British Indian Ocean Territory",
   "British Virgin Islands",
   "Brunei",
   "Bulgaria",
   "Burkina Faso",
   "Burma",
   "Burundi",
   "Cambodia",
   "Cameroon",
   "Canada",
   "Cape Verde",
   "Cayman Islands",
   "Central African Republic",
   "Chad",
   "Chile",
   "Christmas Island",
   "Cina",
   "Clipperton Island",
   "Cocos (Keeling) Islands",
   "Colombia",
   "Comoros",
   "Congo, Democratic Republic of the",
   "Congo, Republic of the",
   "Cook Islands",
   "Coral Sea Islands",
   "Costa Rica",
   "Cote d'Ivoire",
   "Croazia",
   "Cuba",
   "Cyprus",
   "Czech Republic",
   "Denmark",
   "Djibouti",
   "Dominica",
   "Dominican Republic",
   "Ecuador",
   "Egypt",
   "El Salvador",
   "England",
   "Equatorial Guinea",
   "Eritrea",
   "Estonia",
   "Ethiopia",
   "Europa Island",
   "Falkland Islands (Islas Malvinas)",
   "Faroe Islands",
   "Fiji",
   "Finland",
   "Francia",
   "French Guiana",
   "French Polynesia",
   "French Southern and Antarctic Lands",
   "Gabon",
   "Gambia, The",
   "Gaza Strip",
   "Georgia",
   "Germania",
   "Ghana",
   "Giappone",
   "Gibraltar",
   "Glorioso Islands",
   "Greece",
   "Greenland",
   "Grenada",
   "Guadeloupe",
   "Guam",
   "Guatemala",
   "Guernsey",
   "Guinea",
   "Guinea-Bissau",
   "Guyana",
   "Haiti",
   "Heard Island and McDonald Islands",
   "Holy See (Vatican City)",
   "Honduras",
   "Hong Kong",
   "Howland Island",
   "Hungary",
   "Iceland",
   "India",
   "Indonesia",
   "Iran",
   "Iraq",
   "Ireland",
   "Isle of Man",
   "Israel",
    "Italia",
   "Jamaica",
   "Jan Mayen",
   "Jarvis Island",
   "Jersey",
   "Johnston Atoll",
   "Jordan",
   "Juan de Nova Island",
   "Kazakhstan",
   "Kenya",
   "Kingman Reef",
   "Kiribati",
   "Korea, North",
   "Korea, South",
   "Kuwait",
   "Kyrgyzstan",
   "Laos",
   "Latvia",
   "Lebanon",
   "Lesotho",
   "Liberia",
   "Libya",
   "Liechtenstein",
   "Lithuania",
   "Luxembourg",
   "Macau",
   "Macedonia",
   "Madagascar",
   "Malawi",
   "Malaysia",
   "Maldives",
   "Mali",
   "Malta",
   "Marocco",
   "Marshall Islands",
   "Martinique",
   "Mauritania",
   "Mauritius",
   "Mayotte",
   "Mexico",
   "Micronesia, Federated States of",
   "Midway Islands",
   "Moldova",
   "Mongolia",
   "Montenegro",
   "Montserrat",
   "Mozambique",
   "Namibia",
   "Nauru",
   "Navassa Island",
   "Nepal",
   "Netherlands",
   "Netherlands Antilles",
   "New Caledonia",
   "New Zealand",
   "Nicaragua",
   "Niger",
   "Nigeria",
   "Niue",
   "No Man's Land",
   "Norfolk Island",
   "Northern Ireland",
   "Northern Mariana Islands",
   "Norway",
   "Oman",
   "Pakistan",
   "Palau",
   "Palmyra Atoll",
   "Panama",
   "Papua New Guinea",
   "Paracel Islands",
   "Paraguay",
   "Peru",
   "Philippines",
   "Pitcairn Islands",
   "Poland",
   "Portugal",
   "Principato di Monaco",
   "Puerto Rico",
   "Qatar",
   "Reunion",
   "Romania",
   "Russia",
   "Rwanda",
   "Saint Helena",
   "Saint Kitts and Nevis",
   "Saint Lucia",
   "Saint Pierre and Miquelon",
   "Saint Vincent and the Grenadines",
   "Samoa",
   "San Marino",
   "Sao Tome and Principe",
   "Saudi Arabia",
   "Scotland",
   "Senegal",
   "Serbia",
   "Seychelles",
   "Sierra Leone",
   "Singapore",
   "Slovakia",
   "Slovenia",
   "Solomon Islands",
   "Somalia",
   "South Africa",
   "South Georgia and the South Sandwich Islands",
   "Spagna",
   "Spratly Islands",
   "Sri Lanka",
   "Sudan",
   "Suriname",
   "Svalbard",
   "Svizzera",
   "Swaziland",
   "Sweden",
   "Syria",
   "Taiwan",
   "Tajikistan",
   "Tanzania",
   "Thailand",
   "Timor Leste",
   "Togo",
   "Tokelau",
   "Tonga",
   "Trinidad and Tobago",
   "Tromelin Island",
   "Tunisia",
   "Turkey",
   "Turkmenistan",
   "Turks and Caicos Islands",
   "Tuvalu",
   "Uganda",
   "Ukraine",
   "United Arab Emirates",
   "United States",
   "Uruguay",
   "Uzbekistan",
   "Vanuatu",
   "Venezuela",
   "Vietnam",
   "Virgin Islands",
   "Wake Island",
   "Wales",
   "Wallis and Futuna",
   "West Bank",
   "Western Sahara",
   "Yemen",
   "Zambia",
   "Zimbabwe",
] as const


export type INationalities = typeof nationalities; 
