import { ICustomForm } from "../../../models/CustomForm";
import { POSITION } from "../../../models/RealEstate";
import { fillSelection } from "../../../Utils/fuctions";
import { architecturalBarriersTypes, positionOptions } from "../selections";

export interface ILocationFormLand {
  city?: string; // città
  state?: string; // stato/provincia
  region?: string; // regione
  unity?: string; // civico o affini
  street?: string; // via
  latitude?: number | null | string;
  longitude?: number | null | string;
  country?: string;
  zone: string;
  position?: POSITION; // posizione
  seaLakeDistance?: number; // distanza mare/lago
  missingArchitecturalBarriers?: boolean; // barriere architettoniche assenti
  zipCode?: string;
  municipalityId: string;
}

export const initialValueLocationFormLand = {
  street: "",
  city: "",
  state: "",
  region: "",
  unity: "",
  zone: "",
  country: "",
  latitude: null,
  longitude: null,
  position: "",
  seaLakeDistance: 0,
  missingArchitecturalBarriers: false,
  zipCode: "",
  municipalityId: "",
};

export const locationFormLand: ICustomForm<ILocationFormLand> = {
  title: {
    edit: "realEstate.forms.location.title",
    show: "realEstate.forms.location.title",
  },
  description: { edit: "", show: "" },
  labelSubmitButton: { edit: "editImage.save", show: "editImage.save" },
  next: "description",
  forms: [
    {
      title: "realEstate.forms.location.address",
      fields: [
        {
          label: "",
          type: "location2",
          maxColumn: 12,
          isRequired: true,
          key: "",
        },
      ],
    },
    {
      title: "",
      fields: [
        {
          label: "address.nation",
          offset: 12,
          type: "text",
          isRequired: true,
          key: "country",
        },
        {
          label: "address.province",
          type: "text",
          isRequired: true,
          key: "state",
        },
        {
          label: "address.city",
          type: "text",
          isRequired: true,
          key: "city",
        },
        {
          label: "address.address",
          type: "text",
          isRequired: true,
          key: "street",
        },
        {
          label: "realEstate.forms.location.zone",
          type: "text",
          key: "zone",
        },
        {
          column: 2,
          label: "address.civic",
          type: "text",
          key: "unity",
        },
        {
          column: 2,
          label: "address.zipCode",
          type: "text",
          key: "zipCode",
        },
        {
          label: "realEstate.forms.location.latitude",
          type: "number",
          key: "latitude",
          disabled: true,
          column: 5,
        },
        {
          column: 5,
          label: "realEstate.forms.location.longitude",
          type: "number",
          key: "longitude",
          disabled: true,
        },
        {
          column: 2,
          label: "address.istatCode",
          type: "text",
          key: "municipalityId",
        },

        {
          label: "realEstate.forms.location.position",
          type: "select",
          key: "position",
          options: fillSelection(positionOptions),
        },
        {
          column: 2,
          label: "createDemandCustomerForm.infoRealEstate.seaLakeDistance",
          type: "number",
          key: "seaLakeDistance",
        },
      ],
    },
    {
      title: "realEstate.forms.location.info.title",
      fields: [
        {
          label: "realEstate.forms.location.architecturalBarriers",
          type: "select",
          key: "missingArchitecturalBarriers",
          options: fillSelection(architecturalBarriersTypes, false),
        },
      ],
    },
  ],
};
