import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Image, IMAGE_TAG } from "../../forms/realeEstate/images";
import SkeletonImage from "../../assets/img/estate-placeholder.jpeg";
import {
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Box,
  useTab,
  AspectRatio,
} from "@chakra-ui/react";
import CustomButton from "../../shared/components/Button/Button";
import getClasses from "../../Utils/getClasses";
import SliderImages from "./SliderImages/SliderImages";
import CustomDrawer from "../../shared/components/CustomDrawer/CustomDrawer";
import { getUrlYoutube } from "../../Utils/fuctions";
import { useI18n } from "../../hooks/useI18n";
import "./GridImages.scss";

interface IGridImages {
  images: Image[];
  urlVideo: {
    virtualTour: string;
    media: string;
  };
}

const ZoomImages: FC<{
  isOpenSlider: boolean | null;
  onCloseSlider: () => void;
  images: Image[];
  index: number;
}> = ({ isOpenSlider, onCloseSlider, images, index }) => (
  <CustomDrawer isOpen={isOpenSlider} zIndex={60000} removeBodyScroll={false}>
    <div className="mt-4">
      <i
        className="close-button-slider icon icon-close"
        onClick={() => onCloseSlider()}
      />
      <SliderImages images={images} index={index} />
    </div>
  </CustomDrawer>
);

const GridImages: FC<IGridImages> = ({
  images,
  urlVideo: { virtualTour, media },
}) => {
  const { t } = useI18n();

  const [virtualTourUrl, setvirtualTourUrl] = useState("");
  const [mediaUrl, setMediaUrl] = useState("");

  const setAllUrl = async () => {
    setvirtualTourUrl(await getUrlYoutube(virtualTour));
    setMediaUrl(await getUrlYoutube(media));
  };
  useEffect(() => {
    setAllUrl();

    // eslint-disable-next-line
  }, []);

  const CustomTab = React.forwardRef((props: { children: string }, ref) => {
    const tabProps = useTab({ ...props, ref } as any);
    const isSelected = !!tabProps["aria-selected"];

    return (
      <Box
        className={getClasses({
          "container-tabs": true,
          "container-tabs-selected": isSelected,
        })}
        {...tabProps}
      >
        <span className={"tabs-label"}>{tabProps.children}</span>
      </Box>
    );
  });

  const changeAlign = (align: "left" | "center" | "right") =>
    (({
      left: "center",
      center: "right",
      right: "left",
    } as const)[align]);

  const renderImages = useCallback(
    (images: Image[], onClick: (images: Image[], index: number) => void) => {
      let alignImage: "left" | "center" | "right" = "right";
      return (
        <div className="container-images">
          {images.map((image, index) => {
            alignImage = changeAlign(alignImage);
            return (
              <img
                key={index}
                className={`image pointer ${alignImage}`}
                onClick={() => onClick(images, index)}
                src={image.thumbnail}
                alt="realestate"
              ></img>
            );
          })}
        </div>
      );
    },
    []
  );

  const [isOpenSlider, setOpenSlider] = useState<boolean | null>(null);
  const [isOpen, setIsOpen] = useState<boolean | null>(null);
  const [{ images: selectedImages, index }, setselectedImages] = useState<{
    images: Image[];
    index: number;
  }>({ images: [], index: 0 });

  const { publicImages, planimetryImages, privateImages } = useMemo(
    () =>
      images.reduce(
        (acc, arr) => {
          acc[arr.isPublic ? "publicImages" : "privateImages"].push(arr);
          arr.tag === IMAGE_TAG.PLANIMETRY && acc.planimetryImages.push(arr);
          return acc;
        },
        { publicImages: [], privateImages: [], planimetryImages: [] } as Record<
          "publicImages" | "privateImages" | "planimetryImages",
          Image[]
        >
      ),
    // eslint-disable-next-line
    []
  );

  const onClickImage = (images: Image[], index: number) => {
    setselectedImages({ images, index });
    setOpenSlider(true);
  };

  const closeSlider = () => {
    setOpenSlider(false);
  };

  return (
    <div className="container-grid-images">
      <div className="container-left">
        <img
          className="image"
          alt="realEstate-1"
          onError={(e) => {
            e.currentTarget.src = SkeletonImage;
          }}
          src={images[0]?.url || SkeletonImage}
        ></img>
      </div>
      <div className="container-right">
        <img
          className="image"
          alt="realEstate-2"
          onError={(e) => {
            e.currentTarget.src = SkeletonImage;
          }}
          src={images[1]?.url || SkeletonImage}
        ></img>
        <div className="position-relative h-49">
          <img
            className="image-bottom"
            alt="realEstate-3"
            onError={(e) => {
              e.currentTarget.src = SkeletonImage;
            }}
            src={images[2]?.url || SkeletonImage}
          ></img>
          <div className="container-show-gallery">
            <CustomButton
              onClick={() => {
                setIsOpen(true);
              }}
            >
              {t("gallery.showGallery")}
            </CustomButton>
          </div>
        </div>
      </div>

      <CustomDrawer isOpen={isOpen}>
        <>
          <i
            className="close-button icon icon-ch-left"
            onClick={() => setIsOpen(false)}
          />
          <Tabs className="mt-5" align="center">
            <TabList border={"none"}>
              <CustomTab>{t("gallery.photo")}</CustomTab>
              <CustomTab>{t("gallery.planimetry")}</CustomTab>
              <CustomTab>{t("gallery.videoTour")}</CustomTab>
              <CustomTab>{t("gallery.media")}</CustomTab>
            </TabList>

            <TabPanels textAlign={"start"}>
              <TabPanel>
                <h4 className="title">{t("gallery.publicPhoto")}</h4>
                {renderImages(publicImages, onClickImage)}
                <h4 className="title">{t("gallery.privatePhoto")}</h4>
                {renderImages(privateImages, onClickImage)}
              </TabPanel>
              <TabPanel>
                <h4 className="title">{t("gallery.planimetry")}</h4>
                {renderImages(planimetryImages, onClickImage)}
              </TabPanel>
              <TabPanel>
                <div className="d-flex flex-column align-items-center">
                  {virtualTourUrl && (
                    <>
                      {virtualTourUrl !== "error" ? (
                        <div className="container-aspect-radio">
                          <AspectRatio _before={{ p: 0 }} h={"100%"} w={"100%"}>
                            <iframe
                              title="virtual tour"
                              src={virtualTourUrl}
                              allowFullScreen
                              allow={
                                virtualTour.includes("kuula")
                                  ? "vr,gyroscope,accelerometer,fullscreen"
                                  : undefined
                              }
                            />
                          </AspectRatio>
                        </div>
                      ) : (
                        <p className="error-url mt-2">
                          {t("gallery.errorUrl")}
                        </p>
                      )}
                    </>
                  )}
                </div>
              </TabPanel>
              <TabPanel>
                <div className="d-flex flex-column align-items-center">
                  {mediaUrl && (
                    <>
                      {mediaUrl !== "error" ? (
                        <div className="container-aspect-radio">
                          <AspectRatio _before={{ p: 0 }} h={"100%"} w={"100%"}>
                            <iframe
                              title="virtual tour"
                              src={mediaUrl}
                              allowFullScreen
                            />
                          </AspectRatio>
                        </div>
                      ) : (
                        <p className="error-url mt-2">
                          {t("gallery.errorUrl")}
                        </p>
                      )}
                    </>
                  )}
                </div>
              </TabPanel>
            </TabPanels>
          </Tabs>
          <ZoomImages
            isOpenSlider={isOpenSlider}
            onCloseSlider={closeSlider}
            images={selectedImages}
            index={index}
          />
        </>
      </CustomDrawer>
    </div>
  );
};

export default GridImages;
