import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Menu,
  MenuButton,
  MenuList,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import React, { FC, Fragment, useEffect, useState } from "react";
import InfoTooltip from "../../../../components/Tooltip/Tooltip";
import { useEndpoints } from "../../../../hooks/useEndpoints";
import { Form, FormReturnType } from "../../../../hooks/useForm";
import { useI18n } from "../../../../hooks/useI18n";
import { Customer, CUSTOMER_TYPE } from "../../../../models/Customer";
import { Field } from "../../../../models/CustomForm";
import CreateCustomer from "../../../../pages/CreateCustomer";
import { getNestedValueFromString } from "../../../../Utils/fuctions";
import getClasses from "../../../../Utils/getClasses";
import addUserIcon from "../../../../assets/img/add-user.svg";
import "./OwnerInput.scss";

interface IOwnerInput {
  form: Partial<Form<any>>;
  setForm: any;
  data: Field;
  handleError: FormReturnType<any>["handleError"];
  error: string | undefined;
}

type ILabelSelect = Pick<Customer, "_id" | "name" | "surname" | "rating">;

const OwnerInput: FC<IOwnerInput> = ({
  handleError,
  data: {
    isRequired,
    label,
    type,
    column = 4,
    offset = 0,
    key,
    options,
    maxColumn,
  },
  form,
  setForm,
  error,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedOwners, setSelectedOwners] = useState<
    (ILabelSelect | undefined)[]
  >(
    getNestedValueFromString<Customer[]>(key, form).length > 0
      ? getNestedValueFromString<Customer[]>(key, form)
          .filter((item) => item)
          .map(({ name, surname, _id, rating }) => ({
            name,
            surname,
            _id,
            rating,
          }))
      : [undefined]
  );
  const { getCustomers } = useEndpoints();

  const addCowner = () => setSelectedOwners((prev) => [...prev, undefined]);
  const removeCowner = (index: number) =>
    setSelectedOwners((prev) => {
      setSelectedOwners((prev) => {
        prev.splice(index, 1);
        return [...prev];
      });
      return [...prev];
    });

  const handleClick = () => {
    onOpen();
  };

  // const valueForm = useMemo(() => getNestedValueFromString(key, form), [form]);

  const handleSelectOwner = (select: ILabelSelect, index: number) => {
    setSelectedOwners((prev) => {
      prev[index] = select;
      return [...prev];
    });
  };

  const renderDrawer = () => {
    return (
      <Drawer onClose={onClose} isOpen={isOpen} size={"full"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody className="drawer-body">
            <CreateCustomer
            className="container-drawer-form mt-5"
              options={{ onSubmitNavigate: false }}
              onClose={() => onClose()}
              onSubmit={(customer) => {
                if (
                  customer &&
                  customer.customerTypes?.some((type) =>
                    [CUSTOMER_TYPE.OWNER, CUSTOMER_TYPE.APPLICANT].some(
                      (customType) => customType === type
                    )
                  )
                ) {
                  const mappedCustomer = Object.fromEntries(
                    Object.entries(customer).filter(([key]) =>
                      ["_id", "rating", "name", "surname"].some(
                        (keyObj) => key === keyObj
                      )
                    )
                  );
                  handleSelectOwner(
                    mappedCustomer as ILabelSelect,
                    selectedOwners[selectedOwners.length - 1] === undefined
                      ? selectedOwners.length - 1
                      : selectedOwners.length
                  );
                }
                onClose();
              }}
              fetchCustomerById={false}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  };

  // useEffect(() => {
  //   setSelectedOwners((prev) => prev.slice(0, owners))
  // }, [owners])

  useEffect(() => {
    setForm(
      key,
      selectedOwners.filter((item) => item)
    );
    // eslint-disable-next-line
  }, [selectedOwners]);

  const option = (select: ILabelSelect, index: number) => {
    const { name, surname } = select;
    return (
      <div
        onClick={() => {
          handleSelectOwner(select, index);
          error && handleError(key, "", false);
        }}
        className="menu-flex"
      >
        <span className="capitalize-first-letter">{`${name} ${surname}`}</span>
      </div>
    );
  };

  const Owner: FC<{ index: number }> = ({ index }) => {
    const [searchOwner, setSearchOwner] = useState("");
    const [isLoading, setIsloading] = useState(false);
    const [labelSelect, setlabelSelect] = useState<
      Pick<Customer, "_id" | "name" | "surname" | "rating">[]
    >([]);

    const { t } = useI18n();

    const fetchCustomer = async () => {
      setIsloading(true);
      try {
        const {
          data: { docs },
        } = await getCustomers({
          skip: 0,
          limit: 1000,
          search: searchOwner,
          customerTypes: "applicant,owner",
        });
        setlabelSelect(
          docs.map(({ name, surname, _id, rating }) => ({
            name,
            surname,
            _id,
            rating,
          }))
        );
      } catch (e) {
        console.error(e);
      } finally {
        setIsloading(false);
      }
    };
    useEffect(() => {
      if (searchOwner.length >= 3) {
        fetchCustomer();
      }
      if (searchOwner.length === 0) {
        setlabelSelect([]);
      }
      // eslint-disable-next-line
    }, [searchOwner]);

    return (
      <div
        key={index}
        className={`offset-lg-${offset} ${getClasses({
          "form-group": true,
          "is-invalid": error,
        })}`}
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <label
              className="form-control-label capitalize-first-letter"
              htmlFor="name"
            >
              {index === 0
                ? `${t(label)} ${getClasses({ "*": isRequired })}`
                : "Comproprietario"}
            </label>
            {index === 0 && (
              <i onClick={addCowner} className="icon icon-add ms-1 pointer"></i>
            )}
          </div>
          <div className="d-flex align-items-baseline">
            <>
              {index === 0 ? (
                <>
                  <InfoTooltip
                    label={t(
                      "realEstate.forms.residential.generalForm.titles.property.labels.tooltip"
                    )}
                  >
                    <div className="position-relative">
                      <img
                        onClick={handleClick}
                        className="icon-add-user"
                        src={addUserIcon}
                        alt="icon add customer"
                      />
                    </div>
                  </InfoTooltip>
                </>
              ) : (
                <i
                  onClick={() => removeCowner(index)}
                  className="icon color-red icon-delete pointer me-2"
                ></i>
              )}
            </>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <Menu>
            <MenuButton
              border={error && "1px solid #b71922"}
              width={"100%"}
              className="menu-button"
            >
              <div className="container-text-default">
                <span
                  className={`${getClasses({
                    "menu-button-value capitalize-first-letter":
                      Boolean(labelSelect),
                    "menu-button-default": !labelSelect,
                  })}`}
                >
                  {selectedOwners[index] !== undefined
                    ? `${selectedOwners[index]!.surname} ${
                        selectedOwners[index]!.name
                      }`
                    : index === 0
                    ? `Cerca proprietario`
                    : `Cerca comproprietario`}
                </span>

                <ChevronDownIcon w={6} h={6} />
              </div>
            </MenuButton>
            <MenuList
              overflowY={"auto"}
              width={"calc((100vw - 370px) / 3)"}
              p={0}
              border={"none"}
              zIndex={1000}
            >
              <div className="d-flex justify-content-between align-items-center container-search-input px-2 py-1 ">
                <input
                  value={searchOwner}
                  onChange={({ target: { value } }) => setSearchOwner(value)}
                  type={"text"}
                  className={"form-controll search-input w-100 "}
                  placeholder={"search"}
                />
                {isLoading && <Spinner />}
              </div>
              <div className="container-menu-item">
                {labelSelect.map(({ name, surname, _id, rating }, i) => (
                  <div
                    key={_id}
                    className="menu-item"
                    aria-disabled={selectedOwners.some(
                      (item) => item?._id === _id
                    )}
                  >
                    {option({ name, surname, _id, rating }, index)}
                  </div>
                ))}
              </div>
            </MenuList>
          </Menu>
        </div>
        {error && (
          <div className="invalid-feedback">
            <div>{error}</div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className={`col-lg-${column}`}>
        {selectedOwners.map((_, i) => {
          return (
            <Fragment key={i}>
              <Owner index={i} />
            </Fragment>
          );
        })}
        {renderDrawer()}
      </div>
      {maxColumn && <div className={`col-lg-${maxColumn}`} />}
    </>
  );
};

export default OwnerInput;
