import { NestedKeys } from "advanced-types";
import { Translation } from "../../assets/i18n/locals/en";
import { RATING } from "../../models/Customer";
import { DEMAND_STATUS, PARKING } from "../../models/demandCustomer";
import {
  COMMERCIAL_PROPERTY_TYPE,
  CONTEXT,
  FEE,
  ROOM_SEPARATOR,
  WEEKLY_REST,
} from "../../models/RealeEstateCommercial";
import { LAND_KIND } from "../../models/RealEstateLand";
import {
  AVAILABILITY,
  BOX_TYPE,
  CONSERVATION_STATUS,
  CONTRACT_TYPE,
  CURRENT_STATE,
  ELEVATOR_INFO,
  ENERGY_CLASS,
  ENERGY_CLASS_OLD,
  ENERGY_PERFORMANCE,
  FURNITURE_STATUS,
  GARDEN_TYPE,
  HABITABILITY,
  HEATING_FUEL,
  HEATING_SYSTEM,
  HEATING_TYPE,
  KITCHEN_TYPE,
  MEDIATION,
  PERFORMANCE_UNIT,
  PROPERTY_TYPE,
  QUALITY,
  REAL_ESTATE_STATE,
  ROOM,
  ROOM_PERTINENCE_EQUIPMENT,
  ROOM_PERTINENCE_TYPE,
  FLOOR,
  POSITION,
  SOUND_CLASS,
  RENT_CONTRACT,
  WARRANTY,
  CONTRACT_REGISTRATION,
  ELECTRICAL_SYSTEM_STATUS,
  TV_STATUS,
} from "../../models/RealEstate";
import { IMAGE_TAG } from "./images";
import { LAND_PROPERTY_TYPE } from "../../models/RealEstateLand";

// GENERAL

export type SelectOptions<T> = Partial<Record<NestedKeys<Translation, ".">, T>>;

export const ratingOptions: SelectOptions<RATING> = {
  "selectOptions.rating.STANDARD": RATING.STANDARD,
  "selectOptions.rating.LOW": RATING.LOW,
  "selectOptions.rating.HIGH": RATING.HIGH,
  "selectOptions.rating.EXCELLENT": RATING.EXCELLENT,
};

export const stateOptions: SelectOptions<REAL_ESTATE_STATE> = {
  "selectOptions.state.ACTIVE": REAL_ESTATE_STATE.ACTIVE,
  "selectOptions.state.REMOVED_FROM_MARKET":
    REAL_ESTATE_STATE.REMOVED_FROM_MARKET,
};

export const mediationOptions: SelectOptions<MEDIATION> = {
  "selectOptions.mediation.EXCLUSIVE": MEDIATION.EXCLUSIVE,
  "selectOptions.mediation.NOT_EXCLUSIVE": MEDIATION.NOT_EXCLUSIVE,
};

export const contractOptions: SelectOptions<CONTRACT_TYPE> = {
  "selectOptions.contractOptions.RENT": CONTRACT_TYPE.RENT,
  "selectOptions.contractOptions.SALE": CONTRACT_TYPE.SALE,
};
export const rentDetailsContractRegistration: SelectOptions<CONTRACT_REGISTRATION> =
  {
    "selectOptions.rentDetailsContractRegistration.OWNER":
      CONTRACT_REGISTRATION.OWNER,
    "selectOptions.rentDetailsContractRegistration.AGENCY":
      CONTRACT_REGISTRATION.AGENCY,
  };
export const rentDetailsContractType: SelectOptions<RENT_CONTRACT> = {
  "selectOptions.rentDetailsContractType.THREE_PLUS_TWO":
    RENT_CONTRACT.THREE_PLUS_TWO,
  "selectOptions.rentDetailsContractType.THREE_PLUS_TWO_TAX":
    RENT_CONTRACT.THREE_PLUS_TWO_TAX,
  "selectOptions.rentDetailsContractType.FOUR_PLUS_TWO":
    RENT_CONTRACT.FOUR_PLUS_TWO,
  "selectOptions.rentDetailsContractType.FOUR_PLUS_FOUR":
    RENT_CONTRACT.FOUR_PLUS_FOUR,
  "selectOptions.rentDetailsContractType.FOUR_PLUS_FOUR_TAX":
    RENT_CONTRACT.FOUR_PLUS_FOUR_TAX,
  "selectOptions.rentDetailsContractType.SIX_PLUS_TWO":
    RENT_CONTRACT.SIX_PLUS_TWO,
  "selectOptions.rentDetailsContractType.SIX_PLUS_SIX":
    RENT_CONTRACT.SIX_PLUS_SIX,
  "selectOptions.rentDetailsContractType.ROOM": RENT_CONTRACT.ROOM,
  "selectOptions.rentDetailsContractType.TRANSITIONAL":
    RENT_CONTRACT.TRANSITIONAL,
  "selectOptions.rentDetailsContractType.TRANSITIONAL_TAX":
    RENT_CONTRACT.TRANSITIONAL_TAX,
  "selectOptions.rentDetailsContractType.NOT_PROVIDED":
    RENT_CONTRACT.NOT_PROVIDED,
};

export const rentDetailsRequestedWarranty: SelectOptions<WARRANTY> = {
  "selectOptions.rentDetailsRequestedWarranty.NONE": WARRANTY.NONE,
  "selectOptions.rentDetailsRequestedWarranty.SECURITY_DEPOSIT":
    WARRANTY.SECURITY_DEPOSIT,
  "selectOptions.rentDetailsRequestedWarranty.INSURANCE_POLICY":
    WARRANTY.INSURANCE_POLICY,
  "selectOptions.rentDetailsRequestedWarranty.BANK_GUARANTEE":
    WARRANTY.BANK_GUARANTEE,
};
export const propertyOptionsLand: SelectOptions<LAND_PROPERTY_TYPE> = {
  "selectOptions.propertyOptionsLand.NON_BUILDING_LAND":
    LAND_PROPERTY_TYPE.NON_BUILDING_LAND,
  "selectOptions.propertyOptionsLand.BUILDING_LAND":
    LAND_PROPERTY_TYPE.BUILDING_LAND,
  "selectOptions.propertyOptionsLand.AGRICULTURAL_LAND":
    LAND_PROPERTY_TYPE.AGRICULTURAL_LAND,
  "selectOptions.propertyOptionsLand.COMMERCIAL_LAND":
    LAND_PROPERTY_TYPE.COMMERCIAL_LAND,
};
export const propertyOptionsCommercial: SelectOptions<COMMERCIAL_PROPERTY_TYPE> =
  {
    "selectOptions.propertyOptionsCommercial.FARMHOUSE":
      COMMERCIAL_PROPERTY_TYPE.FARMHOUSE, // agriturismo,
    "selectOptions.propertyOptionsCommercial.HOTEL":
      COMMERCIAL_PROPERTY_TYPE.HOTEL,
    "selectOptions.propertyOptionsCommercial.COMMERCIAL_ACTIVITY":
      COMMERCIAL_PROPERTY_TYPE.COMMERCIAL_ACTIVITY,
    "selectOptions.propertyOptionsCommercial.FARM":
      COMMERCIAL_PROPERTY_TYPE.FARM, // azienda agricola
    "selectOptions.propertyOptionsCommercial.BAR": COMMERCIAL_PROPERTY_TYPE.BAR,
    "selectOptions.propertyOptionsCommercial.CAMPING_AREA":
      COMMERCIAL_PROPERTY_TYPE.CAMPING_AREA,
    "selectOptions.propertyOptionsCommercial.CHALET":
      COMMERCIAL_PROPERTY_TYPE.CHALET,
    "selectOptions.propertyOptionsCommercial.COMMERCIAL_OTHER":
      COMMERCIAL_PROPERTY_TYPE.COMMERCIAL_OTHER,
    "selectOptions.propertyOptionsCommercial.DANCE_CLUB":
      COMMERCIAL_PROPERTY_TYPE.DANCE_CLUB, // discoteca
    "selectOptions.propertyOptionsCommercial.LABORATORY":
      COMMERCIAL_PROPERTY_TYPE.LABORATORY,
    "selectOptions.propertyOptionsCommercial.COMMERCIAL_LOCAL":
      COMMERCIAL_PROPERTY_TYPE.COMMERCIAL_LOCAL,
    "selectOptions.propertyOptionsCommercial.STORE":
      COMMERCIAL_PROPERTY_TYPE.STORE,
    "selectOptions.propertyOptionsCommercial.RESTAURANT":
      COMMERCIAL_PROPERTY_TYPE.RESTAURANT,
    "selectOptions.propertyOptionsCommercial.SHOW_ROOM":
      COMMERCIAL_PROPERTY_TYPE.SHOW_ROOM,
    "selectOptions.propertyOptionsCommercial.BUILDING":
      COMMERCIAL_PROPERTY_TYPE.BUILDING, // stabile / palazzo
    "selectOptions.propertyOptionsCommercial.OFFICE":
      COMMERCIAL_PROPERTY_TYPE.OFFICE,
    "selectOptions.propertyOptionsCommercial.NOT_PROVIDED":
      COMMERCIAL_PROPERTY_TYPE.NOT_PROVIDED,
    "selectOptions.propertyOptionsCommercial.DEPOSIT":
      COMMERCIAL_PROPERTY_TYPE.DEPOSIT,
  };

export const eletricalSystemType: SelectOptions<ELECTRICAL_SYSTEM_STATUS> = {
  "selectOptions.otherInfoLabel.ELECTRICAL_SYSTEM_STATUS.LEGAL":
    ELECTRICAL_SYSTEM_STATUS.LEGAL,
  "selectOptions.otherInfoLabel.ELECTRICAL_SYSTEM_STATUS.NOT_LEGAL":
    ELECTRICAL_SYSTEM_STATUS.NOT_LEGAL,
  "selectOptions.otherInfoLabel.ELECTRICAL_SYSTEM_STATUS.ABSENT":
    ELECTRICAL_SYSTEM_STATUS.ABSENT,
};

export const propertyOptions: SelectOptions<
  PROPERTY_TYPE | COMMERCIAL_PROPERTY_TYPE | LAND_PROPERTY_TYPE
> = {
  "selectOptions.propertyOptions.APPARTMENT": PROPERTY_TYPE.APPARTMENT,
  "selectOptions.propertyOptions.ATTIC": PROPERTY_TYPE.ATTIC,
  "selectOptions.propertyOptions.BASEMENT": PROPERTY_TYPE.BASEMENT,
  "selectOptions.propertyOptions.CASTLE": PROPERTY_TYPE.CASTLE,
  "selectOptions.propertyOptions.CHALET": PROPERTY_TYPE.CHALET,
  "selectOptions.propertyOptions.DUPLEX": PROPERTY_TYPE.DUPLEX,
  "selectOptions.propertyOptions.FARM": PROPERTY_TYPE.FARM,
  "selectOptions.propertyOptions.FARMHOUSE": PROPERTY_TYPE.FARMHOUSE,
  "selectOptions.propertyOptions.HOUSE_SECTION": PROPERTY_TYPE.HOUSE_SECTION,
  "selectOptions.propertyOptions.INDEPENDENT_HOUSE":
    PROPERTY_TYPE.INDEPENDENT_HOUSE,
  "selectOptions.propertyOptions.LOFT": PROPERTY_TYPE.LOFT,
  "selectOptions.propertyOptions.MULTI_PROPRIETY":
    PROPERTY_TYPE.MULTI_PROPRIETY,
  "selectOptions.propertyOptions.NEW_CONSTRUCTION":
    PROPERTY_TYPE.NEW_CONSTRUCTION,
  "selectOptions.propertyOptions.OTHER": PROPERTY_TYPE.OTHER,
  "selectOptions.propertyOptions.RESIDENCE": PROPERTY_TYPE.RESIDENCE,
  "selectOptions.propertyOptions.ROOM": PROPERTY_TYPE.ROOM,
  "selectOptions.propertyOptions.RUSTIC": PROPERTY_TYPE.RUSTIC,
  //"selectOptions.propertyOptions.COMMERCIALOTHER": COMMERCIAL_PROPERTY_TYPE.COMMERCIAL_OTHER,
  "selectOptions.propertyOptions.SEMI_DETACHED_VILLA":
    PROPERTY_TYPE.SEMI_DETACHED_VILLA,
  "selectOptions.propertyOptions.SEMI_INDEPENDENT_HOUSE":
    PROPERTY_TYPE.SEMI_INDEPENDENT_HOUSE,
  "selectOptions.propertyOptions.SINGLE_VILLA": PROPERTY_TYPE.SINGLE_VILLA,
  "selectOptions.propertyOptions.TERRACED_HOUSE": PROPERTY_TYPE.TERRACED_HOUSE,
  "selectOptions.propertyOptions.VACATION_HOME": PROPERTY_TYPE.VACATION_HOME,
  "selectOptions.propertyOptions.VILLA": PROPERTY_TYPE.VILLA,
  "selectOptions.propertyOptions.VILLA_SECTION": PROPERTY_TYPE.VILLA_SECTION,
};

export const currentStateOptions: SelectOptions<CURRENT_STATE> = {
  "selectOptions.currentState.FREE_TO_DEED": CURRENT_STATE.FREE_TO_DEED,
  "selectOptions.currentState.LEASED": CURRENT_STATE.LEASED,
  "selectOptions.currentState.UNDER_CONSTRUCTION":
    CURRENT_STATE.UNDER_CONSTRUCTION,
};

// LOCATIONS

export const floorNumbers = Object.fromEntries(
  Array.from({ length: 70 }, (_, i) => [`${i + 1}`, i + 1])
);

export const tvOptions: SelectOptions<TV_STATUS> = {
  "selectOptions.otherInfoLabel.TV_STATUS.AUTONOMOUS": TV_STATUS.AUTONOMOUS,
  "selectOptions.otherInfoLabel.TV_STATUS.CONDOMINIUM": TV_STATUS.CONDOMINIUM,
  "selectOptions.otherInfoLabel.TV_STATUS.UNPROVIDED": TV_STATUS.UNPROVIDED,
};

export const floorOptions: SelectOptions<FLOOR> = {
  "selectOptions.floorOptions.ATTIC": FLOOR.ATTIC,
  "selectOptions.floorOptions.BASEMENT": FLOOR.BASEMENT,
  "selectOptions.floorOptions.BUILDING": FLOOR.BUILDING,
  "selectOptions.floorOptions.EARTH_RETAINING_WALL": FLOOR.EARTH_RETAINING_WALL,
  "selectOptions.floorOptions.GROUND_FLOOR": FLOOR.GROUND_FLOOR,
  "selectOptions.floorOptions.MEZZANINE": FLOOR.MEZZANINE,
  "selectOptions.floorOptions.MULTISTOREY": FLOOR.MULTISTOREY,
  "selectOptions.floorOptions.NOBLE_FLOOR": FLOOR.NOBLE_FLOOR,
  "selectOptions.floorOptions.ON_TWO_LEVELS": FLOOR.ON_TWO_LEVELS,
  "selectOptions.floorOptions.TOP_FLOOR": FLOOR.TOP_FLOOR,
};

export const positionOptions: SelectOptions<POSITION> = {
  "selectOptions.position.AGRICULTURAL_AREA": POSITION.AGRICULTURAL_AREA,
  "selectOptions.position.BOOMING_AREA": POSITION.BOOMING_AREA,
  "selectOptions.position.CENTRAL": POSITION.CENTRAL,
  "selectOptions.position.HIGH_TRAFFIC_STREET": POSITION.HIGH_TRAFFIC_STREET,
  "selectOptions.position.INDUSTRIAL_AREA": POSITION.INDUSTRIAL_AREA,
  "selectOptions.position.ISOLATED": POSITION.ISOLATED,
  "selectOptions.position.MALL": POSITION.MALL,
  "selectOptions.position.MIXED_AREA": POSITION.MIXED_AREA,
  "selectOptions.position.PEEP_AREA": POSITION.PEEP_AREA,
  "selectOptions.position.PERIPHERAL": POSITION.PERIPHERAL,
  "selectOptions.position.RESIDENTIAL_AREA": POSITION.RESIDENTIAL_AREA,
  "selectOptions.position.SEAFRONT": POSITION.SEAFRONT,
  "selectOptions.position.SEMICENTRAL": POSITION.SEMICENTRAL,
  "selectOptions.position.SERVED_AREA": POSITION.SERVED_AREA,
  "selectOptions.position.TOURIST": POSITION.TOURIST,
};

export const elevatorOptions: SelectOptions<ELEVATOR_INFO> = {
  "selectOptions.elevatorOprions.YES": ELEVATOR_INFO.YES,
  "selectOptions.elevatorOprions.NO": ELEVATOR_INFO.NO,
};

//DESCRIPTION

export const kitchenTypes: SelectOptions<KITCHEN_TYPE> = {
  "selectOptions.kitchen.AT_SIGHT": KITCHEN_TYPE.AT_SIGHT,
  "selectOptions.kitchen.HABITABLE": KITCHEN_TYPE.HABITABLE,
  "selectOptions.kitchen.KITCHENETTE": KITCHEN_TYPE.KITCHENETTE,
  "selectOptions.kitchen.ABSENT": KITCHEN_TYPE.ABSENT,
  "selectOptions.kitchen.FOOD_COOK": KITCHEN_TYPE.FOOD_COOK,
  "selectOptions.kitchen.PRESENT": KITCHEN_TYPE.PRESENT,
  "selectOptions.kitchen.SEMI_HABITABLE": KITCHEN_TYPE.SEMI_HABITABLE,
};

export const gardenTypes: SelectOptions<GARDEN_TYPE> = {
  "selectOptions.garden.ABSENT": GARDEN_TYPE.ABSENT,
  "selectOptions.garden.IN_COMMON": GARDEN_TYPE.IN_COMMON,
  "selectOptions.garden.PRIVATE": GARDEN_TYPE.PRIVATE,
};

export const boxTypes: SelectOptions<BOX_TYPE> = {
  "selectOptions.box.SINGLE": BOX_TYPE.SINGLE,
  "selectOptions.box.DOUBLE": BOX_TYPE.DOUBLE,
  "selectOptions.box.TRIPLE": BOX_TYPE.TRIPLE,
  "selectOptions.box.QUADRUPLE": BOX_TYPE.QUADRUPLE,
  "selectOptions.box.SHED": BOX_TYPE.SHED,
  "selectOptions.box.ABSENT": BOX_TYPE.ABSENT,
};

export const dotationTypes: SelectOptions<ROOM_PERTINENCE_EQUIPMENT> = {
  "selectOptions.dotation.EQUIPPED_AND_FURNISHED":
    ROOM_PERTINENCE_EQUIPMENT.EQUIPPED_AND_FURNISHED,
  "selectOptions.dotation.EQUIPPED_NOT_FURNISHED":
    ROOM_PERTINENCE_EQUIPMENT.EQUIPPED_NOT_FURNISHED,
  "selectOptions.dotation.NOT_EQUIPPED_FURNISHED":
    ROOM_PERTINENCE_EQUIPMENT.NOT_EQUIPPED_FURNISHED,
};

export const roomPertinenceTypes: SelectOptions<ROOM_PERTINENCE_TYPE> = {
  "selectOptions.roomPertinence.ABSENT": ROOM_PERTINENCE_TYPE.ABSENT,
  "selectOptions.roomPertinence.PRESENT": ROOM_PERTINENCE_TYPE.PRESENT,
};

export const architecturalBarriersTypes: SelectOptions<ROOM_PERTINENCE_TYPE> = {
  "selectOptions.architecturalBarriers.ABSENT": ROOM_PERTINENCE_TYPE.ABSENT,
  "selectOptions.architecturalBarriers.PRESENT": ROOM_PERTINENCE_TYPE.PRESENT,
};
export const parkingTypes: SelectOptions<PARKING> = {
  "selectOptions.parking.covered": PARKING.COVERED,
  "selectOptions.parking.absent": PARKING.ABSENT,
  "selectOptions.parking.open": PARKING.OPEN,
};

export const countType = (lengthArray: number, offset: number = 0) =>
  Object.fromEntries([
    ["", undefined],
    ...Array.from({ length: lengthArray + 1 }, (_, i) => [
      i + offset,
      i + offset,
    ]),
  ]);
export const countTypeConditionsDemandCustomer = (
  lengthArray: number,
  offset: number = 0
) =>
  Object.fromEntries([
    ["*", "*"],
    ...Array.from({ length: lengthArray + 1 }, (_, i) => [
      i + offset,
      i + offset,
    ]),
  ]);

export const heatingDetailsType: SelectOptions<HEATING_TYPE> = {
  "selectOptions.heatingDetailsType.AUTONOMOUS": HEATING_TYPE.AUTONOMOUS,
  "selectOptions.heatingDetailsType.CENTRALIZED": HEATING_TYPE.CENTRALIZED,
  "selectOptions.heatingDetailsType.CENTRALIZED_WITH_METER":
    HEATING_TYPE.CENTRALIZED_WITH_METER,
  "selectOptions.heatingDetailsType.AIR_CONDITIONED":
    HEATING_TYPE.AIR_CONDITIONED,
  "selectOptions.heatingDetailsType.ABSENT": HEATING_TYPE.ABSENT,
};

export const additionalRooms: SelectOptions<ROOM> = {
  "selectOptions.additionalRooms.ROOF_TERRACE": ROOM.ROOF_TERRACE,
  "selectOptions.additionalRooms.OTHER": ROOM.OTHER,
  "selectOptions.additionalRooms.AMERICAN_ENSUITE": ROOM.AMERICAN_ENSUITE,
  "selectOptions.additionalRooms.ANTEROOM": ROOM.ANTEROOM,
  "selectOptions.additionalRooms.WARDROBE": ROOM.WARDROBE,
  "selectOptions.additionalRooms.DRYING_ROOM": ROOM.DRYING_ROOM,
  "selectOptions.additionalRooms.BATHROOM": ROOM.BATHROOM,
  "selectOptions.additionalRooms.BALCONY": ROOM.BALCONY,
  "selectOptions.additionalRooms.BAR": ROOM.BAR,
  "selectOptions.additionalRooms.BOX": ROOM.BOX,
  "selectOptions.additionalRooms.KIDS_ROOM": ROOM.KIDS_ROOM,
  "selectOptions.additionalRooms.ROOM_WITH_BATHROOM": ROOM.ROOM_WITH_BATHROOM,
  "selectOptions.additionalRooms.BEDROOM": ROOM.BEDROOM,
  "selectOptions.additionalRooms.SECURITY_ROOM": ROOM.SECURITY_ROOM,
  "selectOptions.additionalRooms.FAMILY_ROOM": ROOM.FAMILY_ROOM,
  "selectOptions.additionalRooms.DOUBLE_ROOM": ROOM.DOUBLE_ROOM, // camera matrimoniale
  "selectOptions.additionalRooms.CELLAR": ROOM.CELLAR,
  "selectOptions.additionalRooms.CORRIDOR": ROOM.CORRIDOR,
  "selectOptions.additionalRooms.KITCHEN": ROOM.KITCHEN,
  "selectOptions.additionalRooms.HABITABLE_KITCHEN": ROOM.HABITABLE_KITCHEN,
  "selectOptions.additionalRooms.GAMES_ROOM": ROOM.GAMES_ROOM,
  "selectOptions.additionalRooms.GARDEN": ROOM.GARDEN,
  "selectOptions.additionalRooms.WINTER_GARDEN": ROOM.WINTER_GARDEN,
  "selectOptions.additionalRooms.ENTRANCE": ROOM.ENTRANCE,
  "selectOptions.additionalRooms.WOODSHED": ROOM.WOODSHED,
  "selectOptions.additionalRooms.LIBRARY": ROOM.LIBRARY,
  "selectOptions.additionalRooms.TOOL_ROOM": ROOM.TOOL_ROOM,
  "selectOptions.additionalRooms.BOILER_ROOM": ROOM.BOILER_ROOM,
  "selectOptions.additionalRooms.TANK_ROOM": ROOM.TANK_ROOM,
  "selectOptions.additionalRooms.SHOWER_ROOM": ROOM.SHOWER_ROOM,
  "selectOptions.additionalRooms.LOFT": ROOM.LOFT,
  "selectOptions.additionalRooms.LODGE": ROOM.LODGE,
  "selectOptions.additionalRooms.LUNCH_ROOM": ROOM.LUNCH_ROOM,
  "selectOptions.additionalRooms.WAREHOUSE": ROOM.WAREHOUSE,
  "selectOptions.additionalRooms.MAID_ROOM": ROOM.MAID_ROOM,
  "selectOptions.additionalRooms.GYM": ROOM.GYM,
  "selectOptions.additionalRooms.PARTY_ROOM": ROOM.PARTY_ROOM,
  "selectOptions.additionalRooms.PARKING_PLACE": ROOM.PARKING_PLACE,
  "selectOptions.additionalRooms.PRIVATE_ROOM": ROOM.PRIVATE_ROOM,
  "selectOptions.additionalRooms.RESTING_ROOM": ROOM.RESTING_ROOM,
  "selectOptions.additionalRooms.CLOSET": ROOM.CLOSET,
  "selectOptions.additionalRooms.LIVING_ROOM": ROOM.LIVING_ROOM,
  "selectOptions.additionalRooms.DOUBLE_LIVING_ROOM": ROOM.DOUBLE_LIVING_ROOM,
  "selectOptions.additionalRooms.LOUNGE": ROOM.LOUNGE,
  "selectOptions.additionalRooms.SAUNA": ROOM.SAUNA,
  "selectOptions.additionalRooms.GREEN_HOUSE": ROOM.GREEN_HOUSE,
  "selectOptions.additionalRooms.SOLARIUM": ROOM.SOLARIUM,
  "selectOptions.additionalRooms.L_ROOM": ROOM.L_ROOM,
  "selectOptions.additionalRooms.Z_ROOM": ROOM.Z_ROOM,
  "selectOptions.additionalRooms.STUDIO": ROOM.STUDIO,
  "selectOptions.additionalRooms.TERRACE": ROOM.TERRACE,
  "selectOptions.additionalRooms.DINING_ROOM": ROOM.DINING_ROOM,
  "selectOptions.additionalRooms.TOILETTE": ROOM.TOILETTE,
  "selectOptions.additionalRooms.TOY_ROOM": ROOM.TOY_ROOM,
};
export const roomsTagImage: SelectOptions<IMAGE_TAG> = {
  "selectOptions.additionalRooms.PLANIMETRY": IMAGE_TAG.PLANIMETRY,
  "selectOptions.additionalRooms.ROOF_TERRACE": IMAGE_TAG.ROOF_TERRACE,
  "selectOptions.additionalRooms.OTHER": IMAGE_TAG.OTHER,
  "selectOptions.additionalRooms.AMERICAN_ENSUITE": IMAGE_TAG.AMERICAN_ENSUITE,
  "selectOptions.additionalRooms.ANTEROOM": IMAGE_TAG.ANTEROOM,
  "selectOptions.additionalRooms.WARDROBE": IMAGE_TAG.WARDROBE,
  "selectOptions.additionalRooms.DRYING_ROOM": IMAGE_TAG.DRYING_ROOM,
  "selectOptions.additionalRooms.BATHROOM": IMAGE_TAG.BATHROOM,
  "selectOptions.additionalRooms.BALCONY": IMAGE_TAG.BALCONY,
  "selectOptions.additionalRooms.BAR": IMAGE_TAG.BAR,
  "selectOptions.additionalRooms.BOX": IMAGE_TAG.BOX,
  "selectOptions.additionalRooms.KIDS_ROOM": IMAGE_TAG.KIDS_ROOM,
  "selectOptions.additionalRooms.ROOM_WITH_BATHROOM":
    IMAGE_TAG.ROOM_WITH_BATHROOM,
  "selectOptions.additionalRooms.BEDROOM": IMAGE_TAG.BEDROOM,
  "selectOptions.additionalRooms.SECURITY_ROOM": IMAGE_TAG.SECURITY_ROOM,
  "selectOptions.additionalRooms.FAMILY_ROOM": IMAGE_TAG.FAMILY_ROOM,
  "selectOptions.additionalRooms.DOUBLE_ROOM": IMAGE_TAG.DOUBLE_ROOM, // camera matrimoniale
  "selectOptions.additionalRooms.CELLAR": IMAGE_TAG.CELLAR,
  "selectOptions.additionalRooms.CORRIDOR": IMAGE_TAG.CORRIDOR,
  "selectOptions.additionalRooms.KITCHEN": IMAGE_TAG.KITCHEN,
  "selectOptions.additionalRooms.HABITABLE_KITCHEN":
    IMAGE_TAG.HABITABLE_KITCHEN,
  "selectOptions.additionalRooms.GAMES_ROOM": IMAGE_TAG.GAMES_ROOM,
  "selectOptions.additionalRooms.GARDEN": IMAGE_TAG.GARDEN,
  "selectOptions.additionalRooms.WINTER_GARDEN": IMAGE_TAG.WINTER_GARDEN,
  "selectOptions.additionalRooms.ENTRANCE": IMAGE_TAG.ENTRANCE,
  "selectOptions.additionalRooms.WOODSHED": IMAGE_TAG.WOODSHED,
  "selectOptions.additionalRooms.LIBRARY": IMAGE_TAG.LIBRARY,
  "selectOptions.additionalRooms.TOOL_ROOM": IMAGE_TAG.TOOL_ROOM,
  "selectOptions.additionalRooms.BOILER_ROOM": IMAGE_TAG.BOILER_ROOM,
  "selectOptions.additionalRooms.TANK_ROOM": IMAGE_TAG.TANK_ROOM,
  "selectOptions.additionalRooms.SHOWER_ROOM": IMAGE_TAG.SHOWER_ROOM,
  "selectOptions.additionalRooms.LOFT": IMAGE_TAG.LOFT,
  "selectOptions.additionalRooms.LODGE": IMAGE_TAG.LODGE,
  "selectOptions.additionalRooms.LUNCH_ROOM": IMAGE_TAG.LUNCH_ROOM,
  "selectOptions.additionalRooms.WAREHOUSE": IMAGE_TAG.WAREHOUSE,
  "selectOptions.additionalRooms.MAID_ROOM": IMAGE_TAG.MAID_ROOM,
  "selectOptions.additionalRooms.GYM": IMAGE_TAG.GYM,
  "selectOptions.additionalRooms.PARTY_ROOM": IMAGE_TAG.PARTY_ROOM,
  "selectOptions.additionalRooms.PARKING_PLACE": IMAGE_TAG.PARKING_PLACE,
  "selectOptions.additionalRooms.PRIVATE_ROOM": IMAGE_TAG.PRIVATE_ROOM,
  "selectOptions.additionalRooms.RESTING_ROOM": IMAGE_TAG.RESTING_ROOM,
  "selectOptions.additionalRooms.CLOSET": IMAGE_TAG.CLOSET,
  "selectOptions.additionalRooms.LIVING_ROOM": IMAGE_TAG.LIVING_ROOM,
  "selectOptions.additionalRooms.DOUBLE_LIVING_ROOM":
    IMAGE_TAG.DOUBLE_LIVING_ROOM,
  "selectOptions.additionalRooms.LOUNGE": IMAGE_TAG.LOUNGE,
  "selectOptions.additionalRooms.SAUNA": IMAGE_TAG.SAUNA,
  "selectOptions.additionalRooms.GREEN_HOUSE": IMAGE_TAG.GREEN_HOUSE,
  "selectOptions.additionalRooms.SOLARIUM": IMAGE_TAG.SOLARIUM,
  "selectOptions.additionalRooms.L_ROOM": IMAGE_TAG.L_ROOM,
  "selectOptions.additionalRooms.Z_ROOM": IMAGE_TAG.Z_ROOM,
  "selectOptions.additionalRooms.STUDIO": IMAGE_TAG.STUDIO,
  "selectOptions.additionalRooms.TERRACE": IMAGE_TAG.TERRACE,
  "selectOptions.additionalRooms.DINING_ROOM": IMAGE_TAG.DINING_ROOM,
  "selectOptions.additionalRooms.TOILETTE": IMAGE_TAG.TOILETTE,
  "selectOptions.additionalRooms.TOY_ROOM": IMAGE_TAG.TOY_ROOM,
  "selectOptions.additionalRooms.GENERAL": IMAGE_TAG.GENERAL,
};

export const heatingDetailsFuelType: SelectOptions<HEATING_FUEL> = {
  "selectOptions.heatingDetailsFuelType.METHANE": HEATING_FUEL.METHANE,
  "selectOptions.heatingDetailsFuelType.DIESEL": HEATING_FUEL.DIESEL,
  "selectOptions.heatingDetailsFuelType.GPL": HEATING_FUEL.GPL,
  "selectOptions.heatingDetailsFuelType.SOLAR_PANELS":
    HEATING_FUEL.SOLAR_PANELS,
  "selectOptions.heatingDetailsFuelType.AIR_ELECTRIC":
    HEATING_FUEL.AIR_ELECTRIC,
  "selectOptions.heatingDetailsFuelType.PELLET": HEATING_FUEL.PELLET,
  "selectOptions.heatingDetailsFuelType.WOOD": HEATING_FUEL.WOOD,
  "selectOptions.heatingDetailsFuelType.WOOD_CHIPS": HEATING_FUEL.WOOD_CHIPS,
  "selectOptions.heatingDetailsFuelType.OTHER": HEATING_FUEL.OTHER,
};

export const heatingDetailsSystemType: SelectOptions<HEATING_SYSTEM> = {
  "selectOptions.heatingDetailsSystemType.RADIATOR": HEATING_SYSTEM.RADIATOR,
  "selectOptions.heatingDetailsSystemType.FLOOR": HEATING_SYSTEM.FLOOR,
  "selectOptions.heatingDetailsSystemType.AIR": HEATING_SYSTEM.AIR,
  "selectOptions.heatingDetailsSystemType.HEATER": HEATING_SYSTEM.HEATER,
};

export const detailsQualityType: SelectOptions<QUALITY> = {
  "selectOptions.detailsQualityType.CLASSIC": QUALITY.CLASSIC,
  "selectOptions.detailsQualityType.VINTAGE": QUALITY.VINTAGE,
  "selectOptions.detailsQualityType.MODERN_MEDIUM": QUALITY.MODERN_MEDIUM,
  "selectOptions.detailsQualityType.MODERN_CLASSIC": QUALITY.MODERN_CLASSIC,
  "selectOptions.detailsQualityType.POPULAR": QUALITY.POPULAR,
  "selectOptions.detailsQualityType.CLASSIC_ELEGANT": QUALITY.CLASSIC_ELEGANT,
  "selectOptions.detailsQualityType.HISTORIC": QUALITY.HISTORIC,
};

export const detailsHabitabilityType: SelectOptions<HABITABILITY> = {
  "selectOptions.detailsHabitabilityType.IMMEDIATE": HABITABILITY.IMMEDIATE,
  "selectOptions.detailsHabitabilityType.NOT_HABITABLE":
    HABITABILITY.NOT_HABITABLE,
};

export const detailsConservationType: SelectOptions<CONSERVATION_STATUS> = {
  "selectOptions.detailsConservationType.GOOD": CONSERVATION_STATUS.GOOD,
  "selectOptions.detailsConservationType.NEEDS_CLEANING":
    CONSERVATION_STATUS.NEEDS_CLEANING,
  "selectOptions.detailsConservationType.NEEDS_RESTRUCTURING":
    CONSERVATION_STATUS.NEEDS_RESTRUCTURING,
  "selectOptions.detailsConservationType.DECENT": CONSERVATION_STATUS.DECENT,
  "selectOptions.detailsConservationType.ROUGH": CONSERVATION_STATUS.ROUGH,
  "selectOptions.detailsConservationType.MEDIOCRE":
    CONSERVATION_STATUS.MEDIOCRE,
  "selectOptions.detailsConservationType.GREAT": CONSERVATION_STATUS.GREAT,
  "selectOptions.detailsConservationType.RESTRUCTURED":
    CONSERVATION_STATUS.RESTRUCTURED,
};

export const availabilityType: SelectOptions<AVAILABILITY> = {
  "selectOptions.availabilityType.PRESENT": AVAILABILITY.PRESENT,
  "selectOptions.availabilityType.ABSENT": AVAILABILITY.ABSENT,
};

export const detailsFurnitureType: SelectOptions<FURNITURE_STATUS> = {
  "selectOptions.detailsFurnitureType.NOT_FURNISHED":
    FURNITURE_STATUS.NOT_FURNISHED,
  "selectOptions.detailsFurnitureType.FURNISHED": FURNITURE_STATUS.FURNISHED,
  "selectOptions.detailsFurnitureType.PARTIALLY_FURNISHED":
    FURNITURE_STATUS.PARTIALLY_FURNISHED,
  "selectOptions.detailsFurnitureType.PARTIALLY_FURNISHED_KITCHEN":
    FURNITURE_STATUS.PARTIALLY_FURNISHED_KITCHEN,
};

//CERTIFICATIONS

export const energyOptions: SelectOptions<ENERGY_CLASS> = {
  "selectOptions.energyClass.A1": ENERGY_CLASS.A1,
  "selectOptions.energyClass.A2": ENERGY_CLASS.A2,
  "selectOptions.energyClass.A3": ENERGY_CLASS.A3,
  "selectOptions.energyClass.A4": ENERGY_CLASS.A4,
  "selectOptions.energyClass.B": ENERGY_CLASS.B,
  "selectOptions.energyClass.C": ENERGY_CLASS.C,
  "selectOptions.energyClass.D": ENERGY_CLASS.D,
  "selectOptions.energyClass.E": ENERGY_CLASS.E,
  "selectOptions.energyClass.F": ENERGY_CLASS.F,
  "selectOptions.energyClass.G": ENERGY_CLASS.G,
  "selectOptions.energyClass.NOT_AVAILABLE": ENERGY_CLASS.NOT_AVAILABLE,
};

export const performanceUnitOptions: SelectOptions<PERFORMANCE_UNIT> = {
  "selectOptions.performanceUnit.SQUARE_METERS": PERFORMANCE_UNIT.SQUARE_METERS,
  "selectOptions.performanceUnit.CUBIC_METERS": PERFORMANCE_UNIT.CUBIC_METERS,
};

export const energyPerformanceOptions: SelectOptions<ENERGY_PERFORMANCE> = {
  "selectOptions.energyPerformance.LOW": ENERGY_PERFORMANCE.LOW,
  "selectOptions.energyPerformance.MEDIUM": ENERGY_PERFORMANCE.MEDIUM,
  "selectOptions.energyPerformance.HIGH": ENERGY_PERFORMANCE.HIGH,
};

export const oldEnergyClassOptions: SelectOptions<ENERGY_CLASS_OLD> = {
  "selectOptions.energyClassOld.A_PLUS": ENERGY_CLASS_OLD.A_PLUS,
  "selectOptions.energyClassOld.B": ENERGY_CLASS_OLD.B,
  "selectOptions.energyClassOld.C": ENERGY_CLASS_OLD.C,
  "selectOptions.energyClassOld.D": ENERGY_CLASS_OLD.D,
  "selectOptions.energyClassOld.E": ENERGY_CLASS_OLD.E,
  "selectOptions.energyClassOld.F": ENERGY_CLASS_OLD.F,
  "selectOptions.energyClassOld.G": ENERGY_CLASS_OLD.G,
  "selectOptions.energyClassOld.EVALUATED": ENERGY_CLASS_OLD.EVALUATED,
  "selectOptions.energyClassOld.NOT_APPLICABLE":
    ENERGY_CLASS_OLD.NOT_APPLICABLE,
  "selectOptions.energyClassOld.NOT_DECLARED": ENERGY_CLASS_OLD.NOT_DECLARED,
};
export const soundClassOptions: SelectOptions<SOUND_CLASS> = {
  "selectOptions.soundCLass.A": SOUND_CLASS.A,
  "selectOptions.soundCLass.B": SOUND_CLASS.B,
  "selectOptions.soundCLass.C": SOUND_CLASS.C,
  "selectOptions.soundCLass.D": SOUND_CLASS.D,
};

export const unitOptions: SelectOptions<PERFORMANCE_UNIT> = {
  "selectOptions.unit.SQUARE_METERS": PERFORMANCE_UNIT.SQUARE_METERS,
  "selectOptions.unit.CUBIC__METERS": PERFORMANCE_UNIT.CUBIC_METERS,
};

export const demandStatus: SelectOptions<DEMAND_STATUS> = {
  "selectOptions.demandStatus.active": DEMAND_STATUS.ACTIVE,
  "selectOptions.demandStatus.canceled": DEMAND_STATUS.CANCELED,
  "selectOptions.demandStatus.concluded": DEMAND_STATUS.CONCLUDED,
  "selectOptions.demandStatus.lost": DEMAND_STATUS.LOST,
  "selectOptions.demandStatus.expired": DEMAND_STATUS.EXPIRED,
  "selectOptions.demandStatus.suspended": DEMAND_STATUS.SUSPENDED,
};

export const furnitureStatus: SelectOptions<FURNITURE_STATUS> = {
  "selectOptions.furnitureStatus.furnished": FURNITURE_STATUS.FURNISHED,
  "selectOptions.furnitureStatus.notFurnished": FURNITURE_STATUS.NOT_FURNISHED,
  "selectOptions.furnitureStatus.partiallyFurnisched":
    FURNITURE_STATUS.PARTIALLY_FURNISHED,
  "selectOptions.furnitureStatus.partiallyFurnishedKitchen":
    FURNITURE_STATUS.PARTIALLY_FURNISHED_KITCHEN,
};
export const currentStateDemandCustomers: SelectOptions<CURRENT_STATE> = {
  "selectOptions.currentState.UNDER_CONSTRUCTION":
    CURRENT_STATE.UNDER_CONSTRUCTION,
  "selectOptions.currentState.FREE_TO_DEED": CURRENT_STATE.FREE_TO_DEED,
  "selectOptions.currentState.LEASED": CURRENT_STATE.LEASED,
};
export const feeSelection: SelectOptions<FEE> = {
  "selectOptions.fee.YEARLY": FEE.YEARLY,
  "selectOptions.fee.BIMONTHLY": FEE.BIMONTHLY,
  "selectOptions.fee.MONTHLY": FEE.MONTHLY,
  "selectOptions.fee.WEEKLY": FEE.WEEKLY,
  "selectOptions.fee.SEASONAL": FEE.SEASONAL,
};
export const roomSeparator: SelectOptions<ROOM_SEPARATOR> = {
  "selectOptions.roomSeparator.WALLS": ROOM_SEPARATOR.WALLS,
  "selectOptions.roomSeparator.OPEN_SPACE": ROOM_SEPARATOR.OPEN_SPACE,
  "selectOptions.roomSeparator.PANELS": ROOM_SEPARATOR.PANELS,
};
export const context: SelectOptions<CONTEXT> = {
  "selectOptions.context.IN_MALL": CONTEXT.IN_MALL,
  "selectOptions.context.OFFICE_COMPLEX": CONTEXT.OFFICE_COMPLEX,
  "selectOptions.context.MEZZANINE": CONTEXT.MEZZANINE,
  "selectOptions.context.STREET": CONTEXT.STREET,
  "selectOptions.context.OTHER": CONTEXT.OTHER,
};
export const weeklyRest: SelectOptions<WEEKLY_REST> = {
  "selectOptions.weeklyRest.SATURDAY": WEEKLY_REST.SATURDAY,
  "selectOptions.weeklyRest.SUNDAY": WEEKLY_REST.SUNDAY,
};
export const landKind: SelectOptions<LAND_KIND> = {
  "selectOptions.landKind.FLAT": LAND_KIND.FLAT,
  "selectOptions.landKind.STEEP": LAND_KIND.STEEP,
};
