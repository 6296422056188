import React, {
  FC,
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useDispatch } from "react-redux";
import { To, useLocation, useNavigate, useParams } from "react-router-dom";
import { stages } from "../../../App";
import useForm, { Form } from "../../../hooks/useForm";
import { useI18n } from "../../../hooks/useI18n";
import { Field, ICustomForm } from "../../../models/CustomForm";
import { RoutesRouter } from "../../../models/Routes";
import { setCanChangeStepForm } from "../../../redux/reducers/realEstate";
import { Range } from "../../../typings/Range";
import { getNestedValueFromString } from "../../../Utils/fuctions";
import getClasses from "../../../Utils/getClasses";
import Button from "../Button/Button";
import AddressInput from "./AddressInput/AddressInput";
import AddressInput2 from "./AddressInput/AddressInput2";
import AddressInputDemandCustomer from "./AddressInputDemandCustomer/AddressInputDemandCustomer";
import CheckBox from "./CheckBox/CheckBox";
import ConditionsDemandInput from "./ConditionsDemandInput/ConditionsDemandInput";
import "./CustomForm.scss";
import DatePicker from "./DatePicker/DatePicker";
import EntityInformationInput from "./EntityInformationInput/EntityInformationInput";
import ImageConsultantInput from "./ImageConsultantInput/ImageConsultantInput";
import ImagesInput from "./ImagesInput/ImagesInput";
import ModalChangeConsultant from "./ModalChangeConsultant/ModalChangeConsultant";
import OtherRoomsDetailsInput from "./OtherRoomsDetailsInput/OtherRoomsDetailsInput";
import OwnerInput from "./OwnerInput/OwnerInput";
import Radio from "./Radio/Radio";
import RoomPertinenceDetailsInput from "./RoomPertinenceDetailsInput/RoomPertinenceDetailsInput";
import SelectedZones from "./SelectedZones/SelectedZones";
import SelectInput from "./SelectInput/SelectInput";
import SelectInputCheckBox from "./SelectInputCheckBox/SelectInputCheckBox";
import TextArea from "./TextArea/TextArea";
import TextInput from "./TextInput/TextInput";
import TitleDemand from "./TitleDemand/TitleDemand";

interface PropsForm<T extends object = any> {
  componentsSetter?: React.Dispatch<any>;
  components?: ReactElement[];
  width?: Range<0, 13>;
  props: ICustomForm<T>;
  doSubmit?: {
    isSubmit: { submit: boolean; back: boolean };
    onSubmitted?: () => void;
  };
  className?: string;
  fixedSubmitButton?: boolean;
  checkErrorsOnInit?: boolean;
  submitOnError?: boolean;
  onClose?: () => void;
  submit: (
    form: Partial<Form<T>>,
    next: ICustomForm["next"],
    isSame: boolean,
    isBack: boolean,
    checkErrors: boolean
  ) => void;
  form: {
    initialValue: T;
    validators?: any;
  };
}

const CustomForm: FC<PropsForm> = ({
  componentsSetter,
  components,
  width,
  className = "custom-form-container",
  fixedSubmitButton = false,
  checkErrorsOnInit = false,
  submitOnError = false,
  onClose,
  doSubmit,
  props: {
    forms,
    title,
    description,
    hiddenBackButton = false,
    labelSubmitButton,
    buttonRight,
    labelBackButton,
    typePage = "edit",
    next,
  },

  form: { initialValue, validators },
  submit: submitForm,
}) => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const initialForm = useRef<string>("");
  const navigate = useNavigate();
  const { t } = useI18n();
  const indexCurrentStage = useMemo(
    () => stages.findIndex(({ link }) => pathname.includes(link)),
    [pathname]
  );

  const {
    form,
    setForm,
    submit,
    handleError,
    checkErrors,
    errors: errorsForm,
  } = useForm<any>(
    initialValue,
    //validations
    validators,
    submitOnError
  );

  useEffect(() => {
    componentsSetter &&
      componentsSetter({
        customSetForm: setForm,
        customCheckErrors: errorsForm,
        form,
      });
  }, [form, errorsForm]);

  useEffect(() => {
    initialForm.current = JSON.stringify(form);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (doSubmit && doSubmit?.isSubmit.submit) {
      submit(async () => {
        await submitForm(
          form,
          next,
          initialForm.current === JSON.stringify(form),
          doSubmit.isSubmit.back,
          checkErrors().every(Boolean)
        );
        dispatch(setCanChangeStepForm(true));
      });
      doSubmit.onSubmitted && doSubmit.onSubmitted();
    }

    // eslint-disable-next-line
  }, [doSubmit?.isSubmit.submit]);

  const handleSubmit = () => {
    submit(() => {
      submitForm(
        form,
        next,
        initialForm.current === JSON.stringify(form),
        doSubmit?.isSubmit.back || false,
        checkErrors().every(Boolean)
      );
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
    checkErrorsOnInit && checkErrors();
    // eslint-disable-next-line
  }, []);

  const handleBack = () => {
    if (!labelBackButton) {
      navigate(
        `${RoutesRouter["realEstates"]}/${id!}"/edit/${
          stages[indexCurrentStage - 1].link
        }`
      );
      return;
    }
    navigate(labelBackButton.link as To);
  };
  const renderField = (field: Field, index: number) => {
    const error: string | undefined = errorsForm
      ? getNestedValueFromString(field.key, errorsForm)
      : "";
    const { type } = field;
    let props = { handleError, data: { ...field }, error, setForm, form };
    if (
      !(
        !field.showIfKey ||
        field.showIf!(
          field.showIfKey === "id"
            ? id
            : typeof field.showIfKey === "string"
            ? getNestedValueFromString(field.showIfKey, form)
            : field.showIfKey.map((key) => getNestedValueFromString(key, form))
        )
      )
    )
      return <></>;
    props.data.disabled =
      field.disabled ??
      (field?.disableKey === "id" && field.disableIf && field?.disableIf(id)) ??
      false;

    const Element = {
      radio: <Radio {...props} />,
      number: <TextInput {...props} />,
      text: <TextInput {...props} />,
      "text-area": <TextArea {...props} />,
      checkbox: <CheckBox {...props} />,
      images: <ImagesInput {...props} />,
      datepicker: <DatePicker {...props} />,
      conditions: <ConditionsDemandInput {...props} />,
      owner: <OwnerInput {...props} />,
      select: <SelectInput {...props} />,
      "select-checkbox": <SelectInputCheckBox {...props} />,
      "room-pertinence-details": <RoomPertinenceDetailsInput {...props} />,
      "other-rooms-details": <OtherRoomsDetailsInput {...props} />,
      "entity-information": <EntityInformationInput {...props} />,
      location: <AddressInput {...props} />,
      location2: <AddressInput2 {...props} />,
      "location-demand-customer": <AddressInputDemandCustomer {...props} />,
      "selected-zones": <SelectedZones {...props} />,
      "title-demand": <TitleDemand {...props} />,
      "image-consultant": <ImageConsultantInput {...props} />,
    }[type];

    return Element || <></>;
  };

  const renderButtonRight = () => {
    const props = { setForm, form };
    if (!buttonRight) return <></>;
    switch (buttonRight) {
      case "changeConsultant":
        return <ModalChangeConsultant {...props} />;
    }
  };

  return (
    <div className={`container-custom ${className}`}>
      {(title || description || buttonRight) && (
        <div className="container__top">
          {(title || description) && (
            <div className="left">
              {title && (
                <h1 className="page-title capitalize-first-letter">
                  {t(title["edit"])}
                </h1>
              )}
              {description && (
                <p className="page-description capitalize-first-letter mt-1">
                  {t(description["edit"])}
                </p>
              )}
            </div>
          )}
          <div className="button-right">
            {buttonRight && renderButtonRight()}
          </div>
        </div>
      )}
      <div className="form-container">
        <div className="row">
          <div className={`col-xxl-${width} form-content`}>
            <div className={`row position-relative`}>
              {/* @ts-ignore  */}
              {forms.map((item: ICustomForm["forms"][0], i) => (
                <Fragment key={i}>
                  {item.title && <h2 className="registry">{t(item.title)}</h2>}
                  {item.fields.map((field: Field) => (
                    <>
                      <Fragment key={field.key}>
                        {renderField(field, i)}
                      </Fragment>
                      {field.key === "zoneOffsetShowCase" && (
                        <div className="col-xxl-offset-5"></div>
                      )}
                    </>
                  ))}
                </Fragment>
              ))}
              {components?.length && components.map((element) => element)}
              <div
                className={`col-lg-12 ${getClasses({
                  "fixed-submit-buttom": fixedSubmitButton,
                })}`}
              >
                <div
                  className={`form-action`}
                  //   [class.is-invalid]="submitted && userForm.name.errors"
                >
                  {!hiddenBackButton && labelBackButton && (
                    <div
                      onClick={() => (onClose ? onClose() : handleBack())}
                      className="btn-text medium me-4 cursor-pointer"
                    >
                      {t(labelBackButton["edit"])}
                    </div>
                  )}
                  {labelSubmitButton && (
                    <Button onClick={handleSubmit}>
                      <span className="capitalize-first-letter">
                        {t(labelSubmitButton["edit"])}
                      </span>
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomForm;
