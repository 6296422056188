import { Flex, Select } from "@chakra-ui/react";
import React, { CSSProperties, FC, Fragment, useMemo } from "react";
import { useI18n } from "../../../hooks/useI18n";
import { ElementForPage } from "../../../typings/Pagination";
import "./Pagination.scss";
import PaginationPageButton from "./PaginationPageButton";

interface IPagination {
  style?: CSSProperties;
  isLoading: boolean;
  pagesToShow?: 4 | 6;
  selectLimit?: {
    limit: ElementForPage;
    setLimit: (page: ElementForPage) => void;
    options: {
      label: string;
      value: ElementForPage;
    }[];
  };
  data: {
    count: number;
    hasNextPage: boolean;
    hasPrevPage: boolean;
    limit: number;
    offset: number;
    page: number;
    totalDocs: number;
    totalPages: number;
  };
  onClickPage: (page: number) => void;
}

const Pagination: FC<IPagination> = ({
  style,
  data,
  isLoading,
  onClickPage,
  pagesToShow = 4,
  selectLimit,
}) => {
  const { page, totalPages, hasNextPage, hasPrevPage,totalDocs } = data!;
  
  const { t } = useI18n();
  const paginationComponent = useMemo(() => {
    if (!data) return <></>;
    return (
      <Fragment>
        <PaginationPageButton
          disabled={!hasPrevPage}
          onClick={() => {
            onClickPage(1);
          }}
          label={<i className="icon icon-chevrons-left icon-page" />}
        />
        <PaginationPageButton
          disabled={!hasPrevPage}
          onClick={() => {
            onClickPage(page - 1);
          }}
          label={<i className="icon icon-ch-left icon-page" />}
        />
        <PaginationPageButton
          disabled={!hasNextPage}
          onClick={() => {
            onClickPage(page + 1);
          }}
          label={<i className="icon icon-ch-right icon-page" />}
        />
        <PaginationPageButton
          disabled={!hasNextPage}
          onClick={() => {
            onClickPage(totalPages);
          }}
          label={<i className="icon icon-chevrons-right icon-page" />}
        />
      </Fragment>
    );
    // eslint-disable-next-line 
  }, [data]);

  return (
    <Flex
      style={style}
      justifyContent={"flex-end"}
      alignItems={"center"}
      fontSize={20}
    >
            <span className="pages-label pe-3">{`${t("pagination.elements")}`}</span>
      {selectLimit && data && (
        <Select
          w={"inherit"}
          minW={20}
          bg={"#ECEFFD"}
          value={selectLimit.limit}
          onChange={({ target: { value } }: any) => {
            selectLimit.setLimit(Number(value) as ElementForPage);
          }}
        >
          {selectLimit.options.map(({ label, value }, index) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
      )}
            <span className="pages-label">{`${totalDocs} ${t("pagination.items")}`}</span>
            <span className="pages-label">{`${page} ${t("pagination.pagesLabel")} ${totalPages}`}</span>
      {paginationComponent}
    </Flex>
  );
};

export default Pagination;
