import axios, { AxiosRequestConfig } from "axios";
import { NavigateFunction } from "react-router-dom";
import { RoutesRouter } from "../models/Routes";
import { useToastAlert } from "../shared/components/ToastAlert/ToastAlert";
import { DURATION_TOAST } from "../Utils/costants";
import { getStorageItem, setStorageItem } from "../Utils/localStorage";
import { useEndpoints } from "./useEndpoints";
import { useI18n } from "./useI18n";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();
// const axiosInstance = axios.create();

export const useInterceptor = () => {
  const { reAuth } = useEndpoints();
  const toastAlert = useToastAlert();
  const {t} = useI18n(); 
  const tokenNotExists = (
    request: AxiosRequestConfig<any>,
    navigate: NavigateFunction
  ) => {
    source.cancel("Token expired!");
    request.cancelToken = source.token;
    toastAlert({
      message: t("errorsToast.unauthorized.title"),
      subMessage: t("errorsToast.unauthorized.description"),
      type: "error",
      duration: DURATION_TOAST,
    });
    setTimeout(() => {
      navigate(RoutesRouter.login);
    }, 100);

    return;
  };

  const validateToken = async (
    request: AxiosRequestConfig,
    navigate: NavigateFunction
  ) => {
    const token = getStorageItem("token");
    if (!token) {
      tokenNotExists(request, navigate);
      return;
    }
    const { accessToken, expiresIn, date, refreshToken } = token;
    const expireDate = new Date(date);
    expireDate.setSeconds(expireDate.getSeconds() + expiresIn);
    if (new Date() > expireDate) {
      // refresh token
      console.log("tokenExpired");
      try {
        const { data: resp } = await reAuth(refreshToken);
        setStorageItem("token", { ...resp, date: new Date() });
      } catch (e) {
        tokenNotExists(request, navigate);
        return;
      }
    }
    accessToken &&
      request.headers &&
      (request.headers.Authorization = `Bearer ${accessToken}`);
  };

  const jwtInterceptor = (navigate: NavigateFunction) => {
    axios.interceptors.request.use(
      async (request) => {
        const endpointWithHeader = [
          "/auth/me",
          "/auth/google/auth-url",
          "/auth/google/logout",
          "/appointments",
          "/generate-token",
          "/consultants",
          "/customers",
          "/real-estate",
          "/demands",
          "/agencies",
          "/matches",
          "/contact-requests"
        ];
        // add auth header with jwt if account is logged in and request is to the api url
        if (
          endpointWithHeader.some((endpoint) => request.url?.includes(endpoint))
        ) {
          await validateToken(request, navigate);
        }
        return request;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (
          error?.messsage === "Token expired!" ||
          error?.response?.status === 403
        ) {
          localStorage.clear();
          // toast({
          //   title: "Unauthorized",
          //   description: "Sorry! You don't have permissions",
          //   status: "error",
          //   duration: 2000,
          //   isClosable: true,
          // });
          navigate(RoutesRouter.login);
        }
        return Promise.reject(error);
      }
    );
  };

  return { jwtInterceptor, validateToken };
};
