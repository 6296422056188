import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RespContactRequest } from '../../models/ContactRequest'

interface MessagesState {
  messages: RespContactRequest | undefined
}

const initialState: MessagesState = {
  messages: undefined
}

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setMessages(state: MessagesState, action: PayloadAction<RespContactRequest | undefined>) {
      state.messages = action.payload
    },
    deleteMessage(state: MessagesState, action: PayloadAction<string>) {
      state.messages = state.messages ? {...state.messages,docs: state.messages.docs.filter(({_id}) =>_id !== action.payload)} : undefined
    },
    setHandleMessage(state: MessagesState, action: PayloadAction<{ contactId: string, handled: boolean } | undefined>) {
      if(action.payload && state.messages) {
        const { contactId, handled } = action.payload;
        const index = state.messages.docs.findIndex(({ _id }) => _id === contactId)
        if(index !== undefined && index !== -1) {
          state.messages.docs[index].handled = handled;
        }
      }
    }
  },
})

export const { setMessages, setHandleMessage, deleteMessage } = messagesSlice.actions
export default messagesSlice.reducer