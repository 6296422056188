import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RespCustomers } from '../../models/RespCustomers'

interface ConsultantsState {
    customers: RespCustomers | undefined
}

const initialState = { customers: undefined } as ConsultantsState

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {

    setCustomers(state: ConsultantsState, action: PayloadAction<RespCustomers | undefined>) {
      state.customers = action.payload
    },
    deleteCustomer(state: ConsultantsState, action: PayloadAction<number>) {
      state.customers = {...state.customers!, docs: state.customers!.docs.filter((_, i) => i !== action.payload)}
    },
    enableCustomer(state: ConsultantsState, {payload:{index, enable}}: PayloadAction<{index:number, enable: boolean}>) {
      if(!state.customers) return;
      state.customers.docs[index].enabled = enable
    },
  },
})

export const { setCustomers, deleteCustomer, enableCustomer } = customersSlice.actions
export default customersSlice.reducer