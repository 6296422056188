import React, { FC } from "react";
import MarkerIcon from "./../../assets/img/pin.svg";
import "./Map.scss";

interface IMap {
  center: google.maps.LatLngLiteral;
  zoom: number;
  style?: { [key: string]: string | number };
  className?: string;
}

const MapGoogle: FC<IMap> = ({ center, zoom, style = {}, className = "" }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          center,
          zoom,
          scrollwheel: false,
        })
      );
    }
    // eslint-disable-next-line
  }, [ref, map, marker]);

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker && map) {
      marker.setOptions({
        position: center,
        map,
        icon: { url: MarkerIcon, scaledSize: new google.maps.Size(60, 60) },
      });
    }
  });

  return (
    <>
      <div className={className} ref={ref} style={style} />
    </>
  );
};

export default MapGoogle;
