import React, { FC, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  descriptionFormCommercial,
  IDescriptionFormCommercial,
} from "../../../../forms/realeEstate/Commercial/descriptionCommercial";
import {
  IDescriptionForm,
  descriptionForm,
} from "../../../../forms/realeEstate/description";
import { useEndpoints } from "../../../../hooks/useEndpoints";
import { Form, FormValidationObject } from "../../../../hooks/useForm";
import { useI18n } from "../../../../hooks/useI18n";
import { useRealEstateForm } from "../../../../hooks/useRealEstateForm";
import { ICustomForm } from "../../../../models/CustomForm";
import {
  RoomPertinenceDetails,
  ROOM_PERTINENCE,
} from "../../../../models/RealEstate";
import { RootState } from "../../../../redux/reducers";
import { setIsSubmit } from "../../../../redux/reducers/realEstate";
import CustomForm from "../../../../shared/components/CustomForm/CustomForm";
import Loader from "../../../../shared/components/Loader/Loader";
import {
  addValidationQuery,
  dimensionsValidator,
  getQueryUrl,
} from "../../../../Utils/fuctions";
import { RoutesRouter } from "../../../../models/Routes";
import {
  descriptionFormLand,
  IDescriptionFormLand,
} from "../../../../forms/realeEstate/Land/descriptionLand";

interface IDescription {}

const Description: FC<IDescription> = () => {
  const { putRealEstate } = useEndpoints();
  const absoluteNavigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const isValidation = searchParams.get("validation");
  const { putRealEstate: putRealEstateSelector, isSubmit } = useSelector(
    ({ realEstate }: RootState) => realEstate
  );
  const { queryTable } = useSelector(({ realEstate }: RootState) => realEstate);

  const {
    isLoading,
    initialValue,
    generateForm,
    navigate,
    id,
    setRealStateRedux,
    isCommercial,
    isLand,
    goBackPath,
  } = useRealEstateForm<IDescriptionForm>("description");
  const { t } = useI18n();

  const errorMessages = {
    required: t("errorMessagesForm.required"),
  };
  const { required } = errorMessages;

  // TODO set action

  const handleSubmit = async (
    form: Partial<Form<IDescriptionForm>>,
    next: ICustomForm["next"],
    isSame: boolean,
    isBack: boolean,
    checkErrors: boolean
  ) => {
    try {
      if (!Boolean(isValidation)) {
        if (!isSame) {
          await putRealEstate(id!, "description", form);
          setRealStateRedux(form);
        }
        isSubmit.submit && navigate(addValidationQuery(next, false));
        !isSubmit.submit &&
          absoluteNavigate(
            `${RoutesRouter.realEstates}${getQueryUrl(queryTable?.params)} `
          );
        isBack && navigate(goBackPath());
        return;
      }
      if (isSame && isBack) {
        navigate(goBackPath());
        return;
      }
      if (checkErrors) {
        await putRealEstate(id!, "description", form);
        setRealStateRedux(form);
        navigate(
          isBack
            ? goBackPath()
            : addValidationQuery(next, Boolean(isValidation))
        );
        return;
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const validatorResidential: FormValidationObject<IDescriptionForm> = {
    totalSquareMeters: {
      required,
    },
    locals: {
      required,
    },
    description: {
      custom: (value: string) =>
        value.length < 20
          ? t("errorMessagesForm.invalidDescriptionLength")
          : "",
    },
    roomsPertinencesDetails: {
      custom: (value: RoomPertinenceDetails[]) => {
        let error: string = "";
        Object.values(ROOM_PERTINENCE).forEach((roomName) => {
          let errorType = "";

          let errorDimensions = "";
          const room = value.find(({ name }) => name === roomName);
          if (
            (!room ||
              (room && (!room!.type || room!.type === "notProvided"))) &&
            [
              ROOM_PERTINENCE.BALCONIES,
              ROOM_PERTINENCE.BOX,
              ROOM_PERTINENCE.GARDEN,
              ROOM_PERTINENCE.TERRACE,
            ].some((item) => item === roomName)
          ) {
            errorType = "type";
          }

          if (room?.dimensions && !dimensionsValidator(room.dimensions)) {
            errorDimensions = "dimensions";
          }
          if (errorType) {
            error += `${roomName}:${errorType}${
              errorType && errorDimensions ? "-" : ""
            }${errorDimensions},`;
          }
        });
        return error;
      },
    },
    "heatingDetails/type": {
      required,
    },
    "additionalDetails/conservationStatus": {
      required,
    },
  };

  const validatorCommercial: FormValidationObject<IDescriptionFormCommercial> =
    {
      ...validatorResidential,
      showcases: { required },
    };

  const validatorLand: FormValidationObject<IDescriptionFormLand> = {
    totalSquareMeters: {
      required,
    },
    description: {
      custom: (value: string) =>
        value.length < 20
          ? t("errorMessagesForm.invalidDescriptionLength")
          : "",
    },
  };

  const validatorsForm = () =>
    isCommercial
      ? validatorCommercial
      : isLand
      ? validatorLand
      : validatorResidential;

  const form = useMemo(
    () => generateForm(isValidation ? validatorsForm() : {}),
    // eslint-disable-next-line
    [initialValue, putRealEstateSelector]
  );

  const handleOnSubmitted = () => {
    dispatch(setIsSubmit(false));
  };

  const renderCustomForm = () => (
    // @ts-ignore
    <CustomForm<IDescriptionForm>
      checkErrorsOnInit={Boolean(isValidation)}
      submitOnError={true}
      className="custom-form-container-stepper"
      doSubmit={{ isSubmit: isSubmit, onSubmitted: handleOnSubmitted }}
      submit={handleSubmit}
      form={form}
      props={
        isCommercial
          ? descriptionFormCommercial
          : isLand
          ? descriptionFormLand
          : descriptionForm
      }
    />
  );

  return !isLoading &&
    putRealEstateSelector &&
    initialValue &&
    isCommercial !== undefined ? (
    renderCustomForm()
  ) : (
    <Loader />
  );
};

export default Description;
