import { ChevronDownIcon } from "@chakra-ui/icons";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { NestedKeys } from "advanced-types";
import { concat } from "lodash";
import React, { FC, useEffect, useState } from "react";
import { Translation } from "../../../../assets/i18n/locals/en";
import { infoLabel } from "../../../../forms/realeEstate/otherInfo";

import { Form } from "../../../../hooks/useForm";
import { useI18n } from "../../../../hooks/useI18n";
import { Field } from "../../../../models/CustomForm";
import { getNestedValueFromString } from "../../../../Utils/fuctions";

import "./EntityInformationInput.scss";

interface IEntityInformationInput {
  form: Partial<Form<any>>;
  setForm: any;
  data: Field;
  error: string | undefined;
}

type ItemRadio =
  | {
      selectOption:
        | {
            label: string;
            value: string;
          }
        | undefined;
      checked: boolean;
      label: string;
      value: string;
      squareMeters?: number | "" | undefined;
      select?: boolean | undefined;
      selectOptions?:
        | {
            label: string;
            value: string;
          }[]
        | undefined;
    }[]
  | undefined;

const EntityInformationInput: FC<IEntityInformationInput> = ({
  data: {
    entityInformationOptions,
    multiple,
    label,
    isRequired,
    key,
    column = 4,
    columnRadio = 3,
    offset = 0,
    button,
    secondaryButton,
  },
  setForm,
  form,
  error,
}) => {
  const { t } = useI18n();
  const [itemsRadioFiltered, setItemsRadioFiltered] = useState<
    Record<"radio" | "radioM" | "select", ItemRadio>
  >(() => {
    const elements = entityInformationOptions?.map((option) => {
      const formOption = getNestedValueFromString<
        { entity: string; status: string; squareMeters: number }[]
      >(key, form).find(({ entity }) => option.value === entity);
      const allOptions = infoLabel
        .map(({ selectOptions }) => selectOptions!)
        .filter((select) => !!select)
        .flat();
      return {
        ...option,
        selectOption: formOption?.status
          ? allOptions!.find(({ value }) => value === formOption.status)
          : undefined,
        checked: !!formOption,
        squareMeters:
          option.squareMeters !== undefined
            ? formOption?.squareMeters ?? 0
            : undefined,
      };
    });
    return {
      radio: elements?.filter(
        ({ squareMeters, selectOptions }) =>
          squareMeters === undefined && selectOptions === undefined
      ),
      radioM: elements?.filter(
        ({ squareMeters }) => squareMeters !== undefined
      ),
      select: elements?.filter(
        ({ selectOptions }) => selectOptions !== undefined
      ),
    };
  });

  const [valueRadio, setValueRadio] = useState<any[] | any>(
    multiple ? getNestedValueFromString(key, form) || [] : ""
  );

  const handleSelect = (label: string, value: string, index: number) => {
    setItemsRadioFiltered((prev) => {
      const copy = JSON.parse(JSON.stringify(prev));
      copy!.select![index].selectOption = { label, value };
      return { ...copy };
    });
  };

  const handleclick = (type: "radio" | "radioM", index: number) => {
    setItemsRadioFiltered((prev) => {
      const copy = JSON.parse(JSON.stringify(prev));
      copy[type]![index].checked = !copy[type]![index]!.checked;
      return { ...copy };
    });
  };
  const handleChangeText = (
    type: "radio" | "radioM",
    squareMeters: string,
    index: number
  ) => {
    setItemsRadioFiltered((prev) => {
      prev[type] &&
        (prev[type]![index].squareMeters = squareMeters
          ? Number(squareMeters)
          : "");
      return { ...prev };
    });
  };

  useEffect(() => {
    setForm(key, valueRadio);
    // eslint-disable-next-line
  }, [valueRadio]);

  useEffect(() => {
    setValueRadio(() =>
      concat(
        itemsRadioFiltered.radio!,
        itemsRadioFiltered.radioM!,
        itemsRadioFiltered.select!
      )
        .filter(({ checked, selectOption, value }) => checked || selectOption)
        .map(({ value, selectOption, squareMeters }) => {
          return selectOption
            ? { entity: value, status: selectOption.value }
            : { entity: value, squareMeters };
        })
    );
  }, [itemsRadioFiltered]);

  const option = (
    label: string,
    value: string,
    labelSelect: string | undefined
  ) => {
    return (
      <div className="menu-flex">
        <span className="capitalize-first-letter">
          {t(label as NestedKeys<Translation, ".">)}
        </span>
        {labelSelect === value && <i className="icon icon-checkmark" />}
      </div>
    );
  };

  const renderTypeLabel = (
    label: string,
    options:
      | {
          label: string;
          value: string;
        }[]
      | undefined,
    selectedOption: { label: string; value: string } | undefined,
    index: number
  ) => {
    // const currentType = selectedItems.find(({name: nameItem}) => nameItem === name);

    return (
      <div className="w-25" key={index}>
        <div className="ps-1 pe-1 pt-1">
          <label
            className="form-control-label capitalize-first-letter"
            htmlFor="name"
          >
            {t(label as NestedKeys<Translation, ".">)}
          </label>
          <Menu>
            <MenuButton width={"100%"} className="menu-button">
              <div className="container-text-default">
                <span
                  className={`${
                    selectedOption?.label
                      ? "menu-button-value capitalize-first-letter"
                      : "menu-button-default"
                  }`}
                >
                  {t(selectedOption?.label as NestedKeys<Translation, ".">) ||
                    t(
                      "realEstate.forms.description.roomPertinenceDeatils.select"
                    )}
                </span>

                <ChevronDownIcon w={6} h={6} />
              </div>
            </MenuButton>
            <MenuList
              maxHeight={"20rem"}
              overflowY={"scroll"}
              p={0}
              zIndex={1000}
            >
              {/* {isRequired !== true && (
            <div
              onClick={() => handleSelect("-", "notProvided")}
              className="menu-item"
            >
              {option("-", "notProvided")}
            </div>
          )} */}

              {options &&
                options.map(({ label, value }, i) => {
                  return (
                    <MenuItem
                      key={value}
                      _hover={{ bg: "transparent" }}
                      p={0}
                      _focus={{ bg: "transparent" }}
                      onClick={() => {
                        handleSelect(label, value, index);
                      }}
                      className="menu-item"
                    >
                      {option(label, value, selectedOption?.value)}
                    </MenuItem>
                  );
                })}
            </MenuList>
          </Menu>
        </div>
      </div>
    );
  };

  const renderRadioInput = (
    type: "radio" | "radioM",
    label: string,
    checked: boolean,
    squareMeters: number | undefined | "",
    i: number
  ) => {
    return (
      <div key={i} className={`container-square col-lg-${columnRadio} mt-4`}>
        <div className="d-flex w-100 flex-column gap-2">
          <div onClick={() => handleclick(type, i)} className={`radio me-1`}>
            <div className={`container-square me-1 `}>
              <i className="icon icon-checkmark icon-square-checked"></i>

              <div
                className={`square ${checked ? "checked" : "unchecked"}`}
              ></div>
            </div>

            <span className={`radio-label-entity text-capitalize me-2 pointer `}>
              {t(label as NestedKeys<Translation, ".">)}
            </span>
          </div>
          {squareMeters !== undefined && (
            <input
              disabled={!checked}
              className={`form-control p-md input-text fw-normal page-description"`}
              type={"number"}
              value={squareMeters}
              placeholder="mq"
              onChange={({ target: { value } }) =>
                handleChangeText(type, value, i)
              }
            ></input>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={`col-lg-${column}`}>
      <div
        className={`form-group offset-lg-${offset} ${
          error ? "is-invalid" : ""
        }`}
      >
        <label className="form-control-label text-capitalize" htmlFor="name">
          {`${label} ${isRequired ? "*" : ""}`}
        </label>
        <div className="container-entity-information">
          <div className="row w-100">
            {itemsRadioFiltered.radio!.map(
              ({ label, checked, selectOptions, squareMeters }, i) => {
                return renderRadioInput(
                  "radio",
                  label,
                  checked,
                  squareMeters,
                  i
                );
              }
            )}
          </div>
          <div className="row w-100 my-4">
            {itemsRadioFiltered.radioM!.map(
              ({ label, checked, selectOptions, squareMeters }, i) => {
                return renderRadioInput(
                  "radioM",
                  label,
                  checked,
                  squareMeters,
                  i
                );
              }
            )}
          </div>
          <div className="row w-100">
            {itemsRadioFiltered.select!.map(
              (
                { label, checked, selectOptions, selectOption, squareMeters },
                i
              ) => {
                return renderTypeLabel(label, selectOptions, selectOption, i);
              }
            )}
          </div>
          {error && (
            <div className="invalid-feedback">
              <div>{error}</div>
            </div>
          )}
        </div>
      </div>
      {/* <div className="col-lg-12 d-flex justify-content-center">
        {button && (
          <Button
            margin={2}
            color={"primary"}
            textColor={"light"}
            onClick={() => buttonAction(button.action)}
          >
            {button.label}
          </Button>
        )}
        {secondaryButton && (
          <Button
            margin={2}
            color={"danger"}
            textColor={"light"}
            onClick={() => buttonAction(secondaryButton.action)}
          >
            {secondaryButton.label}
          </Button>
        )}
      </div> */}
    </div>
  );
};

export default EntityInformationInput;
