import { QuestionOutlineIcon } from "@chakra-ui/icons";
import React, { FC, useMemo } from "react";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import { Form } from "../../../../hooks/useForm";
import { useI18n } from "../../../../hooks/useI18n";
import { Field } from "../../../../models/CustomForm";
import { NestedKeys } from "../../../../typings/KeysFromObject";
import { getNestedValueFromString } from "../../../../Utils/fuctions";

import "./CheckBox.scss";

interface IRadio {
  form: Partial<Form<any>>;
  setForm: (key: NestedKeys<any>, value: any) => void;
  data: Field;

  error: string | undefined;
}

const CheckBox: FC<IRadio> = ({
  data: {
    option,
    label,
    isRequired,
    tooltip,
    key,
    column = 4,
    columnRadio = 12,
    offset = 0,
    maxColumn,
  },
  setForm,
  form,
  error,
}) => {
  const { label: labelCheckBox } = option!;
  // eslint-disable-next-line
  const isChecked = useMemo(() => getNestedValueFromString(key, form), [form]);
  const handleclick = () => {
    setForm(key, !isChecked);
  };
  const { t } = useI18n();

  return (
    <>
      <div className={`col-lg-${column}`}>
        <div
          className={`form-group offset-lg-${offset} ${
            error ? "is-invalid" : ""
          }`}
        >
          <label
            className="form-control-label form-label text-capitalize"
            htmlFor="name"
          >
            {`${t(label)} ${isRequired ? "*" : ""}`}
          </label>
          <div className="container-checkbox">
            <div className={`checkbox col-lg-${columnRadio}`}>
              <div
                onClick={handleclick}
                className={`container-checkbox-circle me-1`}
              >
                <div
                  className={`circle ${isChecked ? "checked" : "unchecked"}`}
                ></div>
              </div>
              <span className="checkbox-label text-capitalize">
                <span className={`me-1 pointer `} onClick={handleclick}>
                  {" "}
                  {t(labelCheckBox)}
                </span>
                {tooltip && (
                  <Tooltip label={t(tooltip.label)}>
                    <QuestionOutlineIcon cursor={"pointer"} />
                  </Tooltip>
                )}
              </span>
            </div>
            {error && (
              <div className="invalid-feedback">
                <div>{error}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      {maxColumn && <div className={`col-lg-${maxColumn}`} />}
    </>
  );
};

export default CheckBox;
