import { Wrapper } from "@googlemaps/react-wrapper";
import React, { FC} from "react";
import { apiKeyGoogleMaps } from "../../environment/environment";
import "./Map.scss";
import MapGoogle from "./MapGoogle";


interface IMap {
  center: google.maps.LatLngLiteral;
  zoom: number;
  className?: string
}

const Map: FC<IMap> = ({ center, zoom, className = "" }) => {


  return (
    <Wrapper apiKey={apiKeyGoogleMaps}>
      <MapGoogle zoom={zoom} center={center} className={className}></MapGoogle>
    </Wrapper>
  );
};

export default Map;
