import React, { FC, useEffect, useMemo, useState } from "react";
import { Form, FormReturnType } from "../../../../hooks/useForm";
import { useI18n } from "../../../../hooks/useI18n";
import { Field } from "../../../../models/CustomForm";
import { IDemandCustomer } from "../../../../models/demandCustomer";
import { NestedKeys } from "../../../../typings/KeysFromObject";
import getClasses from "../../../../Utils/getClasses";
import "./TitleDemand.scss";

interface ITitleDemand {
  form: Partial<Form<IDemandCustomer>>;
  setForm: (key: NestedKeys<IDemandCustomer>, value: any) => void;
  data: Field<any>;
  handleError: FormReturnType<any>["handleError"];
  error: string | undefined;
}

const TitleDemand: FC<ITitleDemand> = ({ form, setForm }) => {
  const { title } = form;
  const [editMode, setEditMode] = useState(false);
  const { t } = useI18n();

  useEffect(() => {
    title === "Customer demand" &&
      setForm("title", t("createDemandCustomerForm.title"));
    // eslint-disable-next-line
  }, []);

  const renderTitleInput = useMemo(
    () => (
      <div className={`form-group mb-0 container-title-demand-input`}>
        <input
          type={"text"}
          onWheel={({ currentTarget }) => currentTarget.blur()}
          value={title}
          onBlur={() => {
            setEditMode(false);
            title && setForm("title", title.trim());
            !title?.trim() &&
              setForm("title", t("createDemandCustomerForm.title"));
          }}
          onChange={({ target: { value } }) =>
            value.length < 37 && setForm("title", value)
          }
          className={`${getClasses({
            "form-control-title": true,
          })} p-md input-text`}
        />
      </div>
    ),
    // eslint-disable-next-line
    [title, editMode]
  );
  return (
    <div className="container-title-demand">
      {renderTitleInput}
      <p className="page-description capitalize-first-letter mt-1">
        {t("createDemandCustomerForm.description.edit")}
      </p>
    </div>
  );
};

export default TitleDemand;
