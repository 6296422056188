import { Consultant } from "../models/Consultant";
import { RespLogin } from "../models/RespLogin";

export type KeyStorage = {
    token: RespLogin  & {date: Date};
    me: Consultant & {isLoggedOnGoogle: boolean},
    language: "it" | "en"
};

export const setStorageItem = <K extends keyof KeyStorage>(key: K, value: KeyStorage[K]) => {
    localStorage.setItem(key, JSON.stringify(value));
}

export const removeStorageItem = <K extends keyof KeyStorage>(key: K) => {
    localStorage.removeItem(key);
}

export const getStorageItem = <K extends keyof KeyStorage>(key: K): KeyStorage[K] | null => {
    try{
        return JSON.parse(localStorage.getItem(key)!) as KeyStorage[K];
    } catch(e) {
        console.log('e',e);
        return null;
    }
}