import { NestedKeys } from "advanced-types";
import { Translation } from "../../assets/i18n/locals/en";
import { ICustomForm } from "../../models/CustomForm";
import { ZONE } from "../../models/RealEstate";

const zoneKeysLabel: NestedKeys<Translation, ".">[] = [
  "zone.airport",
  "zone.bar",
  "zone.tennisCourts",
  "zone.shoppingCenters",
  "zone.sportsComplexes",
  "zone.MountainSkiLifts",
  "zone.gym",
  "zone.pinewood",
  "zone.middleSchool",
  "zone.ringRoad",
  "zone.postOffice",
  "zone.dayCare",
  "zone.wood",
  "zone.canal",
  "zone.congressCenter",
  "zone.lake",
  "zone.shops",
  "zone.playground",
  "zone.bikePath",
  "zone.highSchool",
  "zone.publicTransport",
  "zone.motorway",
  "zone.footballFields",
  "zone.wellnessCenter",
  "zone.religiousComplexes",
  "zone.sea",
  "zone.hospital",
  "zone.park",
  "zone.elementarySchool",
  "zone.trainStation",
  "zone.municipalOffices",
];

export const zoneLabel: { label: NestedKeys<Translation, ".">; value: ZONE }[] =
  Object.entries(ZONE).map(([key, value], index) => ({
    label: zoneKeysLabel[index],
    value,
  }));
export interface IZoneCharacteristicsForm {
  zoneCharacteristics: ZONE[];
}
export const initialValueZoneCharacteristicsForm = {
  zoneCharacteristics: [],
};

export const zoneCharacteristicsForm: ICustomForm<IZoneCharacteristicsForm> = {
  title: {
    edit: "realEstate.forms.zoneCharacteristicsForm.title",
    show: "realEstate.forms.zoneCharacteristicsForm.title",
  },
  description: {
    edit: "realEstate.forms.zoneCharacteristicsForm.description.edit",
    show: "realEstate.forms.zoneCharacteristicsForm.description.show",
  },
  // labelSubmitButton: { edit: "realEstate.forms.zoneCharacteristicsForm.labelSubmit.edit", show: "realEstate.forms.zoneCharacteristicsForm.labelSubmit.show" },
  labelSubmitButton: { edit: "editImage.save", show: "editImage.save" },
  next: "images", // TODO REMOVE THIS
  forms: [
    {
      title: "",
      fields: [
        {
          column: 12,
          columnRadio: 3,
          label: "",
          key: "zoneCharacteristics",
          type: "radio",
          multiple: true,
          button: {
            label: "realEstate.forms.zoneCharacteristicsForm.selectAll",
            action: "selectAll",
          },
          secondaryButton: {
            label: "realEstate.forms.zoneCharacteristicsForm.deselectAll",
            action: "resetAll",
          },
          options: Object.values(ZONE).map((info, i) => ({
            label: zoneKeysLabel[i],
            value: info,
            checked: false,
          })),
        },
      ],
    },
  ],
};
