import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LanguageState {
  language: "it" | "en";
}

const initialState = { language: "it" } as LanguageState;

const counterSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguage(state: LanguageState, action: PayloadAction<"it" | "en">) {
      state.language = action.payload;
    },
  },
});

export const { setLanguage } = counterSlice.actions;
export default counterSlice.reducer;
