import dayjs from "dayjs";
import { FC, forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "./MonthDatePicker.scss";

const getMonth = (date: Date) => +dayjs(date).format("MM");

interface IMonthDatepicker {
    date: Date | null,
    changeYear: (year: number) => void,
    changeMonth: (month: number) => void,
    languageCalendar: Locale
}


export const  MonthDatepicker: FC<IMonthDatepicker> = ({
    date,
    changeYear,
    changeMonth,
    languageCalendar
}) => {
    const [currentDate, setCurrentDate] = useState<Date | null>(date);
    const CustomInputDatePicker = forwardRef(({ value, onClick }: any, ref) => (
        <>
          <div
            className="calendar-month-custom-input"
            onClick={onClick}
            ref={ref as any}
          >
              <span className="data-month-label">{value}</span>
              <i className="icon icon-ch-down icon-arrow"></i>
          </div>
        </>
      ));

      useEffect(() => {
        setCurrentDate(date)
      }, [date])
      
    
    return(
    <DatePicker
    customInput={<CustomInputDatePicker />}
      selected={currentDate}
      className={"container-calendar-month"}
      locale={languageCalendar}
      onChange={(dateOnChange) => {
        if (!dateOnChange) return;
        changeYear(+dayjs(dateOnChange!).format("YYYY"));
        changeMonth(getMonth(dateOnChange!) - 1);
        setCurrentDate(dateOnChange)
      }}
      renderCustomHeader={({
        decreaseYear,
        date: dateHeader,
        increaseYear,
        prevYearButtonDisabled,
        nextYearButtonDisabled,
      }) => {
        return (
          <div className="d-flex justify-content-between px-2">
            <button onClick={decreaseYear} disabled={prevYearButtonDisabled}>
              <i className="icon me-3 icon-ch-left icon-arrow"></i>
            </button>
            <span className="month-label">{dayjs(dateHeader).format("YYYY")}</span>
            <button onClick={increaseYear} disabled={nextYearButtonDisabled}>
              <i className="icon icon-ch-right icon-arrow"></i>
            </button>
          </div>
        );
      }}
      dateFormat="MMMM yyyy"
      showMonthYearPicker
      showFullMonthYearPicker
    />
  )};