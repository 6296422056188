import { isCommercial } from "./../../Utils/fuctions";
import { TRUE } from "sass";
import { ICustomForm } from "../../models/CustomForm";
import { IDemandCustomer } from "../../models/demandCustomer";
import { CONTRACT_TYPE } from "../../models/RealEstate";
import { fillSelection } from "../../Utils/fuctions";
import {
  architecturalBarriersTypes,
  contractOptions,
  currentStateDemandCustomers,
  demandStatus,
  furnitureStatus,
  propertyOptions,
  propertyOptionsCommercial,
} from "../realeEstate/selections";

export const initialValueDemandCustomerForm = {
  type: "",
  contractType: "",
  status: "",
  title: "",
  consultant: {
    _id: "",
    name: "",
    surname: "",
    email: "",
    enabled: true,
    owner: false,
    role: "",
    agency: {
      name: "",
      _id: "",
    },
  },
  originalConsultant: {
    _id: "",
    name: "",
    surname: "",
    email: "",
    enabled: true,
    owner: false,
    role: "",
    agency: {
      name: "",
      _id: "",
    },
  },
  agency: {
    _id: "",
    name: "",
  },
  customer: {
    customerState: "",
    acquisitionDate: "",
    rating: "",
    customerTypes: [],
    name: "",
    surname: "",
    gender: "",
    ageRange: "",
    nationality: "",
    birthPlace: "",
    _id: "",
    birthDate: null,
    taxIdCode: "",
    email: "",
    phone1: "",
    phone2: "",
    smsDisabled: false,
    mailingDisabled: true,
    source: "",
    note: "",
    residence: {
      country: "", // nazione
      state: "", // stato/provincia/regione
      municipality: "", // comune
      postalCode: "", // CAP
      address: "", // indirizzo
      houseNumber: "", // civico
    },
    identityDocument: {
      type: "",
      documentId: "",
      releasedBy: "",
      released: "",
    },
    enabled: true,
    consultant: { _id: "", name: "", surname: "" },
    title: "",
    entity: "",
    spokenLanguages: [],
  },
  conditions: {
    required: [],
    optional: [],
  },
  propertyTypes: [],
  createdAt: null,
  updatedAt: null,
  enabled: true,
  miscellaneous: "", // varie
  specificNeeds: "", // esigenze specifiche
  price: {
    from: "",
    to: "",
  },
  totalSquareMeters: {
    from: "",
    to: "",
  },
  locals: {
    from: "",
    to: "",
  },
  seaLakeDistance: {
    from: "",
    to: "",
  },
  acquisitionSource: "",
  latitude: null,
  longitude: null,
  rooms: {
    from: "",
    to: "",
  },
  bathrooms: {
    from: "",
    to: "",
  },
  furnitureStatus: "",
  beds: {
    from: "",
    to: "",
  },
  currentState: "",
  //forStudents: false,
  zones: [],
  isLoan: false,
  isCash: false,
  gotFinancialAdvice: false,
  loanAmount: 0,
};

export const createDemandCustomerForm: ICustomForm<IDemandCustomer> = {
  // buttonRight: "changeConsultant",
  labelSubmitButton: {
    edit: "customers.demandsCustomers.edit",
    show: "createDemandCustomerForm.labelSubmit.show",
  },
  next: "/home/demands",
  forms: [
    {
      title: "",
      fields: [
        {
          label: "",
          type: "title-demand",
          key: "title",
        },
      ],
    },
    {
      title: "createDemandCustomerForm.info.title",
      fields: [
        {
          label: "createDemandCustomerForm.info.fields.status",
          type: "select",
          key: "status",
          options: fillSelection(demandStatus),
        },
      ],
    },
    {
      title: "createDemandCustomerForm.type",
      fields: [
        {
          column: 12,
          label: "",
          type: "radio",
          multiple: true,
          options: fillSelection(propertyOptions, false),
          key: "propertyTypes",
        },
      ],
    },
    {
      title: "createDemandCustomerForm.infoRealEstate.title",
      fields: [
        {
          column: 2,
          label: "createDemandCustomerForm.infoRealEstate.priceFrom",
          type: "number",
          typeField: "currency",
          placeholder:
            "createDemandCustomerForm.infoRealEstate.placeholderfrom",
          key: "price/from",
        },
        {
          column: 2,
          label: "",
          type: "number",
          typeField: "currency",
          placeholder: "createDemandCustomerForm.infoRealEstate.placeholderTo",
          key: "price/to",
        },
        {
          column: 2,
          label:
            "createDemandCustomerForm.infoRealEstate.totalSquareMetersFrom",
          type: "number",
          placeholder:
            "createDemandCustomerForm.infoRealEstate.placeholderfrom",
          key: "totalSquareMeters/from",
        },
        {
          column: 2,
          label: "",
          type: "number",
          placeholder:
            "createDemandCustomerForm.infoRealEstate.placeholderfrom",
          key: "totalSquareMeters/to",
        },
        {
          column: 2,
          label: "createDemandCustomerForm.infoRealEstate.localsFrom",
          type: "number",
          placeholder:
            "createDemandCustomerForm.infoRealEstate.placeholderfrom",
          key: "locals/from",
        },
        {
          column: 2,
          label: "",
          type: "number",
          placeholder: "createDemandCustomerForm.infoRealEstate.placeholderTo",
          key: "locals/to",
        },
        {
          column: 2,
          label: "createDemandCustomerForm.infoRealEstate.rooms",
          type: "number",
          placeholder:
            "createDemandCustomerForm.infoRealEstate.placeholderfrom",
          key: "rooms/from",
        },
        {
          column: 2,
          label: "",
          type: "number",
          placeholder: "createDemandCustomerForm.infoRealEstate.placeholderTo",
          key: "rooms/to",
        },
        {
          column: 2,
          label: "createDemandCustomerForm.infoRealEstate.bathrooms",
          type: "number",
          placeholder:
            "createDemandCustomerForm.infoRealEstate.placeholderfrom",
          key: "bathrooms/from",
        },
        {
          column: 2,
          label: "",
          type: "number",
          placeholder: "createDemandCustomerForm.infoRealEstate.placeholderTo",
          key: "bathrooms/to",
        },
        {
          column: 4,
          label: "createDemandCustomerForm.infoRealEstate.furnitureStatus",
          type: "select",
          options: fillSelection(furnitureStatus),
          key: "furnitureStatus",
        },
        {
          column: 2,
          label: "createDemandCustomerForm.infoRealEstate.beds",
          type: "number",
          placeholder:
            "createDemandCustomerForm.infoRealEstate.placeholderfrom",
          key: "beds/from",
        },
        {
          column: 2,
          label: "",
          type: "number",
          placeholder: "createDemandCustomerForm.infoRealEstate.placeholderTo",
          key: "beds/to",
        },
        {
          column: 4,
          label: "createDemandCustomerForm.infoRealEstate.currentState",
          type: "select",
          options: fillSelection(currentStateDemandCustomers),
          key: "currentState",
        },
        {
          column: 2,
          label: "createDemandCustomerForm.infoRealEstate.seaLakeDistance",
          placeholder:
            "createDemandCustomerForm.infoRealEstate.placeholderfrom",
          type: "number",
          key: "seaLakeDistance/from",
        },
        {
          column: 2,
          label: "",
          type: "number",
          placeholder: "createDemandCustomerForm.infoRealEstate.placeholderTo",
          key: "seaLakeDistance/to",
        },
      ],
    },
    {
      title: "createDemandCustomerForm.infoRealEstate.contract",
      fields: [
        {
          label: "createDemandCustomerForm.infoRealEstate.contractType",
          type: "select",
          options: fillSelection(contractOptions),
          key: "contractType",
        },
        /* {
          column: 3,
          label: "",
          type: "checkbox",
          option: {
            label: "createDemandCustomerForm.infoRealEstate.forStudents",
            value: false,
          },
          key: "forStudents",
        }, */
        /*     {
          column: 3,
          label: "",
          type: "select",
          options: fillSelection(architecturalBarriersTypes, false),
          key: "missingArchitecturalBarriers",
        }, */

        {
          column: 2,
          label: "",
          type: "checkbox",
          option: {
            label: "createDemandCustomerForm.infoRealEstate.cash",
            value: false,
          },
          key: "isCash",
          showIfKey: "contractType",
          showIf: (contractType: CONTRACT_TYPE) =>
            contractType === CONTRACT_TYPE.SALE,
        },
        {
          column: 2,
          label: "",
          type: "checkbox",
          option: {
            label: "createDemandCustomerForm.infoRealEstate.loan",
            value: false,
          },
          key: "isLoan",
          showIfKey: "contractType",
          showIf: (contractType: CONTRACT_TYPE) =>
            contractType === CONTRACT_TYPE.SALE,
        },
        {
          column: 2,
          label: "createDemandCustomerForm.infoRealEstate.amount",
          type: "number",
          typeField: "currency",
          key: "loanAmount",
          showIfKey: ["isLoan", "contractType"],
          showIf: ([isLoan, contractType]: [boolean, CONTRACT_TYPE]) =>
            contractType === CONTRACT_TYPE.SALE && isLoan,
        },
        {
          column: 3,
          label: "",
          type: "checkbox",
          option: {
            label: "createDemandCustomerForm.infoRealEstate.financialAdvice",
            value: false,
          },
          key: "gotFinancialAdvice",
          showIfKey: "contractType",
          showIf: (contractType: CONTRACT_TYPE) =>
            contractType === CONTRACT_TYPE.SALE,
        },
      ],
    },

    {
      title: "createDemandCustomerForm.featuresByCustomer.title",
      fields: [
        {
          column: 12,
          label: "createDemandCustomerForm.notes.specificNeeds",
          type: "conditions",
          key: "conditions/required",
        },
      ],
    },
    {
      title: "createDemandCustomerForm.notes.title",
      fields: [
        {
          column: 6,
          label: "createDemandCustomerForm.notes.specificNeeds",
          type: "text-area",
          key: "specificNeeds",
        },
        {
          column: 6,
          label: "createDemandCustomerForm.notes.miscellaneous",
          type: "text-area",
          key: "miscellaneous",
        },
      ],
    },
    {
      title: "createDemandCustomerForm.zones",
      fields: [
        {
          column: 12,
          label: "createDemandCustomerForm.place",
          type: "location-demand-customer",
          key: "zones",
        },
      ],
    },
  ],
};
export const createDemandCustomerFormCommercial: ICustomForm<IDemandCustomer> =
  {
    // buttonRight: "changeConsultant",
    labelSubmitButton: {
      edit: "customers.demandsCustomers.edit",
      show: "createDemandCustomerForm.labelSubmit.show",
    },
    next: "/home/demands",
    forms: [
      {
        title: "",
        fields: [
          {
            label: "",
            type: "title-demand",
            key: "title",
          },
        ],
      },
      {
        title: "createDemandCustomerForm.info.title",
        fields: [
          {
            label: "createDemandCustomerForm.info.fields.status",
            type: "select",
            key: "status",
            options: fillSelection(demandStatus),
          },
        ],
      },
      {
        title: "createDemandCustomerForm.type",
        fields: [
          {
            column: 12,
            label: "",
            type: "radio",
            multiple: true,
            options: fillSelection(propertyOptionsCommercial, false),
            key: "propertyTypes",
          },
        ],
      },
      {
        title: "createDemandCustomerForm.infoRealEstate.title",
        fields: [
          {
            column: 2,
            label: "createDemandCustomerForm.infoRealEstate.priceFrom",
            type: "number",
            typeField: "currency",
            placeholder:
              "createDemandCustomerForm.infoRealEstate.placeholderfrom",
            key: "price/from",
          },
          {
            column: 2,
            label: "",
            type: "number",
            typeField: "currency",
            placeholder:
              "createDemandCustomerForm.infoRealEstate.placeholderTo",
            key: "price/to",
          },
          {
            column: 2,
            label:
              "createDemandCustomerForm.infoRealEstate.totalSquareMetersFrom",
            type: "number",
            placeholder:
              "createDemandCustomerForm.infoRealEstate.placeholderfrom",
            key: "totalSquareMeters/from",
          },
          {
            column: 2,
            label: "",
            type: "number",
            placeholder:
              "createDemandCustomerForm.infoRealEstate.placeholderfrom",
            key: "totalSquareMeters/to",
          },
          {
            column: 2,
            label: "createDemandCustomerForm.infoRealEstate.localsFrom",
            type: "number",
            placeholder:
              "createDemandCustomerForm.infoRealEstate.placeholderfrom",
            key: "locals/from",
          },
          {
            column: 2,
            label: "",
            type: "number",
            placeholder:
              "createDemandCustomerForm.infoRealEstate.placeholderTo",
            key: "locals/to",
          },
          {
            column: 2,
            label: "createDemandCustomerForm.infoRealEstate.rooms",
            type: "number",
            placeholder:
              "createDemandCustomerForm.infoRealEstate.placeholderfrom",
            key: "rooms/from",
          },
          {
            column: 2,
            label: "",
            type: "number",
            placeholder:
              "createDemandCustomerForm.infoRealEstate.placeholderTo",
            key: "rooms/to",
          },
          {
            column: 2,
            label: "createDemandCustomerForm.infoRealEstate.bathrooms",
            type: "number",
            placeholder:
              "createDemandCustomerForm.infoRealEstate.placeholderfrom",
            key: "bathrooms/from",
          },
          {
            column: 2,
            label: "",
            type: "number",
            placeholder:
              "createDemandCustomerForm.infoRealEstate.placeholderTo",
            key: "bathrooms/to",
          },
          {
            column: 4,
            label: "createDemandCustomerForm.infoRealEstate.furnitureStatus",
            type: "select",
            options: fillSelection(furnitureStatus),
            key: "furnitureStatus",
          },
          {
            column: 2,
            label: "createDemandCustomerForm.infoRealEstate.beds",
            type: "number",
            placeholder:
              "createDemandCustomerForm.infoRealEstate.placeholderfrom",
            key: "beds/from",
          },
          {
            column: 2,
            label: "",
            type: "number",
            placeholder:
              "createDemandCustomerForm.infoRealEstate.placeholderTo",
            key: "beds/to",
          },
          {
            column: 4,
            label: "createDemandCustomerForm.infoRealEstate.currentState",
            type: "select",
            options: fillSelection(currentStateDemandCustomers),
            key: "currentState",
          },
          {
            column: 2,
            label: "createDemandCustomerForm.infoRealEstate.seaLakeDistance",
            placeholder:
              "createDemandCustomerForm.infoRealEstate.placeholderfrom",
            type: "number",
            key: "seaLakeDistance/from",
          },
          {
            column: 2,
            label: "",
            type: "number",
            placeholder:
              "createDemandCustomerForm.infoRealEstate.placeholderTo",
            key: "seaLakeDistance/to",
          },
        ],
      },
      {
        title: "createDemandCustomerForm.infoRealEstate.contract",
        fields: [
          {
            label: "createDemandCustomerForm.infoRealEstate.contractType",
            type: "select",
            options: fillSelection(contractOptions),
            key: "contractType",
          },
          /* {
          column: 3,
          label: "",
          type: "checkbox",
          option: {
            label: "createDemandCustomerForm.infoRealEstate.forStudents",
            value: false,
          },
          key: "forStudents",
        }, */
          /*     {
          column: 3,
          label: "",
          type: "select",
          options: fillSelection(architecturalBarriersTypes, false),
          key: "missingArchitecturalBarriers",
        }, */

          {
            column: 2,
            label: "",
            type: "checkbox",
            option: {
              label: "createDemandCustomerForm.infoRealEstate.cash",
              value: false,
            },
            key: "isCash",
            showIfKey: "contractType",
            showIf: (contractType: CONTRACT_TYPE) =>
              contractType === CONTRACT_TYPE.SALE,
          },
          {
            column: 2,
            label: "",
            type: "checkbox",
            option: {
              label: "createDemandCustomerForm.infoRealEstate.loan",
              value: false,
            },
            key: "isLoan",
            showIfKey: "contractType",
            showIf: (contractType: CONTRACT_TYPE) =>
              contractType === CONTRACT_TYPE.SALE,
          },
          {
            column: 2,
            label: "createDemandCustomerForm.infoRealEstate.amount",
            type: "number",
            typeField: "currency",
            key: "loanAmount",
            showIfKey: ["isLoan", "contractType"],
            showIf: ([isLoan, contractType]: [boolean, CONTRACT_TYPE]) =>
              contractType === CONTRACT_TYPE.SALE && isLoan,
          },
          {
            column: 3,
            label: "",
            type: "checkbox",
            option: {
              label: "createDemandCustomerForm.infoRealEstate.financialAdvice",
              value: false,
            },
            key: "gotFinancialAdvice",
            showIfKey: "contractType",
            showIf: (contractType: CONTRACT_TYPE) =>
              contractType === CONTRACT_TYPE.SALE,
          },
        ],
      },

      {
        title: "createDemandCustomerForm.featuresByCustomer.title",
        fields: [
          {
            column: 12,
            label: "createDemandCustomerForm.notes.specificNeeds",
            type: "conditions",
            key: "conditions/required",
          },
        ],
      },
      {
        title: "createDemandCustomerForm.notes.title",
        fields: [
          {
            column: 6,
            label: "createDemandCustomerForm.notes.specificNeeds",
            type: "text-area",
            key: "specificNeeds",
          },
          {
            column: 6,
            label: "createDemandCustomerForm.notes.miscellaneous",
            type: "text-area",
            key: "miscellaneous",
          },
        ],
      },
      {
        title: "createDemandCustomerForm.zones",
        fields: [
          {
            column: 12,
            label: "createDemandCustomerForm.place",
            type: "location-demand-customer",
            key: "zones",
          },
        ],
      },
    ],
  };
