import React, { FC } from "react";
import getClasses from "../../../Utils/getClasses";
import "./SnackChip.scss";

export type IThemeSnackChip =  "info" | "warning" | "success" | "error" | "info-secondary"
interface Props {
  theme: IThemeSnackChip;
  label: string;
  border?: string;
  iconLeft?: JSX.Element | string;
  iconRight?: JSX.Element | string;
  fontWeight?: number;
  options?: {
    colorTextNeutral?: boolean
  }
  borderRadius?: "sm" | "md";
}
const SnackChip: FC<Props> = ({
  theme,
  iconLeft,
  iconRight,
  label,
  options,
  borderRadius = "md",
}) => {
  const renderIcon = (icon: JSX.Element | string) =>
    typeof icon === "string" ? <i className={`icon-chip ${icon}`} /> : icon;
  return (
    <div
      className={`snack-chip snack-chip-border-radius-${borderRadius} ${theme}-chip ${getClasses({"neutral-text": Boolean(options?.colorTextNeutral)})}`}
    >
      {iconLeft && renderIcon(iconLeft)}
      <span>{label}</span>
      {iconRight && renderIcon(iconRight)}
    </div>
  );
};

export default SnackChip;
