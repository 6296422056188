export interface Customer {
  customerState: CUSTOMER_STATE | string;
  acquisitionDate: string;
  rating: RATING;
  customerTypes: CUSTOMER_TYPE[];
  name: string;
  surname: string;
  gender: GENDER;
  ageRange: AGE_RANGE;
  nationality: string;
  birthPlace: string;
  _id: string;
  birthDate: Date | null;
  taxIdCode: string;
  email: string;
  phone1: string;
  phone2: string;
  smsDisabled: boolean;
  mailingDisabled: boolean;
  source: CUSTOMER_SOURCE;
  note: string;
  residence: CustomerResidence;
  identityDocument: CustomerDocument;
  enabled: boolean;
  consultant: { _id: string; name: string; surname: string };
  title: string;
  entity: CUSTOMER_ENTITY;
  spokenLanguages: LANGUAGE[];
  shortCode: string;
  demandCreated?:boolean;

}

export enum CUSTOMER_STATE {
  ACTIVE = "active",
  SUSPENDED = "suspended",
  DEAL_IN_PROGRESS = "dealProgress",
  DEAL_CONCLUDED = "dealConcluded",
}

export enum CUSTOMER_TYPE {
  APPLICANT = "applicant",
  OWNER = "owner",
  SUPPLIER = "supplier",
  SIGNALER = "signaler",
  OTHER = "other",
}

export enum RATING {
  STANDARD = "standard",
  LOW = "low",
  HIGH = "high",
  EXCELLENT = "excellent",
  NOT_PROVIDED = "notProvided",
}

export enum AGE_RANGE {
  NOT_PROVIDED = "notProvided",
  LESS_THAN_26 = "lessThan26",
  FROM_26_TO_35 = "from26To35",
  FROM_36_TO_45 = "from36To45",
  FROM_46_TO_60 = "from46To60",
  OVER_60 = "over60",
}

export enum IDENTITY_DOCUMENT {
  IDENTITY_CARD = "identityCard",
  PASSPORT = "passport",
  DRIVING_LICENSE = "drivingLicense",
  SIGNED_DOCUMENTS = "signedDocuments",
  ITALIAN_CONSOLATE_DECLARATION = "italianConsolateDeclaration",
  PROFESSIONAL_ATTESTATION = "professionalAttestation",
  INTERMEDIARY_ATTESTATION = "intermediaryAttestation",
  OTHER = "other",
}
export interface CustomerDocument {
  type: IDENTITY_DOCUMENT | string;
  documentId: string;
  releasedBy: string;
  released: string;
}

export const customerDocumentKeys: Array<keyof CustomerDocument> = [
  "documentId",
  "released",
  "releasedBy",
  "type",
];

export enum CUSTOMER_ENTITY {
  PHYSICAL = "physical",
  JURIDICAL = "juridical",
  NOT_PROVIDED = "notProvided",
}

export interface CustomerResidence {
  country: string; // nazione
  state: string; // stato/provincia/regione
  municipality: string; // comune
  postalCode: string; // CAP
  address: string; // indirizzo
  houseNumber: string; // civico
}

export enum LANGUAGE {
  ITALIAN = "italian",
  GERMAN = "german",
  RUSSIAN = "russian",
  CHINESE = "chinese",
  ENGLISH = "english",
  FRENCH = "french",
  SPANISH = "spanish",
  ARABIC = "arabic",
}


export enum GENDER {
  MALE = "male",
  FEMALE = "female",
  NOTPROVIDED = "NOTPROVIDED",
  OTHER = "OTHER",
}

export enum CUSTOMER_SOURCE {
  ADVERTISEMENT = "advertisement",
  SIGNALER = "signaler",
  NOT_PROVIDED = "notProvided",
}
