import { ICustomForm } from "../../models/CustomForm";
import {
  EnergyCertification,
  EnergyCertificationOld,
  ENERGY_CLASS,
  ENERGY_CLASS_OLD,
  ENERGY_PERFORMANCE,
  PERFORMANCE_UNIT,
  SoundCertification,
  SOUND_CLASS,
} from "../../models/RealEstate";
import { fillSelection } from "../../Utils/fuctions";
import {
  energyOptions,
  energyPerformanceOptions,
  oldEnergyClassOptions,
  performanceUnitOptions,
  soundClassOptions,
  unitOptions,
} from "./selections";

export interface ICertificationsForm {
  energyCertification?: EnergyCertification;
  energyCertificationOld?: EnergyCertificationOld;
  soundCertification?: SoundCertification;
}
export const initialValueCertificationsForm = {
  energyCertification: {
    class: ENERGY_CLASS.NOT_PROVIDED,
    performanceIndex: 0,
    performanceUnit: PERFORMANCE_UNIT.NOT_PROVIDED,
    almostZeroEnergy: false,
    energyPerformance: {
      winter: ENERGY_PERFORMANCE.NOT_PROVIDED,
      summer: ENERGY_PERFORMANCE.NOT_PROVIDED,
    },
  },
  energyCertificationOld: {
    class: ENERGY_CLASS_OLD.NOT_PROVIDED,
    performanceIndex: 0,
    performanceUnit: PERFORMANCE_UNIT.NOT_PROVIDED,
    notes: "",
  },
  soundCertification: {
    class: SOUND_CLASS.NOT_PROVIDED,
    notes: "",
  },
};

export const certificationsForm: ICustomForm<ICertificationsForm> = {
  title: {
    edit: "realEstate.forms.certifications.title",
    show: "realEstate.forms.certifications.title",
  },
  description: {
    edit: "realEstate.forms.certifications.description.edit",
    show: "realEstate.forms.certifications.description.show",
  },
  // labelSubmitButton: {edit: "realEstate.forms.certifications.labelSubmit.edit", show: "realEstate.forms.certifications.labelSubmit.show"},
  labelSubmitButton: { edit: "editImage.save", show: "editImage.save" },
  next: "other-info",
  forms: [
    {
      title: "realEstate.forms.certifications.titles.energyCertification.title",
      fields: [
        {
          label:
            "realEstate.forms.certifications.titles.energyCertification.labels.class",
          type: "select",
          key: "energyCertification/class",
          options: fillSelection(energyOptions),
        },
        {
          label:
            "realEstate.forms.certifications.titles.energyCertification.labels.performanceIndex",
          column: 2,
          type: "number",
          typeField: "decimal",
          key: "energyCertification/performanceIndex",
        },
        {
          label:
            "realEstate.forms.certifications.titles.energyCertification.labels.performanceUnit",
          column: 2,
          type: "select",
          key: "energyCertification/performanceUnit",
          options: fillSelection(performanceUnitOptions),
        },
        {
          label: "",
          column: 4,
          type: "checkbox",
          key: "energyCertification/almostZeroEnergy",
          option: {
            label:
              "realEstate.forms.certifications.titles.energyCertification.labels.almostZeroEnergy",
            value: false,
          },
        },
        {
          label:
            "realEstate.forms.certifications.titles.energyCertification.labels.energyPerformanceWinter",
          column: 4,
          type: "select",
          key: "energyCertification/energyPerformance/winter",
          options: fillSelection(energyPerformanceOptions),
        },
        {
          label:
            "realEstate.forms.certifications.titles.energyCertification.labels.energyPerformanceSummer",
          column: 4,
          type: "select",
          key: "energyCertification/energyPerformance/summer",
          options: fillSelection(energyPerformanceOptions),
        },
      ],
    },
    {
      title:
        "realEstate.forms.certifications.titles.energyCertificationOld.title",
      fields: [
        {
          label:
            "realEstate.forms.certifications.titles.energyCertificationOld.labels.class",
          type: "select",
          key: "energyCertificationOld/class",
          options: fillSelection(oldEnergyClassOptions),
        },
        {
          label:
            "realEstate.forms.certifications.titles.energyCertificationOld.labels.performanceIndex",
          column: 2,
          type: "number",
          typeField: "decimal",
          key: "energyCertificationOld/performanceIndex",
        },
        {
          label:
            "realEstate.forms.certifications.titles.energyCertificationOld.labels.performanceUnit",
          column: 2,
          type: "select",
          key: "energyCertificationOld/performanceUnit",
          options: fillSelection(unitOptions),
        },
        {
          label:
            "realEstate.forms.certifications.titles.energyCertificationOld.labels.notes",
          column: 8,
          maxColumn: 4,
          type: "text-area",
          key: "energyCertificationOld/notes",
        },
      ],
    },
    {
      title: "realEstate.forms.certifications.titles.soundCertification.title",
      fields: [
        {
          label:
            "realEstate.forms.certifications.titles.soundCertification.labels.class",
          type: "select",
          key: "soundCertification/class",
          options: fillSelection(soundClassOptions),
        },
        {
          label:
            "realEstate.forms.certifications.titles.soundCertification.labels.notes",
          type: "text-area",
          key: "soundCertification/notes",
        },
      ],
    },
  ],
};
