import React, { FC } from "react";
import "./Svg.scss";

interface ISvg {
  icon: string;
  className: string;
}

const Svg: FC<ISvg> = ({ icon, className }) => {
  return (
    <svg  fill="none" viewBox="0 0 24 24" className={className}>
      <use xlinkHref={icon} href={icon}></use>
    </svg>
  );
};

export default Svg;
