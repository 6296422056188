import React, { FC, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Form } from "../../../../hooks/useForm";
import { useRealEstateForm } from "../../../../hooks/useRealEstateForm";
import { ICustomForm } from "../../../../models/CustomForm";
import CustomForm from "../../../../shared/components/CustomForm/CustomForm";
import Loader from "../../../../shared/components/Loader/Loader";
import { Image, imagesForm } from "../../../../forms/realeEstate/images";
import { RoutesRouter } from "../../../../models/Routes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { setIsSubmit } from "../../../../redux/reducers/realEstate";
import { useI18n } from "../../../../hooks/useI18n";
import { useToastAlert } from "../../../../shared/components/ToastAlert/ToastAlert";
import { getQueryUrl, getRoleUser } from "../../../../Utils/fuctions";
import { useEndpoints } from "../../../../hooks/useEndpoints";
import { APPROVAL_STATUS } from "../../../../models/RealEstate";
import { DURATION_TOAST } from "../../../../Utils/costants";
import "./Images.scss";

interface IImages {}

const Images: FC<IImages> = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const toastAlert = useToastAlert();
  const { patchRealEstate } = useEndpoints();
  const [searchParams] = useSearchParams();
  const isValidation = searchParams.get("validation");
  const { putRealEstate } = useSelector(
    ({ realEstate }: RootState) => realEstate
  );
  const { queryTable } = useSelector(({ realEstate }: RootState) => realEstate);
  const {
    isLand,
    isLoading,
    initialValue,
    generateForm,
    goBackPath,
    navigate: navigateRelativePath,
  } = useRealEstateForm<{
    images: Image[];
  }>("images");
  // const { t } = useI18n();

  const dispatch = useDispatch();
  const { isSubmit } = useSelector(({ realEstate }: RootState) => realEstate);
  const navigateOn = () =>
    navigate(
      `${RoutesRouter.realEstates}${getQueryUrl(queryTable?.params || {})}`,
      {
        replace: true,
      }
    );

  const changeApprovalStatus = async () => {
    if (!putRealEstate) return;
    const { _id, approvalStatus } = putRealEstate;
    const roleUser = getRoleUser();
    const role =
      roleUser === "ADMIN" || roleUser === "SUPER_ADMIN"
        ? "ADMIN"
        : "CONSULTANT";
    switch (approvalStatus) {
      case APPROVAL_STATUS.DRAFT:
        const approvalStatusDraft =
          APPROVAL_STATUS[role === "ADMIN" ? "APPROVED" : "PENDING"];
        await patchRealEstate(_id, {
          approvalStatus: approvalStatusDraft,
        });
        return role === "ADMIN" ? "approved" : "pending";
      case APPROVAL_STATUS.PENDING:
        const approvalStatus =
          APPROVAL_STATUS[role === "ADMIN" ? "APPROVED" : "DRAFT"];

        await patchRealEstate(_id, {
          approvalStatus,
        });

        return role === "ADMIN" ? "approved" : "draft";
    }
    navigateOn();
  };

  const handleSubmit = async (
    form: Partial<Form<{ images: Image[] }>>,
    next: ICustomForm["next"],
    isSame: boolean,
    isBack: boolean
  ) => {
    if (isBack) {
      navigateRelativePath(goBackPath());
      return;
    }
    try {
      if (isValidation && !isLand && form.images!.length < 10) {
        toastAlert({
          duration: 5000,
          message: t("realEstate.forms.images.toast.minLength.title"),
          subMessage: t("realEstate.forms.images.toast.minLength.descrition"),
          type: "error",
        });
        return;
      }
      if (isValidation && isLand && form.images!.length < 5) {
        toastAlert({
          duration: 5000,
          message: t("realEstate.forms.images.toast.minLengthLand.title"),
          subMessage: t(
            "realEstate.forms.images.toast.minLengthLand.description"
          ),
          type: "error",
        });
        return;
      }
      if (isValidation) {
        const newStatus = await changeApprovalStatus();
        newStatus &&
          toastAlert({
            message: t(`realEstate.forms.images.toast.${newStatus}.title`),
            subMessage: t(
              `realEstate.forms.images.toast.${newStatus}.description`
            ),
            type: "success",
            duration: 2000,
          });
      }
      navigateOn();
    } catch (error) {
      toastAlert({
        message: t("realEstate.forms.images.toast.error.title"),
        subMessage: t("realEstate.forms.images.toast.error.descrition"),
        type: "error",
        duration: DURATION_TOAST,
      });
      console.error("error", error);
    }
  };

  const handleOnSubmitted = () => {
    dispatch(setIsSubmit(false));
  };

  const renderCustomForm = () => (
    <>
      {/*@ts-ignore*/}
      <CustomForm
        submit={handleSubmit}
        doSubmit={{ isSubmit, onSubmitted: handleOnSubmitted }}
        fixedSubmitButton={true}
        width={12}
        className={
          "custom-form-container-with-fixed-submit custom-form-container-stepper"
        }
        form={form}
        props={imagesForm}
      />
    </>
  );

  const form = useMemo(
    () => generateForm({}),
    // eslint-disable-next-line
    [initialValue]
  );

  return !isLoading && form.initialValue ? renderCustomForm() : <Loader />;
};

export default Images;
