import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { selectOptionsIt } from "../assets/i18n/locals/it/selectOptions";
import { RealEstate } from "../models/RealEstate";
import { RespDemandsCustomers } from "../models/RespDemands";
import { RootState } from "../redux/reducers";
import { setCustomers } from "../redux/reducers/customers";
import { setDemandsCustomers } from "../redux/reducers/demandsCustomers";
import { ITableButtonOption } from "../shared/components/Table/Table";
import { ElementForPage } from "../typings/Pagination";
import { getQueryUrl } from "../Utils/fuctions";
import { getStorageItem, KeyStorage } from "../Utils/localStorage";
import { useEndpoints } from "./useEndpoints";

// let timeoutTermFilter: NodeJS.Timeout | null = null;
export const useDemandsCustomers = (id: string) => {
  const { demandsCustomers: allDemandsCustomers } = useSelector(
    ({ demandsCustomers }: RootState) => demandsCustomers
  );
  const [searchParams] = useSearchParams();
  const [doFirstFetch, setDoFirstFetch] = useState(false);
  const [demandsInProcessing, setDemandsInProcessing] = useState(true);

  const [termFilter, setTermFilter] = useState<
    Record<"label" | "value", string>
  >({ label: "", value: "" });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  // const toast = useToast();
  const { getDemandsCustomer, getDemandsInProcessing } = useEndpoints();
  const [pagination, setPagination] = useState<{
    limit: ElementForPage;
    page: number;
  }>({ limit: 10, page: 0 });
  const [queryParams, setQueryParams] = useState<Record<any, any>>({});

  const fetchDemandsCustomers = async () => {
    const { page, limit } = pagination;
    setIsLoading(true);
    try {
      const skip = pagination.page === 0 ? 0 : (page - 1) * limit;
      const titleQuery = termFilter.value ? { title: termFilter.value } : {};
      const { data: resp } = await getDemandsCustomer({
        limit: limit,
        skip,
        customer: id!,
        ...titleQuery,
        ...queryParams,
      });
      if (resp.docs.some(({ isProcessing }) => isProcessing))
        setDemandsInProcessing(true);

      dispatch(setDemandsCustomers(resp));
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const resetDemandsCustomers = () => {
    dispatch(setDemandsCustomers(undefined));
  };

  const changePage = (page: number) => {
    setPagination({ ...pagination, page });
  };

  const setLimit = (limit: ElementForPage) => {
    setPagination({ limit, page: 0 });
  };

  const setConsultantId = (id: string) => {
    setQueryParams((prev) => {
      if (id === "") {
        delete prev["consultant"];
      } else {
        prev["consultant"] = id;
      }
      return { ...prev };
    });
  };

  const setQueryParam = (key: keyof RealEstate, value: string | undefined) => {
    setIsLoading(true);
    setQueryParams((prev) => {
      let copyPrev = { ...prev };
      if (!value) {
        copyPrev.hasOwnProperty(key) && delete copyPrev[key];
      } else {
        copyPrev[key] = value;
      }
      return copyPrev;
    });
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const value = event.currentTarget.value;
      setTermFilter(() => ({ value: value, label: value }));
    }
  };

  const handleReset = (value: string) => {
    setTermFilter((prev) => ({ ...prev, label: value }));
    !value && setTermFilter({ label: "", value: "" });
  };

  useEffect(() => {
    doFirstFetch && fetchDemandsCustomers();
    // eslint-disable-next-line
  }, [pagination, queryParams]);

  useEffect(() => {
    setPagination((prev) => ({ ...prev, page: 0 }));
  }, [termFilter.value]);

  useEffect(() => {
    doFirstFetch && fetchDemandsCustomers();
    // eslint-disable-next-line
  }, [doFirstFetch]);

  useEffect(() => {
    return () => {
      dispatch(setCustomers(undefined));
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { page, limit } = pagination;
    const skip =
      pagination.page === 0 ? 0 : (pagination.page - 1) * pagination.limit;
    const newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      getQueryUrl({
        search: termFilter.label,
        page,
        limit,
        skip,
        ...queryParams,
      });
    window.history.replaceState({ path: newurl }, "", newurl);
  }, [pagination, termFilter.label, queryParams]);

  useEffect(() => {
    setIsLoading(true);
    const limitQuery = searchParams.get("limit");
    const pageQuery = searchParams.get("page");
    const searchQuery = searchParams.get("search");
    const consultantQuery = searchParams.get("consultant");

    setQueryParams((prev) => {
      consultantQuery && (prev.consultant = consultantQuery);
      return { ...prev };
    });

    setPagination({
      page: Number(pageQuery) ?? 0,
      limit: (Number(limitQuery) as ElementForPage) || 10,
    });
    setTermFilter({ label: searchQuery || "", value: searchQuery || "" });
    setTimeout(() => {
      setDoFirstFetch(true);
    }, 400);
    // eslint-disable-next-line
  }, []);

  const fetchDemandsProcessing = async () => {
    const {
      data: { demands },
    } = await getDemandsInProcessing(id);

    if (!demands.length) {
      setDemandsInProcessing(false);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      fetchDemandsProcessing();
    }, 5000);
    if (!demandsInProcessing) {
      clearInterval(interval);
      fetchDemandsCustomers();
    }
    return () => {
      clearInterval(interval);
    };
  }, [demandsInProcessing]);

  const demandsCustomersForTable = useMemo(() => {
    return allDemandsCustomers?.docs
      ? allDemandsCustomers.docs.map(
          ({
            status,
            contractType,
            consultant,
            customer,
            customerShortCode,
            agency,
            title,
            createdAt,
            price,
            enabled,
            nMatches,
            isProcessing,
          }) => ({
            title,
            status:
              selectOptionsIt.demandStatus[
                status as keyof (typeof selectOptionsIt)["demandStatus"]
              ] || status,
            contractType,
            consultantNameAndSurname: `${consultant.surname} ${consultant.name}`,
            customerNameAndSurname: `${customer.surname} ${customer.name}`,
            customerShortCode,
            createdAt: dayjs(createdAt).format("YYYY-MM-DD"),
            agencyName: agency.name,
            enabled,
            price,
            nMatches,
            isProcessing,
          })
        )
      : undefined;
  }, [allDemandsCustomers]);

  const demandsCustomers = useMemo(() => {
    return allDemandsCustomers?.docs || undefined;
  }, [allDemandsCustomers]);

  const generateButtons = useCallback(
    (
      callback: (
        value: NonNullable<typeof demandsCustomersForTable>[0],
        role: KeyStorage["me"]["role"]
      ) => ITableButtonOption
    ): Array<ITableButtonOption> => {
      if (!demandsCustomersForTable) return [] as Array<ITableButtonOption>;
      return demandsCustomersForTable.map((demands) => {
        return callback(demands, getStorageItem("me")!.role);
      });
    },
    [demandsCustomersForTable]
  );

  return {
    fetchDemandsCustomers,
    handleReset,
    setTermFilter,
    setLimit,
    generateButtons,
    nameConsultant: allDemandsCustomers?.customerName,
    setConsultantId,
    setQueryParam,
    limit: pagination.limit,
    termFilter,
    handleKeyPress,
    resetDemandsCustomers,
    demandsCustomersForTable,
    changePage,
    allDemandsCustomers,
    queryParams,
    isLoading,
    demandsCustomers,
  };
};

export default useDemandsCustomers;
