import { nationalities, INationalities } from "../../../Utils/nationalities";
import { selectOptionsEn } from "./en/selectOptions";

export const getNationalities = (): Record<
  INationalities[number],
  INationalities[number]
> => {
  return Object.fromEntries(
    Object.entries(nationalities).map(([key, value]) => [value, value])
  ) as Record<INationalities[number], INationalities[number]>;
};

const en = {
  pageNotFound: {
    description:
      "The page you are looking for may have been removed, changed, or temporarily unavailable.",
    returnButtonConsultants: "return to consultants page",
    returnButtonCustomers: "return to customers page",
    returnButtonLogin: "return to login page",
  },
  consultants: {
    toasts: {
      edit: {
        consultant: "Consultant",
        edited: "Consultant edited",
        added: " Consultant added",
        success: "successfully",
      },
      errorFetch: {
        title: "Error",
        description: "Consultant not found",
      },
    },
    title: "Consultants",
    sub_title: "The list of your collaborators",
    placeHolderSearchBar: "Search Bar...",
    buttonAddConsultant: "Add Consultant",
    publishOnShowcase: "Publish on showcase",
    enabled: "Enabled",
    headTable: {
      name: "Name",
      surname: "Surname",
      email: "Email",
      isEnabled: "Enabled",
      customerState: "State",
      acquisitionDate: "Acquisition date",
      role: "Role",
    },
    emptyTable: {
      title: "Create your first consultant",
      description: "Start by creating your first consultant",
      labelButton: "Add Consultant",
    },
    emptyResourcesTable: {
      title: "No results found",
      description: "Try entering a different search term",
      resultDemandCustomer: {
        title: "No real estates found",
      },
      emptyZone: {
        title: "Unmatched real estates",
        description:
          "It is not possible to combine real estates with the request if at least one area has not been entered to the request.",
      },
    },
    table: {
      role: {
        ADMIN: "Admin",
        CONSULTANT: "Consultant",
        SUPER_ADMIN: "Super Admin",
        ANONYMOUS: "Anonymous",
        STAFF: "Staff",
      },
      options: {
        edit: "Edit",
        delete: "Delete",
        generatePassword: "Generate password",
        modalGeneratePassword: {
          title: "Generate new password",
          labelButtonHasCopied: "Copied",
          labelButtonNotCopied: "Copy",
        },
        makeConsultant: "Make Consultant",
        makeAdmin: "Make Admin",
        enable: "Enable",
        disable: "Disable",
      },
    },

    forms: {
      edit: {
        title: {
          edit: "Edit Consultant",
          show: "Consultant",
        },
        description: { edit: "Fill in the form to edit a consultant" },
        labelSubmitButton: {
          edit: "Edit Consultant",
          show: "Back",
        },
      },
      create: {
        title: { edit: "Create Consultant", show: "Consultant" },
        description: {
          edit: "Fill in the form to add a new collaborator to existing customers",
          show: "Consultant information",
        },
        labelSubmitButton: { edit: "Add consultant", show: "Back" },
      },
      common: {
        title: "Consultant data",
        fields: {
          name: {
            label: "Name",
            placeholder: "John",
          },
          surname: {
            label: "Surname",
            placeholder: "Doe",
          },
          email: {
            label: "Email",
            placeholder: "johndoe@example.com",
          },
          role: {
            label: "Role",
            options: {
              admin: "Admin",
              consultant: "Consultant",
              staff: "Staff",
            },
          },
          telephone: {
            label: "Telephone",
            placeholder: "Insert phone number",
          },
          profilePicture: "Picture profile",
          enabled: "Enabled",
        },
      },
    },
  },

  navbar: {
    resetPassword: "Reset Password",
    logoutButton: "Logout",
    customers: "Customers",
    consultants: "Consultants",
    createCustomer: "Create customer",
  },
  toast: {
    customerAdded: {
      title: "Customer Added",
      description: "customer added successfully",
    },
  },
  login: {
    title: "Login to your account",
    labelEmail: "Email address",
    labelPassword: "Password",
    buttonSubmit: "Login",
  },

  customers: {
    title: "Customers",
    description: "Your Customers List",
    addCustomer: "Add Customer",
    headTable: {
      name: "Name",
      surname: "Surname",
      code: "Code",
      consultant: "Consultant",
      isEnabled: "Enabled",
      isRequest: "Request",
      acquisitionDate: "Acquisition date",
    },
    changeConsultant: {
      title: "Change consultant",
      consultant: "Consultant",
      addConsultant: "Want to add a new consultant?",
      skip: "Skip",
      confirm: "Confirm",
      toast: {
        edited: "Customer edited",
        added: "Customer added",
        success: "with success",
      },
      placeholder: "Enter customer name",
    },
    demandsCustomers: {
      title: "Demands Customers",
      description_one: "{{count}} demand made to the customer",
      description_other: "{{count}} demands made to the customer",
      add: "Add Demand",
      edit: "Save",
      firstDemand: "Start by creating the first demand",
      createFirstDemand: "Create your first demand",
      addConditions:
        "Add one or more groups of conditions by clicking on the 'add conditions' button then, select all conditions with the plus button, inside the box.",
      addCustomer: "Add Customer",
      necessaryConditions: "Necessary Conditions",
      optionalConditions: "Necessary Conditions",
      selectConditions: "Select Conditions",
      addConditionsButton: "Add Conditions",
      confirm: "confirm",
      cancel: "Cancel",
      conditionsModal: {
        search: "Search",
        keyWithCount_other: "{{count}} selected conditions",
        keyWithCount_one: "{{count}} selected condition",
        selectedGroups: "selected groups",
        selectedGroup: "selected group",
        reset: "Reset Filters",
        selectPlaceholder: "Select area",
        box: "Garage",
        kitchen: "Kitchen",
        floor: "Floor",
        heatingType: "Heating",
        conservationStatus: "Conservation Status",
        balconies: "Balconies",
        terrace: "Terrace",
        garden: "Garden",
        terrain: "Terrain",
        satTv: "sat Tv",
        tvAntenna: "Antenna tv",
        parkingSpaces: "Parking spaces",
        electricalSystem: "Electrical system",
        coveredParkingSpaces: "Covered Parking Spaces",
        openParkingSpaces: "Open Parking Spaces",
        bikeParkingSpaces: "Bike Parking Spaces",
        camperParkingSpaces: "Camper Parking Spaces",
        boatParkingSpaces: "Boat Parking Spaces",
        cellar: "Cellar",
        warehouse: "Warehouse",
        petsAllowed: "Pets allowed",
        depot: "Depot",
        elevator: "Elevator",
        bareOwnership: "Bare Ownership",
        airConditioning: "Air conditioning",
        loft: "Loft",
        phoneSystem: "Phone system",
        parquet: "Parquet",
        lakeView: "Lake view",
        seaView: "Sea view",
        porch: "Porch",
        "*": "every",
        suspendedSanitary: "Suspended sanitary",
        shutters: "Shutters",
        closet: "Closet",
        woodFixtures: "Wood fixtures",
        atAuction: "At auction",
        pool: "Pool",
        alarmPredisposed: "Alarm predisposed",
        jacuzzi: "Jacuzzi",
        independentEntrance: "Independent entrance",
        aluminiumFixtures: "Aluminium fixtures",
        shower: "Shower",
        chimney: "Chimney",
        safetyBox: "Safety box",
        attic: "Attic",
        shed: "Shed",
        stable: "Stable",
        areas: {
          external: "External",
          internal: "Internal",
          relevance: "Relevance",
          other: "Other",
        },
      },
      collaborationModal: {
        title: "Cooperation",
        description: "Is there active cooperation?",
        options: {
          yes: "Yes",
          no: "No",
          onHold: "On Hold",
        },
        save: "Save",
      },
      commissionModal: {
        title: "Commission",
        description: "Has a commission been agreed upon?",
        options: {
          yes: "Yes",
          no: "No",
        },
        placeholder: "Insert percentage",
        save: "Save",
      },
      negotiationModal: {
        title: "Ongoing Negotiation",
        description: "Is the negotiation ongoing?",
        options: {
          yes: "Yes",
          no: "No",
          onHold: "On Hold",
        },
        save: "Save",
      },
      toast: {
        noPercentage: "Please insert the percentage",
        invalidPercentage: "Invalid Percentage",
        saved: "Saved successfully",
        reset: "Reset successfully",
      },
    },
    table: {
      head: {
        demandStatus: "Stato richiesta",
        titleDemand: "Demand title",
        customerCode: "Customer code",
        creationDate: " Created on",
        nMatches: "Matches",
      },
      options: {
        edit: "Edit",
        enable: "Enable",
        disable: "Disable",
        requests: "Demands",
        delete: "Delete",
      },
    },
    labelFilterButton: "Filter by consultant",
    labelFilterByCustomerType: "Filter by customer type",
    placeHolderSearchBar: "Search Bar...",
    firstItemFilter: "All",
    emptyTable: {
      title: "Create your first customer",
      description: "Start by creating your first customer",
      labelButton: "Add Customer",
    },
  },

  resetPassword: {
    title: "Enter new password",
    labelPassword: "Password",
    labelConfirmPassword: "Confirm Password",
    submit: "Confirm",
  },

  errorMessagesForm: {
    enterAtLeastOneZone: "Enter at least one zone",
    isBeforeDate: "The date must be after today's date",
    isAfterDate: "The date must be before today's date",
    required: "Required field",
    requiredMultiSelect: "select at least one element",
    invalidEmail: "The email address is invalid",
    invalidLengthPassword: "It must be at least 20 characters long",
    invalidDescriptionLength: "Deve essere lunga almeno 20 caratteri",

    invalidTaxIdCode: "The tax code may be incorrect",
    invalidUrl: "Invalid url",
    existingCustomer:
      "Customer {{context}} with this email or phone number is already existing!",
    buttonExistingCustomerAdd: "Click here to add it anyway",
    buttonExistingCustomerEdit: "Click here to edit it anyway",
  },

  // Demand Customer

  createDemandCustomerForm: {
    title: "Customer demand",
    description: {
      edit: "Fill in the form to complete the demand",
      show: "Customer demand information",
    },
    labelSubmit: { edit: "Add demand", show: "Back" },
    info: {
      title: "Demand information",
      fields: {
        status: "Demand status",
        placeholderStatus: "",
      },
    },
    type: "Type",
    descriptionForm: "Description",
    infoRealEstate: {
      title: "Property specifications",
      priceFrom: "Price From",
      totalSquareMetersFrom: "Total sq m From",
      localsFrom: "Locals From",
      rooms: "Rooms",
      bathrooms: "Bathrooms",
      furnitureStatus: "Furnished",
      beds: "Beds",
      currentState: "Current State",
      seaLakeDistance: "Sea/Lake distance",
      contractType: "Contract type",
      contract: "Contract",
      forStudents: "Suitable for students",
      missingArchitecturalBarriers: "Architectural barriers",
      financialAdvice: "Financial advice",
      loan: "Loan",
      cash: "Cash",
      amount: "Amount",
      placeholderfrom: "From",
      placeholderTo: "To",
    },
    zones: "Zones",
    place: "Place",
    featuresByCustomer: {
      title: "features requested by the customer",
      description:
        'Add one or more groups of conditions by clicking on the "add conditions" button then, select all conditions with the plus button inside the box.',
    },
    notes: {
      title: "Notes",
      specificNeeds: "Specific needs",
      miscellaneous: "Miscellaneous",
    },
  },
  //Create Customer
  createCustomerPage: {
    info: "Basic info",
    specific: "Customer specifications",
    type: "Customer type",
    tooltip: {
      type: `A customer can be of several types simultaneously.
      If classified exclusively as 'other' it simply becomes a generic contact,
      useful for taking a census of personal data relating to notaries, architects, etc.`,
      contacts: `It is better to insert at least one telephone number in addition to the email.`,
    },
    personalData: "Personal data",
    contacts: "contacts",
    source: "Acquisition source",
    address: "Address",
    docs: "Documents",
    notes: "Notes",
    customer: "Customer",
    create: "Create customer",
    save: "Save",
    edit: "Edit customer",
    fillToEdit: "Fill in the form to modify the customer",
    fillInTheForm:
      "Fill in the form to add a new customer to existing customers",
    addCustomer: "Add customer",
    back: "Back",
  },
  address: {
    nation: "Nation",
    region: "State Region or District",
    postalCode: "Postal code",
    town: "Town",
    city: "City",
    province: "Province",
    zipCode: "Zip Code",
    address: "Address",
    civic: "Street Number",
    istatCode: "Istat Code",
    placeholder: {
      region: "London",
      town: "Bloomsbury",
      address: "Abbey Road",
      postalCode: "94010",
      civic: "45",
    },
  },
  basicInfo: {
    status: {
      state: "Customer status",
      options: {
        active: "Active",
        suspended: "Suspended",
        dealInProgress: "Deal in progress",
        dealConcluded: "Deal concluded",
      },
    },

    rating: {
      state: "Rating",
      options: {
        standard: "Standard",
        low: "Low",
        high: "High",
        excellent: "Excellent",
      },
    },
    tooltip: {
      rating: "Customer quality based on his requests",
    },
    date: "Acquisition date",
    creationDate: "Creation date",
  },
  contacts: {
    email: "Email",
    phone: { phone1: "Telephone 1", phone2: "Telephone 2" },
    placeholderPhone: "Enter number",
    placeholderEmail: "Enter email",
    checkbox: {
      sms: "Doesn't receive SMS",
      mail: "Doesn't receive EMAILS",
    },
    tooltip: {
      sms: "If checked, the customer will not be able to receive sms communications",
      mail: "If checked, the customer will not be able to receive email communications",
    },
  },
  customerType: {
    done: {
      yes: "Yes",
      no: "No",
      all: "All",
    },
    types: {
      applicant: "Applicant",
      owner: "Owner",
      supplier: "Supplier",
      signaler: "Signaler",
      other: "Other",
    },
    tooltip:
      "Check this option if the customer you are entering does not have any requests or properties owned and you just want to file him",
  },
  documents: {
    docTypes: {
      identityCard: "Identity card",
      passport: "Passport",
      drivingLicense: "Driving license",
      publicDeeds:
        "Public deeds, authenticated private deeds or documents with digital signature",
      declaration: "Declaration of the Italian consular authority",
      certificateFromAnotherProfessional:
        "Certificate from another professional",
      certificateOfQualifiedIntermediaries:
        "Certificate of qualified intermediaries, EEC credit or financial institutions, banks of FATF countries",
      other: "Other documentation",
    },
    type: "Document type",
    docDetails: "Document number or details",
    released: "Released by",
    date: "In date",
    placeholderReleased: "Enter common release",
  },
  notes: {
    placeholder: "Write here any additional information...",
  },
  personalData: {
    types: {
      physical: "Physical",
      juridical: "Juridical",
      notProvided: "Not provided",
    },
    gender: {
      male: "Male",
      female: "Female",
      notProvided: "Not provided",
      other: "Other",
    },
    languages: {
      arabic: "Arabic",
      chinese: "Chinese",
      french: "French",
      italian: "Italian",
      english: "English",
      russian: "Russian",
      spanish: "Spanish",
      german: "German",
    },
    person: "Person",
    title: "Title",
    placeholderTitle: "Dott / Ing / Ecc.",
    surname: "Surname",
    placeholderName: "John",
    placeholderSurname: "Doe",
    placeholderSelect: "Select",

    name: "Name",
    genre: "Genre",
    birth: {
      place: "Birth place",
      city: "City",
      date: "Birth date",
    },
    nationality: "Nationality",
    taxIdCode: "Fiscal code",
    placeHolderTaxIdCode: "QWERTYUIOP123456",
    leng: "Spoken languages",
  },
  source: {
    source: "Source",
    sources: {
      Advertisement: "Advertisement",
      Signaler: "Signaler",
      notProvided: "Not provided",
    },
  },
  pagination: {
    elements: "Items per page",
    pagesLabel: "of",
    items: "items",
  },
  home: {
    customers: "Customers",
    customerDetails: "Customer Details",
    consultants: "Consultants",
    consultantDetails: "Consultants Details",
    realEstate: "Real Estates",
    realestates: "Real Estates",
    realEstateDetails: "Real Estate Details",
    realEstatePage: "Real estates Page",
    agency: "Agency",
    dashboard: "Dashboard",
    agenda: "Agenda",
    matches: "Matches",
    messages: "Messages",
    "demand-customer": "Demand customer",
    "matches-demand-customer": "Match results",
    edit: "Edit",
    logOut: "Log Out",
    languages: {
      it: "Italian",
      en: "English",
    },
  },

  agency: {
    errorToast: {
      title: "Unexpected problem",
      description: "Ops! Unexpected problem!",
    },
    successToast: {
      title: "Changes that have been successful",
      description: "Data Uptated",
    },
    form: {
      fields: {
        agencyUrls: {
          external: "External url realestates",
          internal: "Internal url realestates",
        },
        agencyWikicasaSettings: {
          agencyId: "Agency ID Wikicasa",
          agentId: "Agent ID Wikicasa",
        },
        agencyIdealistaSettings: {
          feedKey: "Feed Key Idealista",
          contactId: "Contact ID Idealista",
        },
        agencyImmobiliareSettings: {
          email: "Email Immobiliare",
          agencyName: "Agency Name Immobiliare",
        },
        imageQuality: {
          label: "Image quality",
          placeholder: "from 30 to 100",
          tooltip: "Percentage of compression of the image",
        },
        thumbnailQuality: {
          label: "Thumbnail quality",
          placeHolder: "from 30 to 100",
          tooltip: "Percentage of compression of the thumbnail",
        },
        sizeThreshold: {
          label: "Uncompressed images",
          tooltip: "Above this size images will be compressed",
        },
        realestates: {
          label: "Dormant immovable threshold",
          tooltip:
            "Threshold in days above which a realestate will be marked as dormant",
        },
        customers: {
          label: "Dormant customers threshold",
          tooltip:
            "Threshold in days above which a customer will be marked as dormant",
        },
        zoneOffset: {
          label: "Percentage's increment size zone map",
          tooltip: "This value specifies how much to increment map zones",
        },
        zoneOffsetShowCase: {
          label: "Radius of the address area in the showcase",
          tooltip:
            "This value is the radius of the zones on the showcase's map",
        },

        timeToArchiveExternalRealEstate: {
          label: "Inactive External Property Archiving Threshold",
          tooltip:
            "Number of days after which the property will be archived if not active among Casafari's matches",
        },
        timeToDeleteExternalRealEstate: {
          label: "Inactive External Property Deletion Threshold",
          tooltip:
            "Number of days after which the property will be deleted if not active among Casafari's matches",
        },

        demands: {
          label: "Dormant demands threshold",
          tooltip:
            "Threshold in days above which a demand will be marked as dormant",
        },
        name: {
          label: "Name agency",
        },
        abbreviation: {
          label: "Name abbreviation agency",
        },
        mobilePhone: {
          label: "Mobile number",
          error: "number not valid",
        },
        landPhone: {
          label: "Land number",
          error: "number not valid",
        },
        fiscalCode: {
          label: "Fiscal code or P.iva",
          error: "Vat or Fiscal Code not valid",
        },
        place: {
          label: "Place",
          error: "Place not valid",
        },
        istatCode: {
          label: "Istat Code",
        },
      },
      errorImage: "Must be between 30 to 100",
      required: "Required Value",
      greaterThanZero: "Must be greater than zero",
      nameAgency: "The name must be between 3 and 25 characters",
      abbreviation: "The abbreviation must be between 3 and 5 characters",
      errorTimeToArchiveExternalRealEstateRange:
        "The archive threshold must be between 1 and 365",
      errorTimeToArchiveGreaterThanTimeToDelete:
        "The archive threshold must be lower than the deletion threshold",
      errorTimeToDeleteExternalRealEstateRange:
        "The deletion threshold must be between 1 and 365",
    },
    editAgency: {
      title: "Edit Agency",
      description: "Agency settings",
      submit: "Save",
    },
  },
  realEstate: {
    clone: "Clone",
    edit: "Edit",
    delete: "Delete",
    placeHolderSearchBar: "Search Bar...",
    agent: "Agent",
    otherFilters: "Other filters",
    hideFilters: "Hide filters",
    onSale: "On Sale",
    forRent: "For Rent",
    alertDeniedPublish: {
      title:
        "Real estate publication failed due to lack of some mandatory fields",
      description: "Click here to add them",
    },
    stateMenuButtonLabels: {
      approval: "Approval status",
      market: "Market status",
    },
    consultant: "Consultant",
    emptyTable: {
      description: "Start by creating your first property",
      title: "Create your first Real Estate",
      labelButton: "Add Real Estate",
    },
    approvalStatus: {
      draft: "Draft",
      pending: "Pending",
      approved: "Approved",
      sold: "Sold",
      rented: "Rented",
      suspended: "Suspended",
      soldSuspended: "Sold with suspension",
    },
    addRealEstate: {
      title: "Add Real Estate",
      type: "Typology",
      RESIDENTIAL: "Residential",
      COMMERCIAL: "Commercial",
      LAND: "Land",
      NOT_PROVIDED: "Not provided",
      add: "Add",
    },
    selectFilters: {
      filters_zero: "Select the filters",
      filters_one: "{{count}} selected",
      filters_other: "{{count}} selected",
    },
    select: "Select",
    buttonsFilters: {
      reset: "Reset",
      apply: "Apply",
      unlockFilters: "Unlock filters",
      resetFilters: "Reset filters",
      lockFilters: "Lock filters",
    },
    forms: {
      landDescription: {
        landKind: "Land kind",
        title: "Land Description",
      },
      residential: {
        generalForm: {
          errorToast: {
            title: "Unexpected problem",
            description: "Ops! Unexpected problem! ",
          },
          title: {
            edit: "General",
            show: "General",
          },
          description: {
            edit: "General information",
            show: "General information",
          },
          labelSubmitButton: {
            edit: "Edit",
            show: "Next",
          },
          labelBackButton: {
            edit: "Exit",
            show: "Exit",
          },
          titles: {
            informationRealState: {
              title: "Information about the real state",
              labels: {
                acquisitionDate: "Acquisition date",
                state: "State",
                mediationType: "Mediation",
                mediationFrom: "From",
                mediationTo: "To",
              },
            },
            property: {
              title: "Property",
              labels: {
                owner: "Owner",
                tooltip: "Add new Owner",
              },
            },
            details: {
              title: "Details",
              labels: {
                contractType: "Contract",
                rentDetails: {
                  contractType: "Contract type",
                  requestedWarranty: "Requested Warranty",
                  contractRegistration: "Contract Registration",
                  propertyType: "Type",
                },
                checkbox: {
                  forStudents: {
                    option: "Suitable for students",
                    tooltip:
                      "Indicates if the property can be suitable to be rented to students",
                  },
                  petsAllowed: { option: "Pet friendly" },
                  bareOwnership: {
                    option: "Bare ownership",
                  },
                  dryTax: "Dry Tax",
                },
              },
            },
            virtualTour: {
              title: "Media",
              labels: {
                virtualTourUrl: "Virtual Tour",
                media: "Video",
              },
            },
            availability: {
              title: "Availability",
              labels: {
                currentState: "Current state",
                availableNow: {
                  option: "Available Now",
                  tooltip:
                    "If the property is currently rented or not available, indicate the expected availability date by removing this tick",
                },
                videoUrl: "Url Video",
                notes: "Notes",
                details: "Optional availability details",
              },
            },
          },
        },
      },
      certifications: {
        title: "certifications",
        description: {
          edit: "Define the certifications that the property has",
          show: "certifications of the property",
        },
        labelSubmit: { edit: "Edit", show: "Next" },
        titles: {
          energyCertification: {
            title: "Energy class and performance index",
            labels: {
              class: "Energy class",
              performanceIndex: "EP glnr",
              performanceUnit: "Performance Unit",
              almostZeroEnergy: "Nearly zero energy building",
              energyPerformanceWinter: "Winter Performance ",
              energyPerformanceSummer: "Summer Performance ",
            },
          },
          energyCertificationOld: {
            title:
              "Energy class and performance index (For attestations prior to October 2015)",
            labels: {
              class: "Energy class",
              performanceIndex: "EP glnr",
              performanceUnit: "Performance Unit",
              notes: "Note",
            },
          },
          soundCertification: {
            title: "Noise certification",
            labels: {
              class: "Class",
              notes:
                "Further details and notes regarding the Acoustic Certification",
            },
          },
        },
      },
      zoneCharacteristicsForm: {
        title: "Area features",
        description: {
          edit: "Check other possible characteristics of the property",
          show: "Property features",
        },
        labelSubmit: {
          edit: "Edit",
          show: "Next",
        },
        selectAll: "Select all",
        deselectAll: "Deselect all",
      },
      description: {
        cards: {
          balconies: "Balconies",
          terrace: "Terrace",
          garden: "Garden",
          terrain: "Terrain",
          garage: "Garage",
          kitchen: "Kitchen",
          coveredParkingSpaces: "Covered Parking Spaces",
          openParkingSpaces: "Open Parking Spaces",
          bikeParkingSpaces: "Bike Parking Spaces",
          camperParkingSpaces: "Camper Parking Spaces",
          boatParkingSpaces: "Boat Parking Spaces",
          cellar: "Cellar",
          warehouse: "Warehouse",
          depot: "Depot",
          loft: "Loft",
          attic: "Attic",
          shed: "Shed",
          stable: "Stable",
        },
        kitchen: "Kitchen",
        title: "Description",
        description: {
          edit: "Add some features of the property",
          show: "Property features",
        },
        labelSubmit: {
          edit: "Edit",
          show: "Next",
        },
        features: {
          title: "Property features",
          labels: {
            totalSquareMeters: "Square Meters",
            totalSquareMetersWalkable: "Square Meters Walkable",
            locals: "Locals",
            rooms: "Bedrooms",
            showcases: "Showcases",
            bathrooms: "Bathrooms",
            description: "Description",
            landKind: "Land kind",
            buildabilityIndex: "Buildability Index",
            buildableSquareMeters: "Buildable Square Meters",
            agriculturalSquareMeters: "Agricultural Square Meters",
            exposition: "Exposition",
            constructionPresent: "Construction Present",
            possibleProduction: "Possible Production",
            approvedProject: "Approved Project",
            easementOfPassage: "Easement of Passage",
            preEmptionRights: "Pre-emption Rights",
            plantations: "Plantations",
          },
        },
        deatils: "Details of main premises and appliances",
        otherRooms: "Additional rooms and rooms",
        heating: {
          title: "Heating",
          labels: {
            type: "Type",
            fuel: "Fuel",
            system: "System",
            radiators: "Radiators",
            expenses: "Monthly heating costs",
          },
        },
        additionalFeatures: {
          title: "Additional features",
          labels: {
            quality: "Quality",
            habitability: "Habitability",
            conservationsStatus: "Conservation status",
            windows: "Window frames / Windows",
            alarm: "Alarm",
            intercom: "Intercom",
            reception: "Reception",
            furniture: "Furnisched",
            furnishedDescription: "Short description of furniture",
            wardrobe: "Wardrobe",
            exposition: "Exposition",
          },
        },
        roomPertinenceDeatils: {
          select: "Select",
          equipment: "Equipment",
          type: "Type",
          dimension: "Dimension",
          errorDimension: "He must respect the NxN",
          errorType: "Type is a mandatory field",
          addRooms: "Add Rooms",
          selectRoomsType: "Select room type",
        },
      },
      images: {
        labelSubmit: {
          edit: "Edit",
          show: "Exit",
        },
        images: "Images",
        uploadImages: "Upload photos of your property",
        uploadImage: "Upload photos",
        dragClicktouploadImages:
          "Drag or click on the appropriate button to add one or more images",
        addImage: " Add Image",
        deleteAllImage: "Delete all images",
        uploadPhoto: "Upload photo",
        dragHere: "Drag here or",
        toast: {
          success: {
            title: "Successful creation and modification",
            description: "Updated data",
          },
          error: {
            title: "Unexpected problem",
            descrition: "Ops! Unexpected error",
          },
          minLength: {
            title: "requirements not met",
            descrition: "there must be at least 10 images",
          },
          minLengthLand: {
            title: "requirements not met",
            description: "there must be at least 5 images",
          },
          approved: {
            title: "Successful creation and modification",
            description: "Updated data, new property status: Approved",
          },
          pending: {
            title: "Successful creation and modification",
            description: "Updated data, new property status: in Pending",
          },
          draft: {
            title: "Successful creation and modification",
            description: "Updated data, new property status: Draft",
          },
          failedCompression: {
            title: "Image compression {context}} failed!",
            description: "Try inserting an image with a lighter weight",
          },
        },
      },
      location: {
        title: "Location",
        labelSubmit: {
          edit: "Edit",
          show: "Next",
        },
        architecturalBarriers: "Barriere Architettoniche",
        address: "Address and geographic coordinates",
        zone: "Zone",
        latitude: "Latitude",
        longitude: "Longitude",
        floor: "Floor",
        totalFloors: "Total Floors",
        position: "Position",
        info: {
          title: "Information on the building",
          labels: {
            constructionYear: "Construction Year",
            totalApartments: "Total Apartments",
            elevator: "Elevator",
          },
        },
      },
      price: {
        title: "Price",
        description: {
          edit: "Define the costs of the property",
          show: "costs of the property",
        },
        labelSubmit: {
          edit: "Edit",
          show: "Next",
        },
        requestedPrice: "Requested price",
        estimatedValue: "Estimated value",
        condominiumFees: "Monthly service charges",
        box: {
          tooltip: "Activate this option if the price also includes a box",
          option: "Box included in the price",
        },
        priceOption: {
          title: "Price Option",
          negotiable: {
            option: "Negotiable",
            tooltip:
              "If selected it will make the words 'Treatable' appear close to the price shown in the prints and on the web",
          },
          startingFrom: {
            option: "Starting from",
            tooltip:
              "If selected, it will display the words 'From' close to the price shown in the prints and on the web",
          },
        },
      },
      otherInfo: {
        title: "Other Info",
        description: {
          edit: "Check other possible characteristics of the property",
          show: "Property features",
        },
        labelSubmit: {
          edit: "Edit",
          show: "Next",
        },
      },
      stepperForm: {
        next: "Save and Continue",
        back: "Back",
        labels: {
          general: "General",
          price: "Price",
          location: "Location",
          description: "Description",
          certifications: "Certifications",
          otherInfo: "Other Info",
          zoneCharacteristics: "Zone Characteristics",
          images: "Images",
        },
      },
      commercialDescription: {
        squareMetersCovered: "M² covered",
        squareMetersUncovered: "M² uncovered",
        squareMetersCoverable: "M² coverable",
        showcaseExposition: "Showcase exposition",
        roomsPertinence: "Details of main premises and appliances",
        additionalRooms: "Additional rooms and rooms",
        additionalDetails: {
          labels: {
            allowsManagement: "Allows management",
            allowsManagementOption: "Possibility of a management",
            activitiesToo: "Activities too",
            activityValue: "Activity value",
            fee: "Fee",
            roomSeparator: "Room separator",
            context: "Context",
            weeklyRest: "Weekly rest",
            averageRevenueFrom: "Average revenue from",
            averageRevenueTo: "Average revenue to",
            from: "From",
            to: "To",
          },
        },
      },

      compactRealEstate: {
        title: "Real Estate description",
        description: "Description of the real estate",
        map: "Map",
        specificInformation: {
          title: "Specific information about the real estate",
          floor: "Floor",
          contractType: "Contract",
          propertyType: "Real Estate type",
          architecturalBarriers: "Architectural barriers",
          showcases: "Showcases",
          elevator: "Elevator",
          yes: "Yes",
          no: "No",
          price: "Price",
          position: "Posizion",
          buildingCosts: "Building costs",
          constructionYear: " Construction year",
          state: "State",
          distanceSeaLake: "Distance Sea/Lake",
          totalFloors: "Total floors",
          floors: "Floors",
          locals: "Locals",
          rooms: "Rooms",
          bathrooms: "Bathrooms",
          surface: "Surface",
          walkable: "walkable",
          heating: "Heating",
        },
        price: {
          title: "Price",
          requestedPrice: "Requested Price",
          estimatedValue: "Estimated value",
          monthlyCondominiumExpenses: "Monthly condominium expenses",
          PriceOption: {
            title: "Price option",
            negotiable: "Negotiable",
            notNegotiable: "Not negotiable",
          },
        },
        generalInformation: {
          title: "General information",
          lastUpdate: "Last update",
          origianlConsultant: "Original consultant",
          dated: "Dated",
          state: "State",
          yearOfConstruction: "year of construction",
          exclusiveAssignment: "Exclusive assignment",
          note: "Notes",
          video: "Real estate video:",
          startOn: "Start on",
          endsOn: "ends on",
          goShowCase: "Go to Kleos ShowCase",
          goToSite: "Go to external site",
          telephone: "Telephone",
          email: "Email",
          agencyOrPrivate: "Agency or private",
          historyPrice: "History Price",
        },
        features: {
          title: "Features",
          walkableMeters: "Walkable meters",
          fuel: "Combustibile",
          system: "Sistema",
          radiators: "Termosifoni",
          monthlyExpenses: "Spese mensili",
          landKind: "Land Kind",
          propertyType: "Property type",
          buildabilityIndex: "Buildability Index",
          buildableSquareMeters: "Buildable Square Meters",
          agriculturalSquareMeters: "Agricultural Square Meters",
          constructionPresent: "Construction Present",
          possibleProduction: "Possible Production",
          approvedProject: "Approved Project",
        },
        additionalFeatures: {
          title: "Additional features",
          quality: "Quality",
          habitability: "Habitability",
          conservationStatus: "Conservation Status",
          windows: "Infissi/Finestre",
          intercom: "Intercom",
          reception: "Reception",
          furnished: "Furnished",
          exposition: "Exposure",
          easementOfPassage: "Easement of Passage",
          preEmptionRights: "Pre-emption Rights",
          plantations: "Plantations",
        },
        zoneCharacteristics: {
          title: "Area characteristics",
        },
        otherCharacteristics: {
          title: "Other characteristics",
        },
        energyEfficiency: {
          title: "Energy class and performance index",
          class: "Class",
          EpGinr: "EP gInr",
          performanceUnit: "Unit",
          winterConsumption: "Winter consumption",
          summerConsumption: "Summer Consumption",
        },
      },
    },
  },
  zone: {
    airport: "Airport",
    bar: "Bar",
    tennisCourts: "Tennis Courts",
    shoppingCenters: "Shopping center",
    sportsComplexes: "Sports complexes",
    MountainSkiLifts: "Mountain/Sky Lifts",
    gym: "Gym",
    pinewood: "Pinewood",
    middleSchool: "Middle school",
    ringRoad: "Ring road",
    postOffice: "Post office",
    dayCare: "Day care",
    wood: "Wood",
    canal: "Canal",
    congressCenter: "Congress center",
    lake: "Lake",
    shops: "Shops",
    playground: "Playground",
    bikePath: "Bike path",
    highSchool: "High school",
    publicTransport: "Public transport",
    motorway: "Motorway",
    footballFields: "Football fields",
    wellnessCenter: "Wellness center",
    religiousComplexes: "Religious complexes",
    sea: "Sea",
    hospital: "Hospital",
    park: "Park",
    elementarySchool: "Elementary school",
    trainStation: "Train station",
    municipalOffices: "Municipal offices",
  },
  nationalities: {
    ...getNationalities(),
  },
  selectOptions: {
    ...selectOptionsEn,
  },
  cardRealEstate: {
    createdAt: "Created at {{context}}",
    state: "State",
    setState: "Set to",
    icons: {
      bath: "baths",
      room: "rooms",
      local: "locals",
    },
  },
  editImage: {
    title: "Edit your image",
    placeHolderAddImage: "Add room",
    paceholderTextArea: "Short description of the room",
    tooltip: "The photo will be visible only for you",
    delete: "Delete",
    save: "Save and Exit",
    privateImage: "Private Image",
    confirm: "Confirm",
    cancel: "Cancel",
    textConfirmCancel: "Confirm delete image",
    textConfirmCancelAll: "Confirm delete All images",
    imagesTenMin:
      "Deleting image not allowed, Minimum 10 images are mandatory in a public estate",
    imagesFiveMin:
      "Deleting image not allowed, Minimum 5 images are mandatory in a public estate",
  },
  loading: "Loading",
  resultsDemandCustomer: {
    title: "Required Results",
    description: "The list of the results of the request",
  },
  resultsMatchesCustomer: {
    title: "Match Results for",
    description: "The list of the results of the match",
  },
  gallery: {
    showGallery: "See gallery",
    photo: "Photo",
    planimetry: "Planimetry",
    videoTour: "Video Tour",
    media: "Media",
    publicPhoto: "public photos",
    privatePhoto: "private photos",
    errorUrl: "Oops! Problems accessing the url",
  },
  alert: {
    clone: {
      title: "Cloned realestate",
      description: "Realestate successfully cloned",
    },
    prePublish: {
      title: {
        title_one:
          "There is {{count}} property in pre - approval. Fill in the missing data to approve it!",
        title_other:
          "There are {{count}} properties in pre - approval. Fill in the missing data to approve them!",
      },
      message: "Show real estates",
    },
  },
  "*": "every",
  errorsToast: {
    noEmail: {
      title: "It has been impossible send email",
      subtitle: "Customer cannot receive emails",
    },
    unauthorized: {
      title: "Unauthorized",
      description: "Sorry! You don't have permissions",
    },
    InternalServerError: {
      title: "Internal error",
      description: "Sorry! Internal server error",
    },
    unexpectedError: {
      title: "Unexpected Error",
      description: "Sorry! Unexpected error",
    },
    abbreviationAlreadyInUse: {
      title: "Invalid abbreviation",
      description: "Abbreviation already used for another agency",
    },
    removedRealEstate: {
      title: "Realestate removed",
      description: "Realestate successfully removed",
    },
    removedDemand: {
      title: "Demand removed",
      description: "Demand successfully removed",
    },
    customerNotFound: {
      title: "Errore",
      description: "Customer not found",
    },
  },
  fullCalendar: {
    timeGridDay: "Day",
    timeGridWeek: "Week",
    dayGridMonth: "Month",
  },
  agenda: {
    title: "It is not possible to view the agenda",
    description: "Sync the email to view your calendar",
    sync: "Sync",
    agenda: "Agenda",
    findCalendar:
      "In this section you will find the calendar and all your appointments.",
    button: "Turn off Sync",
  },
  calendar: {
    modalAddAppointment: {
      rangeToInvalid: "Invalid timetable",
      dateFromInvalid: "Invalid date",
      title: "Appointment's title",
      dateFrom: "Start date Appointment",
      dateTo: "End date Appointment",
      place: "Place*",
      name: "Name",
      notes: "Notes*",
      placePlaceHolder: "insert place",
      notesPlaceHolder: "insert notes",
      estatePlaceHolder: "insert estate address",
      noOptionsAvaible: "no options avaible",
      estate: "Estate Code",
      confirm: "Confirm",
      delete: "Delete",
      time: "Time",
      otherEvents: "Other events",
      errors: {
        fieldRequired: "Required Field",
        client: "Customer not present",
        date: "Invalid Date",
        conflictTime: "There is already a appointment for that time",
      },
    },
  },
  dashboard: {
    title: "Dashboard",
    description:
      "This section contains some useful data and suggestions to improve your company",
    cardExpiringDemands: {
      title: "Expiring demands",
      description: "Some requests you made previously are about to expire.",
    },
    cardExpiredDemands: {
      title: "Expiring demands",
      description: "Some requests you made previously have already expired.",
    },
    increasing: "increasing",
    calendar: {
      title: "Appointments",
      rangeFrom: "From {{context}}",
      rangeTo: "to {{context}}",
      linkAgenda: "Go to the agenda",
      emptyEvent: "There are no events",
      othersEvent_one: "{{count}} other event",
      othersEvent_other: "other {{count}} events",
    },
    tableGlobalCrossings: {
      title: "Global Crossings",
      seeAll: "See all",
      newCrossings: "New crossings",
      more: "{{count}}% more",
      new_one: "{{count}} new",
      new_other: "{{count}} new",
      crossings_one: "{{count}} crossing",
      crossings_other: "{{count}} crossings",
    },
    tableDormantRealEstates: {
      title: "Dormant realestates",
      seeAll: "See all",
      headTable: {
        typologyRealestate: "Typology of the realestate",
        enteredAt: "Entered at",
        inactiveSince: "Inactive since",
        days_one: "{{count}} day",
        days_other: "{{count}} days",
      },
    },
    tableDormantDemands: {
      title: "Dormant demands",
      seeAll: "See all",
      headTable: {
        customerName: "Customer's name",
        lastActivity: "Last activity",
        inactiveSince: "Inactive since",
        days_one: "{{count}} day",
        days_other: "{{count}} days",
      },
    },
    tableSospensive: {
      title: "Sold with suspension",
      seeAll: "See all",
      headTable: {
        customerName: "RealEstate",
        lastActivity: "Date",
        inactiveSince: "Expiration",
        days_one: "day",
        days_other: "days",
      },
    },
    tableMediations: {
      title: "Expiring mediations",
      seeAll: "See all",
      headTable: {
        customerName: "RealEstate",
        lastActivity: "Date",
        inactiveSince: "Expiration",
        days_one: "day",
        days_other: "days",
      },
    },
  },
  matches: {
    title: "Matches",
    description: "The list of your matches",
    placeHolderSearchBar: "Search Bar...",
    labelFilterButton: "Filter by",
    firstItemFilter: "",
    headTable: {
      customer: "Customer",
      demand: "Demand",
      matches: "Matches",
      consultant: "consultant",
    },
    button: {
      getInTouch: "Get in touch",
    },
    senderEmail: {
      success: "Email sended with success",
      error: "Error: Customer is not enabled to receive e-mails",
      title: "Get into contact",
    },
  },
  messages: {
    title: "Messages",
    description: "The list of your messages",
    emptyMessageList: "No message found",
    markAsUnread: "Mark as yet to be read",
    markAsRead: "Mark as read",
    relatedRealEstate: "Related real estate",
    realEstateInfo: "Info real estate",
  },
  portals: {
    publish: "Publish",
    delete: "Delete",
    close: "Close",
    titlePublish: "Publish on",
    titleDelete: "Delete on",
    showcase: "Showcase",
    success: {
      publish: "Estate correctly published on {{context}}",
      delete: "Estate removed from {{context}}",
    },
    errors: {
      publish: "Errore: It has not been possible to publish on {{context}}",
      delete: "Error:it has not possible remove estate from {{context}}",
    },
  },
  floorLevel: {
    attic: "Attic",
    earthRetainingWall: "Earth retaining wall",
    building: "Building",
    multistorey: "Multifloor",
    nobleFloor: "Noblefloor",
    mezzanine: "Mezzanine",
    groundFloor: "Groundfloor",
    basement: "Basement",
    onTwoLevels: "On two levels",
    topFloor: "Top floor",
    notProvided: "not Provided",
  },
  demandSource: {
    internal: "Internal",
    external: "External",
    all: "All",
  },
  typeExternal: {
    all: "All",
    private: "Private",
    agency: "Agency",
    auction: "Auction",
  },
  collaboration: {
    yes: "Yes",
    no: "No",
    onHold: "On Hold",
    null: "Not verified",
  },
  negotiation: {
    yes: "Yes",
    no: "No",
    null: "Not verified",
  },
  statusDemand: {
    active: "Active",
    inactive: "Removeds",
    sent: "Sent",
    sendEmail: "Send Email",
    moveOnDisactived: "Remove",
    resend: "Resend Email",
    moveOnActive: "Active",
    elementCount: "Selected elements",
  },
  demandRealEstate: {
    selectAll: "Select all",
    deselectAll: "Deselect all",
  },
  labelsFilterRealEstate: {
    typology: "Typology",
    collaboration: "Collaboration",
    negotiation: "Negotiation",
    contractType: "Contract Type",
    state: "State",
    approvationState: "Approvation State",
    consultant: "Consultant",
    source: "Provenance",
    typeExt: "Agency/Private",
    priceFrom: "Price From",
    priceTo: "Price To",
    localsFrom: "Locals From",
    localsTo: "Locals To",
    placeholder: {
      minPrice: "Min price",
      maxPrice: "Max price",
      minLocals: "Min locals",
      maxLocals: "Max locals",
    },
  },
  labelsFilterCustomers: {
    request: "Request",
    typology: "Typology",
    consultant: "Consultant",
  },
  matchesForRealEstatePage: {
    title: "Matches result for realestate",
    subtitle: "Match's Result list",
    titlesTable: {
      request: "Request",
      customer: "Customer",
      consultant: "Consultant",
      createdAt: "Created at",

      status: "Status match",
    },
    status: {
      active: "Active",
      inactive: "Inactive",
      sent: "Sent at",
    },
  },
  createConsultant: {
    selectConsultant: "Select Consultant",
  },
  cardDemandInfo: {
    zone: "Zone",
    sale: "Sale",
    rent: "Rent",
  },
  noRealEstate: "No expiring realestate",
};
export type Translation = typeof en;
export default en;
