import React, { FC, useLayoutEffect, useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Image } from "../../../forms/realeEstate/images";
import arrowLeft from "./../../../assets/img/arrow-left.svg";
import arrowRight from "./../../../assets/img/arrow-right.svg";
import "./SliderImages.scss";
import "swiper/css";

interface ISliderImages {
  images: Image[];
  index: number;
}

type ISwiper = ReturnType<typeof useSwiper> | null;

const SliderImages: FC<ISliderImages> = ({ images, index }) => {
  const [firstSwiper, setFirstSwiper] = useState<ISwiper>(null);
  const [secondSwiper, setSecondSwiper] = useState<ISwiper>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(index);

  useLayoutEffect(() => {
    if (!firstSwiper) return;
    document.addEventListener("keydown", ({ key }) => {
      const action = (
        {
          ArrowLeft: "slidePrev",
          ArrowRight: "slideNext",
        } as const
      )?.[key];
      action && firstSwiper?.[action](300);
    });

    return () => {
      document.removeEventListener("keydown", () => {});
    };
  }, [firstSwiper]);

  return (
    <>
      <div className="container-slider-images">
        <p className="counter">{`${currentSlide + 1}/${images.length}`}</p>
        <div className="d-flex align-items-center">
          <img
            src={arrowLeft}
            className="arrow"
            onClick={() => firstSwiper?.slidePrev()}
            alt={"arrow-left"}
          />
          <Swiper
            lazy
            preloadImages={false}
            className="container-swiper"
            spaceBetween={50}
            initialSlide={currentSlide}
            slidesPerView={1}
            onSlideChange={(swiper) => {
              setCurrentSlide(swiper.activeIndex);
              secondSwiper?.slideTo(
                swiper.activeIndex - 1 < 0 ? 0 : swiper.activeIndex - 1
              );
            }}
            onSwiper={setFirstSwiper}
          >
            {images.map(({ url }, index) => (
              <SwiperSlide key={index} className="container-slide-image">
                <img alt="" className="slide-image" src={url}></img>
              </SwiperSlide>
            ))}
          </Swiper>
          <img
            src={arrowRight}
            className="arrow"
            onClick={() => firstSwiper?.slideNext()}
            alt={"arrow-left"}
          />
        </div>
        <Swiper
          className="mx-4"
          lazy
          spaceBetween={25}
          initialSlide={currentSlide - 1 < 0 ? 0 : currentSlide - 1}
          slidesPerView={4}
          //   onSlideChange={() => console.log("slide change")}
          onSwiper={setSecondSwiper}
        >
          {images.map(({ url, thumbnail }, index) => (
            <SwiperSlide
              key={index}
              onClick={() => firstSwiper?.slideTo(index)}
            >
              <img
                className="swiper-image pointer"
                src={thumbnail}
                alt="realestate"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default SliderImages;
