import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RespConsultants } from '../../models/RespConsultants'

interface ConsultantsState {
  consultants: RespConsultants | undefined
}

const initialState = { consultants: undefined } as ConsultantsState

const consultantsSlice = createSlice({
  name: 'consultants',
  initialState,
  reducers: {

    setConsultants(state: ConsultantsState, action: PayloadAction<RespConsultants | undefined>) {
      state.consultants = action.payload
    },
    deleteConsultant(state: ConsultantsState, action: PayloadAction<number>) {
      state.consultants = {...state.consultants!, docs: state.consultants!.docs.filter((_, i) => i !== action.payload)}
    },
    enableConsultant(state: ConsultantsState, {payload:{index, enable}}: PayloadAction<{index:number, enable: boolean}>) {
      if(!state.consultants) return;
      state.consultants.docs[index].enabled = enable
    },
    changeRoleConsultant(state: ConsultantsState, {payload:{index, role}}: PayloadAction<{index:number, role: "ADMIN" | "CONSULTANT"}>) {
      if(!state.consultants) return;
      state.consultants.docs[index].role = role
    },
  },
})

export const { setConsultants, deleteConsultant, enableConsultant, changeRoleConsultant } = consultantsSlice.actions
export default consultantsSlice.reducer