import React, { FC, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { otherInfoFormCommercial } from "../../../../forms/realeEstate/Commercial/otherInfoCommercial";
import { IOtherInfoForm } from "../../../../forms/realeEstate/otherInfo";
import { otherInfoForm } from "../../../../forms/realeEstate/otherInfo";
import { useEndpoints } from "../../../../hooks/useEndpoints";
import { Form } from "../../../../hooks/useForm";
import { useRealEstateForm } from "../../../../hooks/useRealEstateForm";
import { ICustomForm } from "../../../../models/CustomForm";
import { RootState } from "../../../../redux/reducers";
import { setIsSubmit } from "../../../../redux/reducers/realEstate";
import CustomForm from "../../../../shared/components/CustomForm/CustomForm";
import Loader from "../../../../shared/components/Loader/Loader";
import { addValidationQuery, getQueryUrl } from "../../../../Utils/fuctions";
import { RoutesRouter } from "../../../../models/Routes";
import { otherInfoFormLand } from "../../../../forms/realeEstate/Land/otherInfoLand";

interface IOtherInfo {}

const OtherInfo: FC<IOtherInfo> = () => {
  const { pathname } = useLocation();
  const [editMode] = useState(pathname.includes("edit"));
  const { putRealEstate } = useEndpoints();
  const absoluteNavigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isValidation = searchParams.get("validation");
  const {
    isLoading,
    initialValue,
    generateForm,
    navigate,
    id,
    setRealStateRedux,
    isCommercial,
    isLand,
    goBackPath,
  } = useRealEstateForm<IOtherInfoForm>("otherinfo");
  const dispatch = useDispatch();
  const { queryTable } = useSelector(({ realEstate }: RootState) => realEstate);

  const { isSubmit, putRealEstate: putRealEstateSelector } = useSelector(
    ({ realEstate }: RootState) => realEstate
  );

  // const errorMessages = {
  //   required: t("errorMessagesForm.required"),
  //   invalidTaxIdCode: t("errorMessagesForm.invalidTaxIdCode"),
  //   invalidEMail: t("errorMessagesForm.invalidEmail"),
  // };

  const handleSubmit = async (
    form: Partial<Form<IOtherInfoForm>>,
    next: ICustomForm["next"],
    isSame: boolean,
    isBack: boolean,
    checkErrors: boolean
  ) => {
    try {
      if (!Boolean(isValidation)) {
        if (!isSame) {
          putRealEstate(
            id!,
            "other-info",
            form!.otherInfo as Partial<Form<IOtherInfoForm>>
          );
          setRealStateRedux(form);
        }
        isSubmit.submit && navigate(addValidationQuery(next, false));
        !isSubmit.submit &&
          absoluteNavigate(
            `${RoutesRouter.realEstates}${getQueryUrl(queryTable?.params)} `
          );
        isBack && navigate(goBackPath());
        return;
      }
      if (isSame && isBack) {
        navigate(goBackPath());
        return;
      }
      if (editMode && checkErrors) {
        await putRealEstate(
          id!,
          "other-info",
          form!.otherInfo as Partial<Form<IOtherInfoForm>>
        );
        setRealStateRedux(form);
        navigate(
          isBack
            ? goBackPath()
            : addValidationQuery(next, Boolean(isValidation))
        );
        return;
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const form = useMemo(
    () => generateForm(),
    // eslint-disable-next-line
    [initialValue, putRealEstateSelector]
  );

  const handleOnSubmitted = () => {
    dispatch(setIsSubmit(false));
  };

  const renderCustomForm = () => (
    // @ts-ignore
    <CustomForm<IOtherInfoForm>
      checkErrorsOnInit={Boolean(isValidation)}
      submitOnError={true}
      className="custom-form-container-stepper"
      doSubmit={{ isSubmit, onSubmitted: handleOnSubmitted }}
      submit={handleSubmit}
      form={form}
      props={
        isCommercial
          ? otherInfoFormCommercial
          : isLand
          ? otherInfoFormLand
          : otherInfoForm
      }
    />
  );

  return !isLoading && putRealEstateSelector && form.initialValue ? (
    renderCustomForm()
  ) : (
    <Loader />
  );
};

export default OtherInfo;
