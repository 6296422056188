import Tooltip from "../../../../components/Tooltip/Tooltip";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import React, { FC, useEffect, useMemo, useState } from "react";
import { Form, FormReturnType } from "../../../../hooks/useForm";
import { Field } from "../../../../models/CustomForm";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { getNestedValueFromString } from "../../../../Utils/fuctions";
import "./ImageConsultantInput.scss";
import { useI18n } from "../../../../hooks/useI18n";
import avatarPlaceholder from "../../../../assets/avatar-placeholder.jpeg";
interface IImageConsultantInput {
  form: Partial<Form<any>>;
  setForm: any;
  data: Field<any>;
  handleError: FormReturnType<any>["handleError"];
  error: string | undefined;
}

const ImageConsultantInput: FC<IImageConsultantInput> = ({
  handleError,
  data: {
    isRequired,
    label,
    offset = 0,
    key,
    tooltip,
    maxColumn,
  },
  form,
  setForm,
  error,
}) => {
  const [image, setImage] = useState<ImageListType>(() => {
    const value = getNestedValueFromString(key, form);
    return value
      ? [
          {
            data_url: value,
          },
        ]
      : [];
  });
  const { t } = useI18n();

  useEffect(() => {
    image.length && setForm(key, image[0]);
    // eslint-disable-next-line
  }, [image]);

  const textMemo = useMemo(() => {
    return (
      <>
        <div
          className={`container-image-consultant-form offset-lg-${offset} ${
            error ? "is-invalid" : ""
          }`}
        >
          <div className="form-group mb-0">
            <label className="form-control-label" htmlFor="name">
              {`${t(label)} ${isRequired ? "*" : ""}`}
              {tooltip?.label && (
                <span className="ms-1">
                  <Tooltip label={t(tooltip.label)}>
                    <QuestionOutlineIcon cursor={"pointer"} />
                  </Tooltip>
                </span>
              )}
            </label>
          </div>
          <div className="container-body">
            <ImageUploading
              value={image}
              onChange={(imageList: ImageListType) => {
                setImage(imageList);
              }}
              onError={() => {}}
              maxFileSize={5001556}
              dataURLKey="data_url"
            >
              {({ onImageUpload, onImageRemove }) => (
                <>
                  <img
                    className="image-consultant"
                    src={image?.[0]?.data_url || avatarPlaceholder}
                    alt="avatar consultant"
                  ></img>
                  <p
                    className="load-image-consultant pointer"
                    onClick={onImageUpload}
                  >
                    {t("realEstate.forms.images.uploadImage")}
                  </p>
                </>
              )}
            </ImageUploading>
          </div>
        </div>
        {maxColumn && <div className={`col-lg-${maxColumn}`} />}
      </>
    );
    // eslint-disable-next-line
  }, [error, t, image]);

  return <>{textMemo}</>;
};

export default ImageConsultantInput;
