import React, { Dispatch, FC } from 'react'
import getClasses from '../../../Utils/getClasses'
import './Switcher.scss'
 interface Props{
    checked:boolean
    setChecked:()=>void|Dispatch<boolean>
 }
const Switcher:FC<Props> = ({checked,setChecked}) => {
  return (
    <div className={`switch ${getClasses({'switch_actived':checked})}`} onClick={setChecked}>
        <div className='ball_switch'></div>
    </div>
  )
}

export default Switcher