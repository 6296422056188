import React, { useEffect, useState } from "react";
import "./MatchesRealEstate.scss";
import Table, { ITableHome } from "../../shared/components/Table/Table";
import { useI18n } from "../../hooks/useI18n";
import { ElementForPage } from "../../typings/Pagination";
import { Box } from "@chakra-ui/react";
import Pagination from "../../shared/components/Pagination/Pagination";

import { Link, useParams } from "react-router-dom";
import { useEndpoints } from "../../hooks/useEndpoints";
import {
  MatchRealEstate,
  MatchRealEstateForTable,
  RespMatchRealEstate,
} from "../../models/RespMatchRealEstate";
import { getQueryUrl } from "../../Utils/fuctions";
import EmptyResourcesTable from "../../shared/components/EmptyResourcesTable/EmptyResourcesTable";

const MatchesRealEstate = () => {
  let { idRealEstate } = useParams();
  const { t } = useI18n();

  const { getMatchesByRealEstate } = useEndpoints();
  const [isLoading, setIsLoading] = useState(true);
  const [shortIdRealEstate, setShortIdRealEstate] = useState("");
  const [paginationChange, setPaginationChange] = useState<{
    limit: ElementForPage;
    page: number;
  }>({ limit: 10, page: 0 });
  const [dataPagination, setDataPagination] = useState<
    Omit<RespMatchRealEstate, "docs" | "compoundId">
  >({
    count: 0,
    totalDocs: 0,
    totalPages: 0,
    offset: 0,
    hasPrevPage: false,
    hasNextPage: false,
    limit: 0,
    page: 0,
  });
  const [docsMatch, setDocsMatch] = useState<MatchRealEstateForTable[]>([]);

  //handler functions
  const cleanDataForTable = (
    data: MatchRealEstate[]
  ): MatchRealEstateForTable[] => {
    return data.map((item) => {
      const consultantSurname = item.consultant ? item.consultant.surname : "";
      const consultantName = item.consultant ? item.consultant.name : "";
      const customerShortId = item.customer ? item.customer.shortId : "";
      const { demandCreatedAt, status } = item;
      return {
        ...item,
        customer: (
          <Link
            to={`/home/customers?search=${customerShortId}&limit=10&customerTypes=notProvided`}
          >
            {customerShortId}
          </Link>
        ),
        consultant: (
          <Link
            to={`/home/consultants?search=${`${consultantSurname} ${consultantName}`}&page=0&limit=10&skip=0`}
          >
            {`${consultantSurname} ${consultantName}`}
          </Link>
        ),
        demandCreatedAt: new Date(demandCreatedAt).toLocaleDateString(),

        status:
          status === "active"
            ? t("matchesForRealEstatePage.status.active")
            : status === "inactive"
            ? t("matchesForRealEstatePage.status.inactive")
            : `${t("matchesForRealEstatePage.status.sent")} ${new Date(
                item.sentAt!
              ).toLocaleDateString()}`,
      };
    });
  };
  const fetchMatches = async () => {
    const { limit, page } = paginationChange;
    const skip = page === 0 ? 0 : (page - 1) * limit;
    const { data } = await getMatchesByRealEstate(
      { limit, skip },
      idRealEstate!
    );

    // set data for table
    const { docs, compoundId } = data;
    setShortIdRealEstate(compoundId);
    if (docs.length > 0) {
      setDocsMatch(cleanDataForTable(docs));
    }
    setIsLoading(false);
    //set data for pagination
    setDataPagination({
      ...data,
    });

    //replace url with new data
    const newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      getQueryUrl({
        page,
        limit,
        skip,
      });
    window.history.replaceState({ path: newurl }, "", newurl);
  };
  const changePage = (page: number) =>
    setPaginationChange((prev) => {
      return { ...prev, page };
    });

  const setLimit = (limit: ElementForPage) =>
    setPaginationChange({ limit, page: 0 });

  //useEffects
  useEffect(() => {
    fetchMatches();
  }, [paginationChange]);

  const titles: ITableHome["titles"] = [
    {
      textAlign: "start",
      label: t("matchesForRealEstatePage.titlesTable.request"),
      truncateField: 30,
    },
    {
      textAlign: "start",
      label: t("matchesForRealEstatePage.titlesTable.customer"),
      truncateField: 30,
    },

    {
      textAlign: "start",
      label: t("matchesForRealEstatePage.titlesTable.consultant"),
      truncateField: 30,
    },
    {
      textAlign: "start",
      label: t("matchesForRealEstatePage.titlesTable.createdAt"),
    },

    {
      textAlign: "start",
      label: t("matchesForRealEstatePage.titlesTable.status"),
    },
  ];
  const dataKeyShow: string[] = [
    "demandTitle",
    "customer",
    "consultant",
    "demandCreatedAt",
    "status",
  ];

  return (
    <div className="container-matches-realestate-page">
      <div className="container-title">
        <h1 className="page-title">
          {t("matchesForRealEstatePage.title")} {shortIdRealEstate}
        </h1>
        <p className="page-description">
          {t("matchesForRealEstatePage.subtitle")}
        </p>
      </div>
      <div className="container__table margin-top">
        {!isLoading && !docsMatch.length ? (
          <EmptyResourcesTable
            description={""}
            title={"consultants.emptyResourcesTable.title"}
          />
        ) : (
          <Table
            titles={titles}
            dataKeyShow={dataKeyShow}
            title={"Table Match for Realestate"}
            data={docsMatch}
            isLoading={isLoading}
          />
        )}
      </div>
      <Box mt={5}>
        <Pagination
          onClickPage={changePage}
          isLoading={isLoading}
          data={dataPagination}
          selectLimit={{
            limit: paginationChange.limit,
            setLimit,
            options: [
              { label: "10", value: 10 },
              { label: "20", value: 20 },
              { label: "30", value: 30 },
            ],
          }}
        />
      </Box>
    </div>
  );
};

export default MatchesRealEstate;
