import React, { CSSProperties, FC, useEffect, useMemo } from "react";
import { Spinner } from "@chakra-ui/react";
import "./Loader.scss";
import { useI18n } from "../../../hooks/useI18n";
import { NestedKeys } from "advanced-types";
import { Translation } from "../../../assets/i18n/locals/en";

interface ILoader {
  label?: NestedKeys<Translation, ".">;
  style?: CSSProperties;
  className?: string;
}

const Loader: FC<ILoader> = ({
  label = "loading",
  children,
  style = {},
  className = "",
}) => {
  const { t } = useI18n();

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, []);
  const loader = useMemo(
    () => (
      <div className={`loader_page ${className}`} style={{ ...style }}>
        <div className="loader_container">
          <Spinner
            thickness="12px"
            speed="1s"
            emptyColor="gray.200"
            color="blue.500"
            style={{ width: "11rem", height: "11rem" }}
          />
          {children ? (
            <>{children}</>
          ) : (
            <h1 className="in-progress-title">{t(label)}</h1>
          )}
        </div>
      </div>
    ),
    // eslint-disable-next-line
    [children, label, t]
  );
  return loader;
};

export default Loader;
