import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { Tooltip } from "@chakra-ui/react";
import React, { FC } from "react";
import "./Toolpit.scss";
interface Props {
  label: string;
  hasArrow?: boolean
}

const InfoTooltip: FC<Props> = ({ label, children, hasArrow = true }) => {
  return (
    <Tooltip className="toolpit" placement="top" hasArrow={hasArrow} label={label} fontSize="xs" fontWeight={"bold"} closeDelay={200}>
    { children || <QuestionOutlineIcon cursor={"pointer"} /> }
  </Tooltip>
  );
};

export default InfoTooltip;
