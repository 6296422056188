import React, { FC, useEffect, useMemo, useState } from "react";
import { Form, FormReturnType } from "../../../../hooks/useForm";
import { Field } from "../../../../models/CustomForm";
import { Zone } from "../../../../models/demandCustomer";
import { cloneObject, getNestedValueFromString } from "../../../../Utils/fuctions";
import Chip from "../../Chip/Chip";
import "./SelectedZones.scss";

interface ITextAreaInput {
  form: Partial<Form<any>>;
  setForm: any;
 
  handleError: FormReturnType<any>["handleError"]
  data: Field<any>;
  error: string | undefined;
}

const SelectedZones: FC<ITextAreaInput> = ({
  
  handleError,
  data: {
    isRequired,
    label,
    type,
    column = 4,
    offset = 0,
    key,
    placeholder,
    showIfKey,
    showIf = true,
    maxColumn,
  },
  form,
  setForm,
  error,
}) => {

  const [valueForm, setvalueForm] = useState<Zone[]>([]);
  useEffect(() => {
    setvalueForm(getNestedValueFromString<Zone[]>("zones", form))
  }, [form])

  const removeZone = (index: string | number) => {
    const zones = cloneObject(getNestedValueFromString<Zone[]>("zones", form));
    zones.splice(index as number,1)
    setForm("zones", zones);
    setvalueForm(zones);
  }

  const selectedZonesMemo = useMemo(() => {

    return  <>
    <div className={`col-lg-${column}`}>
      <div
        className={`form-group offset-lg-${offset} ${
          error ? "is-invalid" : ""
        }`}
      >
        <label className="form-control-label" htmlFor="name">
          {`${label} ${isRequired ? "*" : ""}`}
        </label>
       <div className={`border-none form-control p-md input-selected-zones d-flex align-items-center gap-2 flex-wrap `}>
        {valueForm && valueForm.map(({name}, index) => {
            return (
                <Chip key={index} id={index} label={name} onClose={removeZone}></Chip>
            )
        })}
       </div>
        {error && (
          <div className="invalid-feedback">
            <div>{error}</div>
          </div>
        )}
      </div>
    </div>
    {maxColumn && <div className={`col-lg-${maxColumn}`} />}
</>
// eslint-disable-next-line
  }, [error, valueForm])

  return (
    <>
           {selectedZonesMemo}
    </>
  );
};

export default SelectedZones;
