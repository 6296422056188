const geocodeByPlaceId = (placeId: string): Promise<google.maps.GeocoderResult[]> => {
  const geocoder = new window.google.maps.Geocoder();
  const { OK } = window.google.maps.GeocoderStatus;

  return new Promise((resolve, reject) => {
    geocoder.geocode(
      { placeId},
      (
        results: google.maps.GeocoderResult[] | null,
        status: google.maps.GeocoderStatus,
      ) => {
          return status !== OK ? reject(status) : resolve(results as google.maps.GeocoderResult[]);
      });
  });
};

export default geocodeByPlaceId;
