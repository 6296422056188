import React, { FC, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  IZoneCharacteristicsForm,
  zoneCharacteristicsForm,
} from "../../../../forms/realeEstate/zoneCharacteristics";
import { useEndpoints } from "../../../../hooks/useEndpoints";
import { Form } from "../../../../hooks/useForm";
import { useRealEstateForm } from "../../../../hooks/useRealEstateForm";
import { ICustomForm } from "../../../../models/CustomForm";
import { RootState } from "../../../../redux/reducers";
import { setIsSubmit } from "../../../../redux/reducers/realEstate";
import CustomForm from "../../../../shared/components/CustomForm/CustomForm";
import Loader from "../../../../shared/components/Loader/Loader";
import { addValidationQuery, getQueryUrl } from "../../../../Utils/fuctions";
import { RoutesRouter } from "../../../../models/Routes";

interface IZoneCharacteristics {}

const ZoneCharacteristics: FC<IZoneCharacteristics> = () => {
  const { putRealEstate } = useEndpoints();
  const absoluteNavigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isValidation = searchParams.get("validation");
  const {
    isLoading,
    initialValue,
    generateForm,
    id,
    setRealStateRedux,
    navigate,
    goBackPath,
  } = useRealEstateForm<IZoneCharacteristicsForm>("zone-characteristics");
  // eslint-disable-next-line
  const form = useMemo(() => generateForm(), [initialValue]);
  const dispatch = useDispatch();
  const { isSubmit } = useSelector(({ realEstate }: RootState) => realEstate);
  const { queryTable } = useSelector(({ realEstate }: RootState) => realEstate);

  // TODO set action
  const handleSubmit = async (
    form: Partial<Form<IZoneCharacteristicsForm>>,
    next: ICustomForm["next"],
    isSame: boolean,
    isBack: boolean,
    checkErrors: boolean
  ) => {
    if (!Boolean(isValidation)) {
      if (!isSame) {
        await putRealEstate(
          id!,
          "zone-characteristics",
          form.zoneCharacteristics as Partial<Form<IZoneCharacteristicsForm>>
        );
        setRealStateRedux(form);
      }
      isSubmit.submit && navigate(addValidationQuery(next, false));
      !isSubmit.submit &&
        absoluteNavigate(
          `${RoutesRouter.realEstates}${getQueryUrl(queryTable?.params)} `
        );
      isBack && navigate(goBackPath());
      return;
    }
    if (isSame && isBack) {
      navigate(goBackPath());
      return;
    }
    if (checkErrors) {
      await putRealEstate(
        id!,
        "zone-characteristics",
        form.zoneCharacteristics as Partial<Form<IZoneCharacteristicsForm>>
      );
      setRealStateRedux(form);
      navigate(
        isBack ? goBackPath() : addValidationQuery(next, Boolean(isValidation))
      );
      return;
    }
  };

  const handleOnSubmitted = () => {
    dispatch(setIsSubmit(false));
  };

  const renderCustomForm = () => (
    // @ts-ignore
    <CustomForm<IZoneCharacteristicsForm>
      checkErrorsOnInit={Boolean(isValidation)}
      submitOnError={true}
      className="custom-form-container-stepper"
      doSubmit={{ isSubmit: isSubmit, onSubmitted: handleOnSubmitted }}
      submit={handleSubmit}
      form={form}
      props={zoneCharacteristicsForm}
    />
  );

  return !isLoading && initialValue ? renderCustomForm() : <Loader />;
};

export default ZoneCharacteristics;
