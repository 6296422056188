import { NestedKeys } from "advanced-types";
import React, { FC, useState } from "react";
import { Translation } from "../../../assets/i18n/locals/en";
import { useI18n } from "../../../hooks/useI18n";
import { ObjectToClassString } from "../../../Utils/fuctions";
import "./Button.scss";

interface IButton {
  color?: "primary" | "secondary" | "tertiary";
  iconPosition?: "left" | "right";
  tooltipMessage?: string;
  stopPropagation?: boolean;
  active?: boolean;
  outline?: boolean;
  outlineSmall?: boolean;
  disabled?: boolean;
  circle?: boolean;
  onlyIcon?: boolean;
  onlyIconMedium?: boolean;
  onlyIconSmall?: boolean;
  text?: boolean;
  textSmall?: boolean;
  textWithIcon?: boolean;
  btnWithoutText?: boolean;
  medium?: boolean;
  fullWidth?: boolean;
  clear?: boolean;
  fabOutline?: boolean;
  onClick?: () => void;
  btnType?: "submit" | "button";
  icon?: string;
  children?: React.ReactNode;
  margin?: number;
  noHover?: boolean;
  noBorder?: boolean;
  className?: string;
}

const Button: FC<IButton> = ({
  color = "primary",
  iconPosition = "left",
  tooltipMessage,
  stopPropagation,
  active,
  outline,
  outlineSmall,
  disabled,
  circle,
  onlyIcon,
  onlyIconMedium,
  onlyIconSmall,
  icon,
  text,
  textSmall,
  textWithIcon,
  btnWithoutText,
  medium,
  fullWidth,
  clear,
  fabOutline,
  onClick,
  btnType = "button",
  children,
  margin,
  noHover,
  noBorder,
  className = "",
 
}) => {
  // eslint-disable-next-line
  const [open, setOpen] = useState(false);

  const getIconClass = (): string => {
    return "icon " + icon;
  };
  const { t } = useI18n();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    onClick && onClick();
  };

  // const handleClose = () => { // TODO remove
  //   disabled && setOpen(false);
  // };

  // const handleOpen = () => { // TODO remove
  //   disabled && setOpen(true);
  // };

  return (
    <>
      <button
        type={btnType}
        disabled={Boolean(disabled)}
        onClick={handleClick}
        className={` ${ObjectToClassString({
          outline,
          "outline-small": outlineSmall,
          [`bt-${color}`]: true,
          "btn-circle": circle,
          "btn-icon": onlyIcon,
          "btn-icon-medium": onlyIconMedium,
          "btn-icon-small": onlyIconSmall,
          "btn-text": text,
          "btn-text-small": textSmall,
          "btn-text-with-icon": textWithIcon,
          "btn-without-text": btnWithoutText,
          clear,
          "fab-outline": fabOutline,
          medium,
          "full-width": fullWidth,
          active,
          "no-hover": noHover,
          "no-border": noBorder,
        })} position-icon-${iconPosition} m-${margin} btn-custom ${className}`}
        // (click)="buttonClick($event)"
      >
        {/* ${!disabled ? `bg-${color}` : ''}  */}
        {icon && <i className={`${getIconClass()} element-icon`}></i>}
        {typeof children === "string"
          ? t(children as NestedKeys<Translation, ".">)
          : children}
      </button>
    </>
  );
};

export default Button;
