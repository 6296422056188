import React, { FC, useEffect, useState } from "react";
import "./AddressInput.scss";

import { Form, FormReturnType } from "../../../../hooks/useForm";
import { Field } from "../../../../models/CustomForm";
import { getNestedValueFromString } from "../../../../Utils/fuctions";
import getClasses from "../../../../Utils/getClasses";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "../../GoogleInput";
import { Styles, OptionTypeBase } from "react-select";
import { apiKeyGoogleMaps } from "../../../../environment/environment";
import { useI18n } from "../../../../hooks/useI18n";
import { reference } from "@popperjs/core";
import { useEndpoints } from "../../../../hooks/useEndpoints";


interface IAddressInput {
  form: Partial<Form<any>>;
  setForm: any;
  data: Field;
  handleError: FormReturnType<any>["handleError"];
  error: string | undefined;

}

 export interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}


const AddressInput2: FC<IAddressInput> = ({
  handleError,
  data: {
    column = 4,
    offset = 0,
    isRequired,
    label,
    maxColumn,
    value,
    key,
    showIf = true,
    showIfKey,
  },
  form,
  setForm,
  error,
}) => {
  const [searchTerm, setSearchTerm] = useState<any>();
  const { t } = useI18n();
  const [address,setAddress]=useState<AddressComponent[]|undefined>(undefined)
  const { getIstatCode } = useEndpoints();

  const fetchIstatCode = async (value: {
    zipCode: string;
    city: string;
    state: string;
  }) => {
    const { data:{municipalityId} } = await getIstatCode(value);
    municipalityId && setForm('istatCode',municipalityId)
    municipalityId && setForm('municipalityId',municipalityId)
  };



 
  useEffect(() => {
    if (!searchTerm) return;

    geocodeByPlaceId(searchTerm?.value.place_id).then((results) => {
      const { address_components } = results[0];
      setAddress(address_components)
      
      const { lat, lng } = results[0].geometry.location;
      setForm("latitude", lat());
      setForm("longitude", lng());
    });
    // eslint-disable-next-line
  }, [searchTerm]);


   const checkParameters=(arrayAddressComp: AddressComponent[],parameter:string)=>{
    const value=arrayAddressComp.find((item) => item.types[0] === parameter)
    return value?.short_name || undefined
   } 

  useEffect(() => {
    if(address){
         if(checkParameters(address,'postal_code')){
      fetchIstatCode({
        zipCode:checkParameters(address,'postal_code') as string,
        state:checkParameters(address,'administrative_area_level_2') as string,
        city:checkParameters(address,'administrative_area_level_3') as string
      })
    }
    setForm('zipCode',checkParameters(address,'postal_code'))
    setForm('city',checkParameters(address,'administrative_area_level_3'))
    setForm('country',checkParameters(address,'country'))
    setForm('state',checkParameters(address,'administrative_area_level_2'))
    setForm("street", checkParameters(address,'route'));
    setForm('unity',checkParameters(address,'street_number'))
    setForm('placeComplete',composePlaceComplete(address))
   
    }
    
 }, [address]);
 
 const composePlaceComplete = (address:AddressComponent[]) => {
  const street = checkParameters(address,'route');
  const unity = checkParameters(address,'street_number')
  const city = checkParameters(address,'administrative_area_level_3')
  const state = checkParameters(address,'administrative_area_level_2')
  const country = checkParameters(address,'country')
  const zipCode=checkParameters(address,'postal_code')
  return `${getClasses({
    [`${city},`]: city,
    [`${street},`]: street,
    [`${unity},`]: unity,
    [`${state},`]: state,
    [`${zipCode},`]: zipCode,
    [`${country},`]: country,
  })}`; 
 }

 const defaultValue=()=>{
  return form.placeComplete || ''
} 


 const customStyles: Partial<Styles<OptionTypeBase, false>> = {
    option: (provided, state) => ({
      ...provided,
      // color: state.isSelected ? 'red' : 'blue',
      backgroundColor: state.isSelected ? "#ECEFFD" : "white",
      lineHeight: "1.5rem",
      fontWeight: "700",
      color: "#3A3878",
      padding: 10,
      "&:hover": {
        backgroundColor: "#ECEFFD",
      },
    }),
    menu: (style, state) => ({
      ...style,
      borderRadius: "0.5rem",
      border: "none",
      boxShadow: "none",
      backgroundColor: "white",
    }),
    control: (provided) => ({
      ...provided,
      border: "none",
      width: "100%",
      borderRadius: "0.5rem",
      height: "50px",
      boxShadow: "none",
      "&:focus-visible": {
        outline: "none",
        boxShadow: "none",
      },
      "&:hover": {
        border: "1px solid #415DEB",
      },
    }),
    input: (provided) => ({
      ...provided,
      color: "#3A3878",
      "& input": {
        fontSize: "1rem",
        lineHeight: "1.5rem",
        fontWeight: "700",
        color: "#3A3878",
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const props = {
        fontSize: "1rem",
        lineHeight: "1.5rem",
        fontWeight: "200",
        color: "#3A3878",
      };

      return { ...provided, opacity, transition, ...props };
    },
  };

  return (
    <>
      <div className={`col-lg-${column}`}>
        <div
          className={`form-group offset-lg-${offset} ${
            error ? "is-invalid" : ""
          }`}
        >
          <label className="form-control-label" htmlFor="name">
            {`${t(label)} ${isRequired ? "*" : ""}`}
          </label>
          <GooglePlacesAutocomplete
            selectProps={{
              styles: customStyles,
              searchTerm,
              defaultInputValue: defaultValue(),
              onChange: setSearchTerm,
            }}
            debounce={500}
            apiKey={apiKeyGoogleMaps}
            placeholder={t("calendar.modalAddAppointment.placePlaceHolder")}
            noOptionText={t("calendar.modalAddAppointment.noOptionsAvaible")}
          />
           {error && (
              <div className="invalid-feedback">
                <div>{error}</div>
              </div>
            )}
        </div>
      </div>
      {maxColumn && <div className={`col-lg-${maxColumn}`} />}
    </>
  );
};

export default AddressInput2;
