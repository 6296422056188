import { useToast } from "@chakra-ui/react";
import { ReactNode } from "react";
import InfoIcon from "./../../../assets/img/info.svg";
import getClasses from "../../../Utils/getClasses";
import "./ToastAlert.scss";
import Svg from "../../Svg/Svg";
interface ToastSettings {
  subMessage?:
    | string
    | ((props: { onClose: () => void }) => ReactNode | JSX.Element);
  onClose?: () => void;
  message: string | ReactNode | JSX.Element;
  duration?: number | null;
  type: "success" | "error" | "warning" | "info";
}
export const useToastAlert = () => {
  const toast = useToast();

  const toastAlert = ({
    duration = null,
    type,
    message,
    subMessage,
    onClose: onCloseProp,
  }: ToastSettings) => {

    const isElement = (
      element:
        | string
        | ((props: { onClose: () => void }) => ReactNode | JSX.Element)
    ): element is (props: { onClose: () => void }) => ReactNode | JSX.Element =>
      typeof element !== "string";
    toast({
      duration,
      isClosable: true,
      position: "bottom",
      render: ({ onClose }) => (
        <div className={`custom-toast-alert  custom-toast-alert-${type}`}>
          {typeof message === "string" ? (
            <div
              className={`message-container ${getClasses({
                "margin-sub": Boolean(subMessage),
              })}`}
            >
              <div className="d-flex align-items-center">
                <Svg
                  className={`icon-toast-alert-info icon-toast-alert-info-${type}`}
                  icon={
                    type === "success" ? `${InfoIcon}#info` : `${InfoIcon}#info`
                  }
                />
                <h5 className="message">{message}</h5>
              </div>
              {Boolean(subMessage) && isElement(subMessage!) ? (
                <>{subMessage && isElement(subMessage) && subMessage({onClose})}</>
              ) : (
                <h5 className="sub-message">{subMessage}</h5>
              )}
            </div>
          ) : (
            message
          )}
          <i
            onClick={() => {
              onCloseProp && onCloseProp();
              onClose();
            }}
            className={`icon icon-close`}
          />
        </div>
      ),
    });
  };
  return toastAlert;
};
