import { NestedKeys } from "advanced-types";
import { Translation } from "../../../assets/i18n/locals/en";
import { ICustomForm } from "../../../models/CustomForm";
import {
  AIR_CONDITIONING_TYPE,
  ELECTRICAL_SYSTEM_STATUS,
  EntityInformation,
  TV_STATUS,
} from "../../../models/RealEstate";
import { LAND_INFO_ENTITY } from "../../../models/RealEstateLand";

export const infoLabelLand: {
  label: NestedKeys<Translation, "."> | "";
  squareMeters?: number;
  value: LAND_INFO_ENTITY;
  selectOptions?: {
    label: NestedKeys<Translation, "."> | "";
    value: ELECTRICAL_SYSTEM_STATUS | TV_STATUS | AIR_CONDITIONING_TYPE;
  }[];
}[] = [
  {
    label: "selectOptions.otherInfoLabel.WATER_PIPES",
    value: LAND_INFO_ENTITY.WATER_PIPES,
  },
  {
    label: "selectOptions.otherInfoLabel.FACING_STREET",
    value: LAND_INFO_ENTITY.FACING_STREET,
  },
  {
    label: "selectOptions.otherInfoLabel.AT_AUCTION",
    value: LAND_INFO_ENTITY.AT_AUCTION,
  },
  {
    label: "selectOptions.otherInfoLabel.PHONE_LINES",
    value: LAND_INFO_ENTITY.PHONE_LINES,
  },
  {
    label: "selectOptions.otherInfoLabel.DIVISIBLE_LOT",
    value: LAND_INFO_ENTITY.DIVISIBLE_LOT,
  },
  {
    label: "selectOptions.otherInfoLabel.METHANE",
    value: LAND_INFO_ENTITY.METHANE,
  },
  {
    label: "selectOptions.otherInfoLabel.LAKE_VIEW",
    value: LAND_INFO_ENTITY.LAKE_VIEW,
  },
  {
    label: "selectOptions.otherInfoLabel.SEA_VIEW",
    value: LAND_INFO_ENTITY.SEA_VIEW,
  },
];

export interface IOtherInfoForm {
  otherInfo: EntityInformation[];
}
export const initialValueOtherInfoFormLand = {
  otherInfo: [
    {
      entity: {},
      status: {},
      squareMeters: 0,
    },
  ],
};

export const otherInfoFormLand: ICustomForm<IOtherInfoForm> = {
  title: {
    edit: "realEstate.forms.otherInfo.title",
    show: "realEstate.forms.otherInfo.title",
  },
  description: {
    edit: "realEstate.forms.otherInfo.description.edit",
    show: "realEstate.forms.otherInfo.description.show",
  },
  labelSubmitButton: { edit: "editImage.save", show: "editImage.save" },

  next: "zone-characteristics",
  forms: [
    {
      title: "",
      fields: [
        {
          column: 12,
          columnRadio: 3,
          label: "",
          key: "otherInfo",
          type: "entity-information",
          multiple: true,
          entityInformationOptions: [...infoLabelLand],
        },
      ],
    },
  ],
};
