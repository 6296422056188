import {
  DeleteIcon,
  EditIcon,
  NotAllowedIcon,
  RepeatIcon,
  UnlockIcon,
} from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Flex,
  Input,
  useClipboard,
  useDisclosure,
} from "@chakra-ui/react";
import React, { FC, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useConsultants } from "../../hooks/useConsultants";
import { useEndpoints } from "../../hooks/useEndpoints";
import { useI18n } from "../../hooks/useI18n";
import { RoutesRouter } from "../../models/Routes";
import { changeRoleConsultant } from "../../redux/reducers/consultants";
import Button from "../../shared/components/Button/Button";
import EmptyResourcesTable from "../../shared/components/EmptyResourcesTable/EmptyResourcesTable";
import EmptyTable from "../../shared/components/EmptyTable/EmptyTable";
import Modal from "../../shared/components/Modal";
import Pagination from "../../shared/components/Pagination/Pagination";
import Table, {
  ITableButtonOption,
  ITableHome,
} from "../../shared/components/Table/Table";
import { ROLE } from "../../typings/Role";
import { getStorageItem } from "../../Utils/localStorage";
import avatarPlaceholder from "../../assets/avatar-placeholder.jpeg";

import "./Consultants.scss";

interface IConsultants {}

// const modalContent = (token: ) => {
//   const [value, setValue] = React.useState('Hello world')
// }

const Consultants: FC<IConsultants> = () => {
  const [generatedToken, setGeneratedToken] = useState("");
  const { hasCopied, onCopy } = useClipboard(generatedToken);
  const { t } = useI18n();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    allConsultants,
    consultants,
    limit,
    termFilter,
    editConsultant,
    setTermFilter,
    generateButtons,
    handleReset,
    consultantsForTable,
    handleKeyPress,
    changePage,
    // debounceFilterTerm: setTermFilter,
    setLimit,
    isLoading,
  } = useConsultants();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { generateToken, putConsultant } = useEndpoints();
  const [isLoadingCopytoken, setIsLoadingCopytoken] = useState<boolean>(false);

  const titles: ITableHome["titles"] = [
    {
      textAlign: "start",
      label: t(["consultants.headTable.name", "consultants.headTable.surname"]),
      truncateField: 30,
    },
    {
      textAlign: "center",
      label: t("consultants.headTable.email"),
    },
    {
      textAlign: "center",
      label: t("consultants.headTable.role"),
    },
    {
      textAlign: "center",
      label: t("consultants.headTable.isEnabled"),
    },
  ];

  const buttonsOption = useMemo(
    () =>
      generateButtons((consultant, role) => {
        let buttons: ITableButtonOption = [];
        if (consultant._id === getStorageItem("me")?._id) {
          buttons.push({
            onClick: (index: number) => {
              navigate(
                `${RoutesRouter.consultants}/${
                  allConsultants!.docs[index]._id
                }/edit`
              );
            },
            label: t("consultants.table.options.edit"),
            colorTheme: "blue",
            icon: <EditIcon />,
          });
          return buttons;
        }

        if (!consultant.owner) {
          buttons.push(
            {
              onClick: (index: number) => {
                navigate(
                  `${RoutesRouter.consultants}/${
                    allConsultants!.docs[index]._id
                  }/edit`
                );
              },
              label: t("consultants.table.options.edit"),
              colorTheme: "blue",
              icon: <EditIcon />,
            },
            {
              onClick: async (index: number) => {
                try {
                  const consultant = allConsultants!.docs[index];
                  const role =
                    consultant.role === "ADMIN" ? ROLE.CONSULTANT : ROLE.ADMIN;
                  await putConsultant(consultant._id, { ...consultant, role });
                  dispatch(changeRoleConsultant({ index, role }));
                } catch (error) {
                  console.error("error", error);
                }
              },
              label: t(
                consultant.role === "ADMIN"
                  ? "consultants.table.options.makeAdmin"
                  : "consultants.table.options.makeConsultant"
              ),
              colorTheme: "blue",
              icon: <RepeatIcon />,
            },
            {
              onClick: (index: number) => {
                copyGeneratedToken(index);
                // onOpen();
              },
              label: t("consultants.table.options.generatePassword"),
              colorTheme: "blue",
              isLoading: isLoadingCopytoken,
              icon: <UnlockIcon />,
            },

            {
              onClick: (index: number) => {
                editConsultant(index, "enable");
              },
              label: t(
                consultant.enabled
                  ? "consultants.table.options.disable"
                  : "consultants.table.options.enable"
              ),
              colorTheme: "blue",
              icon: consultant.enabled ? <NotAllowedIcon /> : <UnlockIcon />,
            },
            {
              onClick: (index: number) => {
                editConsultant(index, "remove");
              },
              label: t("consultants.table.options.delete"),
              colorTheme: "red",
              icon: <DeleteIcon />,
            }
          );
        }
        return buttons;
      }),
    // eslint-disable-next-line
    [consultants]
  );

  const filtersLine = () => {
    return (
      <div className="resources-searching my-3">
        <div className="form-group">
          <input
            id="searchResource"
            type="text"
            value={termFilter.label}
            onKeyPress={(e) => handleKeyPress(e)}
            onChange={({ target: { value } }) => handleReset(value)}
            className="form-control filter-questions"
            placeholder={t("consultants.placeHolderSearchBar")}
          />
          <i className="icon-search"></i>
          {termFilter.label && (
            <i
              className="icon-close"
              onClick={() => {
                setTermFilter({ label: "", value: "" });
              }}
            ></i>
          )}
        </div>
        {/* <Menu>
          <MenuButton className="menu-button">
            Tutte le categorie <ChevronDownIcon />
          </MenuButton>
          <MenuList zIndex={1000}>
            {/* <div className="menu-item">New File</div>
            <div className="menu-item">New Window</div>
          </MenuList>
        </Menu> */}
      </div>
    );
  };
  const copyGeneratedToken = async (index: number) => {
    setIsLoadingCopytoken(true);

    try {
      const {
        data: { token },
      } = await generateToken({
        idConsultants: allConsultants!.docs[index]._id,
      });
      setGeneratedToken(
        `${window.location.origin}${RoutesRouter.resetpassword}?token=${token}`
      );
      onOpen();
    } catch (e) {
      console.error(e);

      // toast({
      //   title: "Error",
      //   description: "Error",
      //   status: "",
      //   duration: 2000,
      //   isClosable: true,
      // });
    } finally {
      setIsLoadingCopytoken(false);
    }
  };

  const handleClickTr = (index: number) => {
    navigate(
      `${RoutesRouter.consultants}/${allConsultants!.docs[index]._id}/edit`
    );
  };

  return (
    <div className="container__home">
      {consultants &&
      !termFilter.value &&
      !isLoading &&
      consultants.length === 0 &&
      allConsultants &&
      allConsultants?.page === 1 ? (
        <EmptyTable
          description={t("consultants.emptyTable.description")}
          title={t("consultants.emptyTable.title")}
          onClick={() => {
            navigate(RoutesRouter.addConsultant);
          }}
          labelButton={t("consultants.emptyTable.labelButton")}
        />
      ) : (
        <>
          <div className="container__top">
            <div className="left">
              <h1 className="page-title">{t("consultants.title")}</h1>
              <p className="page-description mt-1">
                {t("consultants.sub_title")}
              </p>
            </div>
            <div className="right">
              {consultants && (
                <Button
                  onClick={() => {
                    navigate(RoutesRouter.addConsultant);
                  }}
                >
                  {t("consultants.buttonAddConsultant")}
                </Button>
              )}
            </div>
          </div>
          {consultants && filtersLine()}
          {consultants && consultants.length > 0 && (
            <>
              <div className="container__table">
                <Table<NonNullable<typeof consultantsForTable>[0]>
                  onClickTr={handleClickTr}
                  titles={titles}
                  title={t("consultants.title")}
                  data={consultantsForTable?.map((consultant) => ({
                    ...consultant,
                    nameSurname: (
                      <div className="d-flex align-items-center">
                        <Avatar
                          size={"sm"}
                          name={consultant.nameSurname}
                          src={consultant.thumbnail || undefined}
                          me={"2"}
                        />
                        <span>{consultant.nameSurname}</span>
                      </div>
                    ),
                  }))}
                  dataKeyShow={["nameSurname", "email", "role", "enabled"]}
                  options={{ booleanToCircle: true }}
                  buttonsOption={buttonsOption}
                  addLastEmptyTh={true}
                />
              </div>

              <Box mt={5}>
                <Pagination
                  onClickPage={changePage}
                  isLoading={isLoading}
                  data={allConsultants!}
                  selectLimit={{
                    limit,
                    setLimit,
                    options: [
                      { label: "10", value: 10 },
                      { label: "20", value: 20 },
                      { label: "30", value: 30 },
                    ],
                  }}
                />
              </Box>
            </>
          )}
        </>
      )}

      {consultantsForTable &&
        allConsultants?.count === 0 &&
        termFilter.value &&
        consultantsForTable.length === 0 && <EmptyResourcesTable />}

      <Modal
        title={t("consultants.table.options.modalGeneratePassword.title")}
        isOpen={isOpen}
        onClose={onClose}
        body={
          <Flex mb={2} direction={"column"} alignItems={"center"}>
            <Input
              value={generatedToken}
              isReadOnly
              placeholder={generatedToken}
            />

            <Button margin={2} onClick={onCopy}>
              {hasCopied
                ? t(
                    "consultants.table.options.modalGeneratePassword.labelButtonHasCopied"
                  )
                : t(
                    "consultants.table.options.modalGeneratePassword.labelButtonNotCopied"
                  )}
            </Button>
          </Flex>
        }
      />
    </div>
  );
};

export default Consultants;
