import {
  Modal as ModalChakra,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ThemingProps,
} from "@chakra-ui/react";
import React, { FC } from "react";
import Button from "../Button/Button";
import "./Modal.scss";

interface IModal {
  modalForLoaderImages?: boolean;
  isOpen: boolean;
  onClose: () => void;
  body?: string | JSX.Element;
  title: string;
  size?: ThemingProps<"Modal">["size"];
  classNameTitle?: string;
  secondaryButton?: {
    action: () => void;
    label: string;
    isLoading?: boolean;
    loadingText?: string;
    isDisabled?: boolean;
  };
  footer?: boolean;
}

const Modal: FC<IModal> = ({
  isOpen,
  onClose,
  body,
  title,
  secondaryButton,
  children,
  size = "xl",
  classNameTitle = "",
  footer = true,
}) => {
  return (
    <>
      <ModalChakra
        onClose={onClose}
        isOpen={isOpen}
        size={size}
        isCentered
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent className="container__modal">
          <ModalHeader className={classNameTitle} fontSize={"1.75rem"}>
            {title}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>{body || children || <></>}</ModalBody>
          {footer && (
            <ModalFooter style={{ display: "flex", justifyContent: "center" }}>
              {secondaryButton && (
                <Button
                  disabled={secondaryButton.isDisabled}
                  onClick={secondaryButton.action}
                >
                  {secondaryButton.label}
                </Button>
              )}
              {/* <Button onClick={onClose}>Close</Button> */}
            </ModalFooter>
          )}
        </ModalContent>
      </ModalChakra>
    </>
  );
};

export default Modal;
