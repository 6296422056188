import React, { FC, useEffect, useMemo, useState } from "react";
import { Form, FormReturnType } from "../../../../hooks/useForm";
import { useI18n } from "../../../../hooks/useI18n";
import { Field } from "../../../../models/CustomForm";
import { getNestedValueFromString } from "../../../../Utils/fuctions";
import "./TextArea.scss";

interface ITextAreaInput {
  form: Partial<Form<any>>;
  setForm: any;
  handleError: FormReturnType<any>["handleError"];
  data: Field<any>;
  error: string | undefined;
}

const TextAreaInput: FC<ITextAreaInput> = ({
  handleError,
  data: {
    isRequired,
    label,
    type,
    column = 4,
    offset = 0,
    key,
    placeholder,
    showIfKey,
    showIf = true,
    maxColumn,
  },
  form,
  setForm,
  error,
}) => {
  const handleChangeDate = (date: string) => {
    setForm(key, date);
  };
  const [valueForm, setvalueForm] = useState();
  const { t } = useI18n();
  useEffect(() => {
    setvalueForm(getNestedValueFromString(key, form));
    // eslint-disable-next-line
  }, [form]);

  const textAreaMemo = useMemo(() => {
    return (
      <>
        <div className={`col-lg-${column}`}>
          <div
            className={`form-group offset-lg-${offset} ${
              error ? "is-invalid" : ""
            }`}
          >
            {label && (
              <label className="form-control-label" htmlFor="name">
                {`${t(label)} ${isRequired ? "*" : ""}`}
              </label>
            )}
            {
              <textarea
                value={getNestedValueFromString(key, form)}
                onChange={({ target: { value } }) => handleChangeDate(value)}
                onFocus={() => handleError(key, "", false)}
                className={`form-control p-md input-text-area`}
                placeholder={t(placeholder ?? "")}
                rows={10}
              />
            }
            {error && (
              <div className="invalid-feedback">
                <div>{error}</div>
              </div>
            )}
          </div>
        </div>
        {maxColumn && <div className={`col-lg-${maxColumn}`} />}
      </>
    );
    // eslint-disable-next-line
  }, [error, valueForm]);

  return <>{textAreaMemo}</>;
};

export default TextAreaInput;
