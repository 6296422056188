import React, { FC } from "react";
import "./Auth.scss";

interface IAuth {
  wallpaper: string;
  children: React.ReactNode;
}

const Auth: FC<IAuth> = ({ wallpaper, children }) => {
  return (
    <div className="container-fluid">
      <div className="row auth-container">
        <div className="col-lg-6 col-xl-7 img-container p-0">
          <img className="auth-img" src={wallpaper} alt="generic-img" />
        </div>
        <div className="col-lg-6 col-xl-5 auth-content">
          <div className="row field-content">
            <div className="col-lg-8 offset-lg-2">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
