import React, { FC } from 'react';
import Button from '../Button/Button';
import genericImg from "../../../assets/img/generic-img.svg";
import "./EmptyTable.scss";

interface IEmptyTable {
description: string, 
title: string,
onClick: () => void,
labelButton: string
}

const EmptyTable: FC<IEmptyTable> = ({description, title, onClick, labelButton}) => {
return (
    <div className="row empty-container fill-container ">
    <div className="col-lg-6 offset-lg-3 text-center">
      <img
        className="generic-img"
        src={genericImg}
        alt="empty-survey-img" />
      <h1 className="empty-page-title">{title}</h1>
      <p className="empty-page-description">
        {description}
      </p>
      <div className="cta-container">
        <Button
        onClick={onClick}
        >
          {labelButton}
        </Button>
      </div>
    </div>
  </div>
)
};

export default EmptyTable;