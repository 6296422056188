import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import ImageUploading, {
  ErrorsType,
  ImageListType,
} from "react-images-uploading";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import { useEndpoints } from "../../../../hooks/useEndpoints";
import { Form } from "../../../../hooks/useForm";
import { Field } from "../../../../models/CustomForm";
import { setPutRealState } from "../../../../redux/reducers/realEstate";
import {
  cloneObject,
  getNestedValueFromString,
} from "../../../../Utils/fuctions";
import getClasses from "../../../../Utils/getClasses";
import {
  Image as IImage,
  IMAGE_TAG,
} from "../../../../forms/realeEstate/images";
import uploadImage from "../../../../assets/img/import-image.svg";
import "./ImagesInput.scss";
import Button from "../../Button/Button";
import { NestedKeys } from "advanced-types";
import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import {
  delayDispatchUploadImage,
  DURATION_TOAST,
} from "../../../../Utils/costants";
import pensilIcon from "../../../../assets/img/edit-pencil.svg";
import padLock from "../../../../assets/img/padlock.svg";
import Loader from "../../Loader/Loader";
import SnackChip from "../../SnackChip/SnackChip";
import { useI18n } from "../../../../hooks/useI18n";
import { roomsTagImage } from "../../../../forms/realeEstate/selections";
import Switcher from "../../Switcher/Switcher";
import { Translation } from "../../../../assets/i18n/locals/en";
import ArrowLeft from "../../../../assets/img/arrow-left.svg";
import ArrowRight from "../../../../assets/img/arrow-right.svg";
import { useToastAlert } from "../../ToastAlert/ToastAlert";
import { AxiosError } from "axios";
import CustomSelect from "../../CustomSelect/CustomSelect";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
interface IImagesInput {
  form: Partial<Form<any>>;

  setForm: (key: NestedKeys<any>, value: any) => void;
  data: Field;

  error: string | undefined;
}
interface LabelType {
  label: NestedKeys<Translation, "."> | "";
  value: IMAGE_TAG;
}
const ImagesInput: FC<IImagesInput> = ({ data: { key }, setForm, form }) => {
  const realEstateType = useSelector(
    (state: RootState) => state.realEstate.putRealEstate?.type
  );
  const { id } = useParams();
  const { t } = useI18n();
  const toastAlert = useToastAlert();
  const [isLoadingToServer, setIsLoadingToServer] = useState<boolean>(false);
  const [errorsUpdate, setErrorsUpdate] = useState<ErrorsType>();
  const toastsFailedCompressImages = useRef<{ name: string }[]>([]);
  const { postImages, getImages, putImages, patchImage, deleteImages } =
    useEndpoints();
  const dispatch = useDispatch();
  const [isSortingImage, setIsSortingImage] = useState(false);
  const [countUploadImage, setCountUploadImage] = useState({
    count: 0,
    length: 0,
  });
  const [images, setImages] = useState<IImage[]>(
    getNestedValueFromString(key, form)
  );

  const [showModal, setShowModal] = useState<boolean>(false);
  const [indexImageModal, setIndexImageModal] = useState<number>(0);
  const [confirmShow, setConfirmShow] = useState<boolean>(false);
  const [confirmDeleteAllImages, setConfirmDeleteAllImages] =
    useState<boolean>(false);

  useEffect(() => {
    document.addEventListener("dragend", () => setIsSortingImage(false));
    document.addEventListener("dragover", (e) => {
      setIsSortingImage(
        e.dataTransfer ? e.dataTransfer?.items.length > 0 : false
      );
    });

    document.addEventListener("mouseout", () => setIsSortingImage(false));
    return () => {
      document.removeEventListener("dragend", () => {});
      document.removeEventListener("dragover", () => {});
      document.removeEventListener("mouseout", () => {});
    };
  }, []);

  const buttonsMenu = useCallback(
    (addClick) => (
      <Flex justifyContent="flex-end">
        <Menu>
          <MenuButton
            as={IconButton}
            padding={"0rem !important"}
            aria-label="More server options"
            icon={<BsThreeDotsVertical />}
            variant="ghost"
            w="fit-content"
          />
          <MenuList p={0} zIndex={1000}>
            <MenuItem
              _hover={{ bg: "transparent" }}
              _focus={{ bg: "transparent" }}
              p={0}
            >
              <div
                onClick={() => addClick()}
                className={`menu-item menu-item-blue`}
              >
                <span className={`icon color-blue`}>{<EditIcon />}</span>
                <span className={`capitalize-first-letter color-blue`}>
                  {t("realEstate.forms.images.addImage")}
                </span>
              </div>
            </MenuItem>
            <MenuItem
              _hover={{ bg: "transparent" }}
              _focus={{ bg: "transparent" }}
              p={0}
            >
              <div
                onClick={() => handlerModalDeleteAll()}
                className={`menu-item menu-item-red`}
              >
                <span className={`icon color-blue`}>
                  {<DeleteIcon color="red.500" />}
                </span>
                <span className={`capitalize-first-letter color-red`}>
                  {t("realEstate.forms.images.deleteAllImage")}
                </span>
              </div>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    ),
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    const renderDescription = () => {
      switch (true) {
        case errorsUpdate?.acceptType:
          return "Formato immagine non valido";
        case errorsUpdate?.maxFileSize:
          return "Dimensioni massimo immagine: 6MB";
        case errorsUpdate?.maxNumber:
          return "Limite massimo immagini 40";
        default:
          return "Errore upload immagine";
      }
    };
    errorsUpdate &&
      toastAlert({
        message: "Error upload",
        subMessage: renderDescription(),
        type: "error",
        duration: DURATION_TOAST,
      });
    // eslint-disable-next-line
  }, [errorsUpdate]);

  const compressImage = (
    imgToCompress: CanvasImageSource,
    resizingFactor: any,
    quality: any
  ) =>
    new Promise<Blob>((resolve, reject) => {
      // resizing the image
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      const originalWidth = imgToCompress.width as number;
      const originalHeight = imgToCompress.height as number;

      const canvasWidth = originalWidth * resizingFactor;
      const canvasHeight = originalHeight * resizingFactor;

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      context!.drawImage(
        imgToCompress,
        0,
        0,
        originalWidth * resizingFactor,
        originalHeight * resizingFactor
      );

      // reducing the quality of the image
      canvas.toBlob(
        async (blob) => {
          if (blob) {
            // showing the compressed image
            resolve(blob);
          }
        },
        "image/jpeg",
        quality
      );
    });

  const loopCompressImage = (img: HTMLImageElement) =>
    new Promise<Blob | undefined>(async (resolve, reject) => {
      for (let i = 10; i > 0; i--) {
        const compressedImage = await compressImage(img, 1, i / 10);
        if (compressedImage.size < 4000000) return resolve(compressedImage);
      }
      return resolve(undefined);
    });

  const uploadImageToServer = async (files: { image: File }[]) =>
    new Promise((resolve) => {
      setIsSortingImage(false);
      setCountUploadImage({ count: 0, length: files.length });
      const filesPromise = files.map(
        ({ image }) =>
          new Promise(async (resolve, reject) => {
            if (image.size > 5000000) {
              const name = image.name;
              const img = new Image();
              img.onload = async () => {
                const compressedImage = await loopCompressImage(img);
                if (!compressedImage) {
                  toastsFailedCompressImages.current = [
                    ...toastsFailedCompressImages.current,
                    { name },
                  ];
                  setCountUploadImage((prev) => ({
                    ...prev,
                    count: prev.count + 1,
                  }));
                  resolve(undefined);
                  return;
                }
                const newFile = new File([compressedImage], name, {
                  type: "image/jpeg",
                });
                await postImages(id!, { image: newFile });
                setCountUploadImage((prev) => ({
                  ...prev,
                  count: prev.count + 1,
                }));
                resolve(undefined);
              };
              const fr = new FileReader();
              fr.onload = () => {
                (img as any).src = fr.result;
              };
              fr.readAsDataURL(image);
            } else {
              await postImages(id!, { image });
              setCountUploadImage((prev) => ({
                ...prev,
                count: prev.count + 1,
              }));
              resolve(undefined);
            }
          })
      );
      return Promise.all(filesPromise).then(async () => {
        setIsLoadingToServer(false);
        const {
          data: { images },
        } = await getImages(id!);
        setImages(images);
        toastsFailedCompressImages.current.forEach(({ name }) => {
          toastAlert({
            duration: DURATION_TOAST,
            message: t(
              "realEstate.forms.images.toast.failedCompression.title",
              { context: name }
            ),
            subMessage: t(
              "realEstate.forms.images.toast.failedCompression.description"
            ),
            type: "error",
          });
        });
        toastsFailedCompressImages.current = [];
        setCountUploadImage({ count: 0, length: 0 });
      });
    });

  useEffect(() => {
    setForm("images", images);
    dispatch(setPutRealState({ key: "images", payload: cloneObject(images) }));
    // eslint-disable-next-line
  }, [images]);

  const uploadSortedImages = () => {
    setTimeout(() => {
      putImages(id!, { imageIds: images.map(({ id }) => id) });
      dispatch(
        setPutRealState({ key: "images", payload: cloneObject(images) })
      );
    }, delayDispatchUploadImage);
  };

  const onChange = async (
    imageList: ImageListType,
    addUpdateIndex?: number[]
  ) => {
    if (!addUpdateIndex?.length) return;
    const filterImages = imageList
      .filter(({ file }, i) => addUpdateIndex.includes(i) && Boolean(file))
      .map(({ file: image }) => ({ image })) as { image: File }[];
    setIsLoadingToServer(filterImages.length > 0);
    await uploadImageToServer(filterImages);
  };

  const updatePropertyImage = async (
    idImage: string,
    property: Partial<IImage>
  ) => {
    try {
      await patchImage(id!, idImage, property);

      const newImages = images.map((img) =>
        img.id === idImage ? { ...img, ...property } : img
      );
      dispatch(setPutRealState({ key: "images", payload: newImages }));

      setImages(newImages);
    } catch (e) {
      console.error("error", e);
    }
  };
  const removeImage = async (idImageRemove: string) => {
    try {
      await deleteImages(id!, idImageRemove);
      setImages((prev) => {
        const newImages = prev.filter(({ id }) => id !== idImageRemove);
        dispatch(
          setPutRealState({ key: "images", payload: cloneObject(newImages) })
        );
        return [...newImages];
      });
    } catch (e) {
      const error = e as AxiosError<{ message?: string }>;
      if (error.message === "Request failed with status code 409") {
        toastAlert({
          duration: DURATION_TOAST + 1000,
          message:
            realEstateType === "land"
              ? t("editImage.imagesFiveMin")
              : t("editImage.imagesTenMin"),
          type: "error",
        });
      }
    }
  };

  const removeAllImages = async () => {
    setConfirmShow(false);
    setConfirmDeleteAllImages(false);
    try {
      await putImages(id!, { imageIds: [] });
      setImages([]);
      toastAlert({
        duration: DURATION_TOAST,
        message: t("agency.successToast.title"),
        type: "success",
      });
    } catch (e) {
      console.error(e);
      const error = e as AxiosError<{ message?: string }>;
      toastAlert({
        duration: DURATION_TOAST,
        message: t(
          error.response?.status === 409
            ? "realEstate.forms.images.toast.minLength.title"
            : "agency.errorToast.description"
        ),
        subMessage:
          error.response?.status === 409
            ? t("realEstate.forms.images.toast.minLength.descrition")
            : "",
        type: "error",
      });
    }
  };

  const handlerModalDeleteAll = () => {
    setConfirmDeleteAllImages(true);
    setConfirmShow((value) => !value);
  };
  const modalOpenHandler = (index: number) => {
    setIndexImageModal(index);
    setShowModal(true);
  };

  const sortImages = () => {
    return (
      <>
        <ReactSortable
          className={`container-sortable-images d-flex flex-wrap ${getClasses(
            {}
          )}`}
          multiDrag
          animation={200}
          onEnd={(e, i) => uploadSortedImages()}
          forceFallback={true}
          scrollSensitivity={150}
          scrollSpeed={20}
          scroll={true}
          delayOnTouchOnly={true}
          delay={2}
          list={images}
          setList={setImages}
        >
          {images.map(({ id, isPublic, title, thumbnail, tag }, i) => {
            return (
              <Fragment key={id}>
                <div
                  onMouseDown={() => setIsSortingImage(false)}
                  className="p-2 my-2 col-md-4 height-240"
                  key={id}
                >
                  <div className={"image-drag-item"}>
                    <div className="obscure-layer" />
                    {!isPublic && (
                      <div className="button-image">
                        <img
                          src={padLock}
                          alt="padlock"
                          width="20px"
                          height="20px"
                        />
                      </div>
                    )}
                    <img
                      src={thumbnail}
                      alt={title}
                      className="image-uploaded"
                    />
                    {tag !== "planimetry" && (
                      <span className="title-image-hover">{title}</span>
                    )}
                    <div
                      className="button-image-hover"
                      onClick={() => modalOpenHandler(i)}
                    >
                      <img
                        src={pensilIcon}
                        alt="pencil"
                        width="20px"
                        height="20px"
                      />
                    </div>
                    {tag === "planimetry" && (
                      <div className="tag-planimetry">
                        <SnackChip label="Planimetria" theme="info" />
                      </div>
                    )}
                  </div>
                </div>
              </Fragment>
            );
          })}
        </ReactSortable>
      </>
    );
  };

  // modal functions

  const [labelsSelect, setlabelsSelect] = useState<LabelType[]>(
    Array.from(images, ({ tag }) => ({
      value: tag,
      label:
        (Object.entries(roomsTagImage).find(
          ([_, value]) => value === tag
        )?.[0] as NestedKeys<Translation, ".">) || "",
    }))
  );

  const handlerTitleOrDesc = (
    newValue: string,
    key: "description" | "title"
  ) => {
    setImages((prev) => {
      prev[indexImageModal] = { ...prev[indexImageModal], [key]: newValue };
      return [...prev];
    });
  };
  const handlerSwitchImgPrivate = () => {
    setImages((prev) => {
      prev[indexImageModal] = {
        ...prev[indexImageModal],
        isPublic: !prev[indexImageModal].isPublic,
      };
      return [...prev];
    });
  };

  const handleSelect = useCallback(
    (label: string, value: IMAGE_TAG) => {
      let newArrayLabels = [...labelsSelect];
      newArrayLabels[indexImageModal] = {
        label: label as NestedKeys<Translation, ".">,
        value,
      };
      setlabelsSelect(newArrayLabels);

      updatePropertyImage(images[indexImageModal].id, {
        tag: value as IMAGE_TAG,
      });
    },
    // eslint-disable-next-line
    [indexImageModal]
  );
  const deleteImage = () => {
    setConfirmShow(false);
    if (images.length === 1) setShowModal(false);
    if (indexImageModal + 1 === images.length && images.length !== 1)
      setIndexImageModal((value) => value - 1);
    removeImage(images[indexImageModal].id);
  };
  const handlerSaveButton = () => {
    updatePropertyImage(images[indexImageModal].id, {
      description: images[indexImageModal].description,
      title: images[indexImageModal].title,
      isPublic: images[indexImageModal].isPublic,
      tag: labelsSelect[indexImageModal].value as IMAGE_TAG,
    });
    setShowModal(false);
  };

  return (
    <div className="App">
      {isLoadingToServer && (
        <Loader
          style={{
            zIndex: 10001,
            position: "fixed",
            height: "100vh",
            width: "100vw",
            top: 0,
            left: 0,
            backgroundColor: "#edf2f6",
          }}
        >
          <h1 className="in-progress-title">{`Caricamento immagini`}</h1>
          <p>{`${(
            (countUploadImage.count / countUploadImage.length) *
            100
          ).toFixed(0)} %`}</p>
        </Loader>
      )}
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        onError={setErrorsUpdate}
        maxNumber={200}
        acceptType={["jpg", "jpeg"]}
        maxFileSize={20001556}
        dataURLKey="data_url"
      >
        {({
          // imageList,
          onImageUpload,
          // onImageRemoveAll,
          // onImageUpdate,
          // onImageRemove,
          isDragging,
          dragProps,
          // errors,
        }) => (
          <>
            <div className="container__top mt-2">
              {
                <div className="left">
                  <h1 className="page-title capitalize-first-letter">
                    {t("realEstate.forms.images.uploadImages")}
                  </h1>
                  <p className="page-description capitalize-first-letter mt-1">
                    {t("realEstate.forms.images.dragClicktouploadImages")}
                  </p>
                </div>
              }
              <div className="button-right">{buttonsMenu(onImageUpload)}</div>
            </div>
            <>
              <div
                className="zone-drop-area"
                style={{
                  zIndex: isSortingImage ? 10000 : 0,
                }}
                {...dragProps}
              />
              {images.length > 0 ? (
                <>
                  {isSortingImage ? (
                    <div
                      className={`${getClasses({
                        "drop-area d-flex align-items-center justify-content-center":
                          isSortingImage,
                      })}`}
                    >
                      {isSortingImage ? (
                        <span className="drag-area-text">
                          {"Trascina quì le tue foto"}
                        </span>
                      ) : (
                        sortImages()
                      )}
                    </div>
                  ) : (
                    <>{sortImages()}</>
                  )}
                </>
              ) : (
                <div className="container-upload">
                  <img src={uploadImage} alt={"upload"} />
                  <p className="text">
                    {t("realEstate.forms.images.dragHere")}
                  </p>
                  <Button onClick={onImageUpload} color={"tertiary"}>
                    {t("realEstate.forms.images.uploadPhoto")}
                  </Button>
                </div>
              )}
            </>
          </>
        )}
      </ImageUploading>

      {(confirmShow || showModal) && <div className="obscure-background" />}
      <div
        className={`modal-advice-delete ${getClasses({
          "d-none": !confirmShow,
        })}`}
      >
        <div className="modal-advice-delete-content">
          <h1
            className={`text-delete-confirm ${getClasses({
              "text-confirm-all": confirmDeleteAllImages,
            })}`}
          >
            {t(
              confirmDeleteAllImages
                ? "editImage.textConfirmCancelAll"
                : "editImage.textConfirmCancel"
            )}
          </h1>
          <div className="row-buttons-confirm">
            <Button
              color="tertiary"
              onClick={() => {
                setConfirmShow(false);
                setConfirmDeleteAllImages(false);
              }}
            >
              {t("editImage.cancel")}
            </Button>
            <Button
              color="primary"
              onClick={() =>
                confirmDeleteAllImages ? removeAllImages() : deleteImage()
              }
            >
              {t("editImage.confirm")}
            </Button>
          </div>
        </div>
      </div>

      <div
        className={`modal-container ${getClasses({
          "show-modal": showModal && !confirmShow,
        })}`}
      >
        <div className="modal-content">
          <div className="modal-head">
            <h5 className="modal-title">{t("editImage.title")}</h5>
            <i
              className="icon icon-close icon-close-custom"
              onClick={() => setShowModal(false)}
            ></i>
          </div>
          <div className="modal-main-content">
            <div className="image-modal-container">
              <img
                className="image-modal"
                src={images[indexImageModal]?.url}
                alt={images[indexImageModal]?.title}
              />
            </div>

            <div className="slide-input-container">
              <input
                type="text"
                className="form-control input_custom"
                onChange={({ target: { value } }) =>
                  handlerTitleOrDesc(value, "title")
                }
                placeholder={t("editImage.placeHolderAddImage")}
                value={images[indexImageModal]?.title}
                onBlur={({ target: { value } }) =>
                  updatePropertyImage(images[indexImageModal].id, {
                    title: value,
                  })
                }
              />
              <textarea
                className="form-control input_custom_desc"
                onChange={({ target: { value } }) =>
                  handlerTitleOrDesc(value, "description")
                }
                placeholder={t("editImage.paceholderTextArea")}
                name="description"
                spellCheck="false"
                value={images[indexImageModal]?.description}
                onBlur={({ target: { value } }) =>
                  updatePropertyImage(images[indexImageModal].id, {
                    description: value,
                  })
                }
              />
              <div className="switch_container">
                <div className="d_row">
                  <CustomSelect
                    classNameSelectedItem={`${
                      labelsSelect[indexImageModal]?.value
                        ? "menu-button-value capitalize-first-letter"
                        : "menu-button-default"
                    }`}
                    selected={labelsSelect[indexImageModal]}
                    labels={Object.entries(roomsTagImage).map(
                      ([key, value]) => ({
                        label: t(key as NestedKeys<Translation, ".">),
                        value,
                      })
                    )}
                    onChange={({ label, value }) =>
                      handleSelect(label, value as IMAGE_TAG)
                    }
                    placeholder={"personalData.placeholderSelect"}
                  />
                </div>
                <div className="d_row second_row">
                  <Switcher
                    checked={!images[indexImageModal]?.isPublic}
                    setChecked={handlerSwitchImgPrivate}
                  />
                  <span className="text_switch">
                    {t("editImage.privateImage")}
                  </span>
                  <Tooltip
                    label={t("editImage.tooltip")}
                    color="white"
                    bg="blue.900"
                    placement="top"
                    hasArrow
                    arrowSize={8}
                  >
                    <i className="icon icon-warning svg_i" />
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <div className="buttons-row">
            <Button
              color="tertiary"
              onClick={() => setConfirmShow((value) => !value)}
            >
              {t("editImage.delete")}
            </Button>
            <Button color="primary" onClick={() => handlerSaveButton()}>
              {t("editImage.save")}
            </Button>
          </div>
          <div
            className={`left-arrow ${getClasses({
              "d-none": indexImageModal === 0,
            })}`}
            onClick={() => setIndexImageModal((value) => value - 1)}
          >
            <img src={ArrowLeft} alt="arrow-left" />
          </div>
          <div
            className={`right-arrow ${getClasses({
              "d-none": indexImageModal === images.length - 1,
            })}`}
            onClick={() => setIndexImageModal((value) => value + 1)}
          >
            <img src={ArrowRight} alt="arrow-right" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImagesInput;
