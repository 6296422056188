import React, { FC } from "react";
import { countTypeConditionsDemandCustomer } from "../../../../../forms/realeEstate/selections";
import { useI18n } from "../../../../../hooks/useI18n";
import CustomSelect from "../../../CustomSelect/CustomSelect";
import { IConditionOnClick } from "../BodyModalConditions";
import "./ConditionsSelect.scss";

type Item<T extends string | unknown = string> =
  | {
      label: string;
      value: T;
    }
  | {
      label: string;
      value: T;
    }[]
  | undefined;

interface IConditionsSelect {
  item: IConditionOnClick;
  selectedItem: Item;
  onClose: (item: IConditionOnClick) => void;
  onClick: (item: Item<unknown>) => void;
  check: boolean;
}

const ConditionsSelect: FC<IConditionsSelect> = ({
  item,
  onClick,
  check,
  onClose,
}) => {
  const { selectType: labels, selectedItems, label } = item;
  const { t } = useI18n();
  const getLabels = () => {
    switch (typeof labels) {
      case "boolean":
        return [
          { label: "*", value: "*" },
          {
            label: "selectOptions.elevatorOprions.YES",
            value: true,
          },
          {
            label: "selectOptions.elevatorOprions.NO",
            value: false,
          },
        ];

      case "number":
        return [
          ...Object.entries(countTypeConditionsDemandCustomer(15)).map(
            ([label, value]) => ({ label, value })
          ),
        ].sort(({value}) => (value === "*" ? -1 : 1));

      default:
        return [
          ...Object.entries(labels).map(([label, value]) => ({
            label,
            value,
          })),
        ];
    }
  };

  return (
    <div className="background-condition-select">
      <div className="d-flex justify-content-between w-100">
        <span className="title-condition-select">{t(label)}</span>
        <i
          onClick={() => onClose(item)}
          className="icon icon-delete pointer"
        ></i>
      </div>
      <CustomSelect
        options={{ placeholderCounter: true }}
        checkbox={true as any}
        selected={selectedItems}
        placeholder="selectOptions.placeholderSelect"
        onChange={onClick}
        labels={getLabels()}
      />
    </div>
  );
};

export default ConditionsSelect;
