import { NestedKeys } from "advanced-types";
import React, { FC } from "react";
import EmptyResourcesImg from "./../../../assets/img/empty-resources-img.svg";
import { Translation } from "../../../assets/i18n/locals/en";
import "./EmptyResourcesTable.scss";
import { useI18n } from "../../../hooks/useI18n";

interface IEmptyResourcesTable<
  T extends NestedKeys<Translation, "."> = NestedKeys<Translation, ".">
> {
  image?: string;
  title?: T | "";
  description?: T | "";
}

const EmptyResourcesTable: FC<IEmptyResourcesTable> = ({
  image = EmptyResourcesImg,
  title,
  description,
}) => {
  const { t } = useI18n();
  return (
    <div className="container-empty-resources-table">
      <img
        src={image}
        className="container-empty-resources-table-image"
        alt="empty-resources-table"
      />
      {title !== "" && (
        <h1 className="empty-page-title">
          {t(title ?? "consultants.emptyResourcesTable.title")}
        </h1>
      )}
      {description !== "" && (
        <p className="empty-page-description">
          {t(description ?? "consultants.emptyResourcesTable.description")}
        </p>
      )}
    </div>
  );
};

export default EmptyResourcesTable;
