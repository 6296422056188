import { NestedKeys } from "advanced-types";
import { Translation } from "../../assets/i18n/locals/en";
import { ICustomForm } from "../../models/CustomForm";
import {
  AIR_CONDITIONING_TYPE,
  ELECTRICAL_SYSTEM_STATUS,
  EntityInformation,
  RESIDENTIAL_INFO_ENTITY,
  TV_STATUS,
} from "../../models/RealEstate";

export const infoLabel: {
  label: NestedKeys<Translation, "."> | "";
  squareMeters?: number;
  value: RESIDENTIAL_INFO_ENTITY;
  selectOptions?: {
    label: NestedKeys<Translation, "."> | "";
    value: ELECTRICAL_SYSTEM_STATUS | TV_STATUS | AIR_CONDITIONING_TYPE;
  }[];
}[] = [
  {
    label: "selectOptions.otherInfoLabel.SHOWER",
    value: RESIDENTIAL_INFO_ENTITY.SHOWER,
  },
  {
    label: "selectOptions.otherInfoLabel.ALUMINIUM_FIXTURES",
    value: RESIDENTIAL_INFO_ENTITY.ALUMINIUM_FIXTURES,
  },
  {
    label: "selectOptions.otherInfoLabel.PARQUET",
    value: RESIDENTIAL_INFO_ENTITY.PARQUET,
  },
  {
    label: "selectOptions.otherInfoLabel.CLOSET",
    value: RESIDENTIAL_INFO_ENTITY.CLOSET,
  },
  {
    label: "selectOptions.otherInfoLabel.JACUZZI",
    value: RESIDENTIAL_INFO_ENTITY.JACUZZI,
  },
  {
    label: "selectOptions.otherInfoLabel.POOL",
    squareMeters: 0,
    value: RESIDENTIAL_INFO_ENTITY.POOL,
  },
  {
    label: "selectOptions.otherInfoLabel.CHIMNEY",
    value: RESIDENTIAL_INFO_ENTITY.CHIMNEY,
  },
  {
    label: "selectOptions.otherInfoLabel.AT_AUCTION",
    value: RESIDENTIAL_INFO_ENTITY.AT_AUCTION,
  },
  {
    label: "selectOptions.otherInfoLabel.WOOD_FIXTURES",
    value: RESIDENTIAL_INFO_ENTITY.WOOD_FIXTURES,
  },
  {
    label: "selectOptions.otherInfoLabel.SHUTTERS",
    value: RESIDENTIAL_INFO_ENTITY.SHUTTERS,
  },
  {
    label: "selectOptions.otherInfoLabel.SUSPENDED_SANITARY",
    value: RESIDENTIAL_INFO_ENTITY.SUSPENDED_SANITARY,
  },
  {
    label: "selectOptions.otherInfoLabel.LAKE_VIEW",
    value: RESIDENTIAL_INFO_ENTITY.LAKE_VIEW,
  },
  {
    label: "selectOptions.otherInfoLabel.TERRACE",
    squareMeters: 0,
    value: RESIDENTIAL_INFO_ENTITY.TERRACE,
  },
  {
    label: "selectOptions.otherInfoLabel.SAFETY_BOX",
    value: RESIDENTIAL_INFO_ENTITY.SAFETY_BOX,
  },
  {
    label: "selectOptions.otherInfoLabel.INDEPENDENT_ENTRANCE",
    value: RESIDENTIAL_INFO_ENTITY.INDEPENDENT_ENTRANCE,
  },
  {
    label: "selectOptions.otherInfoLabel.ALARM_PREDISPOSED",
    value: RESIDENTIAL_INFO_ENTITY.ALARM_PREDISPOSED,
  },
  {
    label: "selectOptions.otherInfoLabel.SEA_VIEW",
    value: RESIDENTIAL_INFO_ENTITY.SEA_VIEW,
  },
  {
    label: "selectOptions.otherInfoLabel.PORCH",
    value: RESIDENTIAL_INFO_ENTITY.PORCH,
  },
  {
    label: "selectOptions.otherInfoLabel.PHONE_SYSTEM",
    value: RESIDENTIAL_INFO_ENTITY.PHONE_SYSTEM,
  },
  {
    label: "selectOptions.otherInfoLabel.AIR_CONDITIONING",
    value: RESIDENTIAL_INFO_ENTITY.AIR_CONDITIONING,
    selectOptions: [
      { label: "", value: AIR_CONDITIONING_TYPE.NOT_PROVIDED },
      {
        label: "selectOptions.otherInfoLabel.AIR_CONDITIONING_TYPE.ABSENT",
        value: AIR_CONDITIONING_TYPE.ABSENT,
      },
      {
        label: "selectOptions.otherInfoLabel.AIR_CONDITIONING_TYPE.CENTRALIZED",
        value: AIR_CONDITIONING_TYPE.CENTRALIZED,
      },
      {
        label: "selectOptions.otherInfoLabel.AIR_CONDITIONING_TYPE.AUTONOMOUS",
        value: AIR_CONDITIONING_TYPE.AUTONOMOUS,
      },
      {
        label:
          "selectOptions.otherInfoLabel.AIR_CONDITIONING_TYPE.PREDISPOSITION",
        value: AIR_CONDITIONING_TYPE.PREDISPOSITION,
      },
    ],
  },
  {
    label: "selectOptions.otherInfoLabel.TV_ANTENNA",
    value: RESIDENTIAL_INFO_ENTITY.TV_ANTENNA,
    selectOptions: [
      { label: "", value: TV_STATUS.NOT_PROVIDED },
      {
        label: "selectOptions.otherInfoLabel.TV_STATUS.UNPROVIDED",
        value: TV_STATUS.UNPROVIDED,
      },
      {
        label: "selectOptions.otherInfoLabel.TV_STATUS.CONDOMINIUM",
        value: TV_STATUS.CONDOMINIUM,
      },
      {
        label: "selectOptions.otherInfoLabel.TV_STATUS.AUTONOMOUS",
        value: TV_STATUS.AUTONOMOUS,
      },
    ],
  },
  {
    label: "selectOptions.otherInfoLabel.ELECTRICAL_SYSTEM",
    value: RESIDENTIAL_INFO_ENTITY.ELECTRICAL_SYSTEM,
    selectOptions: [
      { label: "", value: ELECTRICAL_SYSTEM_STATUS.NOT_PROVIDED },
      {
        label: "selectOptions.otherInfoLabel.ELECTRICAL_SYSTEM_STATUS.ABSENT",
        value: ELECTRICAL_SYSTEM_STATUS.ABSENT,
      },
      {
        label: "selectOptions.otherInfoLabel.ELECTRICAL_SYSTEM_STATUS.LEGAL",
        value: ELECTRICAL_SYSTEM_STATUS.LEGAL,
      },
      {
        label:
          "selectOptions.otherInfoLabel.ELECTRICAL_SYSTEM_STATUS.NOT_LEGAL",
        value: ELECTRICAL_SYSTEM_STATUS.NOT_LEGAL,
      },
    ],
  },
  {
    label: "selectOptions.otherInfoLabel.SAT_TV",
    value: RESIDENTIAL_INFO_ENTITY.SAT_TV,
    selectOptions: [
      { label: "", value: TV_STATUS.NOT_PROVIDED },
      {
        label: "selectOptions.otherInfoLabel.TV_STATUS.UNPROVIDED",
        value: TV_STATUS.UNPROVIDED,
      },
      {
        label: "selectOptions.otherInfoLabel.TV_STATUS.CONDOMINIUM",
        value: TV_STATUS.CONDOMINIUM,
      },
      {
        label: "selectOptions.otherInfoLabel.TV_STATUS.AUTONOMOUS",
        value: TV_STATUS.AUTONOMOUS,
      },
    ],
  },
];

export interface IOtherInfoForm {
  otherInfo: EntityInformation[];
}
export const initialValueOtherInfoForm = {
  otherInfo: [
    {
      entity: {},
      status: {},
      squareMeters: 0,
    },
  ],
};

export const otherInfoForm: ICustomForm<IOtherInfoForm> = {
  title: {
    edit: "realEstate.forms.otherInfo.title",
    show: "realEstate.forms.otherInfo.title",
  },
  description: {
    edit: "realEstate.forms.otherInfo.description.edit",
    show: "realEstate.forms.otherInfo.description.show",
  },
  // labelSubmitButton: {edit:"realEstate.forms.otherInfo.labelSubmit.edit", show: "realEstate.forms.otherInfo.labelSubmit.show"},
  labelSubmitButton: { edit: "editImage.save", show: "editImage.save" },
  next: "zone-characteristics",
  forms: [
    {
      title: "",
      fields: [
        {
          column: 12,
          columnRadio: 3,
          label: "",
          key: "otherInfo",
          type: "entity-information",
          multiple: true,
          entityInformationOptions: [...infoLabel],
        },
      ],
    },
  ],
};
