import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Form } from '../../hooks/useForm'
import { Coord } from '../../models/demandCustomer';
import { APPROVAL_STATUS, RealEstate } from '../../models/RealEstate'
import { RespRealEstate } from '../../models/RespRealeState'

interface RealEstateState {
    realEstates: RespRealEstate | undefined,
    putRealEstate: RealEstate | undefined,
    isSubmit: {submit: boolean, back: boolean};
    canChangeStepForm: boolean;
    queryTable: {params: Record<string, string | number>, id: string,zone:Coord[]|undefined} | undefined
}

const initialState = { realEstates: undefined, putRealEstate: undefined, isSubmit: {submit: false, back: false}, queryTable: undefined, canChangeStepForm: true } as RealEstateState

const realEstateSlice = createSlice({
  name: 'realEstates',
  initialState,
  reducers: {

    setRealeEstates(state: RealEstateState, {payload}: PayloadAction<RespRealEstate | undefined>) {
      state.realEstates = payload
    },
    setQueryTable(state: RealEstateState, {payload}: PayloadAction<{params: Record<string, string | number> , id: string,zone:Coord[]|undefined}| undefined>) {
      state.queryTable = payload
    },
    setApprovalStatus(state: RealEstateState, {payload: {index, approvalStatus}}: PayloadAction<{index: number, approvalStatus: APPROVAL_STATUS}>) { 
      if(!state.realEstates) return;
      state.realEstates.docs[index].approvalStatus = approvalStatus
    },
    setIsSubmit(state: RealEstateState, {payload}: PayloadAction<RealEstateState["isSubmit"] | boolean>){
      state.isSubmit = typeof payload === "boolean" ? {submit: payload, back: false} : payload
    },
    deleteRealEstate(state: RealEstateState, action: PayloadAction<number>) {
      state.realEstates = {...state.realEstates!, docs: state.realEstates!.docs.filter((_, i) => i !== action.payload)}
    },
    setPutRealState(state: RealEstateState, {payload: {payload, key}}: PayloadAction<{payload: RealEstate | Partial<Form<RealEstate>> | any[], key?: keyof RealEstate}>) {
     if(!key){
       state.putRealEstate = state.putRealEstate ? {...state.putRealEstate, ...payload} : (payload as RealEstate)
     } else {
      state.putRealEstate = state.putRealEstate ? {...state.putRealEstate, [key]: (Array.isArray(payload) ? [...payload] : {...payload})} : state.putRealEstate
     }
    },
    deletePutRealEstate(state: RealEstateState) {
      state.putRealEstate = undefined;
    },
    setCanChangeStepForm(state: RealEstateState, {payload}: PayloadAction<boolean>) {
      state.canChangeStepForm = payload;
    }
  },
})

export const { setRealeEstates, setApprovalStatus, setQueryTable ,setPutRealState, deleteRealEstate, setIsSubmit, deletePutRealEstate, setCanChangeStepForm } = realEstateSlice.actions
export default realEstateSlice.reducer