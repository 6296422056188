import React, { FC } from 'react';
import "./Chip.scss";

interface IChip {
label: string,
id: string | number;
onClose?: (id: string | number) => void
}

const Chip: FC<IChip> = ({label, onClose, id}) => {
return (
    <div className='container-chip'>
        <span className='container-chip-label'>{label}</span>
        <i onClick={() => onClose && onClose(id)} className= {`icon icon-close icon-close-chip container-chip-icon`}></i>
    </div>
)
};

export default Chip;