import React, { FC, useEffect, useState } from "react";
import { useEndpoints } from "../../hooks/useEndpoints";
import { useParams } from "react-router-dom";
import "./CompactRealEstate.scss";
import { RealEstate } from "../../models/RealEstate";
import { RealEstateCommercial } from "../../models/RealeEstateCommercial";
import Loader from "../../shared/components/Loader/Loader";
import GridImages from "./../../components/CompactRealEstate/GridImages";
import GeneralInformation from "../../components/CompactRealEstate/GeneralInformation";
import SpecificInformation from "../../components/CompactRealEstate/SpecificInformation/SpecificInformation";
import ModalChangeConsultantRealEstate from "../../components/CompactRealEstate/SpecificInformation/ModalChangeConsultant/ModalChangeConsultantRealEstate";
import { useI18n } from "../../hooks/useI18n";
import Map from "../../shared/Map/Map";
import Conditions from "../../components/CompactRealEstate/Conditions/Conditions";
import { RealEstateLand } from "../../models/RealEstateLand";

interface ICompactRealEstate {}

const CompactRealEstate: FC<ICompactRealEstate> = () => {
  const { getRealEstate } = useEndpoints();
  const [isLoading, setisLoading] = useState(true);
  const [realEstate, setRealEstate] = useState<
    RealEstate | RealEstateCommercial | RealEstateLand
  >();
  const { id } = useParams();
  const { t } = useI18n();
  const fetchRealEstate = async () => {
    try {
      const { data } = await getRealEstate(id!);
      setRealEstate(data);
    } catch (error) {
      console.error("error", error);
    } finally {
      setisLoading(false);
    }
  };

  useEffect(() => {
    fetchRealEstate();
    // eslint-disable-next-line
  }, []);

  const Description: FC<{ description: string }> = ({ description }) => {
    return (
      <>
        <p className="compact-realestate-title mt-5">
          {t("realEstate.forms.compactRealEstate.description")}
        </p>
        <p className="compact-realestate-text mt-4">{description}</p>
      </>
    );
  };

  return !isLoading && realEstate ? (
    <>
      {/* <Button color="quaternary">S</Button> */}
      <div className="container-compact-realestate">
        <div className="container-header mb-5">
          <div>
            <h1 className="title">{`${realEstate?.location?.city} ${
              realEstate?.location?.state
                ? `(${realEstate?.location?.state})`
                : ""
            }`}</h1>
            <span className="street">
              {`${realEstate?.location?.street}`}
              {`${
                realEstate?.location?.unity
                  ? ` ${realEstate?.location?.unity}`
                  : ""
              }`}
            </span>
          </div>
          <div>
            {!realEstate.casafariId && (
              <ModalChangeConsultantRealEstate
                onChange={(selectedItem) => {
                  setRealEstate((prev) => ({
                    ...prev!,
                    consultant: selectedItem,
                  }));
                }}
                realEstate={realEstate}
              />
            )}
          </div>
        </div>

        <div className="container-body">
          <div className="container-body-left">
            <GridImages
              images={realEstate!.images}
              urlVideo={{
                virtualTour: realEstate.virtualTourUrl,
                media: realEstate.videoUrl,
              }}
            />
            <Description description={realEstate.description} />
            <SpecificInformation realEstate={realEstate} />
            <Conditions realEstate={realEstate}></Conditions>
          </div>
          <div className="container-body-right">
            <GeneralInformation realEstate={realEstate} />
          </div>
        </div>
        {realEstate.location.latitude && realEstate.location.longitude && (
          <>
            <p className="compact-realestate-title mt-5">
              {t("realEstate.forms.compactRealEstate.map")}
            </p>
            <Map
              className="map mt-4 mb-5"
              zoom={16}
              center={{
                lat: realEstate.location.latitude,
                lng: realEstate.location.longitude,
              }}
            ></Map>
          </>
        )}
      </div>
    </>
  ) : (
    <Loader />
  );
};

export default CompactRealEstate;
