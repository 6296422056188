import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { RootState } from "../redux/reducers";
import { useEndpoints } from "./useEndpoints";
import { setPutRealState } from "../redux/reducers/realEstate";
import {
  addValidationQuery,
  filterKeyForForm,
  getNestedValueFromString,
  isResidential,
  isCommercial,
  isLand,
} from "../Utils/fuctions";
import { Form, FormValidationObject } from "./useForm";
import { NestedPathKeys } from "../typings/KeysFromObject";
import {
  RealEstate,
  REAL_ESTATE_TYPE,
  ROOM_PERTINENCE_TYPE,
} from "../models/RealEstate";
import { DURATION_TOAST } from "../Utils/costants";
import { RoutesRouter } from "../models/Routes";
import { initialValueCertificationsForm } from "../forms/realeEstate/certifications";
import { initialValueDescriptionFormCommercial } from "../forms/realeEstate/Commercial/descriptionCommercial";
import { initialValueDescriptionForm } from "../forms/realeEstate/description";
import { initialValueGeneralForm } from "../forms/realeEstate/general";
import { initialValueLocationForm } from "../forms/realeEstate/location";
import { initialValueOtherInfoForm } from "../forms/realeEstate/otherInfo";
import { initialValuePriceForm } from "../forms/realeEstate/price";
import { initialValueZoneCharacteristicsForm } from "../forms/realeEstate/zoneCharacteristics";
import { RealEstateCommercial } from "../models/RealeEstateCommercial";
import { initialValueGeneralFormCommercial } from "../forms/realeEstate/Commercial/generalCommercial";
import { initialValueOtherInfoFormCommercial } from "../forms/realeEstate/Commercial/otherInfoCommercial";
import { useToastAlert } from "../shared/components/ToastAlert/ToastAlert";
import { useI18n } from "./useI18n";
import { stages } from "../App";
import { RealEstateLand } from "../models/RealEstateLand";
import { initialValueGeneralFormLand } from "../forms/realeEstate/Land/generalLand";
import { initialValuePriceFormLand } from "../forms/realeEstate/Land/priceLand";
import { initialValueLocationFormLand } from "../forms/realeEstate/Land/locationLand";
import { initialValueDescriptionFormLand } from "../forms/realeEstate/Land/descriptionLand";

const initialValueForm = (
  realEstate: RealEstate | RealEstateCommercial | RealEstateLand,
  section:
    | "description"
    | "general"
    | "images"
    | "otherinfo"
    | "location"
    | "price"
    | "zone-characteristics"
    | "certifications"
) => {
  const formMap = {
    residential: {
      description: initialValueDescriptionForm,
      certifications: initialValueCertificationsForm,
      images: { images: [] },
      location: initialValueLocationForm,
      otherinfo: initialValueOtherInfoForm,
      general: initialValueGeneralForm,
      price: initialValuePriceForm,
      "zone-characteristics": initialValueZoneCharacteristicsForm,
    },
    commercial: {
      description: initialValueDescriptionFormCommercial,
      certifications: initialValueCertificationsForm,
      images: { images: [] },
      location: initialValueLocationForm,
      otherinfo: initialValueOtherInfoFormCommercial,
      general: initialValueGeneralFormCommercial,
      price: initialValuePriceForm,
      "zone-characteristics": initialValueZoneCharacteristicsForm,
    },
    land: {
      description: initialValueDescriptionFormLand,
      images: { images: [] },
      location: initialValueLocationFormLand,
      otherinfo: initialValueOtherInfoForm,
      general: initialValueGeneralFormLand,
      price: initialValuePriceFormLand,
      "zone-characteristics": initialValueZoneCharacteristicsForm,
    },
  };

  if (isCommercial(realEstate)) {
    return formMap.commercial[section] || {};
  } else if (isResidential(realEstate)) {
    return formMap.residential[section] || {};
  } else if (isLand(realEstate)) {
    if (section === "certifications") {
      return {};
    }
    return formMap.land[section] || {};
  } else {
    return {};
  }
};

export const useRealEstateForm = <T extends object>(
  section:
    | "description"
    | "general"
    | "images"
    | "otherinfo"
    | "location"
    | "price"
    | "zone-characteristics"
    | "certifications",
  nestedKey: NestedPathKeys<RealEstate> | null = null
) => {
  const { getRealEstate } = useEndpoints();
  const [isLoading] = useState(false);
  const dispatch = useDispatch();
  const navigateForm = useNavigate();
  const [initialValue, setInitialValue] = useState<T>();
  const toastAlert = useToastAlert();
  const { t } = useI18n();
  const { putRealEstate } = useSelector(
    ({ realEstate }: RootState) => realEstate
  );
  const [isCommercial, setIsCommercial] = useState<boolean>();
  const [isLand, setIsLand] = useState<boolean>();
  const dipatch = useDispatch();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const isValidation = searchParams.get("validation");
  const indexCurrentStage = useMemo(
    () => stages.findIndex(({ link }) => pathname.includes(link)),
    // eslint-disable-next-line
    [pathname]
  );

  const goBackPath = () => {
    let previousStageIndex = indexCurrentStage - 1;
    if (isLand && stages[previousStageIndex].link.includes("certifications")) {
      previousStageIndex--;
    }

    if (previousStageIndex >= 0) {
      return addValidationQuery(
        stages[previousStageIndex].link,
        Boolean(isValidation)
      );
    } else {
      return RoutesRouter.realEstates;
    }
  };

  const changeArchitecturalBarriersLocationFormBooleanToSelect = (
    newForm: Record<string, any>
  ) => {
    if ("missingArchitecturalBarriers" in newForm) {
      const value = newForm["missingArchitecturalBarriers"];
      newForm["missingArchitecturalBarriers"] =
        ROOM_PERTINENCE_TYPE[value ? "ABSENT" : "PRESENT"];
    }
  };

  const checkRealState = async () => {
    if (!id) return navigateForm(RoutesRouter.realEstates, { replace: true });
    if (putRealEstate && putRealEstate._id === id) {
      setIsCommercial(putRealEstate?.type === REAL_ESTATE_TYPE.COMMERCIAL);
      setIsLand(putRealEstate?.type === REAL_ESTATE_TYPE.LAND);
      let initialValue = filterKeyForForm<T>(
        nestedKey
          ? getNestedValueFromString(nestedKey, putRealEstate)
          : putRealEstate,
        initialValueForm(putRealEstate, section)
      );
      changeArchitecturalBarriersLocationFormBooleanToSelect(initialValue);
      setInitialValue(initialValue);
      return;
    }
    try {
      const { data: response } = await getRealEstate(id);
      let data = JSON.parse(JSON.stringify(response));
      if (data.type && !("averageRevenue" in data.additionalDetails)) {
        data.additionalDetails = {
          ...data.additionalDetails,
          averageRevenue: { from: null, to: null },
        };
      }
      // @ts-ignore
      let newForm: Record<string, any> = filterKeyForForm<T>(
        nestedKey ? getNestedValueFromString(nestedKey, data) : data,
        initialValueForm(data, section)
      );
      changeArchitecturalBarriersLocationFormBooleanToSelect(newForm);
      setIsCommercial(data?.type === REAL_ESTATE_TYPE.COMMERCIAL);
      setIsLand(data?.type === REAL_ESTATE_TYPE.LAND);
      setInitialValue(newForm as T);
      dipatch(setPutRealState({ payload: data }));
    } catch (e) {
      console.log("e", e);
      navigateForm(RoutesRouter.realEstates, { replace: true });
      toastAlert({
        message: t("errorsToast.unexpectedError.title"),
        subMessage: t("errorsToast.unexpectedError.description"),
        type: "error",
        duration: DURATION_TOAST,
      });
    }
  };

  useEffect(() => {
    checkRealState();
    // eslint-disable-next-line
  }, []);

  const setRealStateRedux = (
    payload: Partial<Form<T>>,
    key: keyof RealEstate | undefined = undefined
  ) => {
    dispatch(setPutRealState({ payload, key }));
  };

  const navigate = (formType: string) => {
    navigateForm(`${RoutesRouter["realEstates"]}/${id}/edit/${formType}`);
  };
  const generateForm = useCallback(
    (validators: FormValidationObject<T> | undefined = undefined) => {
      // @ts-ignore
      return { initialValue, validators };
    },
    [initialValue]
  );

  return {
    isLoading,
    initialValue,
    generateForm,
    id,
    navigate,
    setRealStateRedux,
    isCommercial,
    isLand,
    goBackPath,
  };
};
