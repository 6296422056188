import { ICustomForm } from "../../../models/CustomForm";
export interface IPriceFormLand {
  requestedPrice: number;
  estimatedValue: number;
  negotiable?: boolean;
  startingFrom?: boolean;
}

export const initialValuePriceFormLand = {
  requestedPrice: 0,
  estimatedValue: 0,
  negotiable: false,
  startingFrom: false,
};

export const priceFormLand: ICustomForm<IPriceFormLand> = {
  title: {
    edit: "realEstate.forms.price.title",
    show: "realEstate.forms.price.title",
  },
  description: {
    edit: "realEstate.forms.price.description.edit",
    show: "realEstate.forms.price.description.show",
  },
  labelSubmitButton: { edit: "editImage.save", show: "editImage.save" },
  next: "location",
  forms: [
    {
      title: "",
      fields: [
        {
          label: "realEstate.forms.price.requestedPrice",
          column: 2,
          typeField: "currency",
          isRequired: true,
          type: "number",
          key: "requestedPrice",
        },
        {
          label: "realEstate.forms.price.estimatedValue",
          column: 2,
          typeField: "currency",
          maxColumn: 12,
          isRequired: true,
          type: "number",
          key: "estimatedValue",
        },
      ],
    },
    {
      title: "realEstate.forms.price.priceOption.title",
      fields: [
        {
          label: "",
          column: 3,
          type: "checkbox",
          key: "negotiable",
          option: {
            label: "realEstate.forms.price.priceOption.negotiable.option",
            value: false,
          },
          tooltip: {
            label: "realEstate.forms.price.priceOption.negotiable.tooltip",
          },
        },
        {
          label: "",
          column: 3,
          type: "checkbox",
          key: "startingFrom",
          option: {
            label: "realEstate.forms.price.priceOption.startingFrom.option",
            value: false,
          },
          tooltip: {
            label: "realEstate.forms.price.priceOption.startingFrom.tooltip",
          },
        },
      ],
    },
  ],
};
