import React, {
  FC,
  Fragment,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
  useRef,
  useEffect,
} from "react";
import { useI18n } from "../../../../hooks/useI18n";
import Button from "../../Button/Button";
import Chip from "../../Chip/Chip";
import CustomSelect from "../../CustomSelect/CustomSelect";
import TextInput from "../../TextInput/TextInput";
import { IConditions } from "./ConditionsDemandInput";
import { areasFilters, filters, IFIlters } from "./Filters";
import "./BodyModalConditions.scss";
import { NestedKeys } from "advanced-types";
import { Translation } from "../../../../assets/i18n/locals/en";
import getClasses from "../../../../Utils/getClasses";

export type IConditionOnClick = IFIlters[keyof IFIlters] & { name: string };
interface IBodyModalConditions {
  condtions: IConditions;
  selectedConditions: IConditionOnClick[];
  typeConditions: keyof IConditions;
  setSelectedConditions: Dispatch<SetStateAction<IConditions>>;
  onClick?: (item: IConditionOnClick) => void;
}

const BodyModalConditions: FC<IBodyModalConditions> = ({
  condtions,
  selectedConditions,
  typeConditions,
  onClick,
}) => {
  const [valueInput, setsValueInput] = useState<string>("");
  const [hideScrollBar, setHideScrollBar] = useState(false);
  const [selectedItems, setSelectedItems] = useState<typeof areasFilters>([]);
  const bodyModalRef = useRef<HTMLDivElement>(null);

  const countSelectedItems = [...condtions.required, ...condtions.optional]
    .length;

  const { t } = useI18n();
  const filteredConditions = useMemo(
    () =>
      Object.entries(filters)
        .map(([key, value]) => ({ ...value, name: key }))
        .filter((condition) =>
          (selectedItems.length === 0 ? areasFilters : selectedItems).some(
            ({ value: selectedSections }) =>
              selectedSections === condition.section &&
              t(condition.label)
                .toLowerCase()
                .includes(valueInput.toLowerCase())
          )
        ),
    // eslint-disable-next-line
    [selectedItems, valueInput]
  );

  const groupBySectionFilteredConditions = filteredConditions.reduce(
    (acc, arr) => {
      acc[arr.section].push(arr);
      return acc;
    },
    {
      external: [] as IConditionOnClick[],
      internal: [] as IConditionOnClick[],
      relevance: [] as IConditionOnClick[],
      other: [] as IConditionOnClick[],
    } as {
      external: IConditionOnClick[];
      internal: IConditionOnClick[];
      relevance: IConditionOnClick[];
      other: IConditionOnClick[];
    }
  );

  useEffect(() => {
    if (!bodyModalRef.current) return;
    setHideScrollBar(
      bodyModalRef.current.scrollHeight > bodyModalRef.current.clientHeight
    );
    // eslint-disable-next-line
  }, [bodyModalRef.current, selectedItems]);

  const RenderConditionsFiltered = () =>
    Object.entries(groupBySectionFilteredConditions).map(
      ([section, conditions], index) => (
        <Fragment key={index}>
          {!Boolean(selectedItems.length) ||
          selectedItems.some(({ value }) => value === section) ? (
            <Fragment key={index}>
              <h4 className="title-body-modal">
                {t(
                  `customers.demandsCustomers.conditionsModal.areas.${section}` as NestedKeys<
                    Translation,
                    "."
                  >
                )}
              </h4>
              <div className="container-conditions-body-modal" key={index}>
                {conditions.map((item, index) => {
                  const { name: nameItem, label } = item;
                  const isDisable = [
                    ...condtions.required,
                    ...condtions.optional,
                  ]
                    .filter((conditions) =>
                      selectedConditions.some(
                        (condition) => conditions === condition
                      )
                    )
                    .some(({ name }) => name === nameItem);
                  const isSelected =
                    !isDisable &&
                    [...condtions.required, ...condtions.optional].some(
                      ({ name }) => name === nameItem
                    );
                  return (
                    <Button
                      disabled={isDisable}
                      key={index}
                      className={`condition-body-modal ${getClasses({
                        "condition-body-modal-selected": isSelected,
                      })}`}
                      color="tertiary"
                      onClick={() => {
                        onClick && onClick({ ...item });
                      }}
                    >
                      {t(label)}
                    </Button>
                  );
                })}
              </div>
            </Fragment>
          ) : (
            <></>
          )}
        </Fragment>
      )
    );
  return (
    <>
      <div
        ref={bodyModalRef}
        className={`modal-body-conditions transition-scrollbar ${getClasses({
          "hide-scrollbar": !hideScrollBar,
        })}`}
      >
        <h2>
          {`${countSelectedItems}
              ${t(
                "customers.demandsCustomers.conditionsModal.keyWithCount" as NestedKeys<
                  Translation,
                  "."
                >,
                { count: countSelectedItems }
              )}`}
        </h2>
        <div className="filters-line">
          <TextInput
            value={valueInput}
            onChange={setsValueInput}
            placeHolder={t("customers.demandsCustomers.conditionsModal.search")}
          />
          <div className="select-filter">
            <Button
              onClick={() => setSelectedItems([])}
              className={"bg-transparent "}
              noBorder
              color="tertiary"
            >
              {t("customers.demandsCustomers.conditionsModal.reset")}
            </Button>
            <CustomSelect
              classNameMenuButton="select-group-conditions"
              selected={selectedItems}
              checkbox={true}
              onChange={(item) => {
                setSelectedItems(item);
              }}
              placeholder={
                "customers.demandsCustomers.conditionsModal.selectPlaceholder"
              }
              labels={areasFilters}
            />
          </div>
        </div>
        <div className="chips-line">
          {selectedItems.map(({ value, label }, index) => (
            <div key={index} className="single-chip">
              <Chip
                onClose={() =>
                  setSelectedItems(
                    selectedItems.filter(
                      ({ value: valueSelectedItem }) =>
                        valueSelectedItem !== value
                    )
                  )
                }
                label={t(label)}
                id={index}
              />
            </div>
          ))}
        </div>
        {RenderConditionsFiltered()}
      </div>
    </>
  );
};

export default BodyModalConditions;
