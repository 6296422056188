import Tooltip from "../../../../components/Tooltip/Tooltip";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import React, { FC, useEffect, useMemo, useState } from "react";
import { Form, FormReturnType } from "../../../../hooks/useForm";
import { Field } from "../../../../models/CustomForm";
import {
  formatCurrency,
  getNestedValueFromString,
} from "../../../../Utils/fuctions";
import "./TextInput.scss";
import { useI18n } from "../../../../hooks/useI18n";

interface ITextInput {
  form: Partial<Form<any>>;
  setForm: any;
  data: Field<any>;
  handleError: FormReturnType<any>["handleError"];
  error: string | undefined;
}

const TextInput: FC<ITextInput> = ({
  handleError,
  data: {
    isRequired,
    label,
    type,
    disabled = false,
    column = 4,
    offset = 0,
    key,
    placeholder,
    tooltip,
    maxColumn,
    typeField,
  },
  form,
  setForm,
  error,
}) => {
  const [valueForm, setvalueForm] = useState();
  const { t } = useI18n();
  useEffect(() => {
    setvalueForm(getNestedValueFromString(key, form));
    // eslint-disable-next-line
  }, [form]);

  const handleChangeDate = (date: string | number) => {
    if (typeField === "currency" && (date as string).length > 14) return;
    const value = {
      number:
        date === "" || date === undefined
          ? ""
          : typeField === "currency"
          ? parseInt(date.toString().replace(/\./g, ""))
          : typeField !== "decimal"
          ? parseInt(date as string)
          : Number(date),
      text: date,
    }?.[type as "number" | "text"];
    setForm(key, value ?? date);
  };

  const textMemo = useMemo(() => {
    const valueInput = () => {
      const currentValueInput = getNestedValueFromString<string | number>(
        key,
        form
      );
      if (!currentValueInput && currentValueInput !== 0) return "";
      return typeField === "currency"
        ? formatCurrency(
            "EUR",
            Number(currentValueInput.toString().replace(/\./g, "")),
            false
          ).trimEnd()
        : currentValueInput ?? "";
    };

    const getTypeInput = () =>
      ({
        number: typeField === "currency" ? "currency" : "number",
        text: "text",
      }?.[type as "number" | "text"] || "text");

    return (
      <>
        <div className={`col-lg-${column}`}>
          <div
            className={`form-group offset-lg-${offset} ${
              error ? "is-invalid" : ""
            }`}
          >
            <label
              className={`form-control-label  ${
                isRequired ? "title-red" : ""
              } `}
              htmlFor="name"
            >
              {`${t(label)} ${isRequired ? "*" : ""}`}
              {tooltip?.label && (
                <span className="ms-1">
                  <Tooltip label={t(tooltip.label)}>
                    <QuestionOutlineIcon cursor={"pointer"} />
                  </Tooltip>
                </span>
              )}
            </label>
            <input
              disabled={disabled}
              min={type === "number" ? 0 : "initial"}
              onWheel={(e) => e.currentTarget.blur()}
              type={getTypeInput()}
              value={valueInput()}
              step={"any"}
              onChange={({ target: { value } }) => {
                handleChangeDate(value);
              }}
              onFocus={() => handleError(key, "", false)}
              className={`form-control p-md input-text`}
              placeholder={t(placeholder ?? "")}
            />
            {error && (
              <div className="invalid-feedback">
                <div>{error}</div>
              </div>
            )}
          </div>
        </div>
        {maxColumn && <div className={`col-lg-${maxColumn}`} />}
      </>
    );
    // eslint-disable-next-line
  }, [valueForm, error, t]);

  return <>{textMemo}</>;
};

export default TextInput;
