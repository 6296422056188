import React, { FC, useEffect, useState } from "react";
import "./AddressInput.scss";

import { Form, FormReturnType } from "../../../../hooks/useForm";
import { Field } from "../../../../models/CustomForm";
import { getNestedValueFromString } from "../../../../Utils/fuctions";
import getClasses from "../../../../Utils/getClasses";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "../../GoogleInput";
import { Styles, OptionTypeBase } from "react-select";
import { apiKeyGoogleMaps } from "../../../../environment/environment";
import { useI18n } from "../../../../hooks/useI18n";


interface IAddressInput {
  form: Partial<Form<any>>;
  setForm: any;
  data: Field;
  handleError: FormReturnType<any>["handleError"]
  error: string | undefined;
}

const AddressInput: FC<IAddressInput> = ({
  handleError,
  data: {
    column = 4,
    offset = 0,
    isRequired,
    label,
    maxColumn,
    value,
    key,
    showIf = true,
    showIfKey,
  },
  form,
  setForm,
  error,
}) => {
  const [searchTerm, setSearchTerm] = useState<any>();

  const setKeysForm = (obj: Partial<Record<"street" | "unity" | "state" | "country" | "city", any>>) => {
    Object.entries(obj).forEach(([key, value]) => {
      setForm(key, value);
      handleError(key, "", false);
    })
  }
  const { t } = useI18n();
  useEffect(() => {
    if (!searchTerm) return;

    geocodeByPlaceId(searchTerm?.value.place_id).then((results) => {
      if (results.length > 0) {
        const { lat, lng } = results[0].geometry.location;
        setForm("latitude", lat());
        setForm("longitude", lng());
        handleError("latitude", "", false)
        handleError("longitude", "", false)
      }
      ["street", "city", "state", "country", "unity"].forEach((field) => {
        setForm(field, "");
      });

      if (searchTerm.value.terms.length === 4) {
        let [
          { value: street },
          { value: city },
          { value: state },
          { value: country },
        ] = searchTerm.value.terms;
        const streetSplitted: string[] = street.split(",");
        if (streetSplitted.length > 1) {
          setForm("unity", streetSplitted[1].trim());
          street = streetSplitted[0].trim();
        }
        setKeysForm({street,city, state, country})
      } else if (searchTerm.value.terms.length === 5) {
        const [
          { value: street },
          { value: unity },
          { value: city },
          { value: state },
          { value: country },
        ] = searchTerm.value.terms;
        setKeysForm({street,city, state, country, unity})

      } else if (searchTerm.value.terms.length === 3) {
        const [{ value: city }, { value: state }, { value: country }] =
          searchTerm.value.terms;
          setKeysForm({city, state, country})

      }
    });
    // eslint-disable-next-line
  }, [searchTerm]);

  const defaultValue = () => {
    const street = getNestedValueFromString("street", form) || "";
    const unity = getNestedValueFromString("unity", form) || "";
    const city = getNestedValueFromString("city", form) || "";
    const state = getNestedValueFromString("state", form) || "";
    const country = getNestedValueFromString("state", form) || "";
    return `${getClasses({
      [`${street},`]: street,
      [`${unity},`]: unity,
      [`${city},`]: city,
      [`${state},`]: state,
      [`${country},`]: country,
    })}`;
  };

  const customStyles: Partial<Styles<OptionTypeBase, false>> = {
    option: (provided, state) => ({
      ...provided,
      // color: state.isSelected ? 'red' : 'blue',
      backgroundColor: state.isSelected ? '#ECEFFD' : 'white',
      lineHeight: "1.5rem",
      fontWeight: '700',
      color: "#3A3878",
      padding: 10,
      '&:hover': {
        backgroundColor: "#ECEFFD", 
      },
    }),
    menu: (style, state) => ({
        ...style,
        borderRadius: "0.5rem",
        border: "none",
        boxShadow: "none",
        backgroundColor: "white",
      }),
    control: (provided) => ({
      ...provided,
      border: "none",
      width: "100%",
      borderRadius: "0.5rem",
      height: "50px",
      boxShadow: 'none',
      '&:focus-visible': {
        outline: "none", 
        boxShadow: 'none'
      },
      '&:hover': {
        border: "1px solid #415DEB", 
      },
    }),
    input: (provided) => ({
      ...provided,
      color: "#3A3878",
      '& input': {
        fontSize: '1rem',
        lineHeight: "1.5rem",
        fontWeight: '700',
        color: "#3A3878"
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const props = {
        fontSize: '1rem',
        lineHeight: "1.5rem",
        fontWeight: '200',
        color: "#3A3878"
      }
  
      return { ...provided, opacity, transition, ...props };
    }
  }
  
  
  return  (
    <>
      <div className={`col-lg-${column}`}>
        <div
          className={`form-group offset-lg-${offset} ${
            error ? "is-invalid" : ""
          }`}
        >
          <label className="form-control-label" htmlFor="name">
              {`${t(label)} ${isRequired ? "*" : ""}`}
          </label>
          <GooglePlacesAutocomplete
            selectProps={{
              styles: customStyles,
              defaultInputValue: defaultValue(),
              searchTerm,
              onChange: setSearchTerm,
            }}
            debounce={500}
            apiKey={apiKeyGoogleMaps}
            placeholder={t("calendar.modalAddAppointment.placePlaceHolder")}
            noOptionText={t(
              "calendar.modalAddAppointment.noOptionsAvaible"
            )}
          />
        </div>
      </div>
      {maxColumn && <div className={`col-lg-${maxColumn}`} />}

    </>)
  
};

export default AddressInput;
