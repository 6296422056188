import { ChevronDownIcon } from "@chakra-ui/icons";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { NestedKeys, Nullable } from "advanced-types";
import { FC, useMemo } from "react";
import { Translation } from "../../../../assets/i18n/locals/en";
import { FormReturnType } from "../../../../hooks/useForm";
import { useI18n } from "../../../../hooks/useI18n";
import { RoomPertinenceDetails } from "../../../../models/RealEstate";
import "./RoomPertinenceDetailsInput.scss";
interface ICard {
  data: {
    name: {
      label: NestedKeys<Translation, "."> | "";
      value: string;
      isRequired?: boolean;
    };
    type?: { label: NestedKeys<Translation, "."> | ""; value: string }[];
    count?: { label: NestedKeys<Translation, "."> | ""; value: string }[];
    meters?: boolean;
    dotations?: { label: NestedKeys<Translation, "."> | ""; value: string }[];
  };
  handleError: FormReturnType<any>["handleError"];
  error: string | undefined;
  handleSelect: (
    value: any,
    index: number,
    type: "type" | "equipment" | "count"
  ) => void;
  handleChangeText: (
    value: any,
    index: number,
    type: "dimensions" | "squareMeters"
  ) => void;
  selectedItems: Nullable<RoomPertinenceDetails>[];
  keyForm: string;
  index: number;
  offset: number;
  form: any;
}

export const Card: FC<ICard> = ({
  data,
  handleError,
  error,
  selectedItems,
  keyForm,
  handleSelect,
  handleChangeText,
  index,
  offset,
  form,
}) => {
  const { t } = useI18n();
  const { dotations, type, name, meters = true, count } = data;

  const option = (
    label: NestedKeys<Translation, "."> | "",
    value: string,
    labelSelect: string | undefined
  ) => (
    <div className="menu-flex">
      <span className="capitalize-first-letter">{t(label)}</span>
      {labelSelect === value && <i className="icon icon-checkmark" />}
    </div>
  );

  const renderTypeLabel = (
    options: { label: NestedKeys<Translation, "."> | ""; value: string }[],
    isRequired: undefined | boolean,
    type: "type" | "equipment" | "count",
    name: string,
    index: number
  ) => {
    const errors = error
      ?.split(",")
      .find((error: string) => error.split(":")[0] === name)
      ?.split(":")[1];

    const currentType = selectedItems.find(
      ({ name: nameItem }) => nameItem === name
    );
    const labelSelect = currentType
      ? options.find(({ value }) => value === currentType[type])
      : null;

    return (
      <div className="w-25">
        <div
          className={`form-group offset-lg-${offset} ${
            errors?.includes("type") && type === "type" ? "is-invalid" : ""
          }`}
        >
          <label
            className={`form-control-label capitalize-first-letter ${
              isRequired ? "title-red" : ""
            } `}
            htmlFor="name"
          >
            {}
            {`${
              type === "type"
                ? t("realEstate.forms.description.roomPertinenceDeatils.type")
                : type === "equipment"
                ? t(
                    "realEstate.forms.description.roomPertinenceDeatils.equipment"
                  )
                : "Nr"
            } ${isRequired ? "*" : ""}`}
          </label>
          <Menu>
            <MenuButton
              border={errors?.includes("type") ? "1px solid #b71922" : ""}
              ps={2}
              width={"100%"}
              className="menu-button"
            >
              <div className="container-text-default">
                <span
                  className={`${
                    labelSelect?.label
                      ? "menu-button-value capitalize-first-letter"
                      : "menu-button-default"
                  }`}
                >
                  {labelSelect?.label
                    ? t(labelSelect?.label)
                    : t(
                        "realEstate.forms.description.roomPertinenceDeatils.select"
                      )}
                </span>

                <ChevronDownIcon w={6} h={6} />
              </div>
            </MenuButton>
            <MenuList
              onFocus={() =>
                handleError(
                  keyForm,
                  error
                    ?.split(",")
                    .map((item: string) => {
                      let newItem = item;
                      if (item.split(":")[0] === name) {
                        newItem = newItem.replace("type", "");
                      }

                      return newItem;
                    })
                    .join(),
                  false
                )
              }
              maxHeight={"20rem"}
              overflowY={"scroll"}
              p={0}
              zIndex={1000}
            >
              {options.map(({ label, value }, i) => {
                return (
                  <MenuItem
                    key={i}
                    _hover={{ bg: "transparent" }}
                    p={0}
                    _focus={{ bg: "transparent" }}
                    onClick={() => handleSelect(value, index, type)}
                    className="menu-item"
                  >
                    {option(label, value, labelSelect?.value)}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>

          {errors?.includes("type") && (
            <div className="invalid-feedback">
              <div>
                {t(
                  "realEstate.forms.description.roomPertinenceDeatils.errorType"
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderDimensionText = (
    type: "dimensions" | "squareMeters",
    name: string,
    i: number
  ) => {
    const errors = error
      ?.split(",")
      .find((error: string) => error.split(":")[0] === name)
      ?.split(":")[1];
    const valueInput = selectedItems.find(
      ({ name: nameItem }) => nameItem === name
    );

    return (
      <div className="w-25">
        <div
          className={`form-group ${
            errors?.includes("dimensions") && type === "dimensions"
              ? "is-invalid"
              : ""
          }`}
        >
          <label className="form-control-label" htmlFor="name">
            {`${
              type === "dimensions"
                ? t(
                    "realEstate.forms.description.roomPertinenceDeatils.dimension"
                  )
                : "mq"
            }`}
          </label>
          <input
            value={valueInput ? valueInput[type] ?? "" : ""}
            type={type === "dimensions" ? "string" : "number"}
            onChange={({ target: { value } }) => {
              handleChangeText(value, i, type);
            }}
            onFocus={() => {
              type === "dimensions" &&
                handleError(
                  keyForm,
                  error
                    ?.split(",")
                    .map((item: string) => {
                      let newItem = item;
                      if (item.split(":")[0] === name) {
                        newItem = newItem.replace("dimensions", "");
                      }

                      return newItem;
                    })
                    .join(),
                  false
                );
            }}
            className={`form-control p-md input-text p-0`}
            placeholder={`${type === "dimensions" ? "m x m" : "mq"}`}
          />
          {errors?.includes("dimensions") && type === "dimensions" && (
            <div className="invalid-feedback">
              <div>
                {t(
                  "realEstate.forms.description.roomPertinenceDeatils.errorDimension"
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const cardMemo = useMemo(() => {
    return (
      <div className={`col-xxl-6 col-lg-12 mb-5 container-room-pertinence`}>
        <div className={`card overview-card py-2 ps-3 pe-2 `}>
          <div className="score-container my-2">
            <span className="registry text-primary">{t(name.label)}</span>
          </div>
          <div className="global-result my-0">
            <div className="d-flex justify-content-between">
              {type &&
                renderTypeLabel(
                  type,
                  name.isRequired,
                  "type",
                  name.value,
                  index
                )}
              {count &&
                renderTypeLabel(count, undefined, "count", name.value, index)}
              {meters && renderDimensionText("squareMeters", name.value, index)}
              {renderDimensionText("dimensions", name.value, index)}
              {dotations &&
                renderTypeLabel(
                  dotations,
                  undefined,
                  "equipment",
                  name.value,
                  index
                )}
            </div>
          </div>
        </div>
      </div>
    );
    // eslint-disable-next-line
  }, [form, error]);

  return <>{cardMemo}</>;
};
