export const selectOptionsEn = {
  placeholderSelect: "Select",
  placeholderSelectedElements: "Selected",
  demandStatus: {
    active: "Active",
    canceled: "Canceled",
    concluded: "Conclused",
    lost: "Lost",
    expired: "Expired",
    suspended: "Suspended",
  },
  furnitureStatus: {
    furnished: "Furnished",
    notFurnished: "Non furnished",
    partiallyFurnisched: "Partially furnished",
    partiallyFurnishedKitchen: "Partially furnished kitchen",
  },
  parking: {
    absent: "Absent",
    covered: "Covered",
    open: "Open",
  },
  propertyOptions: {
    INDEPENDENTHOUSE: "Indipendent house",
    INDEPENDENT_HOUSE: "Independent house",
    COMMERCIALOTHER: "Other commercial",
    COMMERCIAL_OTHER: "Other commercial",
    APPARTMENT: "Apartment",
    ATTIC: "Attic",
    BASEMENT: "Basement",
    CASTLE: "Castle",
    CHALET: "Chalet",
    DUPLEX: "Duplex",
    FARM: "Farm",
    FARMHOUSE: "Farmhouse",
    HOUSE_SECTION: "House section",
    HOUSESECTION: "House section",
    LOFT: "Loft",
    MULTI_PROPRIETY: "Multi property",
    MULTIPROPRIETY: "Multi property",
    NEW_CONSTRUCTION: "New construction",
    NEWCONSTRUCTION: "New construction",
    OTHER: "Other",
    RESIDENCE: "Residence",
    ROOM: "Room",
    RUSTIC: "Rustic",
    SEMIDETACHEDVILLA: "Semi detached villa",
    SEMI_DETACHED_VILLA: "Semi detached villa",
    SEMIINDEPENDENTHOUSE: "Semi independent house",
    SEMI_INDEPENDENT_HOUSE: "Semi independent house",
    SINGLE_VILLA: "Single villa",
    SINGLEVILLA: "Single villa",
    TERRACED_HOUSE: "Terraced house",
    TERRACEDHOUSE: "Terraced house",
    VACATION_HOME: "Vacation home",
    VACATIONHOME: "Vacation home",
    VILLA: "Villa",
    VILLA_SECTION: "Portion of villa",
    VILLASECTION: "Portion of villa",
    NOTPROVIDED: "Not provided",
    NOT_PROVIDED: "Not provided",
    DANCECLUB: "Discoteca",
    NON_BUILDING_LAND: "Non building land",
    NONBUILDINGLAND: "Non building land",
    BUILDING_LAND: "Building land",
    BUILDINGLAND: "Building land",
    AGRICULTURAL_LAND: "Agricultural land",
    AGRICULTURALLAND: "Agricultural land",
    COMMERCIAL_LAND: "Commercial land",
    COMMERCIALLAND: "Commercial land",
  },
  propertyOptionsCommercial: {
    FARMHOUSE: "Farmhouse",
    HOTEL: "Hotel",
    COMMERCIAL_ACTIVITY: "Commercial Activity",
    COMMERCIALACTIVITY: "Commercial Activity",
    FARM: "Farm",
    BAR: "Bar",
    CAMPING_AREA: "Camping area",
    CAMPINGAREA: "Camping area",
    CHALET: "Chalet",
    COMMERCIAL_OTHER: "Commercial other",
    COMMERCIALOTHER: "Commercial other",
    DANCE_CLUB: "Dance club",
    DANCECLUB: "Dance club",
    LABORATORY: "Laboratory",
    COMMERCIALLOCAL: "Other commercial",
    COMMERCIAL_LOCAL: "Other commercial",
    STORE: "Store",
    RESTAURANT: "Restaurant",
    SHOW_ROOM: "Show Room",
    SHOWROOM: "Show Room",
    BUILDING: "Bulding",
    OFFICE: "Office",
    NOT_PROVIDED: "Not provided",
    NOTPROVIDED: "Not provided",
    DEPOSIT: "Deposit",
  },
  propertyOptionsLand: {
    NON_BUILDING_LAND: "Non building land",
    BUILDING_LAND: "Building land",
    AGRICULTURAL_LAND: "Agricultural land",
    COMMERCIAL_LAND: "Commercial land",
  },
  currentState: {
    FREE_TO_DEED: "Free to deed",
    LEASED: "Leased",
    UNDER_CONSTRUCTION: "Under construction",
  },
  contractOptions: {
    RENT: "Rent",
    SALE: "Sale",
  },
  energyClass: {
    A1: "A1",
    A2: "A2",
    A3: "A3",
    A4: "A4",
    B: "B",
    C: "C",
    D: "D",
    E: "E",
    F: "F",
    G: "G",
    NOT_AVAILABLE: "Not Available",
  },
  energyClassOld: {
    A_PLUS: "A",
    B: "B",
    C: "C",
    D: "D",
    E: "E",
    F: "F",
    G: "G",
    EVALUATED: "Evaluated",
    NOT_APPLICABLE: "Not Applicable",
    NOT_DECLARED: "Not declared",
  },
  performanceUnit: {
    SQUARE_METERS: "Square Meters",
    CUBIC_METERS: "Cubic Meters",
  },
  energyPerformance: {
    LOW: "Low",
    MEDIUM: "Medium",
    HIGH: "High",
  },
  soundCLass: {
    A: "A",
    B: "B",
    C: "C",
    D: "D",
  },
  unit: {
    SQUARE_METERS: "Square Meters",
    CUBIC__METERS: " Cubic Meters",
  },
  state: {
    ACTIVE: "Active",
    REMOVED_FROM_MARKET: "Removed From Market",
  },
  mediation: {
    EXCLUSIVE: "Exclusive",
    NOT_EXCLUSIVE: "Not exclusive",
  },
  roomSeparator: {
    WALLS: "Walls",
    OPEN_SPACE: "Open Space",
    PANELS: "Panels",
  },
  fee: {
    YEARLY: "Yearly",
    BIMONTHLY: "Bimonthly",
    MONTHLY: "Monthly",
    WEEKLY: "Weekly",
    SEASONAL: "Seasonal",
  },
  context: {
    IN_MALL: "In mall",
    OFFICE_COMPLEX: "Office complex",
    MEZZANINE: "Mezzanine",
    STREET: "On Street",
    OTHER: "Other",
  },
  weeklyRest: {
    SATURDAY: "Saturday",
    SUNDAY: "Sunday",
  },
  landKind: {
    FLAT: "Flat",
    STEEP: "Steep",
  },
  detailsFurnitureType: {
    NOT_FURNISHED: "Not Furnished",
    FURNISHED: "Furnished",
    PARTIALLY_FURNISHED: "Partially Furnished",
    PARTIALLY_FURNISHED_KITCHEN: " Partially furnished kitchen",
  },
  availabilityType: {
    PRESENT: "Present",
    ABSENT: "Absent",
  },
  detailsConservationType: {
    GOOD: "Good",
    NEEDS_CLEANING: "Needs cleaning",
    NEEDS_RESTRUCTURING: "Needs restructuring",
    DECENT: "Decent",
    ROUGH: "Rough",
    MEDIOCRE: "Mediocre",
    GREAT: "Great",
    RESTRUCTURED: "Restructured",
  },
  detailsHabitabilityType: {
    IMMEDIATE: "Immediate Habitality",
    NOT_HABITABLE: "Not habitable",
  },
  rating: {
    STANDARD: "Standard",
    LOW: "Low",
    HIGH: "High",
    EXCELLENT: "Excellent",
  },
  rentDetailsRequestedWarranty: {
    NONE: "Nessuna",
    SECURITY_DEPOSIT: "Security deposit",
    INSURANCE_POLICY: "Insurance policy",
    BANK_GUARANTEE: "Bank guarantee",
  },
  rentDetailsContractType: {
    THREE_PLUS_TWO: "3+2",
    THREE_PLUS_TWO_TAX: "3+2 Cedolare",
    FOUR_PLUS_TWO: "4+2",
    FOUR_PLUS_FOUR: "4+4",
    FOUR_PLUS_FOUR_TAX: "4+4 Cedolare",
    SIX_PLUS_TWO: "6+2",
    SIX_PLUS_SIX: "6+6",
    ROOM: "Room",
    TRANSITIONAL: "Transitional",
    TRANSITIONAL_TAX: "Transitional Tax",
    NOT_PROVIDED: "Not Provided",
  },
  floorOptions: {
    ATTIC: "Attic",
    BASEMENT: "Basement",
    BUILDING: "Building",
    EARTH_RETAINING_WALL: "Earth retaining wall",
    GROUND_FLOOR: "Ground floor",
    MULTISTOREY: "Multistorey",
    NOBLE_FLOOR: "Noble floor",
    TOP_FLOOR: "Top floor",
    MEZZANINE: "Mezzanine",
    ON_TWO_LEVELS: "On two levels",
  },
  elevatorOprions: {
    YES: "Yes",
    NO: "No",
  },
  garden: {
    ABSENT: "Absent",
    IN_COMMON: "In common",
    PRIVATE: "Private",
  },
  kitchen: {
    AT_SIGHT: "At sight",
    HABITABLE: "Habitable",
    KITCHENETTE: "Kitchenette / Small kitchen",
    ABSENT: "Absent",
    FOOD_COOK: "Food Cook",
    PRESENT: "Present",
    SEMI_HABITABLE: "Semi habitable",
  },
  box: {
    SINGLE: "Single",
    DOUBLE: "Double",
    TRIPLE: "Triple",
    QUADRUPLE: "Quadruple",
    SHED: "Shed",
    ABSENT: "Absent",
  },
  dotation: {
    EQUIPPED_AND_FURNISHED: "Equipped eand furnished",
    EQUIPPED_NOT_FURNISHED: "Equipped but nont furnished",
    NOT_EQUIPPED_FURNISHED: "Not equipped and not furnished",
  },
  roomPertinence: {
    ABSENT: "Absent",
    PRESENT: "Present",
  },
  architecturalBarriers: {
    ABSENT: "Absent",
    PRESENT: "Present",
  },
  position: {
    AGRICULTURAL_AREA: "Agricultural area",
    BOOMING_AREA: "Booming area",
    CENTRAL: "Central",
    HIGH_TRAFFIC_STREET: "high traffic street",
    INDUSTRIAL_AREA: "Industrial area",
    ISOLATED: "Isolated",
    MALL: "Mall",
    MIXED_AREA: "Mixed area",
    PEEP_AREA: "Peep area",
    PERIPHERAL: "Peripheral",
    RESIDENTIAL_AREA: "Residential area",
    SEAFRONT: "Seafront",
    SEMICENTRAL: "Semicentral",
    SERVED_AREA: "Served area",
    TOURIST: "Tourist area",
  },
  detailsQualityType: {
    CLASSIC: "Classic",
    VINTAGE: "Vintage",
    MODERN_MEDIUM: "Modern medium",
    MODERN_CLASSIC: "Modern classic",
    POPULAR: "Popular",
    CLASSIC_ELEGANT: "Classic elegant",
    HISTORIC: "Historic",
  },
  heatingDetailsSystemType: {
    RADIATOR: "With radiators / radiant panels",
    FLOOR: "To floor / skirting boards",
    AIR: "Air",
    HEATER: "Heater",
  },
  heatingDetailsType: {
    AUTONOMOUS: "Autonomous",
    CENTRALIZED: "Centralized",
    CENTRALIZED_WITH_METER: "Centralized with meter",
    AIR_CONDITIONED: "Air conditioned",
    ABSENT: "Absent",
  },
  heatingDetailsFuelType: {
    METHANE: "Methane",
    DIESEL: "Diesel",
    GPL: "GPL",
    SOLAR_PANELS: "Solar Panels",
    AIR_ELECTRIC: "Air / Eletric",
    PELLET: "Pellet",
    WOOD: "Wood",
    WOOD_CHIPS: "Wood chips",
    OTHER: "Other",
  },
  additionalRooms: {
    ROOF_TERRACE: "Roof terrace",
    OTHER: "Other",
    AMERICAN_ENSUITE: "American Ensuite",
    ANTEROOM: "Anteroom",
    WARDROBE: "Wardrobe",
    DRYING_ROOM: "Drying room",
    BATHROOM: "Bathroom",
    BALCONY: "Balcony",
    BAR: "Bar",
    BOX: "Box",
    KIDS_ROOM: "Kids room",
    ROOM_WITH_BATHROOM: "Room with bathroom",
    BEDROOM: "Bedroom",
    SECURITY_ROOM: "Security room",
    FAMILY_ROOM: "Family room",
    DOUBLE_ROOM: "Double room",
    CELLAR: "Cellar",
    CORRIDOR: "Corridor",
    KITCHEN: "Kitchen",
    HABITABLE_KITCHEN: "Habitable Kitchen",
    GAMES_ROOM: "Games room",
    GARDEN: "Garden",
    WINTER_GARDEN: "Winter garden",
    ENTRANCE: "Entrance",
    LABORATORY: "Laboratory",
    LAUNDRY: "Laundry",
    WOODSHED: "Woodshed",
    LIBRARY: "Library",
    TOOL_ROOM: "Tool room",
    BOILER_ROOM: "Bolier room",
    TANK_ROOM: "Tank room",
    SHOWER_ROOM: "Shower room",
    LOFT: "Loft",
    LODGE: "Lodge",
    LUNCH_ROOM: "Lunch room",
    WAREHOUSE: "Warehouse",
    MAID_ROOM: "Maid room",
    GYM: "Gym",
    PARTY_ROOM: "Perty room",
    PARKING_PLACE: "Parking place",
    PRIVATE_ROOM: "Private room",
    RESTING_ROOM: "Resting room",
    CLOSET: "Closet",
    LIVING_ROOM: "Living room",
    DOUBLE_LIVING_ROOM: "Double living room",
    LOUNGE: "Lounge",
    SAUNA: "Sauna",
    GREEN_HOUSE: "Green house",
    SOLARIUM: "Solarium",
    L_ROOM: "L-shaped room",
    T_ROOM: "T-shaped room",
    U_ROOM: "U-shaped room",
    Z_ROOM: "Z-shaped room",
    STUDIO: "Studio",
    TERRACE: "Terrace",
    DINING_ROOM: "Dining room",
    TOILETTE: "Toilette",
    TOY_ROOM: "Toy room",
    GENERAL: "General",
    PLANIMETRY: "Planimetry",
  },
  rentDetailsContractRegistration: {
    OWNER: "Charged to the owner",
    AGENCY: "Cherged to the agency",
  },
  otherInfoLabel: {
    AIR_CONDITIONING: "Air conditioning",
    AIR_CONDITIONING_TYPE: {
      ABSENT: "Absent",
      AUTONOMOUS: "Autonomous",
      CENTRALIZED: "Centralized",
      PREDISPOSITION: "Predisposition",
      NOT_PROVIDED: "Not provided",
    },
    SHOWER: "Shower",
    ALUMINIUM_FIXTURES: "Alumium fixtures",
    PARQUET: "Parquet",
    CLOSET: "Closet",
    JACUZZI: "Jacuzzi",
    POOL: "Pool",
    CHIMNEY: "Chimmey",
    AT_AUCTION: "At auction",
    WOOD_FIXTURES: "Wood fixtures",
    SHUTTERS: "Shutters",
    SUSPENDED_SANITARY: "Suspended sanitary",
    LAKE_VIEW: "Lake view",
    TERRACE: "Terrace",
    SAFETY_BOX: "Safety box",
    ALARM: "Alarm",
    ANTIFIRE: "antifire",
    REFRIGERATOR: "Refrigeratore",
    GENERATOR: "Continuity generator",
    INDEPENDENT_ENTRANCE: "Independent entrance",
    ALARM_PREDISPOSED: "Alarm predisposed",
    SEA_VIEW: "Sea view",
    PORCH: "Porch",
    PHONE_SYSTEM: "Phone system",
    TV_ANTENNA: "TV antenna",
    TV_STATUS: {
      UNPROVIDED: "Not provided",
      CONDOMINIUM: "Condominium",
      AUTONOMOUS: "Autonomous",
    },
    ELECTRICAL_SYSTEM: "Electrical system",
    ELECTRICAL_SYSTEM_STATUS: {
      ABSENT: "Absent",
      LEGAL: "Regular",
      NOT_LEGAL: "Not regular",
    },
    SAT_TV: "Sat TV",
    WATER_PIPES: "Water pipes",
    FACING_STREET: "Facing street",
    PHONE_LINES: "Phone lines",
    DIVISIBLE_LOT: "Divisible lot",
    METHANE: "Methane",
  },
  REAL_ESTATE_STATE: {
    ACTIVE: "Active",
    REMOVED_FROM_MARKET: "Removed From Market",
  },
  APPROVAL_STATUS: {
    DRAFT: "Draft",
    APPROVED: "Actived",
    PENDING: "Pre-activation",
    PUBLISHED: "Actived",
    SOLD: "Sold",
    SUSPENDED: "Suspended",
    SOLDSUSPENDED: "Sold with suspended",
  },
};
