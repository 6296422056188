import {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Form, FormReturnType } from "../../../../hooks/useForm";
import { Field } from "../../../../models/CustomForm";
import { filters } from "./Filters";
import "./ConditionsDemandInput.scss";
import { useDisclosure } from "@chakra-ui/react";
import { useI18n } from "../../../../hooks/useI18n";
import Button from "../../Button/Button";
import Modal from "../../Modal/Modal";
import BodyModalConditions, { IConditionOnClick } from "./BodyModalConditions";
import {
  getNestedValueFromString,
  revereKeysWithValue,
} from "../../../../Utils/fuctions";
import ConditionsSelect from "./ConditionsSelect/ConditionsSelect";
import { ConditionElement } from "../../../../models/demandCustomer";
import { countTypeConditionsDemandCustomer } from "../../../../forms/realeEstate/selections";
interface IConditionsDemandInput {
  form: Partial<Form<any>>;
  setForm: any;
  data: Field<any>;
  handleError: FormReturnType<any>["handleError"];
}

export interface IConditions {
  required: IConditionOnClick[];
  optional: IConditionOnClick[];
}

const RenderCondition = (
  allConditions: IConditions,
  typeConditions: keyof IConditions,
  setCondition: Dispatch<SetStateAction<IConditions>>
) => {
  const conditions = allConditions[typeConditions];
  const { t } = useI18n();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedConditions, setSelectedConditions] =
    useState<IConditions>(allConditions);

  const addCondition = (newCondition: IConditionOnClick) => {
    setSelectedConditions((prev) => ({
      ...prev,
      [typeConditions]: selectedConditions[typeConditions].some(
        ({ name }) => name === newCondition.name
      )
        ? prev[typeConditions].filter(({ name }) => name !== newCondition.name)
        : [...prev[typeConditions], newCondition],
    }));
  };

  useEffect(() => {
    if (isOpen) setSelectedConditions(allConditions);
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <>
      {conditions.length === 0 ? (
        <>
          <Button icon="icon-add" color="tertiary" onClick={onOpen}>
            <span>{t("customers.demandsCustomers.addConditionsButton")}</span>
          </Button>
        </>
      ) : (
        <>
          <div className="position-conditions">
            {conditions.map((item, i) => (
              <Fragment key={i}>
                <ConditionsSelect
                  onClose={({ name: nameSelectedItem }) => {
                    setCondition((prev) => ({
                      ...prev,
                      [typeConditions]: prev[typeConditions].filter(
                        ({ name }) => name !== nameSelectedItem
                      ),
                    }));
                  }}
                  item={item}
                  onClick={(selectedItems) => {
                    setCondition((prev) => ({
                      ...prev,
                      [typeConditions]: prev[typeConditions].map((condition) =>
                        condition.name === item.name
                          ? { ...condition, selectedItems }
                          : condition
                      ),
                    }));
                  }}
                  check={false}
                  selectedItem={item.selectedItems}
                />
              </Fragment>
            ))}
          </div>
          <div className="position-button-add">
            <Button icon="icon-add" circle={true} onClick={onOpen} />
          </div>
        </>
      )}
      <Modal
        size={"3xl"}
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        title={t("customers.demandsCustomers.selectConditions")}
      >
        <BodyModalConditions
          condtions={selectedConditions}
          selectedConditions={conditions}
          onClick={addCondition}
          typeConditions={typeConditions}
          setSelectedConditions={setSelectedConditions}
        />
        <div className="container-footer-conditions">
          <Button
            onClick={() => {
              onClose();
            }}
            color="tertiary"
          >
            <span>{t("customers.demandsCustomers.cancel")}</span>
          </Button>
          <Button
            onClick={() => {
              setCondition(selectedConditions);
              onClose();
            }}
          >
            <span>{t("customers.demandsCustomers.confirm")}</span>
          </Button>
        </div>
      </Modal>
    </>
  );
};

const ConditionsDemandInput: FC<IConditionsDemandInput> = ({
  setForm,
  form,
}) => {
  const formatInitialValueInput = (type: "required" | "optional") => {
    const getSelectedItems = (
      value: number | string[] | boolean | string,
      name: ConditionElement["name"]
    ) => {
      if (typeof filters[name].selectType === "number") {
        const numberValue =
          countTypeConditionsDemandCustomer(15)[
            `${value}` as keyof typeof countTypeConditionsDemandCustomer
          ];
        return { label: numberValue, value: numberValue };
      } else if (typeof filters[name].selectType === "boolean") {
        return {
          label:
            value === "*"
              ? "*"
              : `selectOptions.elevatorOprions.${value ? "YES" : "NO"}`,
          value,
        };
      } else {
        const key = revereKeysWithValue(
          filters[name].selectType as Record<any, any>
        );
        return (value as string[]).map((valueCondition) => ({
          label: key[value as keyof typeof key],
          value: valueCondition,
        }));
      }
    };

    const data = getNestedValueFromString<
      { name: ConditionElement["name"]; value: number | string[] }[]
    >(`conditions/${type}`, form);
    return data.map(({ name, value }) => ({
      ...filters[name],
      name: name,
      selectedItems: getSelectedItems(value, name),
    }));
  };
  const [conditions, setConditions] = useState<IConditions>(() => ({
    required: formatInitialValueInput("required") as IConditionOnClick[],
    optional: formatInitialValueInput("optional") as IConditionOnClick[],
  }));

  const { t } = useI18n();
  const formatConditionsForForm = (type: keyof IConditions) =>
    conditions[type]
      .filter((condition) => "selectedItems" in condition)
      .map(({ name, selectedItems }) => ({
        name,
        value: Array.isArray(selectedItems)
          ? selectedItems.map(({ value }) => value)
          : selectedItems?.value,
      }))
      .filter(({ value }) =>
        Array.isArray(value) ? Boolean(value.length) : true
      );

  useEffect(() => {
    (["required", "optional"] as const).forEach((type) => {
      setForm(`conditions/${type}`, formatConditionsForForm(type));
    });
    // eslint-disable-next-line
  }, [conditions]);

  return (
    <div className="container-conditions">
      <h2>{t("customers.demandsCustomers.addConditions")}</h2>
      <h4 className="subtitle-conditions-demand">
        {t("customers.demandsCustomers.necessaryConditions")}
      </h4>
      <div className="background">
        {RenderCondition(conditions, "required", setConditions)}
      </div>
      <h4 className="subtitle-conditions-demand">
        {t("customers.demandsCustomers.optionalConditions")}
      </h4>
      <div className="background">
        {RenderCondition(conditions, "optional", setConditions)}
      </div>
    </div>
  );
};

export default ConditionsDemandInput;
