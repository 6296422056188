import { ChevronDownIcon } from "@chakra-ui/icons";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { NestedKeys } from "advanced-types";
import React, { Fragment, useState } from "react";
import { Translation } from "../../../assets/i18n/locals/en";
import { useI18n } from "../../../hooks/useI18n";
import getClasses from "../../../Utils/getClasses";
import "./CustomSelect.scss";

interface ICustomSelect<T extends { label: string; value: any }[], K> {
  selected?: (K extends true ? T : T[0]) | null;
  options?: {
    placeholderCounter?: boolean;
  };
  labels: T;
  error?: boolean;
  firstLabel?: {
    label: NestedKeys<Translation, "."> | "";
    value: string | null;
  };
  onChange: (item: K extends true ? T : T[0]) => void;
  onFocus?: () => void;
  placeholder: NestedKeys<Translation, ".">;
  classNameSelectedItem?: string;
  classNameMenuButton?: string;
  checkbox?: K;
  fullWidth?: boolean;
  showSelectedItems?: boolean;
}

const CustomSelect = <
  T extends { label: string; value: any }[] = any,
  K extends boolean = false
>({
  selected,
  options,
  classNameSelectedItem,
  classNameMenuButton = "",
  labels,
  fullWidth = false,
  onChange,
  onFocus,
  error = false,
  placeholder,
  firstLabel,
  checkbox,
  showSelectedItems,
}: ICustomSelect<T, K>) => {
  const { t } = useI18n();
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (item: K extends true ? T : T[0]) => {
    if (checkbox && Array.isArray(selected)) {
      if (
        !(selected! as T).some(({ value }) => value === (item as T[0]).value)
      ) {
        onChange([...(selected as T), item] as K extends true ? T : T[0]);
      } else {
        onChange(
          (selected! as T).filter(
            ({ value }) => value !== (item as T[0]).value
          ) as K extends true ? T : T[0]
        );
      }
    } else {
      onChange(item);
      setIsOpen(false);
    }
  };

  const areAllOptionsSelected = () => {
    if (!Array.isArray(selected)) return false;
    return (selected as T).length === labels.length;
  };

  const optionState = (
    { label, value }: T[0],
    variable: ICustomSelect<T, K>["selected"]
  ) => (
    <div className="menu-flex-custom-select hover custom-select">
      {Array.isArray(selected) ? (
        <div key={label} className={`radio`}>
          <div className={`container-square`}>
            <i className="icon icon-checkbox-custom-select icon-checkmark m-0"></i>
            <div
              className={`square ${
                selected.some((item) => item.value === value)
                  ? "checked"
                  : "unchecked"
              }`}
            ></div>
          </div>
          <span className="capitalize-first-letter label">
            {t(label as NestedKeys<Translation, ".">)}
          </span>{" "}
        </div>
      ) : (
        <>
          <span className="capitalize-first-letter">
            {t(label as NestedKeys<Translation, ".">)}
          </span>
          {!Array.isArray(variable) && variable?.value === value && (
            <i className="icon icon-checkmark" />
          )}
        </>
      )}
    </div>
  );

  const getPlaceHolder = () => {
    if (
      showSelectedItems &&
      Array.isArray(selected) &&
      areAllOptionsSelected()
    ) {
      return (
        <span
          className={`menu-item ps-0 white-space-nowrap truncate text-start d-inline me-2 capitalize-first-letter ${classNameSelectedItem}`}
        >
          {t("customers.firstItemFilter")}
        </span>
      );
    }

    const multiSelect = () => {
      if (options?.placeholderCounter) {
        switch ((selected! as T).length) {
          case 0:
            return t(placeholder);
          case 1:
            return `${t(
              (selected! as T)[0].label as NestedKeys<Translation, ".">
            )}`;
          default:
            return `${(selected! as T).length} ${t(
              "selectOptions.placeholderSelectedElements"
            )}`;
        }
      } else {
        return (selected! as T)
          .map((item) => t(item.label as NestedKeys<Translation, ".">))
          .join(", ");
      }
    };

    return (
      <>
        {selected ? (
          <span
            className={`menu-item ps-0 white-space-nowrap truncate text-start d-inline me-2 capitalize-first-letter ${getClasses(
              {
                placeholder:
                  Array.isArray(selected) && !Boolean(selected.length),
              }
            )} ${classNameSelectedItem}`}
          >
            {Array.isArray(selected)
              ? multiSelect()
              : t(selected.label as NestedKeys<Translation, ".">)}
          </span>
        ) : (
          <span className="menu-item ps-0 white-space-nowrap truncate d-inline text-start me-2 capitalize-first-letter">
            {t(placeholder)}
          </span>
        )}
      </>
    );
  };

  return (
    <>
      <Menu
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        closeOnSelect={!checkbox}
      >
        <MenuButton
          border={error ? "1px solid #b71922" : "initial"}
          className={`menu-button ${classNameMenuButton}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="d-flex w-100 align-items-center justify-content-between">
            {getPlaceHolder()}
            <ChevronDownIcon fontSize={24} />
          </div>
        </MenuButton>
        <MenuList
          p={0}
          pe={3}
          ps={3}
          zIndex={1000}
          onFocus={() => onFocus && onFocus()}
        >
          <div className="container-custom-select-checkbox">
            {labels?.map((item, index) => (
              <Fragment key={index}>
                {index === 0 && firstLabel && (
                  <MenuItem
                    key={-1}
                    _hover={{ bg: "transparent" }}
                    p={0}
                    _focus={{ bg: "transparent" }}
                    onClick={() =>
                      onChange(firstLabel as K extends true ? T : T[0])
                    }
                    className="menu-item"
                  >
                    <div className="menu-flex-custom-select">
                      {checkbox ? (
                        <div className={`checkbox`}>
                          <div className={`container-square me-1 `}>
                            <i className="icon icon-checkmark icon-square-checked"></i>
                            <div
                              className={`square ${
                                !selected ? "checked" : "unchecked"
                              }`}
                            ></div>
                          </div>
                          <span className="checkbox-label text-capitalize">
                            <span className={`me-1 pointer `}>
                              {t(firstLabel.label)}
                            </span>
                          </span>
                        </div>
                      ) : (
                        <>
                          <span className="capitalize-first-letter">
                            {t(firstLabel.label)}
                          </span>
                          {!selected && <i className="icon icon-checkmark" />}
                        </>
                      )}
                    </div>
                  </MenuItem>
                )}
                <MenuItem
                  key={index}
                  _hover={{ bg: "transparent" }}
                  p={0}
                  _focus={{ bg: "transparent" }}
                  onClick={() => handleClick(item as K extends true ? T : T[0])}
                  className="menu-item"
                >
                  {optionState(item, selected)}
                </MenuItem>
              </Fragment>
            ))}
          </div>
        </MenuList>
      </Menu>
    </>
  );
};

export default CustomSelect;
