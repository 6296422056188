import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { IPriceForm, priceForm } from "../../../../forms/realeEstate/price";
import { useEndpoints } from "../../../../hooks/useEndpoints";
import { useRealEstateForm } from "../../../../hooks/useRealEstateForm";
import { ICustomForm } from "../../../../models/CustomForm";
import { RootState } from "../../../../redux/reducers";
import { setIsSubmit } from "../../../../redux/reducers/realEstate";
import CustomForm from "../../../../shared/components/CustomForm/CustomForm";
import Loader from "../../../../shared/components/Loader/Loader";
import { useToastAlert } from "../../../../shared/components/ToastAlert/ToastAlert";
import { DURATION_TOAST } from "../../../../Utils/costants";
import { addValidationQuery, getQueryUrl } from "../../../../Utils/fuctions";
import "./Price.scss";
import { RoutesRouter } from "../../../../models/Routes";
import { priceFormLand } from "../../../../forms/realeEstate/Land/priceLand";

interface IPrice {}

const Price: FC<IPrice> = () => {
  const { pathname } = useLocation();
  const [editMode] = useState(pathname.includes("edit"));
  const { putRealEstate } = useEndpoints();
  const absoluteNavigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const isValidation = searchParams.get("validation");
  const {
    isLoading,
    initialValue,
    generateForm,
    id,
    setRealStateRedux,
    navigate,
    goBackPath,
    isLand,
  } = useRealEstateForm<IPriceForm>("price", "price");

  const { isSubmit } = useSelector(({ realEstate }: RootState) => realEstate);

  const toastAlert = useToastAlert();

  const { t } = useTranslation();

  const { required, greaterThanZero } = {
    required: t("errorMessagesForm.required"),
    greaterThanZero: t("agency.form.greaterThanZero"),
  };
  const { queryTable } = useSelector(({ realEstate }: RootState) => realEstate);

  const handleSubmit = async (
    form: any,
    next: ICustomForm["next"],
    isSame: boolean,
    isBack: boolean,
    checkErrors: boolean
  ) => {
    try {
      if (!Boolean(isValidation)) {
        if (!isSame) {
          await putRealEstate(id!, "price", form);
          setRealStateRedux(form, "price");
        }
        isSubmit.submit && navigate(addValidationQuery(next, false));
        !isSubmit.submit &&
          absoluteNavigate(
            `${RoutesRouter.realEstates}${getQueryUrl(queryTable?.params)} `
          );
        isBack && navigate(goBackPath());
        return;
      }
      if (isSame && isBack) {
        navigate(goBackPath());
        return;
      }
      if (editMode && checkErrors) {
        await putRealEstate(id!, "price", form);
        setRealStateRedux(form, "price");
        navigate(
          isBack
            ? goBackPath()
            : addValidationQuery(next, Boolean(isValidation))
        );
        return;
      }
    } catch (e) {
      toastAlert({
        message: "Problema inatteso",
        subMessage: "Ops! Errore imprevisto!",
        type: "error",
        duration: DURATION_TOAST,
      });
    }
  };

  const form = useMemo(
    () =>
      generateForm(
        isValidation
          ? {
              requestedPrice: { required, greaterThanZero },
              estimatedValue: { required, greaterThanZero },
            }
          : {}
      ),
    // eslint-disable-next-line
    [initialValue]
  );

  const handleOnSubmitted = () => {
    dispatch(setIsSubmit(false));
  };

  const renderCustomForm = () => (
    // @ts-ignore
    <CustomForm<IPriceForm>
      checkErrorsOnInit={Boolean(isValidation)}
      submitOnError={true}
      className="custom-form-container-stepper"
      doSubmit={{ isSubmit: isSubmit, onSubmitted: handleOnSubmitted }}
      submit={handleSubmit}
      form={form}
      props={isLand ? priceFormLand : priceForm}
    />
  );

  return !isLoading && initialValue ? renderCustomForm() : <Loader />;
};

export default Price;
