import { ICustomForm } from "../../models/CustomForm";
import { ELEVATOR_INFO, FLOOR, POSITION } from "../../models/RealEstate";
import { fillSelection } from "../../Utils/fuctions";
import {
  architecturalBarriersTypes,
  elevatorOptions,
  floorNumbers,
  floorOptions,
  positionOptions,
  roomPertinenceTypes,
} from "./selections";

export interface ILocationForm {
  city?: string; // città
  state?: string; // stato/provincia
  region?: string; // regione
  unity?: string; // civico o affini
  street?: string; // via
  zipCode?: string;
  latitude?: number | null | string;
  longitude?: number | null | string;
  floor?: number | FLOOR; // piano
  country?: string;
  zone: string;
  totalFloors?: number; // di piani totali
  position?: POSITION; // posizione
  seaLakeDistance?: number; // distanza mare/lago
  constructionYear?: number; // anno di costruzione
  totalApartments?: number; // appartamenti totali
  elevator?: ELEVATOR_INFO; // ascensore
  missingArchitecturalBarriers?: boolean; // barriere architettoniche assenti
  municipalityId: string;
}

export const initialValueLocationForm = {
  street: "",
  city: "",
  state: "",
  region: "",
  unity: "",
  zone: "",
  country: "",
  floor: "",
  totalFloors: "",
  latitude: null,
  longitude: null,
  position: "",
  seaLakeDistance: 0,
  constructionYear: 0,
  totalApartments: 0,
  elevator: "",
  missingArchitecturalBarriers: false,
  zipCode: "",
  municipalityId: "",
};

export const locationForm: ICustomForm<ILocationForm> = {
  title: {
    edit: "realEstate.forms.location.title",
    show: "realEstate.forms.location.title",
  },
  description: { edit: "", show: "" },
  labelSubmitButton: { edit: "editImage.save", show: "editImage.save" },
  // labelSubmitButton: {edit:"realEstate.forms.location.labelSubmit.edit", show: "realEstate.forms.location.labelSubmit.show"},
  next: "description",
  forms: [
    {
      title: "realEstate.forms.location.address",
      fields: [
        {
          label: "",
          type: "location2",
          maxColumn: 12,
          isRequired: true,
          key: "",
        },
      ],
    },
    {
      title: "",
      fields: [
        {
          label: "address.nation",
          offset: 12,
          type: "text",
          isRequired: true,
          key: "country",
        },
        {
          label: "address.province",
          type: "text",
          isRequired: true,
          key: "state",
        },
        {
          label: "address.city",
          type: "text",
          isRequired: true,
          key: "city",
        },
        {
          label: "address.address",
          type: "text",
          isRequired: true,
          key: "street",
        },
        {
          label: "realEstate.forms.location.zone",
          type: "text",
          key: "zone",
        },
        {
          column: 2,
          label: "address.civic",
          type: "text",
          key: "unity",
        },
        {
          column: 2,
          label: "address.zipCode",
          type: "text",
          key: "zipCode",
        },
        {
          label: "realEstate.forms.location.latitude",
          type: "number",
          key: "latitude",
          disabled: true,
          column: 5,
        },
        {
          column: 5,
          label: "realEstate.forms.location.longitude",
          type: "number",
          key: "longitude",
          disabled: true,
        },
        {
          column: 2,
          label: "address.istatCode",
          type: "text",
          key: "municipalityId",
        },
        {
          label: "realEstate.forms.location.floor",
          type: "select",
          isRequired: true,
          key: "floor",
          options: [
            ...fillSelection(floorOptions),
            ...fillSelection(floorNumbers, false),
          ],
        },
        {
          column: 2,
          maxColumn: 6,
          label: "realEstate.forms.location.totalFloors",
          type: "number",
          key: "totalFloors",
        },
        {
          label: "realEstate.forms.location.position",
          type: "select",
          key: "position",
          options: fillSelection(positionOptions),
        },
        {
          column: 2,
          label: "createDemandCustomerForm.infoRealEstate.seaLakeDistance",
          type: "number",
          key: "seaLakeDistance",
        },
      ],
    },
    {
      title: "realEstate.forms.location.info.title",
      fields: [
        {
          column: 2,
          label: "realEstate.forms.location.info.labels.constructionYear",
          type: "number",
          key: "constructionYear",
        },
        {
          maxColumn: 12,
          column: 2,
          label: "realEstate.forms.location.info.labels.totalApartments",
          type: "number",
          key: "totalApartments",
        },
        {
          column: 3,
          label: "realEstate.forms.location.info.labels.elevator",
          isRequired: true,
          type: "select",
          key: "elevator",
          options: fillSelection(elevatorOptions),
        },
        {
          label: "realEstate.forms.location.architecturalBarriers",
          type: "select",
          key: "missingArchitecturalBarriers",
          options: fillSelection(architecturalBarriersTypes, false),
        },
      ],
    },
  ],
};
