import dayjs from "dayjs";
import React, { FC, forwardRef, useState, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it"; // the locale you want
import en from "date-fns/locale/en-US"; // the locale you want
import "react-datepicker/dist/react-datepicker.css";
import "./CustomDatePicker.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { MonthDatepicker } from "./monthDatePicker";
import getClasses from "../../../Utils/getClasses";

registerLocale("it", it);
registerLocale("en", en);
interface ICustomDatePicker {
    date: Date | null,
    onChange: (date: Date | null) => void,
    classNameInput?: string;
    onBlurInput?: () => void;
    onFocusInput?: () => void;
}


const CustomDatePicker: FC<ICustomDatePicker> = ({date: initialDate,onChange, classNameInput = "", onBlurInput, onFocusInput}) => {
  const [currentDate, setCurrentDate] = useState<Date | null>(initialDate);
  const { language } = useSelector(({ language }: RootState) => language);
  const [languageCalendar, setLanguageCalendar] = useState(
    language === "it" ? it : en
  );

  useEffect(() => {
    setLanguageCalendar(language === "it" ? it : en);
  }, [language]);
  useEffect(() => {
    onChange(currentDate);
  // eslint-disable-next-line
  }, [currentDate]);

  const getClassDay = (date: Date) => {
    switch (true) {
      case currentDate && dayjs(currentDate!).isSame(dayjs(date)):
        return "calendar-custom-day-selected";
      case dayjs(date).format("DD-MM") === dayjs().format("DD-MM"):
        return "calendar-custom-day-today";
      default:
        return "calendar-custom-day";
    }
  };

  const CustomInputDatePicker = forwardRef(({ value, onClick }: any, ref) => (
    <>
      <button
        className={`calendar-custom-input ${classNameInput}`}
        onClick={onClick}
        ref={ref as any}
      >
        <div className="d-flex align-items-center justify-content-start gap-2 ps-2">
          <i className="icon icon-planner"></i>
          <span className={`${getClasses({"data-label": value, "data-label-placeholder": !value})}`}>{value ? dayjs(value).format("DD/MM/YYYY") : "gg/mm/aaaa"}</span>
        </div>
      </button>
    </>
  ));

  return (
    <>
      <DatePicker
        onCalendarClose={() => {onBlurInput && onBlurInput()}}
        onCalendarOpen={() => onFocusInput&& onFocusInput()}
        selected={currentDate}
        wrapperClassName={"calendar-wrapper"}
        calendarClassName="calendar-month"
        className="container-calendar"
        placeholderText={`gg/mm/YYYY`}
        weekDayClassName={() => "calendar-week-day"}
        customInput={<CustomInputDatePicker />}
        locale={languageCalendar}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="container-calendar-header ">
            <div>
              <MonthDatepicker
                date={date}
                changeMonth={changeMonth}
                changeYear={changeYear}
                languageCalendar={languageCalendar}
              ></MonthDatepicker>
            </div>
            <div>
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <i className="icon me-3 icon-ch-left icon-arrow"></i>
              </button>
              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                <i className="icon icon-ch-right icon-arrow"></i>
              </button>
            </div>
          </div>
        )}
        dayClassName={(date) => getClassDay(date)}
        onChange={(date) => setCurrentDate(date)}
      />
    </>
  );
};

export default CustomDatePicker;
