import React, { FC, useCallback, useEffect, useState, useMemo } from "react";
import {
  Customer,
  CustomerDocument,
  CustomerResidence,
  CUSTOMER_ENTITY,
  CUSTOMER_SOURCE,
  CUSTOMER_STATE,
  CUSTOMER_TYPE,
  GENDER,
  LANGUAGE,
  RATING,
} from "../../models/Customer";
import { useEndpoints } from "../../hooks/useEndpoints";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import Loader from "../../shared/components/Loader/Loader";
import CustomForm from "../../shared/components/CustomForm/CustomForm";
import { ICustomForm } from "../../models/CustomForm";
import { Form, FormValidationObject } from "../../hooks/useForm";
import { editCustomerForm } from "../../forms/customers/editCustomer";
import { createCustomerForm } from "../../forms/customers/createcustomer";
import { AxiosError } from "axios";
import { IAxiosLoginError } from "../../hooks/useLogin";
import { RespAddCustomer } from "../../models/RespCustomers";
import { getStorageItem } from "../../Utils/localStorage";
import { DURATION_TOAST } from "../../Utils/costants";
import { RoutesRouter } from "../../models/Routes";
import { useI18n } from "../../hooks/useI18n";
import { useToastAlert } from "../../shared/components/ToastAlert/ToastAlert";
import "./CreateCustomer.scss";

export interface NewCustomerForm {
  //basic info
  customerState?: CUSTOMER_STATE | string;
  acquisitionDate?: string;
  rating?: RATING;
  //Customer type
  customerTypes?: CUSTOMER_TYPE[];
  //personal data
  entity?: CUSTOMER_ENTITY;
  title?: string;
  name: string;
  surname: string;
  gender?: GENDER;
  birthPlace?: string;
  birthDate?: string;
  nationality?: string;
  taxIdCode?: string;
  spokenLanguages?: LANGUAGE[];
  //contacts
  email: string;
  phone1?: string;
  phone2?: string;
  smsDisabled?: boolean;
  mailingDisabled?: boolean;
  //source
  source?: string;
  //address
  residence?: Partial<CustomerResidence>;
  //documents
  identityDocument?: Partial<CustomerDocument>;
  //notes
  note?: string;
}
interface ICreateCustomer {
  classNameContainerForm?: string;
  options?: { onSubmitNavigate?: boolean };
  onClose?: () => void;
  onSubmit?: (
    customer: (Partial<Form<NewCustomerForm>> & { _id: string }) | undefined
  ) => void;
  fetchCustomerById?: boolean;
  className?: string;
}

const CreateCustomer: FC<ICreateCustomer> = ({
  fetchCustomerById = true,
  onSubmit,
  options,
  onClose,
  classNameContainerForm = "",
  className = "",
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [initialValue, setInitialValue] = useState<NewCustomerForm>();

  const { id } = useParams();
  const toastAlert = useToastAlert();
  const navigate = useNavigate();
  const { t } = useI18n();
  const { addCustomer, getCustomer, postCustomer } = useEndpoints();

  const {required, invalidEmail, afterDate, requiredMultiSelect} = {
    required: t("errorMessagesForm.required"),
    // invalidTaxIdCode: t("errorMessagesForm.invalidTaxIdCode"),
    invalidEmail: t("errorMessagesForm.invalidEmail"),
    afterDate: t("errorMessagesForm.isAfterDate"),
    requiredMultiSelect: t("errorMessagesForm.requiredMultiSelect"),
  };

  const initialValueForm = {
    customerState: CUSTOMER_STATE.ACTIVE,
    acquisitionDate: dayjs().format("YYYY/M/DD"),
    rating: RATING.STANDARD,
    //Customer type
    customerTypes: [],
    //personal data
    entity: CUSTOMER_ENTITY.NOT_PROVIDED,
    title: "",
    name: "",
    surname: "",
    gender: GENDER.NOTPROVIDED,
    birthPlace: "",
    birthDate: "",
    nationality: "",
    taxIdCode: "",
    spokenLanguages: [],
    //contacts
    email: "",
    phone1: "",
    phone2: "",
    smsDisabled: false,
    mailingDisabled: false,
    //source
    source: CUSTOMER_SOURCE.NOT_PROVIDED,
    consultant: {
      _id: getStorageItem("me")?._id,
      name: getStorageItem("me")?.name,
      surname: getStorageItem("me")?.surname,
    },
    //address
    residence: {
      country: "",
      state: "",
      municipality: "",
      postalCode: "",
      address: "",
      houseNumber: "",
    },
    //documents
    identityDocument: {
      type: "",
      documentId: "",
      releasedBy: "",
      released: "",
    },
    //notes
    note: "",
  };

  const fetchCustomer = async () => {
    if (fetchCustomerById && id?.length !== 24) {
      toastAlert({
        message: t("errorsToast.customerNotFound.title"),
        subMessage: t("errorsToast.customerNotFound.description"),
        type: "error",
        duration: DURATION_TOAST,
      });
      navigate(RoutesRouter.pageNotFound);
      return;
    }

    try {
      const { data: resp } = await getCustomer(id!);
      const newForm = Object.fromEntries(
        Object.entries(resp)
          .filter(([key]) =>
            Object.keys(initialValueForm).some((keyForm) => key === keyForm)
          )
          .map(([key, value]) => {
            if (value === null) return [key, ""];
            // key.includes("Date") && (value = dayjs(value).format("YYYY/MM/DD"));

            return [key, value];
          })
      );
      setInitialValue(newForm as NewCustomerForm);
    } catch (e) {
      console.error(e);
      toastAlert({
        message: t("errorsToast.unexpectedError.title"),
        subMessage: t("errorsToast.unexpectedError.description"),
        type: "error",
        duration: DURATION_TOAST,
      });
      setTimeout(() => {
        navigate(-1);
      }, 200);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 400);
    }
  };

  useEffect(() => {
    if (fetchCustomerById && id) {
      fetchCustomer();
    } else {
      setInitialValue(initialValueForm);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const isFetchPost = (data: RespAddCustomer | ""): data is RespAddCustomer =>
    !!data;

  const handleSubmit = async (
    form: Partial<Form<NewCustomerForm>>,
    next: ICustomForm["next"]
  ) => {
    try {
      const prefixToast = "customers.changeConsultant.toast";
      const { data } =
        id && fetchCustomerById
          ? await postCustomer(id, form)
          : await addCustomer(form);
      toastAlert({
        message: `${t(
          `${prefixToast}.${id && fetchCustomerById ? "edited" : "added"}`
        )}`,
        subMessage: `${t([
          `${prefixToast}.${id ? "edited" : "added"}`,
          `${prefixToast}.success`,
        ])}`,
        type: "success",
        duration: DURATION_TOAST,
      });
      if (isFetchPost(data)) {
        onSubmit && onSubmit({ ...form, _id: data.customerId });
      } else {
        onSubmit && onSubmit(undefined);
      }
      options?.onSubmitNavigate !== false && navigate(next);
    } catch (errorRequest) {
      const e = errorRequest as AxiosError<
        IAxiosLoginError & { existingCustomers: Customer[] }
      >;
      const customer = e.response?.data.existingCustomers[0];
      if (
        e.response?.data.message ===
        "Customer(s) with email or phone number(s) already exist(s)."
      ) {
        toastAlert({
          message: t("errorMessagesForm.existingCustomer", {
            context: `${customer?.name || ""} ${customer?.surname || ""}`,
          }),
          subMessage: ({ onClose }) => (
            <span
              className="pointer button-toast-add-new-customer"
              onClick={async () => {
                try {
                  const { data } =
                    id && fetchCustomerById
                      ? await postCustomer(id, { ...form, force: true })
                      : await addCustomer({ ...form, force: true });
                  if (isFetchPost(data)) {
                    onSubmit && onSubmit({ ...form, _id: data.customerId });
                  } else {
                    onSubmit && onSubmit(undefined);
                  }
                  onClose();
                  options?.onSubmitNavigate !== false && navigate(next);
                } catch (error) {
                  console.error("error", error);
                }
              }}
            >
              {id
                ? t("errorMessagesForm.buttonExistingCustomerEdit")
                : t("errorMessagesForm.buttonExistingCustomerAdd")}
            </span>
          ),
          type: "warning",
        });
      } else {
        console.log("e", e);
        toastAlert({
          message: t("errorsToast.unexpectedError.title"),
          subMessage:
            e.response?.data.message ||
            t("errorsToast.unexpectedError.description"),
          type: "error",
          duration: DURATION_TOAST,
        });
      }
    }
  };

  const generateForm = useCallback(
    (validators: FormValidationObject<Customer> | undefined = undefined) => ({
      initialValue,
      validators,
    }),
    [initialValue]
  );

  const form = useMemo(
    () =>
      generateForm({
        name: {
          required,
        },
        surname: {
          required,
        },
        phone1: {
          required,
        },
        customerTypes: {
          custom: (value: string[]) =>
            value.length ? "" : requiredMultiSelect,
        },
        email: {
          invalidEmail,
        },
        birthDate: {
          custom(value: string) {
            return dayjs(value).isAfter(dayjs()) ? afterDate : "";
          },
        },
        "identityDocument/released": {
          custom(value: string) {
            return dayjs(value).isAfter(dayjs()) ? afterDate : "";
          },
        },
      }),
    // eslint-disable-next-line
    [initialValue, id]
  );

  // eslint-disable-next-line
  const props = useMemo(
    () => (id && fetchCustomerById ? editCustomerForm : createCustomerForm),
    // eslint-disable-next-line
    [id]
  );

  const renderCustomForm = () => (
    // @ts-ignore
    <CustomForm
      className={classNameContainerForm}
      submit={handleSubmit}
      form={form}
      onClose={onClose || undefined}
      props={props}
    />
  );

  return !isLoading && initialValue ? (
    <div
      className={`container-overflow container-create-customer ${className}`}
    >
      {renderCustomForm()}
    </div>
  ) : (
    <Loader />
  );
};

export default CreateCustomer;
