import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RespDemandsCustomers } from '../../models/RespDemands'

interface DemandsCustomersState {
    demandsCustomers: RespDemandsCustomers | undefined
}

const initialState = { demandsCustomers: undefined } as DemandsCustomersState

const demandsCustomersSlice = createSlice({
  name: 'demandsCustomers',
  initialState,
  reducers: {

    setDemandsCustomers(state: DemandsCustomersState, action: PayloadAction<RespDemandsCustomers | undefined>) {
      state.demandsCustomers = action.payload
    },
    removeDemandCustomers(state: DemandsCustomersState, action: PayloadAction<number>) {
      state.demandsCustomers = {...state.demandsCustomers!, docs: state.demandsCustomers!.docs.filter((_: any, i: number) => i !== action.payload)}
    }
  },
})

export const { setDemandsCustomers, removeDemandCustomers } = demandsCustomersSlice.actions
export default demandsCustomersSlice.reducer