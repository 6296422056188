import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Consultant } from "../models/Consultant";
import { RootState } from "../redux/reducers";
import { useI18n } from "./useI18n";

import {
  enableConsultant,
  setConsultants,
} from "../redux/reducers/consultants";
import { ITableButtonOption } from "../shared/components/Table/Table";
import { ElementForPage } from "../typings/Pagination";
import { DURATION_TOAST } from "../Utils/costants";
import { getStorageItem, KeyStorage } from "../Utils/localStorage";
import { useEndpoints } from "./useEndpoints";
import { useToastAlert } from "../shared/components/ToastAlert/ToastAlert";

export const useConsultants = () => {
  const toastAlert = useToastAlert();
  const { t } = useI18n();
  const { consultants: allConsultants } = useSelector(
    ({ consultants }: RootState) => consultants
  );
  const [searchParams] = useSearchParams();
  const [doFirstFetch, setDoFirstFetch] = useState(false);

  const [termFilter, setTermFilter] = useState<
    Record<"label" | "value", string>
  >({ label: "", value: "" });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const {
    getConsultants,
    deleteConsultant: eraseConsultant,
    patchConsultant,
  } = useEndpoints();
  const [pagination, setPagination] = useState<{
    limit: ElementForPage;
    page: number;
  }>({ limit: 10, page: 0 });

  const fetchConsultants = async () => {
    const { page, limit } = pagination;
    setIsLoading(true);
    try {
      const skip = page === 0 ? 0 : (page - 1) * limit;
      const { data: resp } = await getConsultants({
        limit,
        skip,
        search: termFilter.value,
      });
      dispatch(setConsultants(resp));
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const consultants = useMemo(() => {
    return allConsultants?.docs || undefined;
  }, [allConsultants]);

  const debounceFilterTerm = (term: string) => {
    setTermFilter((prev) => ({ ...prev, label: term }));
  };

  const changePage = (page: number) => {
    setPagination({ ...pagination, page });
  };

  const setLimit = (limit: ElementForPage) => {
    setPagination({ limit, page: 0 });
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const value = event.currentTarget.value;
      setTermFilter(() => ({ value: value, label: value }));
    }
  };

  const handleReset = (value: string) => {
    setTermFilter((prev) => ({ ...prev, label: value }));
    !value && setTermFilter({ label: "", value: "" });
  };

  useEffect(() => {
    doFirstFetch && fetchConsultants();
    // eslint-disable-next-line
  }, [pagination]);

  useEffect(() => {
    setPagination((prev) => ({ ...prev, page: 0 }));
  }, [termFilter.value]);

  useEffect(() => {
    doFirstFetch && fetchConsultants();
    // eslint-disable-next-line
  }, [doFirstFetch]);

  useEffect(() => {
    return () => {
      dispatch(setConsultants(undefined));
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { page, limit } = pagination;
    const skip = page === 0 ? 0 : (page - 1) * limit;
    const newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      `?search=${termFilter.label}&page=${page}&limit=${limit}&skip=${skip}`;
    window.history.replaceState({ path: newurl }, "", newurl);
  }, [pagination, termFilter.label]);

  useEffect(() => {
    const limitQuery = searchParams.get("limit");
    const pageQuery = searchParams.get("page");
    const searchQuery = searchParams.get("search");

    setPagination({
      page: Number(pageQuery) ?? 0,
      limit: (Number(limitQuery) as ElementForPage) || 10,
    });
    setTermFilter({ label: searchQuery || "", value: searchQuery || "" });
    setTimeout(() => {
      setDoFirstFetch(true);
    }, 500);
    // eslint-disable-next-line
  }, []);

  const generateButtons = useCallback(
    (
      callback: (
        value: NonNullable<Omit<Consultant, "agency">>,
        role: KeyStorage["me"]["role"]
      ) => ITableButtonOption
    ): Array<ITableButtonOption> => {
      if (!allConsultants) return [] as Array<ITableButtonOption>;
      return allConsultants.docs.map((realEstate) => {
        return callback(realEstate, getStorageItem("me")!.role);
      });
    },
    [allConsultants]
  );

  const editConsultant = async (index: number, type: "remove" | "enable") => {
    if (!allConsultants) return;
    try {
      const { _id, enabled } = allConsultants.docs[index];
      if (type === "remove") {
        await eraseConsultant(_id);
        await fetchConsultants();
      } else if (type === "enable") {
        await patchConsultant(_id, !enabled);
        dispatch(enableConsultant({ index, enable: !enabled }));
      }
    } catch (e) {
      toastAlert({
        message: t("agency.errorToast.title"),
        subMessage: t("agency.errorToast.description"),
        type: "error",
        duration: DURATION_TOAST,
      });
      console.log("e", e);
    }
  };

  const consultantsForTable = useMemo(() => {
    return allConsultants?.docs
      ? allConsultants.docs.map(
          ({
            name,
            surname,
            email,
            role,
            enabled,
            profilePicture: { thumbnail },
            visibleOnShowcase,
          }) => ({
            nameSurname: `${name} ${surname}`,
            thumbnail,
            email,
            role: t(`consultants.table.role.${role}`),
            enabled,
            visibleOnShowcase,
          })
        )
      : undefined;
  }, [allConsultants, t]);

  return {
    consultantsForTable,
    fetchConsultants,
    debounceFilterTerm,
    setLimit,
    limit: pagination.limit,
    termFilter,
    generateButtons,
    handleReset,
    handleKeyPress,
    editConsultant,
    setTermFilter,
    consultants,
    changePage,
    allConsultants,
    isLoading,
  };
};
