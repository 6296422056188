import { AddIcon } from "@chakra-ui/icons";
import {
  TableContainer,
  Table as TableChakra,
  Thead,
  Tr,
  Tbody,
  Flex,
  IconButton,
  useColorMode,
  Box,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Skeleton } from "@chakra-ui/react";
import "./Table.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import InfoTooltip from "../../../components/Tooltip/Tooltip";

export type ITableButtonOption = {
  onClick?: (index: number) => void | Promise<void>;
  label: string | JSX.Element | boolean;
  colorTheme: "green" | "blue" | "red";
  isLoading?: boolean;
  icon: JSX.Element;
}[];

export interface ITableHome<K = any> {
  title: string;
  titles: {
    label: string;
    isNumeric?: boolean;
    textAlign?: "start" | "center" | "end";
    truncateField?: number;
  }[];
  isLoading?: boolean;
  addLastEmptyTh?: boolean;
  onClickTr?: (index: number) => void;
  setTermFilter?: (string: string) => void;
  data:
    | Record<
        any,
        string | JSX.Element | number | Record<any, any> | boolean | any
      >[]
    | undefined;
  dataKeyShow: (keyof K)[];
  addButtonHeader?: {
    onClick: () => void;
    isNumeric?: boolean;
    textAlign?: "start" | "center";
  };
  options?: { booleanToCircle: boolean };
  buttonsOption?: Array<ITableButtonOption>;
}

const Table = <K extends any>({
  titles,
  data,
  options,
  dataKeyShow,
  addButtonHeader,
  addLastEmptyTh = false,
  onClickTr,
  isLoading = false,
  buttonsOption,
}: ITableHome<K>) => {
  const { colorMode } = useColorMode();
  const { language } = useSelector(({ language }: RootState) => language);

  const optionsButton = useCallback(
    (indexData: number) => (
      <Flex justifyContent="flex-end">
        <Menu>
          <MenuButton
            as={IconButton}
            padding={"0rem !important"}
            aria-label="More server options"
            icon={<BsThreeDotsVertical />}
            variant="ghost"
            w="fit-content"
          />
          <MenuList p={0} zIndex={1000}>
            {buttonsOption![indexData].map(
              ({ label, onClick, colorTheme, icon, isLoading }, index) => (
                <MenuItem
                  key={index}
                  _hover={{ bg: "transparent" }}
                  _focus={{ bg: "transparent" }}
                  p={0}
                >
                  <div
                    key={index}
                    onClick={() => {
                      onClick && onClick(indexData);
                    }}
                    className={`menu-item menu-item-${colorTheme}`}
                  >
                    <span
                      className={`icon  icon-button-table color-${colorTheme}`}
                    >
                      {icon}
                    </span>
                    <span
                      className={`capitalize-first-letter color-${colorTheme}`}
                    >
                      {label}
                    </span>
                  </div>
                </MenuItem>
              )
            )}
          </MenuList>
        </Menu>
      </Flex>
    ),
    // eslint-disable-next-line
    [buttonsOption, language]
  );

  const BooleanToCircle = ({ enabled }: { enabled: boolean }) => {
    return (
      <Flex justifyContent={"center"}>
        <Box
          w={5}
          h={5}
          borderRadius={50}
          transition={"background-color 0.3s"}
          bg={enabled ? "green.400" : "red.400"}
        ></Box>
      </Flex>
    );
  };

  const truncate = useCallback(
    (field: string, truncateField: number | undefined) =>
      truncateField === undefined ||
      (truncateField && field.length < truncateField) ? (
        field
      ) : (
        <InfoTooltip label={field}>{`${field.substring(
          0,
          truncateField
        )}...`}</InfoTooltip>
      ),
    []
  );

  const renderLabel = useCallback(
    (
      label:
        | string
        | boolean
        | JSX.Element
        | number
        | Record<any, any>
        | undefined,
      truncateField: number | undefined
    ) => {
      switch (true) {
        case typeof label === "string":
          return truncate(String(label), truncateField);
        case typeof label === "boolean":
          return options?.booleanToCircle ? (
            <BooleanToCircle enabled={label as boolean} />
          ) : (
            String(label)
          );
        case typeof label === "number":
          return label;
        case React.isValidElement(label):
          return label;
        default:
          return "";
      }
    },
    // eslint-disable-next-line
    [options?.booleanToCircle]
  );

  const dataSortedAndFiltered = useMemo(
    () =>
      data
        ? data.map((val) => {
            // TODO change with reduce!
            //   Object.fromEntries(Object.entries(val).filter(([key]) => dataKeyShow.some((keyShow) => keyShow === key)))
            let obj: Partial<Record<
              any,
              string | boolean | number | Record<any, any> | JSX.Element
            >> = {};
            dataKeyShow.forEach((key: keyof typeof val) => {
              if (typeof obj[key] === "object" && !Array.isArray(obj[key]))
                return;
              obj[key] = val[key];
            });
            return obj;
          })
        : undefined,
    [data, dataKeyShow]
  );

  return (
    <>
      {dataSortedAndFiltered && !isLoading ? (
        <TableContainer
          overflowY={"auto"}
          borderRadius={10}
          className="container__table"
        >
          <TableChakra p={20}>
            <Thead
              className="table__thead"
              backgroundColor={colorMode === "light" ? "white" : "#1a202c"}
            >
              <Tr>
                {titles.map(({ textAlign, label, isNumeric }, index) => {
                  return (
                    <th
                      key={index}
                      className="text-capitalize th pt-3 pb-2"
                      style={{
                        textAlign: textAlign || "center",
                      }}
                    >
                      {label}
                    </th>
                  );
                })}
                {addLastEmptyTh && (
                  <th
                    style={{
                      width: `${
                        100 / (titles.length + (addButtonHeader ? 1 : 0))
                      }%`,
                    }}
                  ></th>
                )}
                {addButtonHeader && (
                  <th
                    style={{
                      width: `${
                        100 / (titles.length + (addButtonHeader ? 1 : 0))
                      }%`,
                      textAlign: addButtonHeader.textAlign || "end",
                    }}
                  >
                    <AddIcon
                      w={4}
                      h={4}
                      mr={"0.625rem"}
                      cursor={"pointer"}
                      color={"green.500"}
                      onClick={addButtonHeader.onClick}
                    />
                  </th>
                )}
              </Tr>
            </Thead>
            <Tbody>
              {dataSortedAndFiltered.map((labels, index) => {
                return (
                  <Tr key={index} className="tr">
                    {typeof labels === "object" &&
                      Object.values(labels).map((label, i) => {
                        return (
                          <td
                            onClick={() => {
                              onClickTr && onClickTr(index);
                            }}
                            className="td pointer"
                            style={{
                              width: `${
                                100 /
                                (titles.length + (addButtonHeader ? 1 : 0))
                              }%`,
                              textAlign: titles[i].textAlign || "center",
                            }}
                            key={`${index}${i}`}
                          >
                            {renderLabel(label, titles[i].truncateField)}
                          </td>
                        );
                      })}
                    <td
                      className="td"
                      style={{
                        textAlign: "end",
                      }}
                    >
                      {buttonsOption &&
                        !!buttonsOption[index].length &&
                        optionsButton(index)}
                    </td>
                  </Tr>
                );
              })}
            </Tbody>
          </TableChakra>
        </TableContainer>
      ) : (
        Array(10)
          .fill(null)
          .map((_, index) => {
            return <Skeleton key={index} my={2} height={"3.75rem"} />;
          })
      )}
    </>
  );
};

export default Table;
