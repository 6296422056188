import { useDisclosure } from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useEndpoints } from "../../../../hooks/useEndpoints";
import { useI18n } from "../../../../hooks/useI18n";
import { RealEstateCommercial } from "../../../../models/RealeEstateCommercial";
import { RealEstate } from "../../../../models/RealEstate";
import Button from "../../../../shared/components/Button/Button";
import Modal from "../../../../shared/components/Modal";
import TextInput from "../../../../shared/components/TextInput/TextInput";
import "./ModalChangeConsultantRealEstate.scss";
import { getStorageItem } from "../../../../Utils/localStorage";
import { RealEstateLand } from "../../../../models/RealEstateLand";

type ILabelSelect = {
  _id: string;
  name: string | undefined;
  surname: string | undefined;
};

interface IModalChangeConsultant {
  onChange?: (selectedConsultant: ILabelSelect) => void;
  realEstate: RealEstate | RealEstateCommercial | RealEstateLand;
}

const ModalChangeConsultantRealEstate: FC<IModalChangeConsultant> = ({
  realEstate,
  onChange,
}) => {
  const { t } = useI18n();
  const { id } = useParams();
  const {
    consultant: { _id, name, surname },
  } = realEstate;
  const consultant = { _id, name, surname };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getConsultants, patchRealEstate } = useEndpoints();
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [labelSelect, setLabelSelect] = useState<ILabelSelect[] | undefined>();
  const [selectedConsultant, setSelectedConsultant] = useState<
    ILabelSelect | undefined
  >({ _id: _id, name, surname } || undefined);
  const [valueInput, setsValueInput] = useState<string>("");
  const allowedRoles = ["ADMIN", "SUPER_ADMIN", "STAFF"];
  const consultantRole = getStorageItem("me")?.role;

  const fetchConsultants = async () => {
    setIsLoading(true);
    try {
      const {
        data: { docs },
      } = await getConsultants({
        skip: 0,
        limit: 1000,
        search: valueInput,
      });
      setLabelSelect(
        docs.map(({ name, surname, _id, email, enabled }) => ({
          name,
          surname,
          _id,
          email,
        }))
      );
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (valueInput.length > 3) {
      fetchConsultants();
    }
    // eslint-disable-next-line
  }, [valueInput]);

  useEffect(() => {
    setSelectedConsultant(consultant);
    // eslint-disable-next-line
  }, [isOpen]);

  const removeSelected = () => {
    setSelectedConsultant(undefined);
    setsValueInput("");
  };

  const handleClick = () => {
    try {
      if (selectedConsultant) {
        patchRealEstate(id!, { consultant: selectedConsultant._id });
        onChange && onChange(selectedConsultant);
        onClose();
      }
    } catch (e) {
      console.error("error", e);
    }
  };

  const renderBodyModal = () => {
    return (
      <>
        <h1 className="title-modal">{t("customers.changeConsultant.title")}</h1>
        <p className="text-center mb-2">
          {t("customers.changeConsultant.addConsultant")}
        </p>
        <div className="mb-1">
          {selectedConsultant ? (
            <div className="consultant-selected  ps-2">
              {/* `${selectedConsultant.name} ${selectedConsultant.surname}`} id={selectedConsultant._id} onClose={(id) => removeSelectedRemove(id)}></Chip> */}
              <span className="consultant-selected-text">{`${selectedConsultant.name} ${selectedConsultant.surname}`}</span>
              <i
                className="icon icon-close pointer me-2"
                onClick={(id) => removeSelected()}
              ></i>
              {/* <p onClick={onClickREmoveConsultant}>{selectedConsultant.name}</p> */}
            </div>
          ) : (
            <TextInput
              value={valueInput}
              onChange={(value) => setsValueInput(value)}
              placeHolder={t("customers.changeConsultant.placeholder")}
            />
          )}
        </div>
        <div className="container-consultants">
          {labelSelect &&
            labelSelect.map((label) => {
              const { name, surname, _id } = label;
              return (
                <div
                  key={_id}
                  className="container-select"
                  onClick={() => {
                    setSelectedConsultant(label);
                    setLabelSelect(undefined);
                  }}
                >
                  <p
                    key={_id}
                    className="container-select-text"
                  >{`${name} ${surname}`}</p>
                </div>
              );
            })}
        </div>
        <div className="container-modal-buttons">
          <Button color="tertiary" onClick={onClose}>
            {t("customers.changeConsultant.skip")}
          </Button>
          <Button onClick={handleClick}>
            {t("customers.changeConsultant.confirm")}
          </Button>
        </div>
      </>
    );
  };
  return (
    <div className="container-modal-change-consultant">
      <span className="container-modal-change-consultant-label">{`${t(
        "customers.changeConsultant.consultant"
      )} ${consultant.name} ${consultant.surname}`}</span>
      {consultantRole && allowedRoles.includes(consultantRole) && (
        <>
          <Button color="tertiary" onClick={onOpen}>
            <span>{t("customers.changeConsultant.title")}</span>
          </Button>
          <Modal isOpen={isOpen} onClose={onClose} title={""}>
            {renderBodyModal()}
          </Modal>
        </>
      )}
    </div>
  );
};

export default ModalChangeConsultantRealEstate;
