import React, { FC, useEffect, useRef, useState } from "react";
import "./AddressInputDemandCustomer.scss";
import { Form, FormReturnType } from "../../../../hooks/useForm";
import { Field } from "../../../../models/CustomForm";
import { getNestedValueFromString } from "../../../../Utils/fuctions";
import { Zone } from "../../../../models/demandCustomer";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "../../GoogleInput";
import { ActionMeta, OptionTypeBase, Styles, ValueType } from "react-select";
import { useI18n } from "../../../../hooks/useI18n";
import {
  DrawingManager,
  GoogleMap,
  Polygon,
  useJsApiLoader,
} from "@react-google-maps/api";
import { apiKeyGoogleMaps } from "../../../../environment/environment";

import { SphericalUtil, PolyUtil } from "node-geometry-library";
import { LatLng } from "react-google-places-autocomplete/build/GooglePlacesAutocomplete.types";
import { AddressComponent } from "../AddressInput/AddressInput2";
import { useEndpoints } from "../../../../hooks/useEndpoints";
interface PolygonZone {
  name: string;
  coords: { lat: number; lng: number }[];
  radius?: number;
  center: { lat: number; lng: number };
  area: number;
  perimeter?: number;
  bounds: Bounds;
}
type WithArray<T = any> = {
  [key: string]: T[];
};

interface Bounds {
  south: number;
  north: number;
  east: number;
  west: number;
}

interface Point {
  lat: number;
  lng: number;
}

const increasePolygonArea = (percentage: number, zone: PolygonZone) => {
  const { bounds, area, center } = zone;
  // Calcola l'area del quadrato corrente
  const currentArea =
    (bounds.north - bounds.south) * (bounds.east - bounds.west);

  // Calcola il fattore di scala per raddoppiare l'area
  const scale =
    Math.sqrt(area / 10000000000 / currentArea) * ((100 + percentage) / 100);

  // Calcola le nuove dimensioni dei bounds
  const latDiff = (bounds.north - bounds.south) * scale;
  const lngDiff = (bounds.east - bounds.west) * scale;

  // Calcola i nuovi bounds
  const newBounds = new google.maps.LatLngBounds(
    new google.maps.LatLng(center.lat - latDiff / 2, center.lng - lngDiff / 2),
    new google.maps.LatLng(center.lat + latDiff / 2, center.lng + lngDiff / 2)
  );

  // Crea un rettangolo su Google Maps
  const rectangle = new google.maps.Rectangle({ bounds: newBounds });

  // Crea le nuove coordinate
  const newCoords = [
    {
      lat: rectangle.getBounds()?.getNorthEast().lat()!,
      lng: rectangle.getBounds()?.getNorthEast().lng()!,
    },
    {
      lat: rectangle.getBounds()?.getSouthWest().lat()!,
      lng: rectangle.getBounds()?.getNorthEast().lng()!,
    },
    {
      lat: rectangle.getBounds()?.getSouthWest().lat()!,
      lng: rectangle.getBounds()?.getSouthWest().lng()!,
    },
    {
      lat: rectangle.getBounds()?.getNorthEast().lat()!,
      lng: rectangle.getBounds()?.getSouthWest().lng()!,
    },
  ];
  return {
    ...zone,
    center,
    coords: newCoords,
    area: SphericalUtil.computeArea(newCoords),
    bounds: newBounds.toJSON(),
  };
};

interface IAddressInput {
  form: Partial<Form<any>>;
  setForm: any;
  data: Field;
  error: string | undefined;
  handleError: FormReturnType<any>["handleError"];
}

interface Coord {
  lat: number;
  lng: number;
}

const getFirstItemFromArray = (data: WithArray) =>
  Object.values(data).filter((arr) => Array.isArray(arr))[0];
const getLatLngFromRefs = (data: WithArray) =>
  getFirstItemFromArray(getFirstItemFromArray(data)[0]);

const AddressInputDemandCustomer: FC<IAddressInput> = ({
  handleError,
  data: {
    column = 4,
    offset = 0,
    isRequired,
    label,
    value,
    key,
    showIf = true,
    showIfKey,
  },
  form,
  setForm,
  error,
}) => {
  const [percentageZoneOffset, setPercentageZoneOffset] = useState(20);
  const [initialValueInput, setInitialValueInput] = useState<OptionTypeBase[]>(
    () => {
      const zones = getNestedValueFromString<PolygonZone & Zone[]>(
        "zones",
        form
      );

      return zones.map((zone) => ({
        label: zone.name,
        value: { name: zone.name },
        ...zone,
      }));
    }
  );
  const { t } = useI18n();
  const { me } = useEndpoints();
  const [zones, setZones] = useState<PolygonZone[]>([]);

  const refsArray = useRef<any[]>([]);
  const [center, setCenter] = useState<Coord>({
    lat: 52.52549080781086,
    lng: 13.398118538856465,
  });
  const [zoom, setZoom] = useState(14);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: apiKeyGoogleMaps,
  });

  const setterZoom = () => {
    if (zones.length < 3) {
      const kmq = Math.round(
        SphericalUtil.computeArea(zones.at(-1)?.coords!) / 1000
      );

      switch (true) {
        case kmq >= 250000:
          setZoom(10);
          break;
        case kmq > 25000 && kmq < 250000:
          setZoom(11);
          break;
        case kmq <= 25000 && kmq > 10000:
          setZoom(12);
          break;
        case kmq <= 10000 && kmq > 2000:
          setZoom(13);
          break;
        case kmq <= 2000 && kmq > 1000:
          setZoom(14);
          break;
        case kmq <= 1000:
          setZoom(15);
          break;
      }
    }
    if (zones.length > 2) {
      const newPolygonCoords = zones.map(({ center }) => center);
      const newPolygonCenter = getDataFromCoords(
        newPolygonCoords as LatLng[],
        "center"
      );
      const newPoligonArea = Math.round(
        SphericalUtil.computeArea(newPolygonCoords as LatLng[]) / 1000
      );

      setCenter(newPolygonCenter as { lat: number; lng: number });

      switch (true) {
        case newPoligonArea >= 15000000 && newPoligonArea < 100000000:
          setZoom(7);
          break;
        case newPoligonArea >= 5000000 && newPoligonArea < 15000000:
          setZoom(8);
          break;
        case newPoligonArea >= 1000000 && newPoligonArea < 5000000:
          setZoom(9);
          break;
        case newPoligonArea >= 250000 && newPoligonArea < 1000000:
          setZoom(10);
          break;
        case newPoligonArea > 25000 && newPoligonArea < 250000:
          setZoom(11);
          break;
        case newPoligonArea <= 25000 && newPoligonArea > 10000:
          setZoom(12);
          break;
        case newPoligonArea <= 10000 && newPoligonArea > 2000:
          setZoom(13);
          break;
        case newPoligonArea <= 2000 && newPoligonArea > 1000:
          setZoom(14);
          break;
        case newPoligonArea <= 1000:
          setZoom(15);
          break;
      }
    }
  };

  const getDataFromCoords = (coordsArray: LatLng[], key?: string) => {
    const bounds = new google.maps.LatLngBounds();
    coordsArray.forEach((coords) => bounds.extend(coords));
    switch (true) {
      case !key:
        return bounds;
      case key === "center":
        return {
          lat: bounds.getCenter().lat(),
          lng: bounds.getCenter().lng(),
        } as Coord;
      case key === "bounds":
        return bounds.toJSON();
    }
  };

  useEffect(() => {
    if (!initialValueInput) return;
    error && initialValueInput.length > 0 && handleError(key, "", false);
    handleSelectZone();
    // eslint-disable-next-line
  }, [initialValueInput]);

  const handleSelectZone = async () => {
    const zonePromise = initialValueInput.map(
      (search: OptionTypeBase) =>
        new Promise(async (resolve, reject) => {
          if (search.coords && search.center) {
            setCenter(search.center);
            return resolve({
              name: search.name,
              coords: search.coords,
              bounds: search.bounds,
              perimeter: search.perimeter,
              area: search.aerea,
              center: search.center,
            });
          }

          const results = await geocodeByPlaceId(search?.value.place_id);

          if (results.length > 0) {
            const {
              lat: latNorth,
              lng: lngNorth,
            } = results[0].geometry.viewport.getNorthEast();
            const {
              lat: latSouth,
              lng: lngSouth,
            } = results[0].geometry.viewport.getSouthWest();
            const [street, city] = search.value.terms;

            setCenter({
              lat: results.at(-1)!.geometry.location.lat(),
              lng: results.at(-1)!.geometry.location.lng(),
            });
            const coords = [
              { lat: latNorth(), lng: lngNorth() },
              { lat: latNorth(), lng: lngSouth() },
              { lat: latSouth(), lng: lngSouth() },
              { lat: latSouth(), lng: lngNorth() },
            ];

            let newZone: PolygonZone = {
              name: `${street?.value || ""}${
                city?.value ? `, ${city.value}` : ""
              }`,
              coords,
              center: getDataFromCoords(coords, "center") as Coord,
              area: SphericalUtil.computeArea(coords),
              perimeter: SphericalUtil.computeLength(coords),
              bounds: getDataFromCoords(coords, "bounds") as Bounds,
            };
            if (newZone.area && newZone.area < 80000) {
              newZone = increasePolygonArea(200, newZone);
            } else {
              newZone = increasePolygonArea(percentageZoneOffset, newZone);
            }
            return resolve(newZone);
          }
        })
    );

    const newZones = await Promise.all(zonePromise);

    //gestione aggiungi zona o elimina
    if (newZones && newZones.length > zones.length) {
      const additioanlZones = newZones.filter(
        (zone: any) => !zones.some(({ name }) => zone.name === name)
      );
      setZones((prev) => [...prev, ...(additioanlZones as PolygonZone[])]);
    } else {
      const nameZone = zones.find(
        (zone) => !newZones.some((newZone: any) => zone.name === newZone.name)
      )?.name;
      refsArray.current = refsArray.current.filter(
        (zone) => zone.name !== nameZone
      );

      const newZonesAdd: PolygonZone[] = [
        ...zones.filter((zone) => zone.name !== nameZone),
      ];
      setZones([]);
      setZones(newZonesAdd);
    }
  };

  useEffect(() => {
    setForm(
      "zones",
      zones.filter((item) => Boolean(item))
    );
  }, [zones]);

  const customStyles: Partial<Styles<OptionTypeBase, false>> = {
    option: (provided, state) => ({
      ...provided,
      // color: state.isSelected ? 'red' : 'blue',
      backgroundColor: state.isSelected ? "#ECEFFD" : "white",
      lineHeight: "1.5rem",
      fontWeight: "700",
      color: "#3A3878",
      padding: 10,
      "&:hover": {
        backgroundColor: "#ECEFFD",
      },
    }),
    multiValueRemove: (style, state) => {
      return {
        ...style,
        "&:hover": {
          backgroundColor: "transparent",
          cursor: "pointer",
          color: "black",
        },
      };
    },
    menu: (style, state) => ({
      ...style,
      borderRadius: "0.5rem",
      border: "none",
      boxShadow: "none",
      backgroundColor: "white",
    }),
    control: (provided) => ({
      ...provided,
      border: "none",
      width: "100%",
      borderRadius: "0.5rem",
      minHeight: "3.125rem",
      color: "#3B54D4",
      boxShadow: "none",
      "&:focus-visible": {
        outline: "none",
        boxShadow: "none",
      },
      "&:hover": {
        border: "1px solid #415DEB",
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#D9DFFB",
      borderRadius: "6.25rem",
      color: "#3B54D4",
      "& div": {
        fontSize: "0.875rem",
        lineHeight: "1.125rem",
        fontWeight: "700",
        color: "#3B54D4",
      },
    }),
    input: (provided) => ({
      ...provided,
      color: "#3A3878",
      "& input": {
        fontSize: "1rem",
        lineHeight: "1.5rem",
        fontWeight: "700",
        color: "#3A3878",
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const props = {
        fontSize: "1rem",
        lineHeight: "1.5rem",
        fontWeight: "200",
        color: "#3A3878",
      };

      return { ...provided, opacity, transition, ...props };
    },
  };

  const handleRemoveZone = (
    value: ValueType<OptionTypeBase, false>,
    action: ActionMeta<OptionTypeBase>
  ) => {
    const { action: typeAction } = action;
    switch (typeAction) {
      case "remove-value":
        setInitialValueInput((prev: any[]) =>
          prev.filter(({ label }) => label !== action?.removedValue?.label)
        );
        break;
      case "clear":
        setInitialValueInput([]);
        break;
      case "select-option":
        if (
          !initialValueInput.some(({ label }) => label === action.option!.label)
        ) {
          action.option &&
            setInitialValueInput((prev) => [...prev, action!.option!]);
        }
        break;
      default:
        break;
    }
  };

  const containerStyle = {
    width: "100%",
    height: "600px",
  };

  const fetchMeForPercentage = async () => {
    const { data } = await me();
    setPercentageZoneOffset(data.agency.settings.demands.zoneOffset);
    
  };
  useEffect(() => {
    fetchMeForPercentage();
  }, []);
  return (
    <>
      <div className={`col-lg-${column}`}>
        <div
          className={`form-group offset-lg-${offset} ${
            error ? "is-invalid" : ""
          }`}
        >
          <label
            className="form-control-label form-label text-capitalize"
            htmlFor="name"
          >
            {`${t(label)} ${isRequired ? "*" : ""}`}
          </label>
          <div className="container-google-places-autocomplete">
            <GooglePlacesAutocomplete
              selectProps={{
                isMultiSelect: true,
                cacheOptions: true,
                isClearable: false,
                value: initialValueInput,
                onChange: handleRemoveZone,
                styles: customStyles,
              }}
              debounce={500}
              apiKey="AIzaSyBO7bgJHYA57H4BbzKTub3XxLpcfXxxq-Q"
            />
          </div>
          {error && (
            <div className="invalid-feedback">
              <div>{error}</div>
            </div>
          )}
        </div>
      </div>
      <div>
        {isLoaded && zones.length ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
            options={{
              scrollwheel: false,
            }}
          >
            {/** codice sotto è per un futuro drawer */}

            {/*   <DrawingManager
              onPolygonComplete={async (newPoligon) => {
                //@ts-ignore
                const coords = newPoligon
                  .getPaths()
                  .getArray()[0] //@ts-ignore
                  ["Sc"].map(({ lat, lng }) => {
                    return { lat: lat(), lng: lng() };
                  });
                const center = coordGetCenter(coords);
                const place = await geocodeByLatLng(center);
                console.log(place);
                console.log(getNameZone(place, "neighborhood", "locality"));
              }}
              options={{
                drawingControlOptions: {
                  position: google.maps.ControlPosition.TOP_CENTER,
                  drawingModes: [google.maps.drawing.OverlayType.POLYGON],
                },
                polygonOptions: {
                  editable: true,
                  fillColor: "#7A8EF1",
                  fillOpacity: 0.2,
                  strokeColor: "#3B54D4",
                  strokeOpacity: 0.8,
                  strokeWeight: 3,
                },
              }}
            /> */}
            {zones.map((zone, index) => (
              <Polygon
                key={index}
                options={{
                  fillColor: "#7A8EF1",
                  fillOpacity: 0.2,
                  strokeColor: "#3B54D4",
                  strokeOpacity: 0.8,
                  strokeWeight: 3,
                }}
                path={zone.coords}
                editable
                onLoad={(element) => {
                  refsArray.current[index] = {
                    ...element,
                    name: zones[index].name,
                  };

                  setterZoom();
                }}
                onMouseUp={() => {
                  setZones((prev) => [
                    ...prev.map((zone) => {
                      if (zone.name === refsArray.current[index].name) {
                        //@ts-ignore
                        zone.coords = getLatLngFromRefs(
                          refsArray.current[index].latLngs
                        ).map((latLng: any) => {
                          return { lat: latLng.lat(), lng: latLng.lng() };
                        });

                        zone.center = getDataFromCoords(
                          zone.coords,
                          "center"
                        ) as Coord;
                        zone.area = SphericalUtil.computeArea(zone.coords);
                        zone.perimeter = SphericalUtil.computeLength(
                          zone.coords
                        );
                        zone.bounds = getDataFromCoords(
                          zone.coords,
                          "bounds"
                        ) as Bounds;
                      }
                      return zone;
                    }),
                  ]);
                }}
              />
            ))}
          </GoogleMap>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default AddressInputDemandCustomer;
