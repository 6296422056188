import { NestedKeys } from "advanced-types";
import React, { FC, useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { Translation } from "../../../../assets/i18n/locals/en";
import { useI18n } from "../../../../hooks/useI18n";
import { RootState } from "../../../../redux/reducers";
import {
  setCanChangeStepForm,
  setIsSubmit,
} from "../../../../redux/reducers/realEstate";
import getClasses from "../../../../Utils/getClasses";
import Button from "../../Button/Button";
import "./StepperForm.scss";

export interface IStepperForm {
  stages: {
    label: NestedKeys<Translation, ".">;
    link:
      | "general"
      | "price"
      | "description"
      | "location"
      | "other-info"
      | "certifications"
      | "zone-characteristics"
      | "images";
  }[];
}
export interface IStepperFormLand {
  stages: {
    label: NestedKeys<Translation, ".">;
    link:
      | "general"
      | "price"
      | "description"
      | "location"
      | "other-info"
      | "zone-characteristics"
      | "images";
  }[];
}

const StepperForm: FC<IStepperForm | IStepperFormLand> = ({ stages }) => {
  const { pathname } = useLocation();
  const canChangeStepForm = useSelector(
    ({ realEstate: { canChangeStepForm } }: RootState) => canChangeStepForm
  );
  const realEstateTypeFromStore = useSelector(
    ({ realEstate }: RootState) => realEstate.putRealEstate?.type
  );
  const [realEstateType, setRealEstateType] = useState(realEstateTypeFromStore);
  const dispatch = useDispatch();
  useEffect(() => {
    setRealEstateType(realEstateTypeFromStore);
  }, [realEstateTypeFromStore]);
  const indexCurrentStage = useMemo(
    () => stages.findIndex(({ link }) => pathname.includes(link)),
    [pathname, stages]
  );
  const { t, language } = useI18n();

  const stepperFormMemo = useMemo(() => {
    return (
      <>
        <div className="row col-lg-12">
          <div className="col-lg-12 overflow-auto">
            <div className="stepper_line">
              <div
                className={`${getClasses({
                  visibility_hidden: indexCurrentStage === 0,
                })}`}
              >
                <Button
                  className="button-back"
                  onClick={() => {
                    if (!canChangeStepForm) return;
                    dispatch(setCanChangeStepForm(false));
                    dispatch(setIsSubmit({ submit: true, back: true }));
                  }}
                  noHover
                  icon={"icon-ch-left"}
                  color="tertiary"
                  noBorder
                >
                  {t("realEstate.forms.stepperForm.back")}
                </Button>
              </div>
              {realEstateType !== undefined ? (
                <div className="center_column">
                  <div className="text_stage">
                    {t(stages[indexCurrentStage].label)}
                  </div>
                  <div className="text_step">
                    {" "}
                    {`Step ${indexCurrentStage + 1}/${stages.length}`}
                  </div>
                </div>
              ) : null}
              <div
                className={`${getClasses({
                  "visibility_hidden pointer-events-none":
                    indexCurrentStage >= stages.length - 1,
                })}`}
              >
                <Button
                  className="button-next"
                  iconPosition="right"
                  color="tertiary"
                  noHover
                  noBorder
                  icon={"icon-ch-right"}
                  onClick={() => {
                    if (!canChangeStepForm) return;
                    dispatch(setCanChangeStepForm(false));
                    dispatch(setIsSubmit(true));
                  }}
                >
                  {t("realEstate.forms.stepperForm.next")}
                </Button>
              </div>
            </div>
            <div className="progress_custom">
              <div
                className="progress_actived"
                style={{
                  width: `${(100 / stages.length) * (indexCurrentStage + 1)}%`,
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="">
          <Outlet />
        </div>
      </>
    );
    // eslint-disable-next-line
  }, [
    indexCurrentStage,
    language,
    t,
    canChangeStepForm,
    stages,
    realEstateType,
  ]);

  return <>{stepperFormMemo}</>;
};

export default StepperForm;
