import { NestedKeys } from "advanced-types";
import React, { FC, Fragment } from "react";
import { Translation } from "../../../assets/i18n/locals/en";
import { infoLabelCommercial } from "../../../forms/realeEstate/Commercial/otherInfoCommercial";
import { infoLabel } from "../../../forms/realeEstate/otherInfo";
import { infoLabelLand } from "../../../forms/realeEstate/Land/otherInfoLand";
import { zoneLabel } from "../../../forms/realeEstate/zoneCharacteristics";
import { useI18n } from "../../../hooks/useI18n";
import { RealEstateCommercial } from "../../../models/RealeEstateCommercial";
import { RealEstateLand } from "../../../models/RealEstateLand";
import {
  RESIDENTIAL_INFO_ENTITY,
  RealEstate,
  ZONE,
} from "../../../models/RealEstate";
import "./Conditions.scss";

interface IConditions {
  realEstate: RealEstate | RealEstateCommercial | RealEstateLand;
}
const allInfoLabel = [...infoLabel, ...infoLabelCommercial, ...infoLabelLand];
type InfoLabel = typeof allInfoLabel;
type IZone = typeof zoneLabel;

const Conditions: FC<IConditions> = ({ realEstate }) => {
  const { t } = useI18n();

  const getTranslationLabel = <T extends InfoLabel | IZone>(
    array: T,
    currentValue: T extends InfoLabel ? RESIDENTIAL_INFO_ENTITY : ZONE
  ) =>
    (array as { value: string; label: NestedKeys<Translation, "."> }[]).find(
      ({ value }) => value === currentValue
    )?.label || "";

  const { zoneCharacteristics, otherInfo } = realEstate;

  const renderConditionsZoneharacteristics = () => (
    <div className="row">
      {zoneCharacteristics.sort().map((characteristic) => (
        <div key={characteristic} className="col-lg-4 condition">
          {t(getTranslationLabel(zoneLabel, characteristic))}
        </div>
      ))}
    </div>
  );

  const renderConditionsOtherInfo = () => (
    <div className="row">
      {otherInfo.sort().map(({ entity, status, squareMeters }) => {
        const hasDisplayValue = status || squareMeters;
        const translatedLabel = t(getTranslationLabel(allInfoLabel, entity));

        if (!translatedLabel && !hasDisplayValue) {
          return null;
        }

        const separator = hasDisplayValue ? ": " : "";

        return (
          <div key={entity} className="col-lg-4 condition">
            {`${translatedLabel}${separator}${hasDisplayValue || ""}`}
          </div>
        );
      })}
    </div>
  );

  return (
    <Fragment>
      <p className="specific-information-title mt-5 mb-4">
        {t("realEstate.forms.compactRealEstate.otherCharacteristics.title")}
      </p>
      {renderConditionsOtherInfo()}
      <p className="specific-information-title mt-5 mb-4">
        {t("realEstate.forms.compactRealEstate.zoneCharacteristics.title")}
      </p>
      {renderConditionsZoneharacteristics()}
    </Fragment>
  );
};

export default Conditions;
