import { ICustomForm } from "../../models/CustomForm";



export type Image = {
  title: string;
  id: string;
  description: string;
  isPublic: boolean;
  url: string;
  thumbnail: string;
  tag: IMAGE_TAG;
};

export enum IMAGE_TAG {
  GENERAL = "general",
  PLANIMETRY = "planimetry",
  ROOF_TERRACE = 'roofTerrace', // altana
  OTHER = 'other',
  AMERICAN_ENSUITE = 'americanEnsuite',
  ANTEROOM = 'anteroom', // anticamera
  WARDROBE = 'wardrobe', // armadio o guardaroba
  DRYING_ROOM = 'dryingRoom', // asciugatoio
  BATHROOM = 'bathroom',
  BALCONY = 'balcony',
  BAR = 'bar',
  BOX = 'box',
  KIDS_ROOM = 'kidsRoom', // camera bimbi
  ROOM_WITH_BATHROOM = 'roomWithBathroom', // camera con bagno
  BEDROOM = 'bedroom',
  SECURITY_ROOM = 'securityRoom',
  FAMILY_ROOM = 'familyRoom',
  DOUBLE_ROOM = 'doubleRoom', // camera matrimoniale
  CELLAR = 'cellar', // cantina
  CORRIDOR = 'corridor', // corridoio
  KITCHEN = 'kitchen',
  HABITABLE_KITCHEN = 'habitableKitchen',
  GAMES_ROOM = 'gamesRoom',
  GARDEN = 'garden',
  WINTER_GARDEN = 'winterGarden',
  ENTRANCE = 'entrance',
  LABORATORY = 'laboratory',
  LAUNDRY = 'laundry',
  WOODSHED = 'woodshed', // legnaia/carbonaia
  LIBRARY = 'library',
  TOOL_ROOM = 'toolRoom',
  BOILER_ROOM = 'boilerRoom',
  TANK_ROOM = 'tankRoom',
  SHOWER_ROOM = 'showerRoom',
  LOFT = 'loft',
  LODGE = 'lodge',
  LUNCH_ROOM = 'lunchRoom',
  WAREHOUSE = 'warehouse',
  MAID_ROOM = 'maidRoom',
  GYM = 'gym',
  PARTY_ROOM = 'partyRoom',
  PARKING_PLACE = 'parkingPlace',
  PRIVATE_ROOM = 'privateRoom',
  RESTING_ROOM = 'restingRoom',
  CLOSET = 'closet',
  LUNCHROOM = 'lunchroom',
  LIVING_ROOM = 'livingRoom',
  DOUBLE_LIVING_ROOM = 'doubleLivingRoom',
  LOUNGE = 'lounge', // salotto
  SAUNA = 'sauna',
  GREEN_HOUSE = 'greenHouse',
  SOLARIUM = 'solarium',
  L_ROOM = 'lRoom',
  T_ROOM = 'tRoom',
  U_ROOM = 'uRoom',
  Z_ROOM = 'zRoom',
  STUDIO = 'studio',
  TERRACE = 'terrace',
  DINING_ROOM = 'diningRoom', // dinello
  TOILETTE = 'toilette',
  TOY_ROOM = 'toyRoom',
}


export const initialImagesForm = {
  images: [],
};
export type ImagesForm = {
  images: Image[];
};
export const imagesForm: ICustomForm<ImagesForm> = {
  labelSubmitButton: {edit:"editImage.save", show: "editImage.save"},
  hiddenBackButton: true,
  next: "price",
  forms: [
    {
      title: "",
      fields: [
        {
          column: 12,
          label: "realEstate.forms.images.images",
          type: "images",
          isRequired: true,
          key: "images",
        },
      ],
    },
  ],
};
