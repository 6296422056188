import React, { FC } from "react";
import "./TextInput.scss";

interface ITextInput {
  className?: string;
  onChange: (valueInput: string) => void;
  onBlur?: (valueInput: string) => void;
  placeHolder?: string;
  value: string;
}

const TextInput: FC<ITextInput> = ({
  className = "",
  onChange,
  placeHolder = "",
  value,
  onBlur,
}) => {
  return (
    <div className={`form-group mb-0 ${className}`}>
      <input
        key={"consultantInput"}
        type={"text"}
        value={value}
        onBlur={({ target: { value } }) => onBlur && onBlur(value)}
        onChange={({ target: { value } }) => onChange(value)}
        className={`form-control p-md input-text`}
        placeholder={placeHolder}
      />
    </div>
  );
};

export default TextInput;
