import { ICustomForm } from "../../models/CustomForm";
export interface IPriceForm {
  requestedPrice: number;
  estimatedValue: number;
  condominiumFees?: number;
  boxIncluded?: boolean;
  negotiable?: boolean;
  startingFrom?: boolean;
}

export const initialValuePriceForm = {
  requestedPrice: 0,
  estimatedValue: 0,
  condominiumFees: 0,
  boxIncluded: false,
  negotiable: false,
  startingFrom: false,
};

export const priceForm: ICustomForm<IPriceForm> = {
  title: {
    edit: "realEstate.forms.price.title",
    show: "realEstate.forms.price.title",
  },
  description: {
    edit: "realEstate.forms.price.description.edit",
    show: "realEstate.forms.price.description.show",
  },
  // labelSubmitButton: {edit:"realEstate.forms.price.labelSubmit.edit", show: "realEstate.forms.price.labelSubmit.show"},
  labelSubmitButton: { edit: "editImage.save", show: "editImage.save" },
  next: "location", //TOGLIEREEEEEE
  forms: [
    {
      title: "",
      fields: [
        {
          label: "realEstate.forms.price.requestedPrice",
          column: 2,
          typeField: "currency",
          isRequired: true,
          type: "number",
          key: "requestedPrice",
        },
        {
          label: "realEstate.forms.price.estimatedValue",
          column: 2,
          typeField: "currency",
          maxColumn: 12,
          isRequired: true,
          type: "number",
          key: "estimatedValue",
        },
        {
          label: "realEstate.forms.price.condominiumFees",
          typeField: "currency",
          column: 2,
          type: "number",
          key: "condominiumFees",
        },
        {
          label: "",
          column: 10,
          type: "checkbox",
          key: "boxIncluded",
          tooltip: {
            label: "realEstate.forms.price.box.tooltip",
          },
          option: { label: "realEstate.forms.price.box.option", value: false },
        },
      ],
    },
    {
      title: "realEstate.forms.price.priceOption.title",
      fields: [
        {
          label: "",
          column: 3,
          type: "checkbox",
          key: "negotiable",
          option: {
            label: "realEstate.forms.price.priceOption.negotiable.option",
            value: false,
          },
          tooltip: {
            label: "realEstate.forms.price.priceOption.negotiable.tooltip",
          },
        },
        {
          label: "",
          column: 3,
          type: "checkbox",
          key: "startingFrom",
          option: {
            label: "realEstate.forms.price.priceOption.startingFrom.option",
            value: false,
          },
          tooltip: {
            label: "realEstate.forms.price.priceOption.startingFrom.tooltip",
          },
        },
      ],
    },
  ],
};
