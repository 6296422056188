import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RespMatches } from '../../models/RespMatches'

interface MatchesState {
  matches: RespMatches | undefined
}

const initialState: MatchesState = {
  matches: undefined
}

const matchesSlice = createSlice({
  name: 'matches',
  initialState,
  reducers: {
    setMatches(state: MatchesState, action: PayloadAction<RespMatches | undefined>) {
      state.matches = action.payload
    }
  },
})

export const { setMatches } = matchesSlice.actions
export default matchesSlice.reducer