import { NestedKeys } from "advanced-types";
import { Translation } from "../../../assets/i18n/locals/en";
import { ICustomForm } from "../../../models/CustomForm";
import {
  AIR_CONDITIONING_TYPE,
  COMMERCIAL_INFO_ENTITY,
  ELECTRICAL_SYSTEM_STATUS,
  EntityInformation,
  TV_STATUS,
} from "../../../models/RealEstate";

export const infoLabelCommercial: {
  label: NestedKeys<Translation, "."> | "";
  squareMeters?: number;
  value: COMMERCIAL_INFO_ENTITY;
  selectOptions?: {
    label: NestedKeys<Translation, "."> | "";
    value: ELECTRICAL_SYSTEM_STATUS | TV_STATUS | AIR_CONDITIONING_TYPE;
  }[];
}[] = [
  {
    label: "selectOptions.otherInfoLabel.ALARM",
    value: COMMERCIAL_INFO_ENTITY.ALARM,
  },
  {
    label: "selectOptions.otherInfoLabel.ANTIFIRE",
    value: COMMERCIAL_INFO_ENTITY.ANTIFIRE,
  },
  {
    label: "selectOptions.otherInfoLabel.REFRIGERATOR",
    value: COMMERCIAL_INFO_ENTITY.REFRIGERATOR,
  },
  {
    label: "selectOptions.otherInfoLabel.GENERATOR",
    value: COMMERCIAL_INFO_ENTITY.GENERATOR,
  },
  {
    label: "selectOptions.otherInfoLabel.SEA_VIEW",
    value: COMMERCIAL_INFO_ENTITY.SEA_VIEW,
  },
  {
    label: "selectOptions.otherInfoLabel.LAKE_VIEW",
    value: COMMERCIAL_INFO_ENTITY.LAKE_VIEW,
  },
  {
    label: "selectOptions.otherInfoLabel.AIR_CONDITIONING",
    value: COMMERCIAL_INFO_ENTITY.AIR_CONDITIONING,
    selectOptions: [
      { label: "", value: AIR_CONDITIONING_TYPE.NOT_PROVIDED },
      {
        label: "selectOptions.otherInfoLabel.AIR_CONDITIONING_TYPE.ABSENT",
        value: AIR_CONDITIONING_TYPE.ABSENT,
      },
      {
        label: "selectOptions.otherInfoLabel.AIR_CONDITIONING_TYPE.CENTRALIZED",
        value: AIR_CONDITIONING_TYPE.CENTRALIZED,
      },
      {
        label: "selectOptions.otherInfoLabel.AIR_CONDITIONING_TYPE.AUTONOMOUS",
        value: AIR_CONDITIONING_TYPE.AUTONOMOUS,
      },
      {
        label:
          "selectOptions.otherInfoLabel.AIR_CONDITIONING_TYPE.PREDISPOSITION",
        value: AIR_CONDITIONING_TYPE.PREDISPOSITION,
      },
    ],
  },
  {
    label: "selectOptions.otherInfoLabel.ELECTRICAL_SYSTEM",
    value: COMMERCIAL_INFO_ENTITY.ELECTRICAL_SYSTEM,
    selectOptions: [
      { label: "", value: ELECTRICAL_SYSTEM_STATUS.NOT_PROVIDED },
      {
        label: "selectOptions.otherInfoLabel.ELECTRICAL_SYSTEM_STATUS.ABSENT",
        value: ELECTRICAL_SYSTEM_STATUS.ABSENT,
      },
      {
        label: "selectOptions.otherInfoLabel.ELECTRICAL_SYSTEM_STATUS.LEGAL",
        value: ELECTRICAL_SYSTEM_STATUS.LEGAL,
      },
      {
        label:
          "selectOptions.otherInfoLabel.ELECTRICAL_SYSTEM_STATUS.NOT_LEGAL",
        value: ELECTRICAL_SYSTEM_STATUS.NOT_LEGAL,
      },
    ],
  },
];

export interface IOtherInfoForm {
  otherInfo: EntityInformation[];
}
export const initialValueOtherInfoFormCommercial = {
  otherInfo: [
    {
      entity: {},
      status: {},
      squareMeters: 0,
    },
  ],
};

export const otherInfoFormCommercial: ICustomForm<IOtherInfoForm> = {
  title: {
    edit: "realEstate.forms.otherInfo.title",
    show: "realEstate.forms.otherInfo.title",
  },
  description: {
    edit: "realEstate.forms.otherInfo.description.edit",
    show: "realEstate.forms.otherInfo.description.show",
  },
  // labelSubmitButton: {edit:"realEstate.forms.otherInfo.labelSubmit.edit", show: "realEstate.forms.otherInfo.labelSubmit.show"},
  labelSubmitButton: { edit: "editImage.save", show: "editImage.save" },
  next: "zone-characteristics",
  forms: [
    {
      title: "",
      fields: [
        {
          column: 12,
          columnRadio: 3,
          label: "",
          key: "otherInfo",
          type: "entity-information",
          multiple: true,
          entityInformationOptions: [...infoLabelCommercial],
        },
      ],
    },
  ],
};
