import { Image } from "../forms/realeEstate/images";
import { RATING } from "./Customer";

export enum REAL_ESTATE_TYPE {
  RESIDENTIAL = "residential",
  COMMERCIAL = "commercial",
  NOT_PROVIDED = "notProvided",
  LAND = "land",
}
export enum REAL_ESTATE_STATE {
  NOT_PROVIDED = "notProvided",
  ACTIVE = "active",
  REMOVED_FROM_MARKET = "removedFromMarket",
}

export enum WARRANTY {
  NONE = "none", // nessuna
  SECURITY_DEPOSIT = "securityDeposit", // deposito cauzionale
  INSURANCE_POLICY = "insurancePolicy", // polizza assicurativa
  BANK_GUARANTEE = "bankGuarantee", // fideiussione bancaria
  NOT_PROVIDED = "notProvided",
}
export enum CONTRACT_REGISTRATION {
  OWNER = "owner", // a carico del proprietario
  AGENCY = "agency", // a carico agenzia
  NOT_PROVIDED = "notProvided",
}

export enum RENT_CONTRACT {
  THREE_PLUS_TWO = "threePlusTwo", // 3+2
  THREE_PLUS_TWO_TAX = "threePlusTwoTax", // 3+2 cedolare
  FOUR_PLUS_TWO = "fourPlusTwo", // 4+2
  FOUR_PLUS_FOUR = "fourPlusFour", // 4+4
  FOUR_PLUS_FOUR_TAX = "fourPlusFourTax", // 4+4 cedolare
  SIX_PLUS_TWO = "sixPlusTwo", // 6+2
  SIX_PLUS_SIX = "sixPlusSix", // 6+6
  ROOM = "room", // stanza
  TRANSITIONAL = "transitional", // transitorio
  TRANSITIONAL_TAX = "transitionalTax", // transitorio cedolare
  NOT_PROVIDED = "notProvided",
}

export interface RentDetails {
  contractType: RENT_CONTRACT; // tipo di contratto
  requestedWarranty: WARRANTY; // garanzia richiesta
  contractRegistration: CONTRACT_REGISTRATION; // registrazione contratto
  dryTax: boolean; // cedolare secca
}

export interface Mediation {
  type: MEDIATION;
  from?: Date | null | string;
  to?: Date | null | string;
}

export enum ACQUISITION_SOURCE {
  SIGNALER = "signaler",
  ADVERTISEMENT = "advertisement",
  NOT_PROVIDED = "notProvided",
}
export enum MEDIATION {
  EXCLUSIVE = "exclusive",
  NOT_EXCLUSIVE = "notExclusive",
  NOT_PROVIDED = "notProvided",
}
export enum APPROVAL_STATUS {
  DRAFT = "draft",
  PENDING = "pending",
  APPROVED = "approved",
  SOLD = "sold",
  SUSPENDED = "suspended",
  SOLDSUSPENDED = "soldSuspended",
}
export enum CONTRACT_TYPE {
  RENT = "rent",
  SALE = "sale",
  NOT_PROVIDED = "notProvided",
}
export enum AVAILABILITY {
  PRESENT = "present",
  ABSENT = "absent",
  NOT_PROVIDED = "notProvided",
}
export enum PROPERTY_TYPE {
  OTHER = "other",
  APPARTMENT = "appartment",
  ATTIC = "attic",
  CHALET = "chalet",
  INDEPENDENT_HOUSE = "independentHouse",
  SEMI_INDEPENDENT_HOUSE = "semiIndependentHouse",
  VACATION_HOME = "vacationHome",
  FARMHOUSE = "farmhouse", //Cascina o casale
  CASTLE = "castle",
  DUPLEX = "duplex",
  FARM = "farm", //Fattoria
  LOFT = "loft",
  MULTI_PROPRIETY = "multiPropriety",
  NEW_CONSTRUCTION = "newConstruction", //Nuove costruzioni
  HOUSE_SECTION = "houseSection", //Porzione di casa
  VILLA_SECTION = "villaSection", //Porzione di villa
  RESIDENCE = "residence",
  RUSTIC = "rustic",
  BASEMENT = "basement", //Seminterrato
  ROOM = "room",
  TERRACED_HOUSE = "terracedHouse", //Villa a schiera
  VILLA = "villa",
  SINGLE_VILLA = "singleVilla", //Villa singola
  SEMI_DETACHED_VILLA = "semiDetachedVilla", //Villa bifamiliare
  NOT_PROVIDED = "notProvided",
  NON_BUILDING_LAND = "nonBuildingLand",
  BUILDING_LAND = "buildingLand",
  AGRICULTURAL_LAND = "agriculturalLand",
  COMMERCIAL_LAND = "commercialLand",
}
export enum CURRENT_STATE {
  NOT_PROVIDED = "notProvided",
  UNDER_CONSTRUCTION = "underConstruction",
  FREE_TO_DEED = "freeToDeed", //Libero al rogito
  LEASED = "leased", //Dato in locazione
}
export interface AvailabilityStatus {
  availableNow: boolean;
  date?: string;
  notes?: string;
}
export interface EstatePrice {
  requestedPrice: number;
  estimatedValue: number;
  condominiumFees?: number;
  boxIncluded?: boolean;
  negotiable?: boolean;
  startingFrom?: boolean;
}
export enum ELEVATOR_INFO {
  YES = "yes",
  NO = "no",
  NOT_PROVIDED = "notProvided",
}
export interface EstateLocation {
  [key: string]: any;
}
export enum ROOM_PERTINENCE {
  KITCHEN = "kitchen",
  BALCONIES = "balconies",
  TERRACE = "terrace",
  GARDEN = "garden",
  TERRAIN = "terrain",
  BOX = "box",
  COVERED_PARKING_SPACES = "coveredParkingSpaces", // posti auto coperti
  OPEN_PARKING_SPACES = "openParkingSpaces", // posti auto scoperti
  BIKE_PARKING_SPACES = "bikeParkingSpaces", // posti moto
  CAMPER_PARKING_SPACES = "camperParkingSpaces", // posti camper
  BOAT_PARKING_SPACES = "boatParkingSpaces", // posti barca
  CELLAR = "cellar", // cantina
  WAREHOUSE = "warehouse", // magazzino
  DEPOT = "depot", // deposito
  LOFT = "loft", // soffitta
  ATTIC = "attic", // solaio
  SHED = "shed", // rimessa
  STABLE = "stable", // stalla
  NOT_PROVIDED = "notProvided",
}
export enum KITCHEN_TYPE {
  AT_SIGHT = "atSight", // a vista
  HABITABLE = "habitable",
  SEMI_HABITABLE = "semiHabitable",
  KITCHENETTE = "kitchenette", // angolo cottura / cucinotto
  FOOD_COOK = "foodCook", // cuocivivande,
  NOT_PROVIDED = "notProvided",
  ABSENT = "absent",
  PRESENT = "present",
}
export enum GARDEN_TYPE {
  IN_COMMON = "inCommon", // comune,
  PRIVATE = "private",
  NOT_PROVIDED = "notProvided",
  ABSENT = "absent",
}
export enum BOX_TYPE {
  PRIVATE = "private",
  SINGLE = "single",
  DOUBLE = "double",
  TRIPLE = "triple",
  QUADRUPLE = "quadruple",
  SHED = "shed", // rimessa
  ABSENT = "absent",
  NOT_PROVIDED = "notProvided",
}
export enum ROOM_PERTINENCE_TYPE {
  PRESENT = "present",
  ABSENT = "absent",
  NOT_PROVIDED = "notProvided",
}
export enum ROOM_PERTINENCE_EQUIPMENT {
  EQUIPPED_AND_FURNISHED = "equippedAndFurnished", // arredata ed equipaggiata
  EQUIPPED_NOT_FURNISHED = "equippedNotFurnished", // equipaggiata ma non arredata
  NOT_EQUIPPED_FURNISHED = "notEquippedFurnished", // non arredata ne equipaggiata
  NOT_PROVIDED = "notProvided",
}
export interface RoomPertinenceDetails {
  name: ROOM_PERTINENCE;
  type?:
    | ROOM_PERTINENCE_TYPE
    | KITCHEN_TYPE
    | GARDEN_TYPE
    | BOX_TYPE
    | undefined;
  count?: number; // Nr
  equipment?: ROOM_PERTINENCE_EQUIPMENT; // Dotazione
  squareMeters?: number; // metri quadrati
  dimensions?: string;
}
export enum ROOM {
  ROOF_TERRACE = "roofTerrace", // altana
  OTHER = "other",
  AMERICAN_ENSUITE = "americanEnsuite",
  ANTEROOM = "anteroom", // anticamera
  WARDROBE = "wardrobe", // armadio o guardaroba
  DRYING_ROOM = "dryingRoom", // asciugatoio
  BATHROOM = "bathroom",
  BALCONY = "balcony",
  BAR = "bar",
  BOX = "box",
  KIDS_ROOM = "kidsRoom", // camera bimbi
  ROOM_WITH_BATHROOM = "roomWithBathroom", // camera con bagno
  BEDROOM = "bedroom",
  SECURITY_ROOM = "securityRoom",
  FAMILY_ROOM = "familyRoom",
  DOUBLE_ROOM = "doubleRoom", // camera matrimoniale
  CELLAR = "cellar", // cantina
  CORRIDOR = "corridor", // corridoio
  KITCHEN = "kitchen",
  HABITABLE_KITCHEN = "habitableKitchen",
  GAMES_ROOM = "gamesRoom",
  GARDEN = "garden",
  WINTER_GARDEN = "winterGarden",
  ENTRANCE = "entrance",
  LABORATORY = "laboratory",
  LAUNDRY = "laundry",
  WOODSHED = "woodshed", // legnaia/carbonaia
  LIBRARY = "library",
  TOOL_ROOM = "toolRoom",
  BOILER_ROOM = "boilerRoom",
  TANK_ROOM = "tankRoom",
  SHOWER_ROOM = "showerRoom",
  LOFT = "loft",
  LODGE = "lodge",
  LUNCH_ROOM = "lunchRoom",
  WAREHOUSE = "warehouse",
  MAID_ROOM = "maidRoom",
  GYM = "gym",
  PARTY_ROOM = "partyRoom",
  PARKING_PLACE = "parkingPlace",
  PRIVATE_ROOM = "privateRoom",
  RESTING_ROOM = "restingRoom",
  CLOSET = "closet",
  LUNCHROOM = "lunchroom",
  LIVING_ROOM = "livingRoom",
  DOUBLE_LIVING_ROOM = "doubleLivingRoom",
  LOUNGE = "lounge", // salotto
  SAUNA = "sauna",
  GREEN_HOUSE = "greenHouse",
  SOLARIUM = "solarium",
  L_ROOM = "lRoom",
  T_ROOM = "tRoom",
  U_ROOM = "uRoom",
  Z_ROOM = "zRoom",
  STUDIO = "studio",
  TERRACE = "terrace",
  DINING_ROOM = "diningRoom", // dinello
  TOILETTE = "toilette",
  TOY_ROOM = "toyRoom",
  NOT_PROVIDED = "notProvided",
}
export interface RoomDetails {
  type: ROOM;
  dimensions?: string;
  squareMeters?: number;
}
export enum HEATING_TYPE {
  AUTONOMOUS = "autonomous",
  CENTRALIZED = "centralized",
  CENTRALIZED_WITH_METER = "centralizedWithMeter", // centralizzato con contabilizzatore
  AIR_CONDITIONED = "airConditioned",
  ABSENT = "absent",
  NOT_PROVIDED = "notProvided",
}

export enum HEATING_FUEL {
  METHANE = "methane",
  DIESEL = "diesel",
  GPL = "gpl",
  AIR_ELECTRIC = "airElectric",
  PELLET = "pellet",
  WOOD = "wood",
  WOOD_CHIPS = "woodChips", // cippato
  SOLAR_PANELS = "solarPanels", // pannelli fotovoltaici
  OTHER = "other",
  NOT_PROVIDED = "notProvided",
}

export enum FLOOR {
  ATTIC = "attic",
  EARTH_RETAINING_WALL = "earthRetainingWall", // controterra
  BUILDING = "building", // edificio,
  MULTISTOREY = "multistorey", // multipiano
  NOBLE_FLOOR = "nobleFloor", // piano nobile
  MEZZANINE = "mezzanine", // piano rialzato
  GROUND_FLOOR = "groundFloor", // piano terra
  BASEMENT = "basement", // seminterrato
  ON_TWO_LEVELS = "onTwoLevels", // su due livelli
  TOP_FLOOR = "topFloor", // ultimo
  NOT_PROVIDED = "notProvided",
}
export enum POSITION {
  CENTRAL = "central",
  MALL = "mall", // centro commerciale
  ISOLATED = "isolated", // isolata
  SEAFRONT = "seafront", // lungomare
  PERIPHERAL = "peripheral", // periferica
  SEMICENTRAL = "semicentral",
  HIGH_TRAFFIC_STREET = "highTrafficStreet", // strada alto traffico
  TOURIST = "tourist",
  AGRICULTURAL_AREA = "agriculturalArea", // zona agricola
  BOOMING_AREA = "boomingArea", // zona in forte espansione
  INDUSTRIAL_AREA = "industrialArea", // zona industriale
  MIXED_AREA = "mixedArea", // zona mista
  PEEP_AREA = "peepArea", // zona peep
  RESIDENTIAL_AREA = "residentialArea", // zona residenziale
  SERVED_AREA = "servedArea",
  NOT_PROVIDED = "notProvided",
}

export enum HEATING_SYSTEM {
  RADIATOR = "radiator",
  FLOOR = "floor",
  AIR = "air",
  HEATER = "heater",
  NOT_PROVIDED = "notProvided",
}
export interface HeatingDetails {
  type: HEATING_TYPE;
  fuel?: HEATING_FUEL;
  system?: HEATING_SYSTEM;
  radiators?: number;
  expenses?: number;
}
export enum QUALITY {
  CLASSIC_ELEGANT = "classicElegant", // classico signorile
  VINTAGE = "vintage", // epoca
  MODERN_MEDIUM = "modernMedium", // moderno medio
  MODERN_CLASSIC = "modernClassic", // moderno signorile
  POPULAR = "popular",
  CLASSIC = "classic", // signorile
  HISTORIC = "historic", // storico
  NOT_PROVIDED = "notProvided",
}
export enum HABITABILITY {
  IMMEDIATE = "immediate",
  NOT_HABITABLE = "notHabitable",
  NOT_PROVIDED = "notProvided",
}
export enum CONSERVATION_STATUS {
  GOOD = "good",
  NEEDS_CLEANING = "needsCleaning",
  NEEDS_RESTRUCTURING = "needsRestructuring",
  DECENT = "decent", // discreto
  ROUGH = "rough", // grezzo
  MEDIOCRE = "mediocre",
  GREAT = "great", // ottimo
  RESTRUCTURED = "restructured",
  NOT_PROVIDED = "notProvided",
}
export enum FURNITURE_STATUS {
  FURNISHED = "furnished",
  NOT_FURNISHED = "notFurnished",
  PARTIALLY_FURNISHED = "partiallyFurnished",
  PARTIALLY_FURNISHED_KITCHEN = "partiallyFurnishedKitchen",
  NOT_PROVIDED = "notProvided",
}
export interface AdditionalDetails {
  quality: QUALITY;
  habitability: HABITABILITY;
  conservationStatus: CONSERVATION_STATUS;
  windows?: number;
  alarm: AVAILABILITY;
  furnishedDescription: string;
  reception: AVAILABILITY;
  intercom: AVAILABILITY;
  furniture: FURNITURE_STATUS;
  wardrobe: boolean;
  exposition: string;
}
export enum ENERGY_CLASS {
  A4 = "a4",
  A3 = "a3",
  A2 = "a2",
  A1 = "a1",
  B = "b",
  C = "c",
  D = "d",
  E = "e",
  F = "f",
  G = "g",
  NOT_AVAILABLE = "notAvailable",
  NOT_PROVIDED = "notProvided",
}
export enum ENERGY_CLASS_OLD {
  A_PLUS = "aPlus",
  A = "a",
  B = "b",
  C = "c",
  D = "d",
  E = "e",
  F = "f",
  G = "G",
  NOT_APPLICABLE = "notApplicable",
  NOT_DECLARED = "notDeclared",
  EVALUATED = "evaluated",
  NOT_PROVIDED = "notProvided",
}
export enum SOUND_CLASS {
  A = "a",
  B = "b",
  C = "c",
  D = "d",
  NOT_PROVIDED = "notProvided",
}
export enum PERFORMANCE_UNIT {
  SQUARE_METERS = "squareMeters",
  CUBIC_METERS = "cubicMeters",
  NOT_PROVIDED = "notProvided",
}
export enum ENERGY_PERFORMANCE {
  HIGH = "high",
  MEDIUM = "medium",
  LOW = "low",
  NOT_PROVIDED = "notProvided",
}
export interface EnergyCertification {
  class: ENERGY_CLASS;
  performanceIndex?: number;
  performanceUnit: PERFORMANCE_UNIT;
  almostZeroEnergy: boolean;
  energyPerformance: {
    winter: ENERGY_PERFORMANCE;
    summer: ENERGY_PERFORMANCE;
  };
}
export interface EnergyCertificationOld {
  class: ENERGY_CLASS_OLD;
  performanceIndex?: number;
  performanceUnit: PERFORMANCE_UNIT;
  notes?: string;
}
export interface SoundCertification {
  class: SOUND_CLASS;
  notes?: string;
}
export interface Certifications {
  energyCertification?: EnergyCertification;
  energyCertificationOld?: EnergyCertificationOld;
  soundCertification?: SoundCertification;
}

export enum AIR_CONDITIONING_TYPE {
  ABSENT = "absent",
  AUTONOMOUS = "autonomous",
  CENTRALIZED = "centralized",
  PREDISPOSITION = "predisposition",
  NOT_PROVIDED = "notProvided",
}

export enum RESIDENTIAL_INFO_ENTITY {
  AIR_CONDITIONING = "airConditioning",
  SHOWER = "shower",
  ALUMINIUM_FIXTURES = "aluminiumFixtures", // infissi alluminio
  PARQUET = "parquet",
  CLOSET = "closet",
  JACUZZI = "jacuzzi", // vasca idromassaggio
  POOL = "pool", // piscina
  TV_ANTENNA = "tvAntenna",
  CHIMNEY = "chimney", // camino
  AT_AUCTION = "atAuction", // all'asta
  WOOD_FIXTURES = "woodFixtures", // infissi legno
  SHUTTERS = "shutters", // persiane / tapparelle
  SUSPENDED_SANITARY = "suspendedSanitary",
  LAKE_VIEW = "lakeView",
  TERRACE = "terrace",
  ELECTRICAL_SYSTEM = "electricalSystem",
  SAFETY_BOX = "safetyBox", // cassetta siciurezza
  PHONE_SYSTEM = "phoneSystem",
  INDEPENDENT_ENTRANCE = "independentEntrance",
  ALARM_PREDISPOSED = "alarmPredisposed",
  SEA_VIEW = "seaView", // vista mare
  PORCH = "porch", // veranda
  SAT_TV = "satTv",
}

export enum COMMERCIAL_INFO_ENTITY {
  ALARM = "alarm",
  GENERATOR = "generator", // gruppo elettrogeno
  SEA_VIEW = "seaView",
  ELECTRICAL_SYSTEM = "electricalSystem",
  ANTIFIRE = "antifire",
  REFRIGERATOR = "refrigerator",
  AIR_CONDITIONING = "airConditioning",
  LAKE_VIEW = "lakeView",
  NOT_PROVIDED = "notProvided",
}

export enum TV_STATUS {
  UNPROVIDED = "unprovided", // sprovvisto
  CONDOMINIUM = "condominium", // condiminio
  AUTONOMOUS = "autonomous", // autonomo
  NOT_PROVIDED = "notProvided",
}
export enum ELECTRICAL_SYSTEM_STATUS {
  ABSENT = "absent",
  LEGAL = "legal", // a norma
  NOT_LEGAL = "notLegal", // non a norma
  NOT_PROVIDED = "notProvided",
}
export interface EntityInformation {
  entity: RESIDENTIAL_INFO_ENTITY;
  status?: TV_STATUS | ELECTRICAL_SYSTEM_STATUS;
  squareMeters?: number;
}
export enum ZONE {
  AIRPORT = "airport",
  BAR = "bar",
  TENNIS_FIELD = "tennisField",
  MALLS = "malls", // centri commerciali
  SPORT_COMPLEX = "sportComplex",
  SKIING = "skiing", // Montagna/Impianti da Sci
  GYM = "gym",
  PINE_FOREST = "pineForest", // pineta
  MIDDLE_SCHOOL = "middleSchool",
  FREEWAY = "freeway", // tangenziale
  POST_OFFICE = "postOffice",
  KINDERGARTEN = "kindergarten", // asilo
  WOODS = "woods", // bosco
  CANAL = "canal",
  CONGRESS_CENTER = "congressCenter",
  LAKE = "lake",
  STORES = "stores", // negozi
  PLAYGROUND = "playground", // parchi giochi
  BICYCLE_LANE = "bicycleLane",
  HIGH_SCHOOL = "highSchool",
  PUBLIC_TRANSPORT = "publicTransport",
  HIGHWAY = "highway", // Autostrada
  FOOTBALL_FIELD = "footballField",
  HEALTH_CENTER = "healthCenter", // centri benessere
  RELIGIOUS_COMPLEX = "religiousComplex", // complessi religiosi
  SEA = "sea",
  HOSPITAL = "hospital",
  PARK = "park",
  ELEMENTARY_SCHOOL = "elementarySchool",
  RAILWAY_STATION = "railwayStation", // stazione ferroviaria
  MUNICIPAL_OFFICES = "municipalOffices", // uffici comunali
}
export enum EXTERNAL_INFO_STATUS {
  YES = "yes",
  NO = "no",
  ON_HOLD = "onHold",
}
export interface ExternalInfo {
  agencyCollaborates?: EXTERNAL_INFO_STATUS;
  commissionCancellation?: boolean;
  commissionCancellationPercentage?: number;
  negotiationInProgress?: EXTERNAL_INFO_STATUS;
}

export interface RealEstate {
  saleSuspensionExpiration: null | Date | string;
  _id: string;
  type: REAL_ESTATE_TYPE;
  acquisitionDate: Date;
  originalConsultant: {
    _id: string;
    name?: string;
    surname?: string;
    email: string;
  };
  consultant: { _id: string; name?: string; surname?: string };
  rating: RATING;
  state: REAL_ESTATE_STATE;
  mediation: Mediation;
  owners: string[];
  ready: boolean;
  acquisitionSource: ACQUISITION_SOURCE[];
  approvalStatus: APPROVAL_STATUS;
  contractType: CONTRACT_TYPE;
  propertyType: PROPERTY_TYPE;
  forStudents: boolean;
  images: Image[];
  rentDetails: RentDetails;
  petsAllowed: boolean;
  bareOwnership: boolean;
  currentState: CURRENT_STATE;
  availability: AvailabilityStatus;
  notes: string;
  updatedAt: Date | string;
  createdAt: Date | string;
  price: EstatePrice;
  location: EstateLocation;
  compoundId: string;
  virtualTourUrl: string;
  videoUrl: string;
  incremental: number;
  constructionYear?: number;
  hasElevator: ELEVATOR_INFO;
  totalApartments?: number;
  architecturalBarriersMissing: boolean;
  totalSquareMeters?: number;
  totalSquareMetersWalkable?: number;
  locals?: number;
  rooms?: number;
  bathrooms?: number;
  description: string;
  roomsPertinencesDetails: RoomPertinenceDetails[];
  otherRoomsDetails: RoomDetails[];
  heatingDetails: HeatingDetails;
  additionalDetails: AdditionalDetails;
  certifications: Certifications;
  otherInfo: EntityInformation[];
  zoneCharacteristics: ZONE[];
  agency: string;
  publishedOnWikicasa: boolean;
  publishedOnIdealista: boolean;
  publishedOnImmobiliare: boolean;
  visibleOnIdealista: boolean;
  visibleOnShowcase: boolean;
  casafariContactInfo: null | {
    agency: string;
    agent: string;
    email: string;
    phone: string;
  };
  externalInfo: ExternalInfo;
  casafariDescription: null | string;
  casafariId: null | number;
  isVerified?: boolean | null;
  isCasafariAuction?: boolean;
  isPrivateProperty?: boolean;
  externalListingUrl?: string;
  priceHistory?: Array<{ price: number; date: Date }>;
  "locals<"?: string;
  "locals>"?: string;
  "price.requestedPrice<"?: string;
  "price.requestedPrice>"?: string;
}
