import { ICustomForm } from "../../../models/CustomForm";
import {
  AVAILABILITY,
  CONSERVATION_STATUS,
  FURNITURE_STATUS,
  HeatingDetails,
  HEATING_FUEL,
  HEATING_SYSTEM,
  HEATING_TYPE,
  RoomDetails,
  RoomPertinenceDetails,
  ROOM_PERTINENCE,
} from "../../../models/RealEstate";
import { fillSelection } from "../../../Utils/fuctions";
import {
  availabilityType,
  boxTypes,
  context,
  countType,
  detailsConservationType,
  detailsFurnitureType,
  dotationTypes,
  feeSelection,
  gardenTypes,
  heatingDetailsFuelType,
  heatingDetailsSystemType,
  heatingDetailsType,
  kitchenTypes,
  roomPertinenceTypes,
  roomSeparator,
  weeklyRest,
} from "../selections";
import {
  CommercialAdditionalDetails,
  CONTEXT,
  FEE,
  ROOM_SEPARATOR,
  WEEKLY_REST,
} from "../../../models/RealeEstateCommercial";
import { NestedKeys } from "advanced-types";
import { Translation } from "../../../assets/i18n/locals/en";

export interface IDescriptionFormCommercial {
  totalSquareMeters?: number;
  totalSquareMetersWalkable?: number;
  locals?: number;
  rooms?: number;
  bathrooms?: number;
  squareMetersCovered?: number;
  squareMetersUncovered?: number;
  squareMetersCoverable?: number;
  description: string;
  roomsPertinencesDetails: RoomPertinenceDetails[];
  otherRoomsDetails: RoomDetails[];
  heatingDetails: HeatingDetails;
  additionalDetails: CommercialAdditionalDetails;
  showcaseExposition?: string;
  showcases?: number;
}

export const initialValueDescriptionFormCommercial = {
  totalSquareMeters: 0,
  totalSquareMetersWalkable: 0,
  locals: 0,
  bathrooms: 0,
  squareMetersCovered: 0,
  squareMetersUncovered: 0,
  squareMetersCoverable: 0,
  description: "",
  showcaseExposition: "",
  showcases: 0,
  roomsPertinencesDetails: [],
  otherRoomsDetails: [],
  heatingDetails: {
    type: HEATING_TYPE.NOT_PROVIDED,
    fuel: HEATING_FUEL.NOT_PROVIDED,
    system: HEATING_SYSTEM.NOT_PROVIDED,
    radiators: 0,
    expenses: 0,
  },
  additionalDetails: {
    roomSeparator: ROOM_SEPARATOR.NOT_PROVIDED,
    context: CONTEXT.NOT_PROVIDED,
    averageRevenue: { from: 0, to: 0 },
    weeklyRest: WEEKLY_REST.NOT_PROVIDED,
    allowedActivities: "",
    activitiesToo: false, // anche attività
    activityValue: 0,
    fee: FEE.NOT_PROVIDED, // canone
    allowsManagement: false, // possibilità di dare in gestione
    conservationStatus: CONSERVATION_STATUS.NOT_PROVIDED,
    alarm: AVAILABILITY.NOT_PROVIDED,
    reception: AVAILABILITY.NOT_PROVIDED,
    intercom: AVAILABILITY.NOT_PROVIDED,
    furniture: FURNITURE_STATUS.NOT_PROVIDED,
    furnishedDescription: "",
  },
};

const prefixLabelCards = "realEstate.forms.description.cards";
const cards: {
  label: NestedKeys<Translation, ".">;
  value: ROOM_PERTINENCE;
  noDefaultValue?: boolean;
  isRequired?: boolean;
  secondaryKey: string;
  selectType: any;
}[] = [
  {
    label: `${prefixLabelCards}.balconies`,
    value: ROOM_PERTINENCE.BALCONIES,
    isRequired: true,
    secondaryKey: "type",
    selectType: roomPertinenceTypes,
  },
  {
    label: `${prefixLabelCards}.terrace`,
    value: ROOM_PERTINENCE.TERRACE,
    isRequired: true,
    secondaryKey: "type",
    selectType: roomPertinenceTypes,
  },
  {
    label: `${prefixLabelCards}.garden`,
    value: ROOM_PERTINENCE.GARDEN,
    isRequired: true,
    secondaryKey: "type",
    selectType: gardenTypes,
  },
  {
    label: `${prefixLabelCards}.terrain`,
    value: ROOM_PERTINENCE.TERRAIN,
    secondaryKey: "type",
    selectType: roomPertinenceTypes,
  },
  {
    label: `${prefixLabelCards}.garage`,
    value: ROOM_PERTINENCE.BOX,
    isRequired: true,
    secondaryKey: "type",
    selectType: boxTypes,
  },
  {
    label: `${prefixLabelCards}.coveredParkingSpaces`,
    value: ROOM_PERTINENCE.COVERED_PARKING_SPACES,
    secondaryKey: "count",
    noDefaultValue: false,
    selectType: countType(10),
  },
  {
    label: `${prefixLabelCards}.openParkingSpaces`,
    value: ROOM_PERTINENCE.OPEN_PARKING_SPACES,
    secondaryKey: "count",
    noDefaultValue: false,
    selectType: countType(10),
  },
  {
    label: `${prefixLabelCards}.bikeParkingSpaces`,
    value: ROOM_PERTINENCE.BIKE_PARKING_SPACES,
    secondaryKey: "count",
    noDefaultValue: false,
    selectType: countType(10),
  },
  {
    label: `${prefixLabelCards}.camperParkingSpaces`,
    value: ROOM_PERTINENCE.CAMPER_PARKING_SPACES,
    secondaryKey: "count",
    noDefaultValue: false,
    selectType: countType(10),
  },
  {
    label: `${prefixLabelCards}.boatParkingSpaces`,
    value: ROOM_PERTINENCE.BOAT_PARKING_SPACES,
    secondaryKey: "count",
    noDefaultValue: false,
    selectType: countType(10),
  },

  {
    label: `${prefixLabelCards}.cellar`,
    value: ROOM_PERTINENCE.CELLAR,
    secondaryKey: "type",
    selectType: roomPertinenceTypes,
  },
  {
    label: `${prefixLabelCards}.warehouse`,
    value: ROOM_PERTINENCE.WAREHOUSE,
    secondaryKey: "type",
    selectType: roomPertinenceTypes,
  },
  {
    label: `${prefixLabelCards}.depot`,
    value: ROOM_PERTINENCE.DEPOT,
    secondaryKey: "type",
    selectType: roomPertinenceTypes,
  },
  {
    label: `${prefixLabelCards}.loft`,
    value: ROOM_PERTINENCE.LOFT,
    secondaryKey: "type",
    selectType: roomPertinenceTypes,
  },
  {
    label: `${prefixLabelCards}.attic`,
    value: ROOM_PERTINENCE.ATTIC,
    secondaryKey: "type",
    selectType: roomPertinenceTypes,
  },
  {
    label: `${prefixLabelCards}.shed`,
    value: ROOM_PERTINENCE.SHED,
    secondaryKey: "type",
    selectType: roomPertinenceTypes,
  },
  {
    label: `${prefixLabelCards}.stable`,
    value: ROOM_PERTINENCE.STABLE,
    secondaryKey: "type",
    selectType: roomPertinenceTypes,
  },
];

export const roomPertinenceDetailsOptions: any = [
  // TODO remove any
  {
    name: {
      label: "realEstate.forms.description.kitchen",
      value: ROOM_PERTINENCE.KITCHEN,
    },
    type: fillSelection(kitchenTypes),
    dotations: fillSelection(dotationTypes),
  },
  ...cards.map(
    ({
      label,
      value,
      secondaryKey,
      selectType,
      noDefaultValue,
      isRequired,
    }) => ({
      name: { label, value, isRequired },
      [secondaryKey]: fillSelection(selectType, noDefaultValue),
    })
  ),
];

const prefixLabelCommercialDescription =
  "realEstate.forms.commercialDescription";
const prefixLabelDescription = "realEstate.forms.description";

export const descriptionFormCommercial: ICustomForm<IDescriptionFormCommercial> =
  {
    title: {
      edit: `${prefixLabelDescription}.title`,
      show: `${prefixLabelDescription}.title`,
    },
    description: {
      edit: `${prefixLabelDescription}.description.edit`,
      show: `${prefixLabelDescription}.description.show`,
    },
    // labelSubmitButton: { edit: "realEstate.forms.description.labelSubmit.edit", show:"realEstate.forms.description.labelSubmit.show" },
    labelSubmitButton: { edit: "editImage.save", show: "editImage.save" },
    next: "certifications",
    forms: [
      {
        title: `${prefixLabelDescription}.features.title`,
        fields: [
          {
            label: `${prefixLabelDescription}.features.labels.totalSquareMeters`,
            column: 2,
            isRequired: true,
            type: "number",
            key: "totalSquareMeters",
          },
          {
            label: `${prefixLabelDescription}.features.labels.totalSquareMetersWalkable`,
            column: 2,
            maxColumn: 2,
            type: "number",
            key: "totalSquareMetersWalkable",
          },
          {
            label: `${prefixLabelDescription}.features.labels.locals`,
            column: 2,
            isRequired: true,
            type: "number",
            key: "locals",
          },
          {
            label: `${prefixLabelDescription}.features.labels.showcases`,
            column: 2,
            isRequired: true,
            type: "number",
            key: "showcases",
          },
          {
            label: `${prefixLabelDescription}.features.labels.bathrooms`,
            column: 2,
            type: "number",
            key: "bathrooms",
          },
          {
            label: `${prefixLabelCommercialDescription}.squareMetersCovered`,
            column: 2,
            type: "number",
            key: "squareMetersCovered",
          },
          {
            label: `${prefixLabelCommercialDescription}.squareMetersUncovered`,
            column: 2,
            maxColumn: 2,
            type: "number",
            key: "squareMetersUncovered",
          },
          {
            label: `${prefixLabelCommercialDescription}.squareMetersCoverable`,
            column: 2,
            type: "number",
            key: "squareMetersCoverable",
          },
          {
            label: `${prefixLabelCommercialDescription}.showcaseExposition`,
            column: 12,
            isRequired: true,
            type: "text-area",
            key: "showcaseExposition",
          },
          {
            label: `${prefixLabelDescription}.features.labels.description`,
            column: 12,
            isRequired: true,
            type: "text-area",
            key: "description",
          },
        ],
      },
      {
        title: `${prefixLabelCommercialDescription}.roomsPertinence`,
        fields: [
          {
            label: "",
            column: 12,
            type: "room-pertinence-details",
            key: "roomsPertinencesDetails",
            roomPertinenceDetailsOptions,
          },
        ],
      },
      {
        title: `${prefixLabelCommercialDescription}.additionalRooms`,
        fields: [
          {
            label: "",
            column: 12,
            type: "other-rooms-details",
            key: "otherRoomsDetails",
          },
        ],
      },
      {
        title: `${prefixLabelDescription}.heating.title`,
        fields: [
          {
            label: `${prefixLabelDescription}.heating.labels.type`,
            type: "select",
            isRequired: true,
            key: "heatingDetails/type",
            options: fillSelection(heatingDetailsType),
          },
          {
            label: `${prefixLabelDescription}.heating.labels.fuel`,
            type: "select",
            key: "heatingDetails/fuel",
            options: fillSelection(heatingDetailsFuelType),
          },
          {
            label: `${prefixLabelDescription}.heating.labels.system`,
            type: "select",
            key: "heatingDetails/system",
            options: fillSelection(heatingDetailsSystemType),
          },
          {
            label: `${prefixLabelDescription}.heating.labels.radiators`,
            column: 2,
            maxColumn: 2,
            type: "number",
            key: "heatingDetails/radiators",
          },
          {
            label: `${prefixLabelDescription}.heating.labels.expenses`,
            column: 2,
            type: "number",
            key: "heatingDetails/expenses",
          },
        ],
      },
      {
        title: `${prefixLabelDescription}.additionalFeatures.title`,
        fields: [
          {
            label: `${prefixLabelDescription}.additionalFeatures.labels.conservationsStatus`,
            type: "select",
            isRequired: true,
            column: 3,
            key: "additionalDetails/conservationStatus",
            options: fillSelection(detailsConservationType),
          },
          {
            label: `${prefixLabelDescription}.additionalFeatures.labels.alarm`,
            type: "select",
            column: 3,
            key: "additionalDetails/alarm",
            options: fillSelection(availabilityType),
          },
          {
            label: `${prefixLabelDescription}.additionalFeatures.labels.intercom`,
            type: "select",
            column: 3,
            key: "additionalDetails/intercom",
            options: fillSelection(availabilityType),
          },
          {
            label: `${prefixLabelDescription}.additionalFeatures.labels.reception`,
            type: "select",
            column: 3,
            key: "additionalDetails/reception",
            options: fillSelection(availabilityType),
          },
          {
            label: `${prefixLabelDescription}.additionalFeatures.labels.furniture`,
            type: "select",
            column: 3,
            key: "additionalDetails/furniture",
            options: fillSelection(detailsFurnitureType),
          },
          {
            label:
              "realEstate.forms.description.additionalFeatures.labels.furnishedDescription",
            type: "text-area",
            showIfKey: "additionalDetails/furniture",
            showIf: (furniture: FURNITURE_STATUS) =>
              furniture !== FURNITURE_STATUS.NOT_FURNISHED &&
              furniture !== FURNITURE_STATUS.NOT_PROVIDED,
            column: 12,
            key: "additionalDetails/furnishedDescription",
          },
          {
            label: `${prefixLabelCommercialDescription}.additionalDetails.labels.allowsManagement`,
            type: "checkbox",
            column: 3,
            key: "additionalDetails/allowedActivities",
            option: {
              label: `${prefixLabelCommercialDescription}.additionalDetails.labels.allowsManagementOption`,
              value: false,
            },
          },
          {
            label: `${prefixLabelCommercialDescription}.additionalDetails.labels.activitiesToo`,
            type: "checkbox",
            maxColumn: 12,
            column: 3,
            key: "additionalDetails/activitiesToo",
            option: {
              label: `${prefixLabelCommercialDescription}.additionalDetails.labels.activitiesToo`,
              value: false,
            },
          },
          {
            label: `${prefixLabelCommercialDescription}.additionalDetails.labels.activityValue`,
            type: "number",
            column: 3,
            key: "additionalDetails/activityValue",
          },
          {
            label: `${prefixLabelCommercialDescription}.additionalDetails.labels.fee`,
            type: "select",
            column: 3,
            key: "additionalDetails/fee",
            options: fillSelection(feeSelection),
          },
          {
            label: `${prefixLabelCommercialDescription}.additionalDetails.labels.roomSeparator`,
            type: "select",
            column: 3,
            key: "additionalDetails/roomSeparator",
            options: fillSelection(roomSeparator),
          },
          {
            label: `${prefixLabelCommercialDescription}.additionalDetails.labels.context`,
            type: "select",
            column: 3,
            key: "additionalDetails/context",
            options: fillSelection(context),
          },
          {
            label: `${prefixLabelCommercialDescription}.additionalDetails.labels.weeklyRest`,
            type: "select",
            column: 3,
            key: "additionalDetails/weeklyRest",
            options: fillSelection(weeklyRest),
          },
          {
            label: `${prefixLabelCommercialDescription}.additionalDetails.labels.averageRevenueFrom`,
            type: "number",
            column: 3,
            key: "additionalDetails/averageRevenue/from",
            placeholder: `${prefixLabelCommercialDescription}.additionalDetails.labels.from`,
          },
          {
            label: `${prefixLabelCommercialDescription}.additionalDetails.labels.averageRevenueTo`,
            type: "number",
            column: 3,
            key: "additionalDetails/averageRevenue/to",
            placeholder: `${prefixLabelCommercialDescription}.additionalDetails.labels.to`,
          },
        ],
      },
    ],
  };
