import { useEffect, useState } from "react";
import { NestedKeys } from "../typings/KeysFromObject";
import {
  cloneObject,
  getNestedValueFromString,
  isEmailValid,
  resetFieldObject,
} from "../Utils/fuctions";
export type Form<T> = { [K in keyof T]: T[K] };
export type SetForm<T> = (key: keyof T, value: T[keyof T]) => void;
export type FormReturnType<T extends object> = {
  form: Partial<Form<T>>;
  setForm: (key: NestedKeys<T>, value: any) => void;
  setAllForm: (form: T) => void;
  checkErrors: () => boolean[];
  handleError: (
    key: NestedKeys<T>,
    value: string | undefined,
    autoScroll: boolean
  ) => void;
  submit: (callback: (form: Form<T>) => void) => void;
  errors: Partial<Form<T>>;
};
export type ErrorsForm<T> = { [x in keyof T]: string | undefined };
// eslint-disable-next-line
export interface FormValidationItem<T> {
  required?: string;
  invalidLengthLessSix?: string;
  containSpace?: string;
  greaterThanZero?: string;
  invalidEmail?: string;
  // custom?: (value: T[K]) => string | undefined;
  custom?: (value: any) => string | undefined;
}

// @ts-ignore
export type FormValidationObject<T extends object> = {
  [K in NestedKeys<T, "/", 3>]?: FormValidationItem<T>;
};

const useForm = <T extends object>(
  initialValue: Form<T>,
  // @ts-ignore
  validators?: FormValidationObject<T>,
  submitOnError?: boolean
): FormReturnType<T> => {
  const [form, setForm] = useState<Form<T>>(cloneObject(initialValue));
  const [errors, setErrors] = useState<Partial<Form<T>>>(() => {
    const initialErrors = cloneObject(initialValue);
    resetFieldObject(initialErrors);
    return initialErrors;
  });

  const handleError = (
    key: NestedKeys<T>,
    value: string | undefined,
    autoScroll: boolean = true
  ) => {
    // ts-ignore
    const splittedKey = (key as string).split("/");
    if (splittedKey.length === 1) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        [key]: value,
      }));
    } else {
      setErrors((oldErrors) => {
        const splittedKey = (key as string).split("/");
        const nestedKey = splittedKey.reduce(
          (acc: any, arr: any, index, array) => {
            return (acc = index < array.length - 1 ? acc[`${arr}`] : acc);
          },
          oldErrors
        );
        nestedKey[splittedKey[splittedKey.length - 1]] = value;
        return { ...oldErrors };
      });
    }
    if (autoScroll) {
      setTimeout(() => {
        const el = document.querySelectorAll(`[class~=is-invalid]`);
        if (el.length) {
          el[0].scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
      }, 50);
    }
  };

  const setAllForm = (allForm: T) => {
    setForm(allForm);
  };

  const onChange = (key: NestedKeys<T>, value: any) => {
    // handleError(key, undefined);
    setForm((prev: any) => {
      //@ts-ignore
      const splittedKey: (keyof T)[] = key.split("/");
      if (splittedKey.length === 1) {
        prev[splittedKey[0]] = value;
      } else {
        splittedKey.reduce((acc: any, arr: any, index, array) => {
          return (acc = index < array.length - 1 ? acc[arr] : acc);
        }, form)[splittedKey[splittedKey.length - 1]] = value;
      }

      return { ...prev };
    });
  };

  const checkErrors = () => {
    let valids: boolean[] = [];
    if (validators) {
      valids = Object.keys(validators).map((key) => {
        const currentKey = key as NestedKeys<T>;
        // @ts-ignore
        const currentField = getNestedValueFromString(currentKey, form);
        const validator = validators[key as keyof typeof validators];

        if (validator) {
          if (
            (validator.required && !currentField) ||
            (typeof currentField === "string" && currentField === "notProvided")
          ) {
            handleError(currentKey, validator.required);
            return false;
          } else if (
            validator.invalidEmail &&
            typeof currentField === "string" &&
            currentField &&
            !isEmailValid(currentField)
          ) {
            handleError(currentKey, validator.invalidEmail);
            return false;
          } else if (
            validator.greaterThanZero &&
            (typeof currentField === "undefined" ||
              (typeof currentField === "number" && currentField <= 0))
          ) {
            handleError(currentKey, validator.greaterThanZero);
            return false;
          } else if (
            validator.invalidLengthLessSix &&
            (typeof currentField === "undefined" ||
              (typeof currentField === "string" && currentField.length < 6))
          ) {
            handleError(currentKey, validator.invalidLengthLessSix);
            return false;
          } else if (
            validator.containSpace &&
            (typeof currentField === "undefined" ||
              (typeof currentField === "string" && currentField.includes(" ")))
          ) {
            handleError(currentKey, validator.containSpace);
            return false;
          } else if (validator.custom) {
            const customError = validator.custom(currentField);
            handleError(currentKey, customError);
            return !customError;
          } else {
            handleError(currentKey, "");
          }
        } else {
          handleError(currentKey, "");
        }
        return true;
      });
    }
    return valids;
  };

  const submit = (callback?: (fields: Form<T>) => void) => {
    let valids: boolean[] = checkErrors();
    if (
      (valids.every(Boolean) && callback) ||
      (Boolean(submitOnError) && callback)
    ) {
      callback(form);
    }
  };

  // @ts-ignore
  return {
    form,
    setForm: onChange,
    submit,
    checkErrors,
    handleError,
    setAllForm,
    errors,
  };
};

export default useForm;
