import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { Box } from "@chakra-ui/react";
import { NestedKeys } from "advanced-types";
import React, { FC, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Translation } from "../../assets/i18n/locals/en";
import useDemandsCustomers from "../../hooks/useDemandsCustomers";
import { useEndpoints } from "../../hooks/useEndpoints";
import { useI18n } from "../../hooks/useI18n";
import { REAL_ESTATE_TYPE } from "../../models/RealEstate";
import { RoutesRouter } from "../../models/Routes";
import { removeDemandCustomers } from "../../redux/reducers/demandsCustomers";
import Button from "../../shared/components/Button/Button";
import EmptyResourcesTable from "../../shared/components/EmptyResourcesTable/EmptyResourcesTable";
import EmptyTable from "../../shared/components/EmptyTable/EmptyTable";
import Pagination from "../../shared/components/Pagination/Pagination";
import SnackChip from "../../shared/components/SnackChip/SnackChip";
import Table, { ITableHome } from "../../shared/components/Table/Table";
import { useToastAlert } from "../../shared/components/ToastAlert/ToastAlert";
import { DURATION_TOAST } from "../../Utils/costants";
import "./DemandsCustomers.scss";

interface IDemandsCustomers {}

const DemandsCustomers: FC<IDemandsCustomers> = () => {
  const { id } = useParams();
  const { postDemandCustomer, deleteDemandCustomer } = useEndpoints();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    demandsCustomers,
    termFilter,
    demandsCustomersForTable,
    limit,
    changePage,
    generateButtons,
    resetDemandsCustomers,
    queryParams,
    setLimit,
    handleKeyPress,
    nameConsultant,
    handleReset,
    setTermFilter,
    allDemandsCustomers,
    isLoading,
  } = useDemandsCustomers(id!);

  const toastAlert = useToastAlert();

  const { t } = useI18n();

  const titles: ITableHome["titles"] = [
    {
      textAlign: "start",
      label: t("customers.table.head.titleDemand"),
      truncateField: 30,
    },
    {
      textAlign: "start",
      label: t("customers.table.head.customerCode"),
      truncateField: 30,
    },
    {
      textAlign: "start",
      label: t("customers.table.head.creationDate"),
    },

    {
      textAlign: "start",
      label: t("customers.table.head.demandStatus"),
      truncateField: 30,
    },
    {
      textAlign: "start",
      label: t("customers.table.head.nMatches"),
    },
  ];

  /*  useEffect(
    () => () => resetDemandsCustomers(),
    // eslint-disable-next-line
    []
  ); */

  const buttonsOption = useMemo(
    () =>
      generateButtons((consultant, role) => [
        {
          onClick: (index: number) => {
            navigate(
              `${RoutesRouter.customers}/${
                allDemandsCustomers!.docs[index].customer._id
              }/demand-customer/${allDemandsCustomers!.docs[index]._id}/edit`
            );
          },
          label: "Modifica",
          colorTheme: "blue",
          icon: <EditIcon />,
        },
        {
          onClick: async (index: number) => {
            try {
              await deleteDemandCustomer(allDemandsCustomers!.docs[index]._id);
              dispatch(removeDemandCustomers(index));
              toastAlert({
                message: t("errorsToast.removedDemand.title"),
                subMessage: t("errorsToast.removedDemand.description"),
                type: "success",
                duration: DURATION_TOAST,
              });
            } catch (error) {
              console.log("error", error);
              toastAlert({
                message: t("errorsToast.unexpectedError.title"),
                subMessage: t("errorsToast.unexpectedError.description"),
                type: "error",
                duration: DURATION_TOAST,
              });
            }
          },
          label: "Elimina",
          colorTheme: "red",
          icon: <DeleteIcon />,
        },
      ]),
    // eslint-disable-next-line
    [allDemandsCustomers]
  );

  const handleClickTr = (index: number) => {
    navigate(
      `${RoutesRouter.matches}/${id!}/demand-customer/${
        allDemandsCustomers!.docs[index]._id
      }/results`
    );
  };

  const filtersLine = () => (
    <div className="resources-searching my-3">
      <div className="form-group">
        <input
          id="searchResource"
          type="text"
          value={termFilter.label}
          onKeyPress={handleKeyPress}
          onChange={({ target: { value } }) => handleReset(value)}
          className="form-control filter-questions"
          placeholder={t("customers.placeHolderSearchBar")}
        />
        <i className="icon-search" />
        {termFilter && termFilter.label && (
          <i
            className="icon-close"
            onClick={() => {
              setTermFilter({ label: "", value: "" });
            }}
          ></i>
        )}
      </div>
    </div>
  );

  return (
    <div className="container__home">
      {demandsCustomersForTable &&
      !termFilter.value &&
      !isLoading &&
      // !queryParams &&
      allDemandsCustomers &&
      !Object.keys(queryParams).length &&
      allDemandsCustomers.page === 1 &&
      queryParams &&
      allDemandsCustomers.count === 0 ? (
        // && !selectedConsultant
        <EmptyTable
          description={t("customers.demandsCustomers.firstDemand")}
          title={t("customers.demandsCustomers.createFirstDemand")}
          onClick={async () => {
            const {
              data: { id: idNewDemand },
            } = await postDemandCustomer({
              type: REAL_ESTATE_TYPE.RESIDENTIAL,
              customer: id!,
              title: t("createDemandCustomerForm.title"),
            });
            navigate(
              `${
                RoutesRouter.editCustomer
              }/${id!}/demand-customer/${idNewDemand}/edit?isNew=true`
            );
          }}
          labelButton={t("customers.demandsCustomers.add")}
        />
      ) : (
        <>
          <div className="container__top">
            <div className="left">
              {demandsCustomersForTable && (
                <>
                  <h1 className="page-title text-capitalize">
                    {nameConsultant}
                  </h1>
                  <p className="page-description mt-1">
                    {t(
                      "customers.demandsCustomers.description" as NestedKeys<
                        Translation,
                        "."
                      >,
                      { count: demandsCustomersForTable?.length }
                    )}
                  </p>
                </>
              )}
            </div>
            <div className="right">
              {demandsCustomers && (
                <Button
                  onClick={async () => {
                    const {
                      data: { id: idNewDemand },
                    } = await postDemandCustomer({
                      type: REAL_ESTATE_TYPE.RESIDENTIAL,
                      customer: id!,
                      title: t("createDemandCustomerForm.title"),
                    });
                    navigate(
                      `${
                        RoutesRouter.editCustomer
                      }/${id!}/demand-customer/${idNewDemand}/edit?isNew=true`
                    );
                  }}
                >
                  {t("customers.demandsCustomers.add")}
                </Button>
              )}
            </div>
          </div>
          {demandsCustomers && filtersLine()}
          {demandsCustomers &&
            demandsCustomersForTable &&
            demandsCustomers.length > 0 && (
              <>
                <div className="container__table">
                  <Table<NonNullable<typeof demandsCustomersForTable>[0]>
                    onClickTr={handleClickTr}
                    titles={titles}
                    title={"titolo"}
                    // data={...demandsCustomersForTable,
                    data={demandsCustomersForTable.map((demand) => ({
                      ...demand,
                      nMatches: (
                        <SnackChip
                          theme={"info"}
                          label={
                            !demand.isProcessing
                              ? `${String(demand.nMatches)} ${t(
                                  "customers.table.head.nMatches"
                                )}`
                              : "In elaborazione"
                          }
                        />
                      ),
                    }))}
                    dataKeyShow={[
                      "title",
                      "customerShortCode",
                      "createdAt",

                      "status",
                      "nMatches",
                    ]}
                    options={{ booleanToCircle: true }}
                    buttonsOption={buttonsOption}
                  />
                </div>

                <Box mt={5}>
                  <Pagination
                    onClickPage={changePage}
                    isLoading={isLoading}
                    data={allDemandsCustomers!}
                    selectLimit={{
                      limit,
                      setLimit,
                      options: [
                        { label: "10", value: 10 },
                        { label: "20", value: 20 },
                        { label: "30", value: 30 },
                      ],
                    }}
                  />
                </Box>
              </>
            )}
        </>
      )}

      {demandsCustomersForTable &&
        allDemandsCustomers?.count === 0 &&
        termFilter.value &&
        demandsCustomersForTable.length === 0 && <EmptyResourcesTable />}
    </div>
  );
};

export default DemandsCustomers;
