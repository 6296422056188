import React, {
  Dispatch,
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  APPROVAL_STATUS,
  CONTRACT_TYPE,
  RealEstate,
  REAL_ESTATE_TYPE,
  EXTERNAL_INFO_STATUS,
  ExternalInfo,
} from "../../../models/RealEstate";
import "./styleCardRealEstates.scss";
import { ITableButtonOption } from "../Table/Table";
import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { BsThreeDotsVertical } from "react-icons/bs";
import language from "../../../redux/reducers/language";
import bathIcon from "../../../assets/img/bath-icon.svg";
import metersIcon from "../../../assets/img/meters-icon.svg";
import localsIcon from "../../../assets/img/locals-icon.svg";
import roomIcon from "../../../assets/img/room-icon.svg";
import { formatCurrency } from "../../../Utils/fuctions";
import SkeletonImage from "../../../assets/img/estate-placeholder.jpeg";
import SnackChip, { IThemeSnackChip } from "../SnackChip/SnackChip";
import { useI18n } from "../../../hooks/useI18n";
import dayjs from "dayjs";
import { NestedKeys } from "advanced-types";
import ImmobiliareLogo from "../../../assets/immobiliareLogo.png";
import IdealistaLogo from "../../../assets/idealista.png";
import kleosLogo from "../../../assets/icons/house.png";
import wikiCasaLogo from "../../../assets/wikicasa.png";
import idealistaLogo from "../../../assets/idealista.png";
import { Translation } from "../../../assets/i18n/locals/en";
import getClasses from "../../../Utils/getClasses";
import Modal from "../../../shared/components/Modal";
import Button from "../Button/Button";
import { useEndpoints } from "../../../hooks/useEndpoints";
import { DURATION_TOAST, DURATION_TOAST_LONG } from "../../../Utils/costants";
import { useToastAlert } from "../ToastAlert/ToastAlert";
import messageIcon from "../../../assets/img/email.svg";
import { useLocation, useSearchParams } from "react-router-dom";
import IconPrivate from "../../../assets/img/private-realestate.svg";
import IconAgency from "../../../assets/img/agency-realestate.svg";
import IconAuction from "../../../assets/img/auction.svg";

import IconCollaboration from "../../../assets/img/carbon_collaborate.svg";
import IconCommision from "../../../assets/img/carbon_percentage.svg";
import IconNegotiation from "../../../assets/img/ph_handshake-light.svg";

import CustomButton from "../Button/Button";
import { RespAgency } from "../../../models/Agency";

const valuesIcon: TypeIcons[] = ["m", "locals", "rooms", "baths"];

interface PropsCard {
  realEstate: RealEstate;
  buttonsOption?: Array<ITableButtonOption>;
  index: number;
  hidden?: { approvalStatus?: boolean };
  onClick?: (index: number) => void;
  fetchRealeState?: () => Promise<void>;
  portals?: boolean;
  setterCheckBox?: (value: { checked: boolean; id: string }) => void;
  checkListArray?: { checked: boolean; id: string }[];
  sentAt?: string;
  currentAgency?: RespAgency;
}
type TypeIcons = "m" | "locals" | "rooms" | "baths";
interface Icons {
  type: TypeIcons;
  value: string;
  svg: string;
}

interface ISelectedPortal {
  type: "Immobiliare" | "Idealista" | "Wikicasa" | "showcase" | undefined;
  show: boolean;
}
const CardRealEstates: FC<PropsCard> = ({
  realEstate,
  buttonsOption,
  index,
  hidden,
  onClick,
  fetchRealeState,
  portals = false,
  setterCheckBox,
  checkListArray,
  sentAt,
  currentAgency,
}) => {
  const {
    images,
    location,
    price,
    locals,
    rooms,
    bathrooms,
    totalSquareMeters,
    description,
    approvalStatus,
    contractType,
    createdAt,
    compoundId,
    visibleOnIdealista,
    publishedOnImmobiliare,
    publishedOnWikicasa,
    type,
    visibleOnShowcase,
    consultant,
    _id,
    propertyType,
    saleSuspensionExpiration,
    externalInfo,
  } = realEstate;

  const path = useLocation();
  const { t } = useI18n();
  const { postOrDeletePortal, patchRealEstateCollaboration } = useEndpoints();
  const [selectedPortal, setSelectedPortal] = useState<ISelectedPortal>({
    type: undefined,
    show: false,
  });
  const alertToast = useToastAlert();
  const [searchParams] = useSearchParams();

  const [isLoadingPortalButton, setIsLoadingPortalButton] = useState(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [showCollaborationModal, setShowCollaborationModal] =
    useState<boolean>(false);
  const [showTextField, setShowTextField] = useState<boolean>(false);
  const [showLibraModal, setShowLibraModal] = useState<boolean>(false);
  const [showNegotiationModal, setShowNegotiationModal] =
    useState<boolean>(false);
  const [agencyCollaborates, setAgencyCollaborates] = useState<
    EXTERNAL_INFO_STATUS | undefined
  >(externalInfo?.agencyCollaborates);
  const [commissionCancellation, setCommissionCancellation] = useState<
    boolean | undefined
  >(externalInfo?.commissionCancellation);
  const [
    commissionCancellationPercentage,
    setCommissionCancellationPercentage,
  ] = useState<number | undefined>(
    externalInfo?.commissionCancellationPercentage
  );
  const [negotiationInProgress, setNegotiationInProgress] = useState<
    EXTERNAL_INFO_STATUS | undefined
  >(externalInfo?.negotiationInProgress);
  const temp: ExternalInfo = {};
  const generateValue = (value: string, valueNumber: number, index: number) => {
    switch (value) {
      case "locals":
        return {
          type: valuesIcon[index],
          value: `${valueNumber} ${t("cardRealEstate.icons.local")}`,
          svg: localsIcon,
        };
      case "baths":
        return {
          type: valuesIcon[index],
          value: `${valueNumber} ${t("cardRealEstate.icons.bath")}`,
          svg: bathIcon,
        };
      case "rooms":
        return {
          type: valuesIcon[index],
          value: `${valueNumber} ${t("cardRealEstate.icons.room")}`,
          svg: roomIcon,
        };
      default:
        return {
          type: valuesIcon[index],
          value: `${valueNumber} m`,
          svg: metersIcon,
        };
    }
  };
  const getCollaborationIconsIfExternal = () => {
    const { casafariId } = realEstate;
    if (casafariId && path.pathname.includes("matches")) {
      return (
        <>
          <div className="position-relative ms-3 ">
            <img
              title={t("customers.demandsCustomers.collaborationModal.title")}
              src={IconCollaboration}
              alt="collaboration-icon"
              className={getClasses({
                deactivated: agencyCollaborates === undefined,
                pointer: true,
                "external-icons": true,
                "bg-green": agencyCollaborates === "yes",
                "bg-red": agencyCollaborates === "no",
                "bg-yellow": agencyCollaborates === "onHold",
              })}
              onClick={() => setShowCollaborationModal(true)}
            />
          </div>
          <div>
            <div
              className={getClasses({
                "position-relative": true,
                "ms-2": true,
                "align-items-center": true,
                "d-flex": true,
                activated: commissionCancellation !== undefined,
                "border-rounded": true,
              })}
            >
              {commissionCancellation && commissionCancellationPercentage && (
                <span className="ps-2">{commissionCancellationPercentage}</span>
              )}

              <img
                title={t("customers.demandsCustomers.commissionModal.title")}
                src={IconCommision}
                alt="libra-icon"
                className={getClasses({
                  deactivated: commissionCancellation === undefined,
                  acivated: commissionCancellation !== undefined,
                  " pointer": true,
                  "external-icons": true,
                })}
                onClick={() => setShowLibraModal(true)}
              />
              <div
                className={getClasses({
                  "pointer-external-logo": true,
                  "portal-logo-green": commissionCancellation,
                  "portal-logo-red": commissionCancellation === false,
                })}
              />
            </div>
          </div>

          <div className="position-relative ms-2">
            <img
              title={t("customers.demandsCustomers.negotiationModal.title")}
              src={IconNegotiation}
              alt="negotiation-icon"
              className={getClasses({
                deactivated: negotiationInProgress === undefined,
                activated: negotiationInProgress !== undefined,
                pointer: true,
                "external-icons": true,
              })}
              onClick={() => setShowNegotiationModal(true)}
            />
            <div
              className={getClasses({
                "pointer-external-logo": true,
                "portal-logo-red": negotiationInProgress === "yes",
                "portal-logo-green": negotiationInProgress === "no",
              })}
            />
          </div>
        </>
      );
    }
  };

  const getIconIfExternal = () => {
    const { casafariId, isCasafariAuction, isPrivateProperty } = realEstate;
    if (casafariId) {
      if (isCasafariAuction) {
        return (
          <img
            src={IconAuction}
            alt="auction-icon"
            width="20"
            height="20"
            className="ms-4"
          />
        );
      } else {
        return isPrivateProperty ? (
          <img
            src={IconPrivate}
            alt="private-icont"
            width="20"
            height="20"
            className="ms-4"
          />
        ) : (
          <img
            src={IconAgency}
            alt="agency-icon"
            width="20"
            height="20"
            className="ms-4"
          />
        );
      }
    }
  };

  const getIfExternalModified = () => {
    const { casafariId, isVerified } = realEstate;

    return casafariId && isVerified ? (
      <div className="ps-2">
        <SnackChip
          theme={"info"}
          iconLeft={"icon-warning"}
          options={{ colorTextNeutral: true }}
          borderRadius={"sm"}
          label={"Modificato"}
        />
      </div>
    ) : (
      <></>
    );
  };
  const renderIcons = useMemo(() => {
    const results: Icons[] = [
      totalSquareMeters,
      locals,
      rooms,
      bathrooms,
    ].reduce((acc, arr, index) => {
      if (arr !== undefined && arr !== null) {
        acc.push(generateValue(valuesIcon[index], arr, index));
      }
      return acc;
    }, [] as Icons[]);

    return (
      <>
        {results.map(({ type, value, svg }, index) => {
          let formattedValue = value;
          if (type === valuesIcon[0]) {
            const numericValue =
              typeof value === "string" ? parseFloat(value) : value;
            if (!isNaN(numericValue)) {
              formattedValue = numericValue.toLocaleString("it-IT");
            }
          }

          return (
            <div
              className="chip"
              key={index}
              style={{
                marginRight: `${
                  contractType === CONTRACT_TYPE.RENT && results.length > 3
                    ? "8px"
                    : "16px"
                }`,
              }}
            >
              <div className="icon_chip">
                <img src={svg} alt="svg" width="16px" height="16px" />
              </div>
              <div className="text_chip">
                {formattedValue} {type === valuesIcon[0] ? "m" : ""}
              </div>
            </div>
          );
        })}
      </>
    );
    // eslint-disable-next-line
  }, [realEstate]);

  const handlePublish = async () => {
    setIsLoadingPortalButton(true);
    const { type, show } = selectedPortal;
    if (!type) return;
    try {
      await postOrDeletePortal(
        _id,
        type.toLowerCase() as Lowercase<NonNullable<ISelectedPortal["type"]>>,
        !show
      );
      alertToast({
        message: t(`portals.success.${show ? "delete" : "publish"}`, {
          context: type,
        }),
        type: "success",
        duration: DURATION_TOAST,
      });
      await fetchRealeState?.();
    } catch (error) {
      const err = error as any;
      if (err.response.status < 420) {
        const errors = err.response.data.error?.map((err: string) => err);
        console.log("checl", errors);
        alertToast({
          message: t(`portals.errors.${show ? "delete" : "publish"}`, {
            context: type,
          }),
          type: "error",
          duration: DURATION_TOAST_LONG,
          subMessage: `${errors.join(", ")}`,
        });
      } else if (err.response.status > 499) {
        alertToast({
          message: t(`portals.errors.${show ? "delete" : "publish"}`, {
            context: type,
          }),
          type: "error",
          duration: DURATION_TOAST_LONG,
          subMessage: `Errore Server`,
        });
      }

      console.error("error", error);
    } finally {
      setSelectedPortal({ type: undefined, show: false });
      setIsLoadingPortalButton(false);
    }
  };

  const BodyModalPortal: FC = () => {
    return (
      <div className="d-flex justify-content-center gap-2 pb-4">
        <Button
          color={"secondary"}
          onClick={() => {
            setSelectedPortal({ type: undefined, show: false });
          }}
        >
          {t("portals.close")}
        </Button>
        <Button disabled={isLoadingPortalButton} onClick={handlePublish}>
          {t(`portals.${selectedPortal.show ? "delete" : "publish"}`)}
        </Button>
      </div>
    );
  };
  const optionsButton = useCallback(
    (indexData: number) => (
      <Flex justifyContent="flex-end">
        <Menu>
          <MenuButton
            as={IconButton}
            padding={"0rem !important"}
            aria-label="More server options"
            icon={<BsThreeDotsVertical />}
            variant="ghost"
            w="fit-content"
          />
          <MenuList p={0} zIndex={1000}>
            {buttonsOption![indexData].map(
              ({ label, onClick, colorTheme, icon }, index) => (
                <MenuItem
                  key={index}
                  _hover={{ bg: "transparent" }}
                  _focus={{ bg: "transparent" }}
                  p={0}
                >
                  <div
                    key={index}
                    onClick={() => {
                      onClick && onClick(indexData);
                    }}
                    className={`menu-item menu-item-${colorTheme}`}
                  >
                    <span className={`icon color-${colorTheme}`}>{icon}</span>
                    <span
                      className={`capitalize-first-letter color-${colorTheme}`}
                    >
                      {label}
                    </span>
                  </div>
                </MenuItem>
              )
            )}
          </MenuList>
        </Menu>
      </Flex>
    ),
    // eslint-disable-next-line
    [buttonsOption, language]
  );
  const { settings } = currentAgency || {};
  const { systemIntegrators } = settings || {};
  const {
    wikicasa: wikiCasaAgency,
    idealista: idealistaAgency,
    immobiliare: ImmobiliareAgency,
  } = systemIntegrators || {};

  const portalsLogo = [
    {
      url: kleosLogo,
      show: visibleOnShowcase,
      alt: "logo-showcase",
      type: "showcase",
      enabled: true,
    },
    {
      url: wikiCasaLogo,
      show: publishedOnWikicasa,
      alt: "logo-wikicasa",
      type: "Wikicasa",
      enabled: !!(wikiCasaAgency?.agencyId && wikiCasaAgency.agentId) || false,
    },
    {
      url: ImmobiliareLogo,
      show: publishedOnImmobiliare,
      alt: "logo-immobiliare",
      type: "Immobiliare",
      enabled:
        !!(ImmobiliareAgency?.agencyName && ImmobiliareAgency.email) || false,
    },
    {
      url: IdealistaLogo,
      show: visibleOnIdealista,
      alt: "logo-idealista",
      type: "Idealista",
      enabled:
        !!(idealistaAgency?.contactId && idealistaAgency.feedKey) || false,
    },
  ] as const;

  const getThemeSnackChip = (): IThemeSnackChip =>
    ((
      {
        [APPROVAL_STATUS.APPROVED]: "success",
        [APPROVAL_STATUS.DRAFT]: "error",
        [APPROVAL_STATUS.PENDING]: "warning",
        [APPROVAL_STATUS.SOLD]: "info",
        [APPROVAL_STATUS.SUSPENDED]: "info-secondary",
        [APPROVAL_STATUS.SOLDSUSPENDED]: "warning",
      } as const
    )[approvalStatus]);

  const handleOnError = ({
    currentTarget,
  }: SyntheticEvent<HTMLImageElement, Event>) => {
    currentTarget.src = SkeletonImage;
  };

  const getLabelSnackChip = () => {
    switch (approvalStatus) {
      case APPROVAL_STATUS.SOLD:
        return contractType === CONTRACT_TYPE.RENT ? "rented" : "sold";
      default:
        return approvalStatus;
    }
  };
  const checkElementTrue = (arrayList: { id: string; checked: boolean }[]) => {
    const file = arrayList.find(({ id }) => id === _id);
    if (!file) return false;
    return file.checked;
  };
  useEffect(() => {
    checkListArray && setIsChecked(checkElementTrue(checkListArray));
  }, [checkListArray]);

  return (
    <>
      <div className="card_container">
        <div className="d-flex flex-column w-100">
          <div className="d-flex justify-content-between mb-3">
            <div className="d-flex align-items-center">
              {!hidden?.approvalStatus && (
                <SnackChip
                  theme={getThemeSnackChip()}
                  iconLeft={"icon-warning"}
                  options={{ colorTextNeutral: true }}
                  borderRadius={"sm"}
                  label={`${t(
                    `realEstate.approvalStatus.${getLabelSnackChip()}`
                  )}`}
                />
              )}
              {path.pathname.includes("home/realestates") && (
                <span className="card-rif">{`Rif: ${compoundId}`}</span>
              )}

              {portals && !realEstate.casafariId && (
                <div className="d-flex ms-3 gap-1">
                  {React.Children.toArray(
                    portalsLogo
                      .filter(({ type }) =>
                        realEstate.type === "land" ? type === "showcase" : true
                      )
                      .map(({ show, url, alt, type, enabled }) =>
                        enabled ? (
                          <div
                            className={`${getClasses({
                              "position-relative": true,
                              pointer:
                                approvalStatus === APPROVAL_STATUS.APPROVED,
                            })}`}
                            onClick={() => {
                              approvalStatus === APPROVAL_STATUS.APPROVED &&
                                setSelectedPortal({ type, show });
                            }}
                          >
                            <img
                              className={getClasses({
                                "portal-logo": true,
                                opacity: !show,
                              })}
                              src={url}
                              alt={alt}
                            />
                            <div
                              className={getClasses({
                                "pointer-portal-logo": true,
                                "portal-logo-green": show,
                                "portal-logo-grey": !show,
                              })}
                            />
                          </div>
                        ) : (
                          <></>
                        )
                      )
                  )}
                </div>
              )}
              {sentAt && (
                <div className="sentAt">
                  <img
                    src={messageIcon}
                    alt="email-icon"
                    width="20px"
                    height="20px"
                  />
                  <span>{dayjs(sentAt).format("DD-MM-YYYY HH:mm")}</span>
                </div>
              )}
              {getIconIfExternal()}
              {getCollaborationIconsIfExternal()}
              {getIfExternalModified()}
              {saleSuspensionExpiration &&
                approvalStatus === APPROVAL_STATUS.SOLDSUSPENDED && (
                  <div className="suspensive-date">
                    <span className="card-rif">
                      {t("dashboard.tableSospensive.headTable.inactiveSince")}{" "}
                      {dayjs(saleSuspensionExpiration).format("DD-MM-YYYY")}
                    </span>
                  </div>
                )}
              {path.pathname.includes("home/matches") && (
                <span className="card-rif">{`Rif: ${compoundId}`}</span>
              )}
            </div>
            {!realEstate.casafariId && consultant ? (
              <div>
                <i className="icon icon-person card-consultant-icon me-1"></i>
                <span className="card-consultant me-3">
                  {`${t("realEstate.agent")} ${consultant?.surname} ${
                    consultant?.name
                  }`}
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="d-flex justify-content-between">
            <div
              className="container_image pointer"
              onClick={() => onClick && onClick(index)}
            >
              <div className="green_type">
                {[
                  APPROVAL_STATUS.APPROVED,
                  APPROVAL_STATUS.DRAFT,
                  APPROVAL_STATUS.PENDING,
                ].some(
                  (currentApprovalStatus) =>
                    approvalStatus === currentApprovalStatus
                ) && (
                  <SnackChip
                    theme="info"
                    label={
                      contractType === CONTRACT_TYPE.SALE
                        ? t("realEstate.onSale")
                        : contractType === CONTRACT_TYPE.RENT
                        ? t("realEstate.forRent")
                        : "no_provided"
                    }
                  />
                )}
              </div>
              <img
                src={images[0]?.thumbnail || SkeletonImage}
                onError={handleOnError}
                alt="img"
                className="card_image"
              />
            </div>

            <div className="card_content_main ms-2">
              <div>
                <div>
                  <div className="card_title">
                    {location.street ? `${location.street}` : ""}
                    {location.unity ? `, ${location.unity}` : ""}
                    {`${location.city ? `, ${location.city}` : ""} ${
                      location.state ? `(${location.state})` : ""
                    } ${location.zone ? `- ${location.zone}` : ""}`}
                  </div>
                  <div className="card-subtitle">
                    {t(
                      `selectOptions.propertyOptions${
                        type === REAL_ESTATE_TYPE.COMMERCIAL ? "Commercial" : ""
                      }.${propertyType.toUpperCase()}` as NestedKeys<
                        Translation,
                        "."
                      >
                    )}
                  </div>
                </div>
                <div className="text_icons">
                  <span className="price">
                    {`€ ${formatCurrency("EUR", price.requestedPrice, false)}${
                      contractType === CONTRACT_TYPE.RENT ? "mensili" : ""
                    }`}
                  </span>
                  <div className="chips">{renderIcons}</div>
                </div>
                <div className="card_description">{description}</div>
              </div>
              <p className="card_data text-end">
                {t("cardRealEstate.createdAt", {
                  context: dayjs(createdAt).format("DD-MM-YYYY HH:mm"),
                })}
              </p>
            </div>

            <div className="card_three_dots">
              {buttonsOption &&
                buttonsOption[index]?.length > 0 &&
                optionsButton(index)}
            </div>
          </div>
        </div>
        {setterCheckBox && (
          <div
            onClick={() => {
              setterCheckBox({ checked: !isChecked, id: realEstate._id });
              setIsChecked((prev) => !prev);
            }}
            className={`container-checkbox-circle me-1`}
          >
            <div
              className={`circle ${isChecked ? "checked" : "unchecked"}`}
            ></div>
          </div>
        )}
      </div>
      <Modal
        title={`${t(
          `portals.${selectedPortal.show ? "titleDelete" : "titlePublish"}`
        )} ${
          selectedPortal.type === "showcase"
            ? t("portals.showcase")
            : selectedPortal.type || ""
        }`}
        isOpen={Boolean(selectedPortal.type)}
        body={<BodyModalPortal />}
        classNameTitle={"title-modal-portal"}
        onClose={() => {
          setSelectedPortal({ type: undefined, show: false });
        }}
        size="md"
        footer={false}
      />

      <Modal
        title={t("customers.demandsCustomers.collaborationModal.title")}
        isOpen={Boolean(showCollaborationModal)}
        onClose={() => setShowCollaborationModal(false)}
        body={
          <>
            <div className="d-flex flex-column justify-content-evenly">
              <p className="mb-3">
                {t("customers.demandsCustomers.collaborationModal.description")}
              </p>
              <div className="mb-3">
                <input
                  className="radio-style ms-2"
                  type="radio"
                  id="yesCollaboration"
                  name="collaboration"
                  value="yes"
                  onChange={() => {
                    temp.agencyCollaborates = EXTERNAL_INFO_STATUS.YES;
                  }}
                  defaultChecked={
                    agencyCollaborates === EXTERNAL_INFO_STATUS.YES
                  }
                />
                <label className="ms-2" htmlFor="yesCollaboration">
                  {t(
                    "customers.demandsCustomers.collaborationModal.options.yes"
                  )}
                </label>
              </div>

              <div className="mb-3">
                <input
                  className="radio-style ms-2"
                  type="radio"
                  id="noCollaboration"
                  name="collaboration"
                  value="no"
                  onChange={() => {
                    temp.agencyCollaborates = EXTERNAL_INFO_STATUS.NO;
                  }}
                  defaultChecked={
                    agencyCollaborates === EXTERNAL_INFO_STATUS.NO
                  }
                />
                <label className="ms-2" htmlFor="noCollaboration">
                  {t(
                    "customers.demandsCustomers.collaborationModal.options.no"
                  )}
                </label>
              </div>

              <div className="mb-3">
                <input
                  className="radio-style ms-2"
                  type="radio"
                  id="onHoldCollaboration"
                  name="collaboration"
                  value="onHold"
                  onChange={() => {
                    temp.agencyCollaborates = EXTERNAL_INFO_STATUS.ON_HOLD;
                  }}
                  defaultChecked={
                    agencyCollaborates === EXTERNAL_INFO_STATUS.ON_HOLD
                  }
                />
                <label className="ms-2" htmlFor="onHoldCollaboration">
                  {t(
                    "customers.demandsCustomers.collaborationModal.options.onHold"
                  )}
                </label>
              </div>
            </div>
            <div className="d-flex justify-content-evenly mt-4">
              <CustomButton
                color={"secondary"}
                onClick={() => {
                  setShowCollaborationModal(false);
                  setAgencyCollaborates(undefined);
                  patchRealEstateCollaboration(realEstate._id, {
                    agencyCollaborates: null,
                  });
                  alertToast({
                    message: t("customers.demandsCustomers.toast.reset"),
                    type: "success",
                    duration: 2000,
                  });
                }}
              >
                {t("realEstate.buttonsFilters.reset")}
              </CustomButton>

              <CustomButton
                onClick={() => {
                  if (
                    temp.agencyCollaborates === undefined &&
                    agencyCollaborates !== undefined
                  ) {
                    patchRealEstateCollaboration(realEstate._id, {
                      agencyCollaborates,
                    });
                  } else {
                    setAgencyCollaborates(temp.agencyCollaborates);
                    patchRealEstateCollaboration(realEstate._id, {
                      agencyCollaborates: temp.agencyCollaborates,
                    });
                  }

                  setShowCollaborationModal(false);
                  alertToast({
                    message: t("customers.demandsCustomers.toast.saved"),
                    type: "success",
                    duration: 2000,
                  });
                }}
              >
                {t("customers.demandsCustomers.collaborationModal.save")}
              </CustomButton>
            </div>
          </>
        }
      />

      <Modal
        title={t("customers.demandsCustomers.commissionModal.title")}
        isOpen={Boolean(showLibraModal)}
        onClose={() => {
          setShowLibraModal(false);
          setShowTextField(commissionCancellation ? true : false);
        }}
        body={
          <>
            <div className="d-flex flex-column justify-content-evenly">
              <p className="mb-3">
                {t("customers.demandsCustomers.commissionModal.description")}
              </p>
              <div className="mb-3">
                <input
                  className="radio-style ms-2 mb-2"
                  type="radio"
                  id="yes"
                  name="commission"
                  value="yes"
                  onChange={() => {
                    if (
                      temp.commissionCancellationPercentage === undefined &&
                      commissionCancellationPercentage !== undefined
                    ) {
                      temp.commissionCancellationPercentage =
                        commissionCancellationPercentage;
                    }
                    temp.commissionCancellation = true;
                    setShowTextField(true);
                  }}
                  defaultChecked={commissionCancellation === true}
                />
                <label className="ms-2" htmlFor="yes">
                  {t("customers.demandsCustomers.commissionModal.options.yes")}
                </label>
              </div>
              {showTextField && (
                <div className="d-block mb-3 ">
                  <input
                    className="input-percentage p-2 rounded"
                    type="number"
                    placeholder={t(
                      "customers.demandsCustomers.commissionModal.placeholder"
                    )}
                    onChange={(e) =>
                      (temp.commissionCancellationPercentage = Number(
                        e.target.value
                      ))
                    }
                    defaultValue={commissionCancellationPercentage}
                  />
                  <span className="position-percentage">%</span>
                </div>
              )}

              {useEffect(() => {
                if (commissionCancellation || temp.commissionCancellation)
                  setShowTextField(true);
              }, [commissionCancellation, temp.commissionCancellation])}

              <div>
                <input
                  className="radio-style ms-2 mb-3"
                  type="radio"
                  id="no"
                  name="commission"
                  value="no"
                  onChange={() => {
                    temp.commissionCancellation = false;
                    setShowTextField(false);
                  }}
                  defaultChecked={commissionCancellation === false}
                />
                <label className="ms-2" htmlFor="no">
                  {t("customers.demandsCustomers.commissionModal.options.no")}
                </label>
              </div>
            </div>

            {useEffect(() => {
              if (showTextField) {
                temp.commissionCancellation = true;
                if (temp.commissionCancellationPercentage !== undefined) {
                  setCommissionCancellationPercentage(
                    temp.commissionCancellationPercentage
                  );
                }
              } else temp.commissionCancellation = false;
            }, [showTextField])}

            <div className="d-flex justify-content-evenly mt-4">
              <CustomButton
                color={"secondary"}
                onClick={() => {
                  temp.commissionCancellation = undefined;
                  temp.commissionCancellationPercentage = undefined;
                  setCommissionCancellation(undefined);
                  setShowTextField(false);
                  patchRealEstateCollaboration(realEstate._id, {
                    commissionCancellation: null,
                  });
                  setShowLibraModal(false);
                  alertToast({
                    message: t("customers.demandsCustomers.toast.reset"),
                    type: "success",
                    duration: 2000,
                  });
                }}
              >
                {t("realEstate.buttonsFilters.reset")}
              </CustomButton>
              <CustomButton
                onClick={() => {
                  if (
                    (temp.commissionCancellation === undefined &&
                      temp.commissionCancellationPercentage === undefined &&
                      commissionCancellationPercentage === undefined &&
                      commissionCancellation) ||
                    (temp.commissionCancellation &&
                      temp.commissionCancellationPercentage === undefined &&
                      commissionCancellation === undefined &&
                      commissionCancellationPercentage === undefined) ||
                    (temp.commissionCancellation &&
                      temp.commissionCancellationPercentage === undefined &&
                      commissionCancellation === false &&
                      commissionCancellationPercentage === undefined)
                  ) {
                    if (commissionCancellation !== false) {
                      setCommissionCancellation(undefined);
                    }
                    alertToast({
                      message: t(
                        "customers.demandsCustomers.toast.noPercentage"
                      ),
                      type: "error",
                      duration: 2000,
                    });
                    return;
                  }
                  if (
                    (temp.commissionCancellation ||
                      temp.commissionCancellation === undefined) &&
                    temp.commissionCancellationPercentage !== undefined &&
                    (temp.commissionCancellationPercentage <= 0 ||
                      temp.commissionCancellationPercentage > 100)
                  ) {
                    alertToast({
                      message: t(
                        "customers.demandsCustomers.toast.invalidPercentage"
                      ),
                      type: "error",
                      duration: 2000,
                    });
                    return;
                  }

                  if (
                    (temp.commissionCancellation || commissionCancellation) &&
                    temp.commissionCancellationPercentage !== undefined &&
                    commissionCancellationPercentage !== undefined
                  ) {
                    setCommissionCancellation(true);
                    setCommissionCancellationPercentage(
                      temp.commissionCancellationPercentage
                    );
                    patchRealEstateCollaboration(realEstate._id, {
                      commissionCancellation: true,
                      commissionCancellationPercentage:
                        temp.commissionCancellationPercentage,
                    });
                    setShowLibraModal(false);
                    alertToast({
                      message: t("customers.demandsCustomers.toast.saved"),
                      type: "success",
                      duration: 2000,
                    });
                    return;
                  }
                  if (
                    (temp.commissionCancellation === undefined &&
                      temp.commissionCancellationPercentage === undefined &&
                      commissionCancellation &&
                      commissionCancellationPercentage) ||
                    (temp.commissionCancellation &&
                      temp.commissionCancellationPercentage === undefined &&
                      commissionCancellation === undefined &&
                      commissionCancellationPercentage !== undefined)
                  ) {
                    setCommissionCancellation(true);
                    patchRealEstateCollaboration(realEstate._id, {
                      commissionCancellation: true,
                      commissionCancellationPercentage:
                        commissionCancellationPercentage,
                    });
                    setShowLibraModal(false);
                    alertToast({
                      message: t("customers.demandsCustomers.toast.saved"),
                      type: "success",
                      duration: 2000,
                    });
                    return;
                  }

                  if (
                    temp.commissionCancellation === undefined &&
                    commissionCancellation !== undefined
                  ) {
                    //Caso in cui non viene effettuata alcuna modifica ai campi nella modale e viene cliccato salva
                    if (temp.commissionCancellationPercentage === undefined) {
                      setShowLibraModal(false);
                      alertToast({
                        message: t("customers.demandsCustomers.toast.saved"),
                        type: "success",
                        duration: 2000,
                      });
                      return;
                    }
                  }

                  //Caso in cui dato uno storno positivo e con valore, l'utente clicchi su no (senza salvare), poi su sì e provi a salvare NON cambiando valore
                  if (
                    temp.commissionCancellation &&
                    temp.commissionCancellationPercentage === undefined &&
                    commissionCancellation
                  ) {
                    setCommissionCancellation(true);
                    setCommissionCancellationPercentage(
                      commissionCancellationPercentage
                    );
                    setShowLibraModal(false);
                    alertToast({
                      message: t("customers.demandsCustomers.toast.saved"),
                      type: "success",
                      duration: 2000,
                    });
                    return;
                  }

                  setCommissionCancellation(
                    temp.commissionCancellation === undefined &&
                      temp.commissionCancellationPercentage !== undefined
                      ? true
                      : temp.commissionCancellation
                  );

                  setCommissionCancellationPercentage(
                    temp.commissionCancellation === false
                      ? undefined
                      : temp.commissionCancellationPercentage
                  );

                  patchRealEstateCollaboration(realEstate._id, {
                    commissionCancellation:
                      temp.commissionCancellation === undefined &&
                      temp.commissionCancellationPercentage !== undefined
                        ? true
                        : temp.commissionCancellation,

                    commissionCancellationPercentage:
                      temp.commissionCancellationPercentage,
                  });
                  setShowLibraModal(false);
                  alertToast({
                    message: t("customers.demandsCustomers.toast.saved"),
                    type: "success",
                    duration: 2000,
                  });
                }}
              >
                {t("customers.demandsCustomers.commissionModal.save")}
              </CustomButton>
            </div>
          </>
        }
      />

      <Modal
        title={t("customers.demandsCustomers.negotiationModal.title")}
        isOpen={Boolean(showNegotiationModal)}
        onClose={() => setShowNegotiationModal(false)}
        body={
          <>
            <div className="d-flex flex-column justify-content-evenly">
              <p className="mb-3">
                {t("customers.demandsCustomers.negotiationModal.description")}
              </p>
              <div className="mb-3">
                <input
                  className="radio-style ms-2"
                  type="radio"
                  id="yesNegotiation"
                  name="negotiation"
                  value="yes"
                  onChange={() => {
                    temp.negotiationInProgress = EXTERNAL_INFO_STATUS.YES;
                  }}
                  defaultChecked={
                    negotiationInProgress === EXTERNAL_INFO_STATUS.YES
                  }
                />
                <label className="ms-2" htmlFor="yesNegotiation">
                  {t("customers.demandsCustomers.negotiationModal.options.yes")}
                </label>
              </div>

              <div className="mb-3">
                <input
                  className="radio-style ms-2"
                  type="radio"
                  id="noNegotiation"
                  name="negotiation"
                  value="no"
                  onChange={() => {
                    temp.negotiationInProgress = EXTERNAL_INFO_STATUS.NO;
                  }}
                  defaultChecked={
                    negotiationInProgress === EXTERNAL_INFO_STATUS.NO
                  }
                />
                <label className="ms-2" htmlFor="noNegotiation">
                  {t("customers.demandsCustomers.negotiationModal.options.no")}
                </label>
              </div>
            </div>
            <div className="d-flex justify-content-evenly mt-4">
              <CustomButton
                color={"secondary"}
                onClick={() => {
                  setShowNegotiationModal(false);
                  setNegotiationInProgress(undefined);
                  patchRealEstateCollaboration(realEstate._id, {
                    negotiationInProgress: null,
                  });
                  alertToast({
                    message: t("customers.demandsCustomers.toast.reset"),
                    type: "success",
                    duration: 2000,
                  });
                }}
              >
                {t("realEstate.buttonsFilters.reset")}
              </CustomButton>
              <CustomButton
                onClick={() => {
                  if (
                    temp.negotiationInProgress === undefined &&
                    negotiationInProgress !== undefined
                  ) {
                    patchRealEstateCollaboration(realEstate._id, {
                      negotiationInProgress,
                    });
                  } else {
                    setNegotiationInProgress(temp.negotiationInProgress);
                    patchRealEstateCollaboration(realEstate._id, {
                      negotiationInProgress: temp.negotiationInProgress,
                    });
                  }
                  setShowNegotiationModal(false);
                  alertToast({
                    message: t("customers.demandsCustomers.toast.saved"),
                    type: "success",
                    duration: 2000,
                  });
                }}
              >
                {t("customers.demandsCustomers.negotiationModal.save")}
              </CustomButton>
            </div>
          </>
        }
      />
    </>
  );
};

export default CardRealEstates;
