import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import getClasses from "../../../Utils/getClasses";
import "./CustomDrawer.scss";

interface ICustomDrawer {
  isOpen: boolean | null;
  zIndex?: number;
  removeBodyScroll?: boolean;
}

const CustomDrawer: FC<ICustomDrawer> = ({
  children,
  isOpen,
  zIndex = 100,
  removeBodyScroll = true,
}) => {
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean | null>(isOpen);
  const timeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    removeBodyScroll &&
      (document.body.style.overflow = isOpen ? "hidden" : "auto");

    if (isOpen) {
      setIsOpenDrawer(true);
    } else if (isOpen === false) {
      timeout.current = setTimeout(() => {
        setIsOpenDrawer(false);
      }, 400);
    }
    return () => {
      timeout.current && clearTimeout(timeout.current);
    };
    // eslint-disable-next-line
  }, [isOpen]);

  const drawerMemo = useMemo(
    () => (
      <>
        {isOpenDrawer && (
          <>
            <div
              style={{ zIndex }}
              className={getClasses({
                drawer: true,
                "is-open": isOpenDrawer,
                "is-closed": !isOpen,
              })}
            >
              {children}
            </div>
          </>
        )}
      </>
    ),
    // eslint-disable-next-line
    [isOpenDrawer, isOpen, children]
  );

  return <>{drawerMemo}</>;
};

export default CustomDrawer;
