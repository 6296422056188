import { FixedArray, NestedKeys } from "advanced-types";
import axios from "axios";
import dayjs from "dayjs";
import { Translation } from "../assets/i18n/locals/en";
import { SelectOptions } from "../forms/realeEstate/selections";
import { RealEstateCommercial } from "../models/RealeEstateCommercial";
import { RealEstate, REAL_ESTATE_TYPE } from "../models/RealEstate";
import { getStorageItem } from "./localStorage";
import { RealEstateLand } from "../models/RealEstateLand";

export const ObjectToClassString = (obj: Record<string, any>) =>
  Object.entries(obj)
    .filter(([key, value]) => Boolean(value))
    .map(([key, value]) => key)
    .join(" ");

export const getRandomByRange = (min: number, max: number) =>
  Math.random() * (max - min) + min;

export const filterByTerm = (
  obj: Record<string, string | number>,
  term: string
) =>
  Object.values(obj).some((value) =>
    String(value).toLowerCase().includes(term)
  );

export const isEmailValid = (email: string) =>
  email.match(
    // eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

export const isTaxIdCodeValid = (taxIdCode: string) => {
  taxIdCode.match(
    /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
  );
};

export const addValidationQuery = (url: string, addQuery: boolean = true) =>
  `${url}${addQuery ? "?validation=true" : ""}`;
export const isValidUrl = (urlString: string) => {
  try {
    new URL(urlString);
    return true;
  } catch (e) {
    return false;
  }
};
export const dimensionsValidator = (dimensions: string) =>
  new RegExp(/^\d{1,}x\d{1,}$/).test(dimensions);

export const getNestedValueFromString = <T = any>(
  key: string,
  form: Record<string, any>
): T => {
  const splittedKey = key.split("/");
  if (splittedKey.length === 1) {
    return form[splittedKey[0]];
  } else {
    return splittedKey.reduce((acc, arr, index, array) => {
      return (acc = index < array.length - 1 ? acc[`${arr}`] : acc);
    }, form)[splittedKey[splittedKey.length - 1]];
  }
};

export const resetFieldObject = (obj: Record<any, any>) => {
  Object.keys(obj).forEach((key) => {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === null) {
        obj[key] = null;
      } else if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        resetFieldObject(obj[key]);
      } else {
        obj[key] = "";
      }
    } else {
    }
  });
};
export const cloneObject = (obj: Record<any, any>) =>
  JSON.parse(JSON.stringify(obj));

export const filterKeyForForm = <K = any>(
  object: Record<any, any>,
  objectWithKeys: Record<any, any>
): K =>
  Object.fromEntries(
    Object.entries(object)
      .filter(([key]) =>
        Object.keys(objectWithKeys).some((keyForm) => key === keyForm)
      )
      .map(([key, value]) => {
        if (value === null) return [key, ""];
        if (value === undefined) return [key, ""];
        key.includes("Date") && (value = dayjs(value).format("YYYY-MM-DD"));

        return [key, value];
      })
  ) as K;

export const euroCurrency = Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
});

export const formatCurrency = (
  currency: string,
  value: number,
  showCurrency: boolean
) => {
  const formatter = Intl.NumberFormat("it-IT", {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
  });
  return showCurrency
    ? formatter.format(value)
    : formatter.format(value).slice(0, -1);
};

export const fillSelection = (
  dotationTypes: SelectOptions<any>,
  notProvidedAdded: boolean = true
) => {
  const options = Object.entries(dotationTypes).map(([key, value]) => ({
    label: key as NestedKeys<Translation, "."> | "",
    value: value as string,
  }));
  notProvidedAdded && options.unshift({ label: "", value: `notProvided` });
  return options;
};

export const getQueryUrl = (
  obj: Record<string, string | number | null | undefined> | undefined
) =>
  obj
    ? `?${Object.entries(obj)
        .filter(([_, value]) => !!value)
        .map(([key, value]) => `${key}=${value}`)
        .join("&")}`
    : "";

export const getValueByCondition = <K extends readonly any[]>(
  elementToCheck: any,
  defaultValue: any,
  conditions: K,
  returns?: FixedArray<any, K["length"]>
) => {
  let value = defaultValue;
  conditions.forEach((element, i) => {
    if (elementToCheck === conditions[i]) {
      value = (returns ? (returns as any)[i] : element) || element;
      return;
    }
  });
  return value;
};

export const isResidential = (
  realEstate: RealEstate | RealEstateCommercial | RealEstateLand
): realEstate is RealEstate => realEstate.type === REAL_ESTATE_TYPE.RESIDENTIAL;
export const isCommercial = (
  realEstate: RealEstate | RealEstateCommercial | RealEstateLand
): realEstate is RealEstateCommercial =>
  realEstate.type === REAL_ESTATE_TYPE.COMMERCIAL;

export const isLand = (
  realEstate: RealEstate | RealEstateCommercial | RealEstateLand
): realEstate is RealEstateLand => realEstate.type === REAL_ESTATE_TYPE.LAND;

export const checkIsValidUrl = async (url: string) => {
  try {
    await axios.get(url);
    return true;
  } catch (e) {
    return false;
  }
};
export const youtubeParser = (url: string) => {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
};
export const getUrlYoutube = async (path: string) => {
  if (!path) return "";
  if (path.includes("kuula")) return path;
  if (!path.includes("www.youtube.com") && (await checkIsValidUrl(path)))
    return "error";
  /*   if (path.includes("www.youtube.com")) {
    return youtubeParser(path)
      ? `https://www.youtube.com/embed/${youtubeParser(path)}`
      : "";
  } else {
    return (await checkIsValidUrl(path)) ? path : "error";
  } */
  return youtubeParser(path)
    ? `https://www.youtube.com/embed/${youtubeParser(path)}`
    : "";
};

export const getRoleUser = () => getStorageItem("me")?.role;

export const revereKeysWithValue = <
  K extends string | number | symbol = any,
  T extends string | number | symbol = any
>(
  obj: Partial<Record<K, T>>
) =>
  Object.fromEntries(Object.entries(obj).map(([key, value]) => [value, key]));

export const setStylesOnElement = (
  styles: Record<string, string | number>,
  element: HTMLElement
) => {
  Object.assign(element.style, styles);
};

export const appendRangeGetAppointment = (params: {
  "range.from"?: string | Date;
  "range.to"?: string | Date;
}) => `?range.from>=${params["range.from"]}&range.to<=${params["range.to"]}`;

export const truncateString = (
  text: string,
  limit: number | undefined = undefined
) =>
  !limit ? text : text.length > limit ? `${text.substring(0, limit)}...` : text;
