import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Form, FormValidationObject } from "../../hooks/useForm";
import { IDemandCustomer, Zone } from "../../models/demandCustomer";
import CustomForm from "../../shared/components/CustomForm/CustomForm";
import "./DemandCustomer.scss";
import {
  createDemandCustomerForm,
  createDemandCustomerFormCommercial,
  initialValueDemandCustomerForm,
} from "../../forms/customers/demandCustomer";
import { ICustomForm } from "../../models/CustomForm";
import { useEndpoints } from "../../hooks/useEndpoints";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { filterKeyForForm } from "../../Utils/fuctions";
import Loader from "../../shared/components/Loader/Loader";
import { Demand } from "../../models/RespDemands";
import { useI18n } from "../../hooks/useI18n";
import { useToastAlert } from "../../shared/components/ToastAlert/ToastAlert";
import { DURATION_TOAST } from "../../Utils/costants";
import { ROOM_PERTINENCE_TYPE } from "../../models/RealEstate";
import { cloneDeep } from "lodash";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
interface IDemandCustomerForm {}

const DemandCustomer: FC<IDemandCustomerForm> = () => {
  const { idDemand } = useParams();
  const [selectedTab, setSelectedTab] = useState("");
  const [initialValueForm, setInitialValueForm] = useState();
  const [initialValueFormCommercial, setInitialValueFormCommercial] =
    useState();
  const { putDemandCustomer, getDemandCustomer } = useEndpoints();
  const [isNew, setIsNew] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { t } = useI18n();

  const { required, enterAtLeastOneZone } = {
    required: t("errorMessagesForm.required"),
    enterAtLeastOneZone: t("errorMessagesForm.enterAtLeastOneZone"),
  };

  const fetchDemandCustomer = async () => {
    try {
      const newReq = searchParams.get("isNew");
      const { data } = await getDemandCustomer(idDemand!);
      setSelectedTab(data.type);
      newReq && setIsNew(true);
      data.missingArchitecturalBarriers = data.missingArchitecturalBarriers
        ? "absent"
        : "present";
      if (newReq) {
        setSelectedTab("residential");
        setInitialValueForm(
          filterKeyForForm(data, initialValueDemandCustomerForm)
        );
        setInitialValueFormCommercial(
          filterKeyForForm(data, initialValueDemandCustomerForm)
        );
      } else if (data.type === "residential") {
        setSelectedTab("residential");
        setInitialValueForm(
          filterKeyForForm(data, initialValueDemandCustomerForm)
        );
      } else {
        setSelectedTab("commercial");
        setInitialValueFormCommercial(
          filterKeyForForm(data, initialValueDemandCustomerForm)
        );
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    fetchDemandCustomer();
    // eslint-disable-next-line
  }, []);

  const generateForm = useCallback(
    (validators: FormValidationObject<Demand> | undefined = undefined) => {
      // @ts-ignore
      return { initialValue: initialValueForm, validators };
    },
    [initialValueForm]
  );
  const generateFormCommercial = useCallback(
    (validators: FormValidationObject<Demand> | undefined = undefined) => {
      // @ts-ignore
      return { initialValue: initialValueFormCommercial, validators };
    },
    [initialValueFormCommercial]
  );
  const toastAlert = useToastAlert();

  const form = useMemo(
    () =>
      generateForm({
        contractType: { required },
        status: { required },
        zones: {
          custom: (value: Zone[]) => (value.length ? "" : enterAtLeastOneZone),
        },
      }),
    // eslint-disable-next-line
    []
  );

  const formCommercial = useMemo(
    () =>
      generateFormCommercial({
        contractType: { required },
        status: { required },
        zones: {
          custom: (value: Zone[]) => (value.length ? "" : enterAtLeastOneZone),
        },
      }),

    []
  );

  const handleSubmit = async (
    submitForm: Partial<Form<IDemandCustomer>>,
    next: ICustomForm["next"]
  ) => {
    const form = cloneDeep(submitForm);
    const value = form.missingArchitecturalBarriers;
    /*  form["missingArchitecturalBarriers"] =
      ((value as unknown) as ROOM_PERTINENCE_TYPE) ===
      ROOM_PERTINENCE_TYPE.ABSENT; */

    try {
      await putDemandCustomer(idDemand!, form);
      toastAlert({
        message: t("agency.successToast.title"),
        subMessage: t("agency.successToast.description"),
        type: "success",
        duration: DURATION_TOAST,
      });
      navigate(-1);
    } catch {
      toastAlert({
        message: t("realEstate.forms.images.toast.error.title"),
        subMessage: t("realEstate.forms.images.toast.error.descrition"),
        type: "error",
        duration: DURATION_TOAST,
      });
    }
  };
  const handleSubmitCommercial = async (
    submitForm: Partial<Form<IDemandCustomer>>
  ) => {
    const form = cloneDeep(submitForm);
    const value = form.missingArchitecturalBarriers;
    form.type = selectedTab;

    try {
      await putDemandCustomer(idDemand!, form);
      toastAlert({
        message: t("agency.successToast.title"),
        subMessage: t("agency.successToast.description"),
        type: "success",
        duration: DURATION_TOAST,
      });
      navigate(-1);
    } catch {
      toastAlert({
        message: t("realEstate.forms.images.toast.error.title"),
        subMessage: t("realEstate.forms.images.toast.error.descrition"),
        type: "error",
        duration: DURATION_TOAST,
      });
    }
  };

  const renderCustomFormResidential = () => (
    // @ts-ignore
    <CustomForm
      submit={handleSubmit}
      form={{ ...form, initialValue: initialValueForm }}
      props={createDemandCustomerForm}
      className="custom-height-request"
    />
  );
  const renderCustomFormCommercial = () => (
    // @ts-ignore
    <CustomForm
      submit={handleSubmitCommercial}
      form={{ ...formCommercial, initialValue: initialValueFormCommercial }}
      props={createDemandCustomerFormCommercial}
      className="custom-height-request"
    />
  );
  return (
    <div className="height-test">
      <Tabs variant="soft-rounded" colorScheme="blue" className="pt-2 ps-1">
        <TabList>
          <Tab
            isSelected={selectedTab === "residential"}
            onClick={() => setSelectedTab("residential")}
            _selected={{
              color: "#3b54d4",
              bg: "#eceffd",
              "&:focus": { boxShadow: "none" },
            }}
            sx={{ color: "#3a3878", "&:focus": { boxShadow: "none" } }}
            className={`${
              isNew ? "" : selectedTab === "residential" ? "" : "d-none"
            }`}
          >
            Residenziale
          </Tab>
          <Tab
            isSelected={selectedTab === "commercial"}
            onClick={() => setSelectedTab("commercial")}
            _selected={{
              color: "#3b54d4",
              bg: "#eceffd",
              "&:focus": { boxShadow: "none" },
            }}
            sx={{ color: "#3a3878", "&:focus": { boxShadow: "none" } }}
            className={`${
              isNew ? "" : selectedTab === "commercial" ? "" : "d-none"
            }`}
          >
            Commerciale
          </Tab>
        </TabList>
      </Tabs>
      {selectedTab === "residential" ? (
        <div>
          {initialValueForm ? renderCustomFormResidential() : <Loader />}
        </div>
      ) : (
        <div>
          {initialValueFormCommercial ? (
            <>
              <div>{renderCustomFormCommercial()}</div>
            </>
          ) : (
            <Loader />
          )}
        </div>
      )}
    </div>
  );
};

export default DemandCustomer;
//
