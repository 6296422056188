import { RefObject, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { RootState } from "../redux/reducers";
import AlertImage from "../assets/img/critical.svg";
import { setRealeEstates } from "../redux/reducers/realEstate";
import { ElementForPage } from "../typings/Pagination";
import { getQueryUrl } from "../Utils/fuctions";
import { useEndpoints } from "./useEndpoints";
import {
  setQueryTableResultsDemandCustomer,
  setResultsDemandCustomer,
} from "../redux/reducers/resultsDemandCustomer";
import { AxiosError } from "axios";
import { NestedKeys } from "advanced-types";
import { Translation } from "../assets/i18n/locals/en";
import { Customer } from "../models/Customer";
import { useToastAlert } from "../shared/components/ToastAlert/ToastAlert";
import { DURATION_TOAST } from "../Utils/costants";
import { useI18n } from "./useI18n";

import {
  RespRealEstatesDemand,
  StatusDemand,
} from "../models/ResultRealEstateDemand";
import debounce from "lodash.debounce";

export type CollaborationFilter = "yes" | "no" | "onHold" | "null";
export type NegotiationFilter = "yes" | "no" | "null";

export const useResultDemandCustomer = (
  containerTableRef: RefObject<HTMLDivElement>,
  id: string
) => {
  const { queryTable } = useSelector(
    ({ resultsDemandCustomer }: RootState) => resultsDemandCustomer
  );
  const [allResultRealEstates, setAllResultRealEstates] =
    useState<RespRealEstatesDemand>();
  const [searchParams] = useSearchParams();
  const [doFirstFetch, setDoFirstFetch] = useState(false);
  const [errorFetch, setErrorFetch] = useState<
    | Partial<
        Record<"title" | "description", NestedKeys<Translation, ".">> & {
          image?: string;
        }
      >
    | undefined
  >();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { getResultsDemandCustomer, getCustomer } = useEndpoints();
  const [pagination, setPagination] = useState<{
    limit: ElementForPage;
    page: number;
  }>({ limit: 10, page: 0 });
  const toastAlert = useToastAlert();
  const { t } = useI18n();

  const { idCustomer } = useParams();
  const [customerDemand, setCustomerDemand] = useState<Customer | null>(null);
  const [statusForFetch, setStatusForFetch] = useState<StatusDemand>("active");
  const [source, setSource] = useState<"internal" | "external">("internal");
  const [typeExternal, setTypeExternal] = useState("all");

  const [agencyCollaborates, setAgencyCollaborates] =
    useState<CollaborationFilter>();

  const setCollaboration = (value: string) => {
    setAgencyCollaborates(value as CollaborationFilter);
  };

  const [negotiationInProgress, setNegotiationInProgress] =
    useState<NegotiationFilter>();

  const setNegotiation = (value: string) => {
    setNegotiationInProgress(value as NegotiationFilter);
  };

  const fetchResultsRealeStates = async () => {
    const { page, limit } = pagination;
    setIsLoading(true);

    try {
      const skip = page === 0 ? 0 : (page - 1) * limit;
      const { data } = await getResultsDemandCustomer(id, {
        limit,
        skip,
        status: statusForFetch,
        source: source,
        isCasafariAuction: typeExternal === "auction" ? "true" : undefined,
        isPrivateProperty:
          source !== "external"
            ? undefined
            : typeExternal === "all"
            ? undefined
            : typeExternal === "private"
            ? "true"
            : "false",

        agencyCollaborates: agencyCollaborates
          ? agencyCollaborates
          : "yes,no,onHold,null",
        negotiationInProgress: negotiationInProgress
          ? negotiationInProgress
          : "yes,no,null",
      });

      setAllResultRealEstates(data);

      if (queryTable?.id) {
        const index: number = data.docs.findIndex(
          ({ realEstate }) => realEstate._id === queryTable.id
        );
        if (index >= 0 && containerTableRef.current) {
          containerTableRef.current?.children[index].scrollIntoView({
            block: "center",
            inline: "nearest",
          });
          setReduxStateQueryTable("", true);
        }
      } else {
        containerTableRef.current?.scrollTo(0, 0);
      }
    } catch (error) {
      console.error(error);
      const errorRequest = error as AxiosError<{
        message: string;
        error: string[];
      }>;
      setErrorFetch(
        errorRequest.response?.data.error.includes(
          "At least one zone must be specified"
        )
          ? {
              image: AlertImage,
              description:
                "consultants.emptyResourcesTable.emptyZone.description",
              title: "consultants.emptyResourcesTable.emptyZone.title",
            }
          : {
              image: undefined,
              description: "errorsToast.unexpectedError.title",
              title: "errorsToast.unexpectedError.description",
            }
      );
    } finally {
      setIsLoading(false);
    }
  };
  const debouncedFetchResultsRealeStates = useMemo(
    () => debounce(fetchResultsRealeStates, 500),
    [
      pagination,
      statusForFetch,
      source,
      typeExternal,
      agencyCollaborates,
      negotiationInProgress,
    ]
  );

  const reFetch = () => {
    debouncedFetchResultsRealeStates();
  };

  const fetchCustomer = async (query: string) => {
    try {
      const { data } = await getCustomer(query);
      setCustomerDemand(data);
    } catch (error) {
      toastAlert({
        message: t("errorsToast.customerNotFound.title"),
        subMessage: t("errorsToast.customerNotFound.description"),
        type: "error",
        duration: DURATION_TOAST,
      });
      console.error(error);
    }
  };

  const resultRealEstates = useMemo(() => {
    return allResultRealEstates?.docs || undefined;
  }, [allResultRealEstates]);

  const changePage = (page: number) => {
    setPagination({ ...pagination, page });
  };

  const setLimit = (limit: ElementForPage) => {
    setPagination({ limit, page: 0 });
  };

  const setReduxStateQueryTable = (id: string, reset: boolean = false) => {
    const { page, limit } = pagination;
    const skip = page === 0 ? 0 : (page - 1) * limit;
    dispatch(
      setQueryTableResultsDemandCustomer(
        reset
          ? undefined
          : {
              params: {
                limit,
                skip,
                page,
              },
              id,
            }
      )
    );
  };

  useEffect(() => {
    doFirstFetch && debouncedFetchResultsRealeStates();
    return () => {
      debouncedFetchResultsRealeStates.cancel();
    };
    // eslint-disable-next-line
  }, [
    pagination,
    doFirstFetch,
    statusForFetch,
    source,
    typeExternal,
    agencyCollaborates,
    negotiationInProgress,
  ]);
  useEffect(() => {
    const { page, limit } = pagination;
    const skip = page === 0 ? 0 : (page - 1) * limit;
    const newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      getQueryUrl({
        page,
        limit,
        skip,
      }) +
      `&status=${statusForFetch}&source=${source}&typeIfExt=${typeExternal}&agencyCollaborates=${agencyCollaborates}&negotiationInProgress=${negotiationInProgress}`;
    //window.history.replaceState({ path: newurl }, "", newurl);
    window.history.pushState({ path: newurl }, "", newurl);
  }, [
    pagination,
    statusForFetch,
    source,
    typeExternal,
    agencyCollaborates,
    negotiationInProgress,
  ]);

  useEffect(() => {
    const limitQuery = searchParams.get("limit");
    const pageQuery = searchParams.get("page");
    idCustomer && fetchCustomer(idCustomer);
    setPagination({
      page: Number(pageQuery) ?? 0,
      limit: (Number(limitQuery) as ElementForPage) || 10,
    });
    setTimeout(() => {
      setDoFirstFetch(true);
    }, 500);
    return () => {
      dispatch(setRealeEstates(undefined));
    };
    // eslint-disable-next-line
  }, []);

  return {
    fetchResultsRealeStates,
    setLimit,
    limit: pagination.limit,
    page: pagination.page,
    doFirstFetch,
    errorFetch,
    resultRealEstates,
    //queryTable,
    setReduxStateQueryTable,
    changePage,
    allResultRealEstates,
    isLoading,
    customerDemand,
    setStatusForFetch,
    reFetch,
    setSource,
    setTypeExternal,
    setCollaboration,
    setNegotiation,
    setPagination,
  };
};

export default useResultDemandCustomer;
