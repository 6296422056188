import React, { FC } from "react";
import { Form, FormReturnType } from "../../../../hooks/useForm";
import { useI18n } from "../../../../hooks/useI18n";
import { Field } from "../../../../models/CustomForm";
import { getNestedValueFromString } from "../../../../Utils/fuctions";
import CustomDatePicker from "../../CustomDatePicker/CustomDatePicker";
import dayjs from "dayjs";
import "./DatePicker.scss";

interface IDatePicker {
  form: Partial<Form<any>>;
  setForm: any;
  data: Field;
  handleError: FormReturnType<any>["handleError"];
  error: string | undefined;
}

const DatePicker: FC<IDatePicker> = ({
  handleError,
  data: {
    column = 4,
    offset = 0,
    isRequired,
    label,
    value,
    key,
    showIf = true,
    showIfKey,
    maxColumn,
  },
  form,
  setForm,
  error,
}) => {
  const getDate = () => {
    if (!getNestedValueFromString(key, form)) return null;
    if (!dayjs(getNestedValueFromString(key, form)).isValid()) return null;
    return dayjs(getNestedValueFromString(key, form)).toDate();
  };

  const handleChangeDate = (date: any) => {
    setForm(key, date ? dayjs(date).format("YYYY-MM-DD") : "");
  };

  const { t } = useI18n();

  return (
    <>
      <div className={`col-lg-${column}`}>
        <div
          className={`form-group offset-lg-${offset} ${
            error ? "is-invalid" : ""
          }`}
        >
          <label
            className={`form-control-label capitalize-first-letter ${
              isRequired ? "title-red" : ""
            } `}
            htmlFor="name"
          >
            {`${t(label)} ${isRequired ? "*" : ""}`}
          </label>
          {/* <input
                onChange={({ target: { value } }) => handleChangeDate(value)}
                onFocus={() => handleError(key, "", false)}
                type={"date"}
                value={getNestedValueFromString(key, form) === null ? "" : dayjs(getNestedValueFromString(key, form)).format("YYYY-MM-DD")}
                className="form-control p-md date-picker"
                placeholder="form.name.placeholder"
              /> */}
          <div className="container-custom-datepicker-form">
            <CustomDatePicker
              classNameInput="form-control h-100"
              onFocusInput={() => {
                handleError(key, "", false);
              }}
              date={getDate()}
              onChange={(date) => handleChangeDate(date)}
            ></CustomDatePicker>
          </div>
          {error && (
            <div className="invalid-feedback">
              <div>{error}</div>
            </div>
          )}
        </div>
      </div>
      {maxColumn && <div className={`col-lg-${maxColumn}`} />}
    </>
  );
};
export default DatePicker;
