import {
  AIR_CONDITIONING_TYPE,
  COMMERCIAL_INFO_ENTITY,
  ELECTRICAL_SYSTEM_STATUS,
  RESIDENTIAL_INFO_ENTITY,
  RealEstate,
  TV_STATUS,
} from "./RealEstate";
export enum LAND_PROPERTY_TYPE {
  NON_BUILDING_LAND = "nonBuildingLand", // Terreno non edificabile
  BUILDING_LAND = "buildingLand", // Terreno edificabile
  AGRICULTURAL_LAND = "agriculturalLand", // Terreno agricolo
  COMMERCIAL_LAND = "commercialLand", // Terreno commerciale
  NOT_PROVIDED = "notProvided",
}
export interface LandAdditionalDetails {
  easementOfPassage?: string; // Servitù di passaggio
  preEmptionRights?: string; // Diritti di prelazione
  plantations?: string; // Piantagioni
}
export type RealEstateLand = RealEstate &
  LandRealEstateI & {
    additionalDetails: LandAdditionalDetails;
  };
export interface LandRealEstateI {
  buildabilityIndex?: number; // Indice edificabilità
  buildableSquareMeters?: number; // m2 edificabili
  agriculturalSquareMeters?: number; // m2 agricoli
  constructionPresent?: boolean; // Costruzione presente
  possibleProduction?: string; // Possibile realizzazione
  approvedProject?: boolean; // Progetto approvato
  landKind?: LAND_KIND; // Tipologia
}

export enum LAND_KIND {
  FLAT = "flat", // Pianeggiante
  STEEP = "steep", // scosceso
  NOT_PROVIDED = "notProvided",
}
export interface EntityInformation {
  entity: RESIDENTIAL_INFO_ENTITY | COMMERCIAL_INFO_ENTITY | LAND_INFO_ENTITY;
  status?: TV_STATUS | ELECTRICAL_SYSTEM_STATUS | AIR_CONDITIONING_TYPE;
  squareMeters?: number;
}

export enum LAND_INFO_ENTITY {
  WATER_PIPES = "waterPipes", // Tubature acqua
  FACING_STREET = "facingStreet", // Fronte strada
  AT_AUCTION = "atAuction", // all'asta
  PHONE_LINES = "phoneLines", // Linee telefoniche
  DIVISIBLE_LOT = "divisibleLot", // Lotto frazionabile
  METHANE = "methane",
  LAKE_VIEW = "lakeView",
  SEA_VIEW = "seaView",
}
